import * as yup from 'yup';

import WAPurchaseOrderInput from 'components/molecules/WAPurchaseOrderInput';

import { waUploadFileWithStatus } from 'actions/wabriefings/waComms';

import constants from 'constants/config';
import { transformWAPayloadBySchedule } from 'selectors';

const waPurchaseOrderInfoSchema = (
  dispatch,
  {
    defaultValues,
    pathUid,
    closeModal,
    setToggle,
    waType,
    authorizedUserData,
    closePoModelClick
  }
) => {
  return {
    formType: 'modal',
    submitBtnLabel: 'Send purchase order',
    btnClassName: 'd-flex justify-content-end mt-20',
    modelBtnSwap: true,
    fields: [
      {
        type: 'custom',
        name: 'sharePurchaseOrder',
        component: WAPurchaseOrderInput,
        id: 'sharePurchaseOrder',
        label: 'What do you want to do',
        emailType: waType,
        rules: yup.object({
          poNumber: yup
            .number()
            .positive('Purchase order number must be greater than 0')
            .required('Please enter your purchase order (PO) number')
            .typeError('Please enter your purchase order (PO) number'),
          poFile: yup
            .mixed()
            .required('Please upload a file')
            .typeError('Please upload a file'),
          additionalcomments: yup.string()
        })
      }
    ],
    onSubmit: (data, { user }) => {
      let dataToSend = {};
      const paramUid = pathUid;
      if (data && data?.sharePurchaseOrder) {
        const formData = new FormData();
        const fileData = data?.sharePurchaseOrder?.poFile;
        formData.append('file', fileData);
        formData.append('userEmail', user.emailId);
        formData.append('userName', user.name);
        formData.append('status', constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP);
        formData.append('purchaseOrderFlow', true); // for identify file
        formData.append(
          'sharePurchaseOrder',
          JSON.stringify(data.sharePurchaseOrder)
        );
        formData.append('uid', paramUid);
        formData.append('role', authorizedUserData.group);
        const transformedData = transformWAPayloadBySchedule(dataToSend);
        dispatch(
          waUploadFileWithStatus(
            formData,
            { ...transformedData, uid: paramUid },
            (error, result) => {
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                closeModal(true);
              }
            },
            { disableLoader: false }
          )
        );
      }
    },
    onPrevious: () => {
      setToggle(false);
      closePoModelClick(false);
    },
    previousBtnLabel: 'Cancel',
    disableFormError: true
  };
};

export default waPurchaseOrderInfoSchema;
