import React, { Component } from 'react';
import CardBasic from '../../components/molecules/CardBasic';
import RequestComponent from '../../components/CWRequestComponent';
import Loader from '../../components/Loader';
import constant from '../../constants';
const Home = props => {
  const [emailAccessError, setEmailAccessError] = React.useState(false);
  const [waAccessError, setWaAccessError] = React.useState(false);
  const isAccessAllowed = constant.featureFlags.ENABLE_EMAIL_PRIVILEGED_ACCESS
    ? constant.EMAIL_PRIVILEGED_ACCESS.includes(props.userEmail)
    : constant.featureFlags.ENABLE_WA_PRIVILEGED_ACCESS
    ? constant.WA_PRIVILEGED_ACCESS.includes(props.userEmail)
    : true;;
  
  
  const sections = [
    {
      type: 'form',
      icon: 'icon-form',
      title: 'Form Request',
      description:
        'Capture zero party data at scale and create value exchange opportunities through online sign-up touchpoints.',
      ctaPrimaryText: 'Create form',
      ctaPrimaryUrl: '/campaign-wizard/newcampaign',
      ctaSecondaryText: 'View dashboard',
      ctaSecondaryUrl: '/campaign-wizard/dashboard',
      ctaError: ''
    },
    {
      type: 'sms',
      icon: 'icon-sms',
      title: 'SMS briefs',
      description:
        'Leverage consumer data to drive brand performance and improve relevancy through SMS communications',
      ctaPrimaryText: 'Create SMS',
      ctaPrimaryUrl: '/campaign-wizard/newcommunications',
      ctaSecondaryText: 'View dashboard',
      ctaSecondaryUrl: '/campaign-wizard/smsdashboard',
      ctaError: ''
    },
    {
      type: 'email',
      icon: 'icon-email',
      title: 'Email briefs',
      description:
        'Nurture personalized relationships to grow brand affinity, engagement and conversion via emails',
      ctaPrimaryText: 'Create email',
      ctaPrimaryUrl: '/campaign-wizard/new-email-campaign',
      ctaSecondaryText: 'View dashboard',
      ctaSecondaryUrl: '/campaign-wizard/email-dashboard',
      ctaError: 'Emails briefing will be available in a few days'
    },
    {
      type: 'wa',
      icon: 'icon-sms',
      title: 'WhatsApp briefs',
      description:
        'Leverage consumer data to drive brand performance and improve relevancy through Whatsapp communications',
      ctaPrimaryText: 'Create Wa',
      ctaPrimaryUrl: `${constant.WA_BASE_ROUTE}/${constant.WA_ROUTES.NEW_CAMPAIGN}`,
      ctaSecondaryText: 'View dashboard',
      ctaSecondaryUrl: `${constant.WA_BASE_ROUTE}/${constant.WA_ROUTES.DASHBOARD}`,
      ctaError: 'Sorry, you do not have access to this page'
    },
  ];
  const callbackEmail = (evt, item) => {
    evt.preventDefault();
    setEmailAccessError(true);
  };
  const callbackWa = (evt, item) => {
    evt.preventDefault();
    setWaAccessError(true);
  };
  return (
    <div className="cw--home skypink">
      <div className="row">
        {sections.map((item, i) => {
          if (
            item.type === 'email' &&
            !constant.featureFlags.ENABLE_CAMPAIGN_EMAIL
          ) {
            return null;
          } else if(item.type === 'wa'  && !constant.featureFlags.ENABLE_WA_CAMPAIGN){
            return null;
          } else {
            return (
              <div className={constant.featureFlags.ENABLE_WA_CAMPAIGN ? `col-lg-3` : `col-lg-4`} key={`campaign-${i}`}>
                <CardBasic
                  type={item.type}
                  cardClass={'home-card'}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  ctaPrimaryText={item.ctaPrimaryText}
                  ctaSecondaryText={item.ctaSecondaryText}
                  btnBlock={true}
                  tag={item.tag}
                  tagClass={item.tagClass}
                  ctaPrimaryUrl={item.ctaPrimaryUrl}
                  ctaSecondaryUrl={item.ctaSecondaryUrl}
                  callbackFunc={callbackEmail}
                  showError={emailAccessError}
                  ctaError={item.ctaError}
                  privileged={isAccessAllowed}
                  privilegedOnlyAccess={
                    constant.featureFlags.ENABLE_EMAIL_PRIVILEGED_ACCESS
                  }
                  callbackWaFunc={callbackWa}
                  privilegedOnlyWaAccess = {
                    constant.featureFlags.ENABLE_WA_PRIVILEGED_ACCESS
                  }
                  waAccessError={waAccessError}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Home;
