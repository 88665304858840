import React, { forwardRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { FormReactSelect } from '../FormReactSelect';
import { TextArea } from 'components/atoms/TextArea';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { WAButtons } from '../FormFieldArray/WAButtons';
import GenerateImagePathModal from '../WaToggleInput/GenerateImagePathModal';
import GenerateImageInput from '../WaToggleInput/GenerateImageInput';
const MAX_CAROUSAL_CARDS = 10;
const MAX_CAROUSAL_BUTTONS = 2;
const options = [
  { label: 'Single message card', value: 'single' },
  { label: 'Carousal message - multiple cards', value: 'carousel' }
];

const WaToggleHeaderTextField = ({
  control,
  watchInput,
  errors,
  name,
  register,
  dateFormat,
  defaultValue,
  setValue,
  abTesting,
  defaultWatchValue
}) => {
  let callBackFn = null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setTabImageUrl] = useState();
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  const handleSubmitImagePath = (url, frm) => {
    setIsModalOpen(false);
    setTabImageUrl(url);
  };

  return triggerType === 'headerImage' ? (
    <>
      <Controller
        name={`${name}.headerImage`}
        control={control}
        defaultValue={get(defaultValue, `headerImage`)}
        render={({ field }) => (
          <GenerateImageInput
            {...field}
            errors={errors}
            defaultValue={defaultValue}
            setValue={setValue}
            fieldPrefix={`${name}`}
          />
        )}
      />
    </>
  ) : triggerType === 'headerVideo' ? (
    <>
      <p>headerVideo show</p>
    </>
  ) : triggerType === 'headerDocument' ? (
    <>
      <p>headerDocument</p>
    </>
  ) : null;
};

const WaToggleRadioInput = forwardRef(
  ({ name, value, onChange, canRemove, removeCard }, ref) => {
    const handleOnChange = e => {
      onChange(e.target.value);
    };
    return (
      <div className="themes-container d-flex flex-wrap">
        <label
          htmlFor="headerImage"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerImage'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerImage"
              value="headerImage"
              type="radio"
              checked={value === 'headerImage'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="image outline" size="large" />
              <p className="theme-label text-size-small">{'Image'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerVideo"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerVideo'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerVideo"
              value="headerVideo"
              type="radio"
              checked={value === 'headerVideo'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="file video outline" size="large" />
              <p className="theme-label text-size-small">{'Video'}</p>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

const CarouselCard = ({
  name,
  key,
  item,
  index,
  control,
  errors,
  register,
  setValue,
  dateFormat,
  defaultValue,
  canRemove,
  removeCard
}) => {
  return (
    <div className="nt-10 mb-10" key={key}>
      <div className="d-flex mb-10">
        {/* flex-justify-between */}
        <label>
          <strong>{`Carousal card ${index + 1}`}</strong>
        </label>
        {canRemove && (
          <span
            className={'saas-accordion-close'}
            onClick={() => removeCard(index)}
          ></span>
        )}
      </div>

      {/* Card Header */}
      <label htmlFor={`Card Header ${index + 1}`}>{`Card Header`}</label>
      <Controller
        name={`${name}.carouselCards.${index}.cardHeader.type`}
        control={control}
        // defaultValue={get(defaultValue, `${name}.type`)}
        defaultValue={get(item, 'cardHeader.type', 'headerImage')}
        render={({ field }) => <WaToggleRadioInput {...field} />}
      />
      <WaToggleHeaderTextField
        control={control}
        watchInput={`${name}.carouselCards.${index}.cardHeader.type`}
        defaultWatchValue={get(item, `cardHeader.type`, 'headerImage')}
        errors={errors}
        register={register}
        name={`${name}.carouselCards.${index}.cardHeader`}
        dateFormat={dateFormat}
        defaultValue={get(item, `cardHeader`, { type: 'headerImage' })}
        setValue={setValue}
        abTesting={false}
      />

      {/* Card Body Text */}
      <label htmlFor={`Card body text ${index + 1}`}>{`Card body text`}</label>
      <Controller
        name={`${name}.carouselCards.${index}.cardBodyText`}
        control={control}
        rules={{ maxLength: 160 }}
        defaultValue={get(item, `cardBodyText`, '')}
        render={({ field }) => (
          <TextArea
            {...field}
            id={`cardBodyText-${index}`}
            maxLength="160"
            hasError={
              get(errors, `${name}.carouselCards.${index}.cardBodyText`)
                ? true
                : false
            }
            placeholder="Enter card body text"
          />
        )}
      />
      <FieldError
        errors={errors}
        name={`${name}.carouselCards.${index}.cardBodyText`}
      />
      <div className="mt-10">
        <Controller
          name={`${name}.carouselCards.${index}.buttons`}
          control={control}
          render={({ field }) => (
            <WAButtons
              {...field}
              name={`${name}.carouselCards.${index}.buttons`}
              type={'custom'}
              label="Buttons"
              subLabel="Atleast one button is mandatory"
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              showCommonErrors ={true}
              defaultValue={get(item, `buttons`, [
                {
                  type: {
                    value: '',
                    label: ''
                  },
                  buttonName: '',
                  buttonValue: ''
                }
              ])}
              maxRow={MAX_CAROUSAL_BUTTONS}
              isMulti={true}
            />
          )}
        />
      </div>
      {/* <FieldError
        errors={errors}
        name={`${name}.carouselCards.${index}.buttons`}
      /> */}
    </div>
  );
};

export const WACarouselMessageInput = ({
  name,
  rules,
  register,
  label,
  subLabel,
  errors,
  setValue,
  control,
  defaultValue,
  defaultWatchValue,
  waType,
  abTesting,
  dateFormat,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const messageType = useWatch({
    control,
    name: `${name}.messageType`,
    defaultValue: get(defaultValue, `${name}.messageType`, {
      label: 'Single message card',
      value: 'single'
    }) // Set 'Single message card' as the default selected value
  });

  const carouselCardsDataWatch = useWatch({
    control,
    name: `${name}.carouselCards`,
    defaultValue: []
  });

  const carouselCards = get(defaultValue, `${name}.carouselCards`);
  let [carouselFields, setCarouselFields] = useState(
    carouselCards && carouselCards.length
      ? carouselCards
      : [
          {
            cardHeader: { type: 'headerImage' },
            cardBodyText: '',
            buttons: [
              {
                type: {
                  value: '',
                  label: ''
                },
                buttonName: '',
                buttonValue: ''
              }
            ]
          },
          {
            cardHeader: { type: 'headerImage' },
            cardBodyText: '',
            buttons: [
              {
                type: {
                  value: '',
                  label: ''
                },
                buttonName: '',
                buttonValue: ''
              }
            ]
          }
        ]
  );

  const addCard = () => {
    if (carouselFields.length < 10) {
      let newFieldData = [...carouselCardsDataWatch];
      newFieldData.push({
        cardHeader: { type: 'headerImage' },
        cardBodyText: '',
        buttons: [
          {
            type: {
              value: '',
              label: ''
            },
            buttonName: '',
            buttonValue: ''
          }
        ]
      });
      setCarouselFields(newFieldData);
    }
  };

  const remove = index => {
    let updatedFields = [...carouselCardsDataWatch];
    updatedFields.splice(index, 1);
    setCarouselFields(updatedFields);
    setValue(`${name}.carouselCards`, updatedFields);
  };

  return (
    <div className="form-group email-secondary-theme" aria-live="polite">
      <Controller
        control={control}
        name={`${name}.messageType`}
        defaultValue={get(defaultValue, `${name}.messageType`, {
          label: 'Single message card',
          value: 'single'
        })}
        render={({ field }) => (
          <FormReactSelect
            name={`${name}.messageType`}
            id="messageType"
            label={label}
            placeholder="Choose a message type"
            control={control}
            errors={errors}
            options={options}
            setValue={setValue}
            extraOptions={[]}
            showExtraOptions={false}
            hasNestedError={true}
            {...field}
          />
        )}
      />

      {/* Carousel Message Configuration */}
      {messageType && messageType.value === 'carousel' && (
        <div>
          <label htmlFor={props.id}>
            <strong>{`Configure your carousal message`}</strong>
          </label>
          {/* Main Message Bubble Text */}
          <div className="form-group mt-1">
            <label htmlFor="mainMessageBubbleText">
              Main message bubble text
            </label>
            <Controller
              name={`${name}.mainMessageBubbleText`}
              control={control}
              rules={{ maxLength: 1024 }}
              defaultValue={get(
                defaultValue,
                `${name}.mainMessageBubbleText`,
                ''
              )}
              render={({ field }) => (
                <TextArea
                  {...field}
                  id={`${name}.mainMessageBubbleText`}
                  maxLength="1024"
                  hasError={
                    get(errors, `${name}.mainMessageBubbleText`) ? true : false
                  }
                  placeholder="Please enter main message bubble text"
                />
              )}
            />
            <FieldError
              errors={errors}
              name={`${name}.mainMessageBubbleText`}
            />
          </div>
          {carouselFields.map((item, index) => (
            <Controller
              name={`carouselCards.${index}`}
              control={control}
              render={({ field }) => (
                <CarouselCard
                  name={name}
                  key={item.id}
                  item={item}
                  index={index}
                  control={control}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  dateFormat={dateFormat}
                  removeCard={remove}
                  canRemove={carouselFields.length > 2}
                  defaultValue={defaultValue}
                />
              )}
            />
          ))}
          {carouselFields.length < MAX_CAROUSAL_CARDS && (
            <button
              type="button"
              className="btn btn-primary mt-20"
              onClick={addCard}
            >
              Add carousal card
            </button>
          )}
        </div>
      )}
    </div>
  );
};
