import React from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

export default function WaTriggerTypeInfoModal({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--questions"
      contentLabel="Field Types"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>
      <h3 className="cw-heading--primary mb-10">WhatsApp trigger</h3>
      <div className="col-sm-12">
        <div className="row cw-bgpattern-primary">
          <div className="col-sm-4">
            <strong>Fixed date & time</strong>
          </div>
          <div className="col-sm-8">
            <span>
              These are WhatsApp that are meant to be sent on a known fixed date
              and time rather than a dynamic trigger condition
            </span>
          </div>
        </div>
        <div className="row cw-bgpattern-primary">
          <div className="col-sm-4">
            <strong>Dynamic trigger</strong>
          </div>
          <div className="col-sm-8">
            <span>
              These are WhatsApp that are sent upon a trigger that could be a
              user action (e.g. a user signs up and receives a welcome
              WhatsApp), or any other dynamic condition (e.g. send an WhatsApp
              on a daily basis to all consumers who have their birthdays each
              day)
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
