import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import classNames from 'classnames';
import CampaignCostRequestInfoSchema from './waCostRequestInfoSchema';

export const CustomDynamicButtons = ({
  isSubmitting,
  onPrevious,
  previousBtnLabel,
  submitBtnLabel,
  customButtonWatch,
  control
}) => {
  const watchedField = useWatch({
    control,
    name: customButtonWatch
  });
  const isOptionSelected = watchedField && Object.keys(watchedField).length > 0;
  const selectedOption = watchedField && watchedField.value
  return (
    <div className="d-flex justify-content-end mt-20 mb-10">
      <button
        className={classNames('btn btn btn-outline-secondary')}
        type="button"
        onClick={onPrevious}
        disabled={isSubmitting}
      >
        {previousBtnLabel || 'Cancel'}
      </button>
      {isOptionSelected && (<button className="btn btn-primary ml-3" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Loading....' : selectedOption === "requestMoreInfo"? 'Submit':  submitBtnLabel || 'Submit'}
      </button>)}
    </div>
  );
};

const WACostAndMoreRequestInfo = ({
  pathUid,
  closeModal,
  setToggle,
  waDetails,
  toggle
}) => {
  const { isLoading, uid, deliverySchedule, status, waType,requestInfoText } = waDetails;
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal,
    setToggle,
    defaultValues: deliverySchedule || {},
    waType,
    status,
    requestInfoText,
    toggle,
    authorizedUserData
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={CampaignCostRequestInfoSchema(dispatch, compProps)}
        user={authorizedUserData}
        customButtonsComp={CustomDynamicButtons}
      />
    </div>
  );
};

export default WACostAndMoreRequestInfo;
