import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import Modal from 'react-modal';

import { FormBuilder } from 'components/organisms/FormBuilder';
import ProgressBar from 'components/molecules/ProgressBar';
import Loader from 'components/Loader';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import EmailCustomAudienceGuildline from 'pages/CommunicationAudience/AudienceGuidelinesModal';

import formSchema from './WACampaignAudienceSchema';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';

import constants from 'constants/config';

const transformData = data => {
  if (isEmpty(data)) return {};

  const clonedData = { ...data };
  const sendWAToType = get(clonedData, 'sendWATo.type.value');
  if (sendWAToType && sendWAToType === 'consumersLinkedToCampaignIds') {
    const campaignIds = get(clonedData, 'sendWATo.details.campaignIds');

    clonedData.sendWATo.details.campaignIds = campaignIds.map(
      (campaign, index) => {
        if (typeof campaign.value === 'string') return campaign;
        return { id: `field_${index + 1}`, value: campaign };
      }
    );
  }
  return clonedData;
};

const WACampaignAudience = props => {
  const pathUid = props.match.params.id;
  const dispatch = useDispatch();

  const [isEdit, setEdit] = useState(false);
  const [isCustomAudienceModel, setIsCustomAudienceModel] = useState(false);

  const { waCommsData, isLoading } = useSelector(state => state.waBriefings);
  const campaignConfig = useSelector(state => state.app.campaignConfig);
  const { authorizedUserData } = useSelector(state => state.authorized);

  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      dispatch(getWAcommsByUid(pathUid));
    }
  }, []);

  if (!pathUid) {
    return (
      <Redirect
        to={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_CAMPAIGN}`}
      />
    );
  }

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: transformData(waCommsData.audienceDefinition) || {},
    campaignConfig,
    waCommsData,
    openCustomAudienceModal: () => setIsCustomAudienceModel(true)
  };

  const isWADataEmpty = isEmpty(waCommsData);

  return (
    <div className="col-sm-12 skypink email-campaign-brand-selection">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.AUDIENCE}
          totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            WhatsApp audience
          </h2>
        </div>

        <div className="row">
          {isLoading ? <Loader /> : null}

          <div className="col-md-8">
            {!isLoading && !isWADataEmpty && (
              <FormBuilder
                schema={formSchema(dispatch, compProps)}
                user={authorizedUserData}
                isEdit={isEdit}
                customButtonsComp={CustomButtons}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        scrollable={true}
        isOpen={isCustomAudienceModel}
        onRequestClose={() => setIsCustomAudienceModel(false)}
        className="cw-modal cw-modal--audience-guildline"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailCustomAudienceGuildline
          closeIsEditModal={() => setIsCustomAudienceModel(false)}
          data={constants.WA_CUSTOM_AUDIENCE_GUIDELINE}
          isEmailCampaign={false}
          title={'Steps to create custom audience'}
        />
      </Modal>
    </div>
  );
};

export default WACampaignAudience;
