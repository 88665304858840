import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Loader from '../Loader';
import Pagination from '../Pagination';
import { getDateRange, textCapitalization } from '../../selectors/index';
import {
  fetchSmsBrandDetails,
  fetchSmsBrandsForCountries,
  fetchAllSmsBrands,
  resetSmsBrandDetails
} from '../../actions/communications/communicationBrandDetails.js';
import {
  getBrandCountries,
  getBrands,
  getWebsiteTypes
} from '../../actions/dashboard';
import { resetSmsCampaignDetails } from 'actions/communications/communicationCampaignDetails';
import { fetchSMSCampaignsList } from '../../actions/communications/communicationDashboard';
import constant from '../../constants';
import CheckboxDropDown from '../CheckboxDropDown';
import { sortArray } from '../../selectors';
import BreadCrumb from '../BreadCrumb';
import CommunicationDashboardRow from './CommunicationDashboardRow';
import LastUpdatedFilter from 'components/organisms/Filters/LastUpdatedFilter/LastUpdatedFilter';
import NotificationMessage from 'components/Messages';
import { lastUpdatedData } from '../../components/DashboardData';
import BrandFilter from 'components/organisms/Filters/BrandFilter/BrandFilter';
import CountryFilter from 'components/organisms/Filters/CountryFilter/CountryFilter';
import StatusFilter from 'components/organisms/Filters/StatusFilter/StatusFilter';
import SmsAdvanceSearch from 'components/SmsAdvanceSearch';
import CheckBoxInput from 'components/molecules/CheckBoxInput';
import DeleteIcon from '../../images/times.png';
import { SimpleSelect } from 'components/atoms/SimpleSelect';
class CommunicationDashboard extends Component {
  state = {
    sortClass: 'cw-sort cw-sort--asc',
    brandcountry: [],
    brands: [],
    smsType: [],
    status: [],
    country: '',
    smsWithIncentives: [],
    smsContentTheme: [],
    smsContentSecondaryTheme: [],
    isOpen: false,
    value: undefined,
    lastUpdated: '',
    showFilters: false,
    showMyCampaigns: false,
    sortDropdownValue : {
      label: 'Updated date - most recent',
      value: 'lastUpdatedDate-desc'
    }
  };
  showAdvanceSearch = false;

  filtersCnt = 0;
  radioDropDownOptions = constant.SMS_CAMPAIGN_STATUS;
  brands = [];

  optionRef = React.createRef();
  listfilter = React.createRef();

  roleForFilter = constant.dashboardRoleFilter;
  roleBasedStatus = constant.roleBasedStatus;

  constructor(props) {
    super(props);
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this.sortDashboard = this.sortDashboard.bind(this);
    this._toggleFilters = this._toggleFilters.bind(this);
  }

  componentDidMount() {
    this.props.fetchSmsBrandDetails();
    //this.props.fetchAllSmsBrands();
    this.props.getBrandCountries();
    this.props.getBrands();
    let { limit, pageNumber } = this.props;
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';
    this.country = [];
    this._setDefaultLastUpdatedFilter();
    // this.props.fetchSMSCampaignsList(pageNumber, limit);
  }

  componentWillUnmount() {
    // this.props.resetAppProps(false);
  }

  _setDefaultLastUpdatedFilter = () => {
    //setting default last updated filter to last 30 days
    const getcurentMonthDateRange = getDateRange('last 30 days');
    this.defaultLastUpdatedValue = getcurentMonthDateRange;
    this.setState(
      {
        lastUpdated: {
          value: getcurentMonthDateRange,
          label: 'Last 30 days'
        }
      },
      () => {
        let { limit, pageNumber } = this.props;
        this.props.fetchSMSCampaignsList(
          pageNumber,
          limit,
          this.sortKey,
          this.sortBy,
          this.getRequestBodyFromFilters({
            brandcountry: this.state.brandcountry,
            smsType: this.state.smsType,
            smsWithIncentives: this.state.smsWithIncentives,
            smsContentTheme: this.state.smsContentTheme,
            smsContentSecondaryTheme: this.state.smsContentSecondaryTheme,
            brands: this.state.brands,
            status: this.state.status,
            lastUpdated: this.state.lastUpdated
          })
        );
        this.getFiltersList();
      }
    );
  };

  handleSearch = query => {
    let { limit, pageNumber } = this.props;
    this.setState({ query }, () => {
      this.props.fetchSMSCampaignsList(
        pageNumber,
        limit,
        this.sortKey,
        this.sortBy,
        this.getRequestBodyFromFilters({
          brandcountry: this.state.brandcountry,
          smsType: this.state.smsType,
          smsWithIncentives: this.state.smsWithIncentives,
          smsContentTheme: this.state.smsContentTheme,
          smsContentSecondaryTheme: this.state.smsContentSecondaryTheme,
          brands: this.state.brands,
          status: this.state.status,
          lastUpdated: this.state.lastUpdated,
          searchText: this.state.query
        })
      );
    });
  };

  // _getCountries = () => {
  //   let { smsCountries } = this.props;
  //   if (smsCountries.length > 0) {
  //     return smsCountries.map(country => {
  //       return {
  //         value: country,
  //         label: textCapitalization(country)
  //       };
  //     });
  //   }
  // };

  // _getBrands = () => {
  //   const { allBrands } = this.props;
  //   if (allBrands.length > 0) {
  //     return allBrands.map(brand => {
  //       return {
  //         value: brand,
  //         label: textCapitalization(brand)
  //       };
  //     });
  //   }
  // };

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.code + '-' + country._id, label: country.name };
      });
    }
  }
  _getBrands() {
    let { brands } = this.props;
    if (Object.keys(brands).length > 0) {
      return brands.map(function(brand) {
        return { value: brand.code + '-' + brand._id, label: brand.name };
      });
    }
  }
  checkCampaignsExist() {
    return this.props.list.length ? true : false;
  }
  _handleFocus(event) {
    event.target.select();
  }

  onPageChange = pageNumber => {
    const {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns,
      lastUpdated
    } = this.state;
    this.props.fetchSMSCampaignsList(
      pageNumber,
      this.props.limit,
      this.sortKey,
      this.sortBy,
      this.getRequestBodyFromFilters({
        brandcountry,
        brands,
        smsType,
        smsWithIncentives,
        smsContentTheme,
        smsContentSecondaryTheme,
        status,
        searchText: this.state.query,
        showMyCampaigns,
        lastUpdated
      })
    );
  };

  _clearAllFilters = event => {
    event.preventDefault();
    const { limit, pageNumber } = this.props;
    this.sortKey = 'lastUpdatedDate';
    this.sortBy = 'desc';
    this.filtersCnt = 0;

    const updatedFilters = {
      status: [],
      brands: [],
      smsType: [],
      brandcountry: [],
      smsWithIncentives: [],
      smsContentTheme: [],
      smsContentSecondaryTheme: [],
      lastUpdated: '',
      searchText: this.state.query,
      showMyCampaigns: false
    };
    this.setState(state => {
      return {
        ...state,
        ...updatedFilters
      };
    });

    this.props.fetchSMSCampaignsList(
      1,
      limit,
      this.sortKey,
      this.sortBy,
      this.getRequestBodyFromFilters(updatedFilters)
    );
  };

  _handleDropDownValue(selectedOption, action) {
    const { limit, pageNumber } = this.props;
    const {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns
    } = this.state;
    const optionName = action && action.name;
    this.filtersCnt++;
    this.setState({ [optionName]: selectedOption });
    const filters = {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns,
      [optionName]: selectedOption,
      searchText: this.state.query
    };
    if (
      this.state.lastUpdated &&
      optionName !== 'lastUpdated' &&
      this.state.lastUpdated.value != 'All time'
    ) {
      filters['lastUpdated'] = this.state.lastUpdated.value;
    }
    if (
      this.state.brandcountry &&
      this.state.brandcountry.length &&
      optionName !== 'brandcountry'
    ) {
      filters['brandcountry'] = brandcountry;
    }
    if (
      this.state.brands &&
      this.state.brands.length &&
      optionName !== 'brands'
    ) {
      filters['brands'] = brands;
    }
    if (
      this.state.smsType &&
      this.state.smsType.length &&
      optionName !== 'smsType'
    ) {
      filters['smsType'] = smsType;
    }
    if (
      this.state.smsWithIncentives &&
      this.state.smsWithIncentives.length &&
      optionName !== 'smsWithIncentives'
    ) {
      filters['smsWithIncentives'] = smsWithIncentives;
    }
    if (
      this.state.smsContentTheme &&
      this.state.smsContentTheme.length &&
      optionName !== 'smsContentTheme'
    ) {
      filters['smsContentTheme'] = smsContentTheme;
    }
    if (
      this.state.smsContentSecondaryTheme &&
      this.state.smsContentSecondaryTheme.length &&
      optionName !== 'smsContentSecondaryTheme'
    ) {
      filters['smsContentSecondaryTheme'] = smsContentSecondaryTheme;
    }
    if (
      this.state.status &&
      this.state.status.length &&
      optionName !== 'status'
    ) {
      filters['status'] = status;
    }
    if(this.state.showMyCampaigns && optionName !== 'showMyCampaigns'){
      filters['showMyCampaigns'] = showMyCampaigns;
    }
    this.props.fetchSMSCampaignsList(
      1,
      limit,
      this.sortKey,
      this.sortBy,
      this.getRequestBodyFromFilters(filters)
    );
  }

  sortDashboard(e) {
    const { limit, pageNumber } = this.props;
    const {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns
    } = this.state;
    let classname = e.target.className;
    let updatedClassName;
    if (classname.indexOf('--asc') >= 0) {
      this.sortBy = 'asc';
      updatedClassName = 'cw-sort cw-sort--desc';
    } else {
      this.sortBy = 'desc';
      updatedClassName = 'cw-sort cw-sort--asc';
    }
    this.setState({
      sortClass: updatedClassName
    });
    e.target.className = updatedClassName;
    this.sortKey = e.currentTarget.getAttribute('data-type');

    const filters = {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      searchText: this.state.query,
      showMyCampaigns
    };
    if (this.state.lastUpdated && this.state.lastUpdated.value != 'All time') {
      filters['lastUpdated'] = this.state.lastUpdated.value;
    }
    this.props.fetchSMSCampaignsList(
      pageNumber,
      limit,
      this.sortKey,
      this.sortBy,
      this.getRequestBodyFromFilters(filters)
    );
  }

  /**
   * @name getRequestBodyFromFilters
   * @description This function converts filter values from
      select dropdown format to format required by API.

     Input Example:
      {
        filterKey: [
          { label: "Label 1", value: "value1" },
          { label: "Label 2", value: "value2" }
        ]
      }

     Output Example:
     { filterKey: ["value1", "value2"] }
  */
  getRequestBodyFromFilters = filters => {
    const result = {};
    const userData = this.props.authorizedUserData;
    Object.keys(filters).forEach(filterKey => {
      if (Array.isArray(filters[filterKey]) && filters[filterKey].length) {
        if (filterKey === 'brandcountry') {
          result[filterKey] = filters[filterKey].flatMap(data =>
            data.label.toLowerCase()
          );
        } else if (filterKey === 'brands') {
          result[filterKey] = filters[filterKey].flatMap(data =>
            data.label.toLowerCase()
          );
        } else if (filterKey === 'smsType') {
          result[filterKey] = filters[filterKey].flatMap(data => data.value);
        } else if (filterKey === 'smsWithIncentives') {
          result[filterKey] = filters[filterKey].flatMap(data => data.value);
        } else if (filterKey === 'smsContentTheme') {
          result[filterKey] = filters[filterKey].flatMap(data => data.value);
        } else if (filterKey === 'smsContentSecondaryTheme') {
          result[filterKey] = filters[filterKey].flatMap(data => data.value);
        } else {
          result[filterKey] = filters[filterKey].flatMap(data => data.value);
        }
      } else {
        if (filters[filterKey] && filters[filterKey].value) {
          if (filters[filterKey].value !== 'All time') {
            result[filterKey] = filters[filterKey].value;
          }
        } else if (filterKey === 'showMyCampaigns') {
          if(filters[filterKey]){
            result[filterKey] = `${encodeURIComponent(userData.emailId)}|${
              userData.group
            }`;
          }
          
        } else {
          result[filterKey] = filters[filterKey];
        }
      }
    });

    return result;
  };

  getFiltersList() {
    const {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      lastUpdated,
      showMyCampaigns
    } = this.state;
    const orderedFilters = {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns
    };
    if (lastUpdated) {
      orderedFilters['lastUpdated'] = [lastUpdated];
    }
    this.filtersCnt = 0;
    const filters = Object.keys(orderedFilters).map((keyName, i) => {
      if (orderedFilters[keyName].length) {
        return orderedFilters[keyName]
          .sort(sortArray('label'))
          .map((item, indx) => {
            this.filtersCnt++;
            return (
              <div key={indx} className="cw-dashboard-filters">
                <div className="cw-dashboard-filters-label">
                  {keyName === 'lastUpdated'
                    ? orderedFilters[keyName][indx]['value']
                    : orderedFilters[keyName][indx]['label']}
                </div>
                <div
                  className="cw-dashboard-filters-remove"
                  onClick={() =>
                    this.removeFilter(
                      orderedFilters[keyName][indx]['value'],
                      keyName
                    )
                  }
                >
                  <img src={DeleteIcon} alt="Remove filter icon" />
                </div>
              </div>
            );
          });
      }else if(typeof orderedFilters[keyName] === 'boolean'){
        if(orderedFilters[keyName]) {
          this.filtersCnt++;
          return (
          <div className="cw-dashboard-filters">
                <div className="cw-dashboard-filters-label">
                  My campaigns
                </div>
                <div
                  className="cw-dashboard-filters-remove"
                  onClick={() =>
                    this.removeFilter(
                      orderedFilters[keyName],
                      keyName
                    )
                  }
                >
                  <img src={DeleteIcon} alt="Remove filter icon" />
                </div>
          </div>
          ) 
        };
      } else {
        return '';
      }
    });

    return filters;
  }

  removeFilter(value, type) {
    const {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns,
      lastUpdated
    } = this.state;
    let lastUpdatedFilter = lastUpdated;
    let showMyCampaignsFilter = showMyCampaigns;
    if (type === 'lastUpdated') {
      lastUpdatedFilter = '';
      this.setState({ [type]: '' });
    } else if (this.state[type].length) {
      var typedata = [...this.state[type]];
      typedata.forEach((item, index) => {
        if (item.value === value) {
          typedata.splice(index, 1);
        }
      });
      this.setState({ [type]: typedata ? typedata : '' });
    } else if(type === 'showMyCampaigns') {
      this.setState({ showMyCampaigns: false });
      showMyCampaignsFilter = false;
    }
    this.filtersCnt = this.filtersCnt - 1;
    this.props.fetchSMSCampaignsList(
      1,
      this.props.limit,
      this.sortKey,
      this.sortBy,
      this.getRequestBodyFromFilters({
        brandcountry,
        brands,
        smsType,
        smsWithIncentives,
        smsContentTheme,
        smsContentSecondaryTheme,
        status,
        [type]: typedata ? typedata : '',
        searchText: this.state.query,
        showMyCampaigns: showMyCampaignsFilter,
        lastUpdated: lastUpdatedFilter
      })
    );
  }

  renderDashboard() {
    let { asyncInProgress, list } = this.props;
    if (!this.checkCampaignsExist()) {
      return (
        <tr>
          <td>
            <span>{'0 campaigns match your search'}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    } else {
      return list.map((campaign, index) => {
        return (
          <CommunicationDashboardRow
            key={campaign.uid}
            campaign={campaign}
            index={index}
          />
        );
      });
    }
  }

  _toggleFilters() {
    this.setState(state => ({ showFilters: !state.showFilters }));
  }

  handleShowMyCampaignsOnChange = () => {
    this._handleDropDownValue(!this.state.showMyCampaigns, {
      name: 'showMyCampaigns'
    });
  };

   handleSort = e => {
    const { limit, pageNumber } = this.props;
    const {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns
    } = this.state;
    const filters = {
      brandcountry,
      brands,
      smsType,
      smsWithIncentives,
      smsContentTheme,
      smsContentSecondaryTheme,
      status,
      showMyCampaigns,
      lastUpdated: this.state.lastUpdated,
      searchText: this.state.query
    };
    const [key, order] = e.value.split('-');
    this.sortKey =  key
    this.sortBy = order
    this.setState({
      sortDropdownValue : e
    })
    this.props.fetchSMSCampaignsList(
      pageNumber,
      limit,
      this.sortKey,
      this.sortBy,
      this.getRequestBodyFromFilters(filters)
    );
  };

  render() {
    let {
      asyncInProgress,
      showLoader,
      resetSmsBrandDetails,
      resetSmsCampaignDetails
    } = this.props;
    return (
      <>
        <div className="col-sm-12">
          <div className="cw-section cw-dashboard ml-20">
            <NotificationMessage
              showcomponent={this.props.notificationMessage}
              message={this.props.notificationMessage}
              type={this.props.notificationType}
              link={this.props.notificationLink}
              campaignname={this.props.notificationCampaign}
              notificationMessageComp={this.props.notificationMessageComp}
            />

            <div className="cw-listing--header mb-10 d-flex align-items-center justify-content-between">
              <h2 className="cw-heading--secondary mr-4 d-inline-block">
                SMS Campaigns Dashboard
              </h2>
            </div>

            <div className='d-flex flex-justify-between mt-20'>
            <SmsAdvanceSearch
              searchEvent={this.handleSearch}
              placeholder="Search using campaign names"
              email
              toggleFilters={this._toggleFilters}
            />
             <NavLink
                className="btn btn-primary btn-newcampaign"
                to={'/campaign-wizard/newcommunications'}
                onClick={() => {
                  resetSmsBrandDetails();
                  resetSmsCampaignDetails();
                }}
              >
                Create SMS Campaign
              </NavLink>
              </div>
            {this.state.showFilters && (
              <>
                <div className="row mt-15 cw-dashboard__filters">
                  <div className="d-flex align-items-center col-sm-12">
                    {/* Country dropdown */}
                    <CountryFilter
                      asyncInProgress={asyncInProgress}
                      value={this.state.brandcountry}
                      onChange={this._handleDropDownValue}
                      options={this._getCountries()}
                      classNamePrefix="react-multi-select"
                      isEmailCampaign={true}
                    />

                    {/* Brand Filter */}
                    <BrandFilter
                      asyncInProgress={asyncInProgress}
                      value={this.state.brands}
                      onChange={this._handleDropDownValue}
                      options={this._getBrands()}
                      classNamePrefix="react-multi-select"
                      isEmailCampaign={true}
                    />

                    {/* Type Filter */}
                    <div className="filter-item mr-10">
                      <CheckboxDropDown
                        isMulti={true}
                        name={'smsType'}
                        label={'SMS Type'}
                        placeholder={'SMS Type'}
                        className="react-multi-select-container"
                        classNamePrefix="react-multi-select"
                        options={constant.SMS_TYPE_OPTIONS}
                        value={this.state.smsType}
                        onChange={this._handleDropDownValue}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        isClearable={false}
                        isEmailCampaign={true}
                        hideSelectedOptions={false}
                      />
                    </div>

                    {/* Status Dropdown */}
                    <StatusFilter
                      asyncInProgress={asyncInProgress}
                      value={this.state.status}
                      onChange={this._handleDropDownValue}
                      options={this.radioDropDownOptions}
                      classNamePrefix="react-multi-select"
                      isEmailCampaign={true}
                    />

                    {/* Last Updated Dropdown */}
                    <LastUpdatedFilter
                      isLoading={asyncInProgress}
                      value={this.state.lastUpdated}
                      onChange={this._handleDropDownValue}
                      options={lastUpdatedData}
                      classNamePrefix="react-multi-select"
                      isEmailCampaign={true}
                    />
                  </div>
                </div>

                <div className="row mt-15 cw-dashboard__filters">
                  <div className="d-flex align-items-center col-sm-8">
                    {/* Incentives Filter */}
                    <div className="filter-item mr-10">
                      <CheckboxDropDown
                        isMulti={true}
                        name={'smsWithIncentives'}
                        label={'SMS with incentives'}
                        placeholder={'SMS with incentives'}
                        className="react-multi-select-container"
                        classNamePrefix="react-multi-select"
                        options={constant.EMAIL_COUPON_FILTER_OPTIONS}
                        value={this.state.smsWithIncentives}
                        onChange={this._handleDropDownValue}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        isClearable={false}
                        isEmailCampaign={true}
                        hideSelectedOptions={false}
                      />
                    </div>

                    {/* Primary theme Filter */}
                    <div className="filter-item mr-10">
                      <CheckboxDropDown
                        isMulti={true}
                        name={'smsContentTheme'}
                        label={'Primary content theme'}
                        placeholder={'Primary content theme'}
                        className="react-multi-select-container"
                        classNamePrefix="react-multi-select"
                        options={constant.EMAIL_PRIMARY_THEME}
                        value={this.state.smsContentTheme}
                        onChange={this._handleDropDownValue}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        isClearable={false}
                        isEmailCampaign={true}
                        hideSelectedOptions={false}
                      />
                    </div>

                    {/* Secondary theme Filter */}
                    <div className="filter-item">
                      <CheckboxDropDown
                        isMulti={true}
                        name={'smsContentSecondaryTheme'}
                        label={'Secondary content theme'}
                        placeholder={'Secondary content theme'}
                        className="react-multi-select-container"
                        classNamePrefix="react-multi-select"
                        options={constant.EMAIL_SECONDARY_THEMES}
                        value={this.state.smsContentSecondaryTheme}
                        onChange={this._handleDropDownValue}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        isClearable={false}
                        isEmailCampaign={true}
                        hideSelectedOptions={false}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            
              <div className="row mb-20 mt-20">
                {/* <div className="ml-20">Filters</div> */}
                <div className="col-sm-8 justify-content-between align-items-center">
                {this.filtersCnt ? (
                  <>
                    {this.getFiltersList()}
                    {this.filtersCnt >= 2 ? (
                      <div className="cw-dashboard-filters clearall">
                        <div
                          className="cw-dashboard-filters-label clearalllabel"
                          onClick={this._clearAllFilters}
                        >
                          Clear all
                        </div>
                      </div>
                    ) : null}
                  </>
                  ) : null}
                </div>
                <div className="col-sm-4">
                  <div className="d-flex justify-content-end">
                    <SimpleSelect
                      options={constant.DASHBOARD_SORT_OPTIONS}
                      selected={this.state.sortDropdownValue}
                      handleChange={this.handleSort}
                    />
                    <CheckBoxInput
                      id="showMyCampaigns"
                      value={this.state.showMyCampaigns}
                      label="My campaigns"
                      onChange={this.handleShowMyCampaignsOnChange}
                      className={classNames("email-dashboard-checkbox")}
                      labelClass="email-dashboard-checkbox__label"
                    />
                  </div>
                </div>
              </div>

            <div className="cw-listing--content">
              {showLoader ? (
                <Loader />
              ) : (
                <table className="table table-striped-dashboard mb-40 mt-30">
                  <caption>List of campaigns</caption>
                  <thead>
                    <tr>
                      <th scope="col" data-type="name" style={{ width: '28%' }}>
                        <span className="">Campaign Name</span>
                      </th>
                      <th scope="col">
                        <span className="">Brand</span>
                      </th>
                      <th scope="col" className="max-width-column">
                        <span className="">Audience</span>
                      </th>
                      <th scope="col">
                        <span
                          className={this.state.sortClass}
                          data-type="lastUpdatedDate"
                          onClick={this.sortDashboard}
                        >
                          Last Updated
                        </span>
                      </th>
                      <th scope="col">
                        <span className="">Status</span>
                      </th>
                      <th scope="col">
                        <span className="">Preview</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderDashboard()}</tbody>
                </table>
              )}
              {showLoader || !this.props.list.length ? (
                <span>&nbsp;</span>
              ) : (
                <Pagination
                  totalRecords={this.props.totalCount}
                  sortBy={this.sortBy}
                  limit={this.props.limit}
                  pageNumber={this.props.pageNumber}
                  onPageChange={this.onPageChange}
                  recordShowStart={this.props.recordShowStart}
                  recordShowto={this.props.recordShowto}
                  sortKey={this.sortKey}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  // }
  // }
}

const mapStateToProps = state => ({
  smsBrands: state.communicationBrandDetails.smsBrands,
  smsCountries: state.communicationBrandDetails.smsCountries,
  smsBrandList: state.communicationBrandDetails.smsBrandList,
  countries: state.dashboard.countries,
  brands: state.dashboard.brands,
  allBrands: state.communicationBrandDetails.allBrands,
  list: state.communicationDashboard.campaigns,
  limit: state.communicationDashboard.limit,
  recordShowStart: state.communicationDashboard.recordShowStart,
  recordShowto: state.communicationDashboard.recordShowto,
  showLoader: state.communicationDashboard.showLoader,
  totalCount: state.communicationDashboard.totalCount,
  pageNumber: state.communicationDashboard.pageNumber,
  notificationMessage: state.dashboard.notificationMessage,
  notificationType: state.dashboard.notificationType,
  notificationMessageComp: state.dashboard.notificationMessageComp,
  notificationLink : state.dashboard.notificationLink,
  notificationCampaign : state.dashboard.notificationCampaign,
  authorizedUserData: state.authorized.authorizedUserData,
});

export default connect(mapStateToProps, {
  fetchSmsBrandDetails,
  fetchSmsBrandsForCountries,
  fetchAllSmsBrands,
  fetchSMSCampaignsList,
  getBrandCountries,
  getBrands,
  resetSmsBrandDetails,
  resetSmsCampaignDetails
})(CommunicationDashboard);
