import constants from 'constants/config';
import React, { useState } from 'react';
import GuideLines from './GuideLines';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MultiGuideLines from './MultiGuideLines';
import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';

const TAB_MAP = {
  '0': 'GDPR_COUNTRIES',
  '1': 'NON_GDPR_COUNTRIES',
  '2': 'UPDATE_CONSENT_STATEMENT'
};

const MultiChannelGuideline = props => {
  const [activeTab, setActiveTab] = useState(0);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };
  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={{maxHeight :'98vh'}}>
      <div className="guideline-wrapper--header">
        <p className={`title`}>{`Consent guidelines`}</p>
        <i className="guideline-wrapper-close-icon" onClick={props.onClose}></i>
      </div>
      <p className="mb-10">
        All sign up forms should adhere to the following guidelines.
      </p>
      <div className="col-sm-12 mt-10">
        <div className="overlay-content-wrapper">
          <div className="overlay-content">
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              disableRipple
              style={{ borderBottom: '1px solid #aaa', marginBottom: '1rem' }}
            >
              <Tab disableRipple style={tabStyle} label="GDPR countries" />
              <Tab disableRipple style={tabStyle} label="Non-GDPR countries" />
              <Tab
                disableRipple
                style={tabStyle}
                label="Updating consent statement text"
              />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <div className="col-sm-12 qna-guideline-scroll-browsable">
                <MultiGuideLines
                  data={constants.MULTI_CHANNEL_GUIDE_LINES[TAB_MAP['0']]}
                  qnaIndex={true}
                  noLineBreak={true}
                  ulStyle={{ listStyleType: 'circle', marginLeft: 20 }}
                  liStyle={{ color: '#000000 !important' }}
                  liClassName="brandidetails"
                />
              </div>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <div className="col-sm-12 qna-guideline-scroll-browsable">
                <MultiGuideLines
                  data={constants.MULTI_CHANNEL_GUIDE_LINES[TAB_MAP['1']]}
                  qnaIndex={true}
                  noLineBreak={true}
                  ulStyle={{ listStyleType: 'circle', marginLeft: 20 }}
                  liStyle={{ color: '#000000 !important' }}
                  liClassName="brandidetails"
                />
              </div>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <div className="col-sm-12 qna-guideline-scroll-browsable">
                <MultiGuideLines
                  data={constants.MULTI_CHANNEL_GUIDE_LINES[TAB_MAP['2']]}
                  qnaIndex={true}
                  noLineBreak={true}
                  ulStyle={{ listStyleType: 'circle', marginLeft: 20 }}
                  liStyle={{ color: '#000000 !important' }}
                  liClassName="brandidetails"
                />
              </div>
            </TabPanel>
          </div>
          <div className="bottom-cta mt-10">
            <div className="horizontal-line"></div>
            <div className="mt-3">
              <button
                type="submit"
                onClick={props.onClose}
                className="btn btn-block btn-outline-secondary mb-10 cw-print-hide text-nowrap"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiChannelGuideline;
