import React from 'react';
import constant from '../../../constants';

const MutliGuideLineBtn = ({ isOpenMultiChannelGuideModel }) => {
  return (
    <>
      <a
        href="#"
        onClick={isOpenMultiChannelGuideModel}
        style={{ fontWeight: 'bold' }}
        className='text-link-right'
      >
        Consent guidelines
      </a>{' '}
    </>
  );
};

const SinglePageDescription = ({
  isQnaCampaign,
  isOpenGuidelineModel,
  isMultiGuideLine,
  isOpenMultiChannelGuideModel
}) => (
  <>
    {isQnaCampaign ? (
      <div>
        {constant.SAAS_MESSAGES.configureFormFields[0]} and use our&nbsp;
        <a
          href="#"
          onClick={isOpenGuidelineModel}
          style={{ fontWeight: 'bold' }}
        >
          Q&A guidelines
        </a>{' '}
        to setup your custom questions
        <div>correctly.</div>
        {isMultiGuideLine ? (
          <div style={{ textAlign: 'right' }}>
            <MutliGuideLineBtn
              isOpenMultiChannelGuideModel={isOpenMultiChannelGuideModel}
            />
          </div>
        ) : null}
      </div>
    ) : (
      <div className="d-flex justify-content-between">
        <div>
          {' '}
          <p>{constant.SAAS_MESSAGES.configureFormFields}.</p>
        </div>
        {isMultiGuideLine ? (
          <div style={{ textAlign: 'right' }}>
            <MutliGuideLineBtn
              isOpenMultiChannelGuideModel={isOpenMultiChannelGuideModel}
            />
          </div>
        ) : null}
      </div>
    )}
  </>
);

export default SinglePageDescription;
