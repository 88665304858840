import * as yup from 'yup';
import {
  getYupSchema,
  _generateUUID,
  WaSubmitRedirectURL,
  WACampaignSubmitRedirectURL
} from 'selectors';
import { FormEmailRadioInputV2 } from 'components/molecules/FormEmailRadioInput/inputv2';
import { EmailSecondaryThemeInput } from 'components/molecules/EmailSecondaryThemeInput';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';
import constants from '../../constants';
import { SmsPraInput } from 'components/molecules/SmsPraInput';
import EmailCollaboratorsInput from 'components/molecules/EmailCollaboratorsInput';
import { createWAcomms, updateWAComms } from 'actions/wabriefings/waComms';

const SummarySchema = (
  dispatch,
  {
    defaultValues = {},
    match,
    history,
    campaignDetails,
    waCampaignDetails,
    waType
  }
) => {
  const isEdited = getIsEdited({ history });

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const paramUid = match.params.id;
    console.log('user=====>', user);
    if (paramUid) {
      const dataToSend = {
        uid: paramUid,
        name: data.name,
        waOverview: data.waOverview,
        status: '1',
        dpcFlag: false,
        dpcPraReferenceNo: data.dpiaReferenceNo,
        saveAsDraft: false,
        waContentSecondaryTheme: data.waContentSecondaryTheme,
        waContentTheme: data.waContentTheme,
        emailCollaborators: data.emailCollaborators,
        requestorsEmail: user.emailId
      };
      dispatch(
        updateWAComms(paramUid, dataToSend, (error, result) => {
          if (error) {
            console.log('OnSubmit error: ', error);
          } else {
            history.push(
              WACampaignSubmitRedirectURL({
                backToReview: isEdited && backToReview,
                status: campaignDetails.status,
                uid: paramUid,
                nextPage: `${constants.WA_ROUTES.CAMPAIN_ATTRIBUTES}`
              })
            );
          }
        })
      );
    } else {
      // create new details
      const dataPayload = {
        ...waCampaignDetails,
        ...waType,
        name: data.name,
        waOverview: data.waOverview,
        status: '1',
        dpcFlag: false,
        dpcPraReferenceNo: data.dpiaReferenceNo,
        saveAsDraft: false,
        waContentSecondaryTheme: data.waContentSecondaryTheme,
        waContentTheme: data.waContentTheme,
        emailCollaborators: data.emailCollaborators,
        isNewCampaign: true,
        requestorsEmail: user.emailId,
        userName: user.name,
        userEmail: user.emailId
      };
      dispatch(
        createWAcomms(dataPayload, (error, result) => {
          if (error) {
            console.log('OnSubmit error: ', error);
          } else {
            const uuid = result.uid;
            history.push(
              WACampaignSubmitRedirectURL({
                backToReview: false,
                status: '1',
                uid: uuid,
                nextPage: `${constants.WA_ROUTES.CAMPAIN_ATTRIBUTES}`
              })
            );
          }
        })
      );
    }
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'text',
        name: 'name',
        id: 'name',
        label: 'Campaign name',
        maxLength: '50',
        defaultValue: defaultValues.name,
        rules: yup
          .string()
          .trim()
          .required('Please enter campaign name')
      },
      {
        type: 'textarea',
        name: 'waOverview',
        id: 'waOverview',
        label: 'Campaign description',
        placeholder: 'Describe your marketing initiative and goal',
        defaultValue: defaultValues && defaultValues.waOverview,
        rules: yup
          .string()
          .trim()
          .required('Please enter campaign description'),
        maxLength: '300'
      },
      {
        type: 'custom',
        //type: 'reactselect',
        name: 'waContentTheme',
        id: 'waContentTheme',
        label: 'Content theme',
        subLabel:
          'We will use this info to generate cross brand inspiration as well as track content themes to WhatsApp engagement',
        component: FormEmailRadioInputV2,
        placeholder: 'Please select',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required('Please select WhatsApp content theme')
          .default(undefined),
        defaultValue: defaultValues.waContentTheme,
        options: constants.EMAIL_PRIMARY_THEME
      },
      {
        type: 'custom',
        name: 'waContentSecondaryTheme',
        id: 'waContentSecondaryTheme',
        label: 'Secondary theme',
        subLabel:
          'Please select values as accurately as possible to help us effectively attribute your WhatsApp to content based learnings',
        component: EmailSecondaryThemeInput,
        defaultValue: defaultValues.waContentSecondaryTheme,
        rules: yup
          .array()
          .of(yup.object())
          .min(1, 'Please select WhatsApp content theme')
          .required('Please select WhatsApp content theme'),
        options: constants.EMAIL_SECONDARY_THEMES
      },
      {
        type: 'custom',
        name: 'dpiaReferenceNo',
        id: 'dpiaReferenceNo',
        label: 'PRA ID',
        maxLength: '5',
        component: SmsPraInput,
        defaultValue: defaultValues && defaultValues.dpcPraReferenceNo,
        rules: yup
          .string()
          .required('Please enter a valid PRA ID from your Scout assessment.')
          .matches(
            /^[0-9\b]+$/,
            'Please enter a valid PRA ID from your Scout assessment.'
          )
      },
      {
        type: 'custom',
        name: 'emailCollaborators',
        component: EmailCollaboratorsInput,
        id: 'emailCollaborators',
        label: 'Specify collaborators',
        subLabel:
          'Please specify who should be allowed to edit your campaign or contribute to it before it is submitted. Any other users will not be able to resume drafts and will only be able to view your campaign in read only mode.',
        defaultValue: defaultValues.emailCollaborators,
        rules: yup.object({
          type: yup
            .string()
            .required('Please select email trigger')
            .nullable(),
          collaborators: yup.array().when('type', {
            is: 'requestorAndSelectedUsers',
            then: schema =>
              schema
                .test(
                  'is-min',
                  'Please enter your collaborator’s email address',
                  value => {
                    const lastElem = value[value.length - 1];
                    if (
                      value.length < 2 &&
                      (!lastElem || lastElem.value === '')
                    ) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  'is-email-valid',
                  'Please enter a valid email address',
                  value => {
                    const lastElem = value[value.length - 1];
                    const customValidation = constants.regex.emailAddress;
                    if (value.length >= 1) {
                      if (lastElem?.value.trim() !== '') {
                        if (
                          customValidation &&
                          !customValidation.test(lastElem?.value)
                        ) {
                          return false;
                        }
                      }
                      return true;
                    }
                    return true;
                  }
                )
                .test(
                  'is-email-allowed',
                  'Please enter a valid email. Only emails on domain unilever.com are allowed.',
                  value => {
                    const lastElem = value[value.length - 1];
                    if (value.length >= 1) {
                      if (lastElem?.value.trim() !== '') {
                        if (
                          !constants.EMAIL_SENDTEST_VALID_DOMAINS.includes(
                            lastElem?.value.split('@')[1]
                          )
                        ) {
                          return false;
                        }
                      }
                      return true;
                    }
                    return true;
                  }
                )
                .test('max-5', 'Only upto 5 users are allowed', value => {
                  const lastElem = value[value.length - 1];
                  if (lastElem?.value.trim() === '') {
                    return value.length - 1 > 5 ? false : true;
                  }
                  return value.length > 5 ? false : true;
                }),
            otherwise: schema => schema.notRequired()
          })
        })
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      if (paramUid) {
        const dataToSend = {
          uid: paramUid,
          name: data.name,
          waOverview: data.waOverview,
          status: '1',
          dpcFlag: false,
          dpcPraReferenceNo: data.dpiaReferenceNo,
          saveAsDraft: false,
          waContentSecondaryTheme: data.waContentSecondaryTheme,
          waContentTheme: data.waContentTheme,
          emailCollaborators: data.emailCollaborators,
          requestorsEmail: user.emailId
        };
        dispatch(
          updateWAComms(
            paramUid,
            dataToSend,
            (error, result) => {
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                history.push(
                  `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
                );
              }
            },
            { isSaveAsDraft: true }
          )
        );
      } else {
        // create new details
        const dataPayload = {
          ...waCampaignDetails,
          ...waType,
          name: data.name,
          waOverview: data.waOverview,
          status: '1',
          dpcFlag: false,
          dpcPraReferenceNo: data.dpiaReferenceNo,
          saveAsDraft: false,
          waContentSecondaryTheme: data.waContentSecondaryTheme,
          waContentTheme: data.waContentTheme,
          emailCollaborators: data.emailCollaborators,
          isNewCampaign: true,
          requestorsEmail: user.emailId,
          userName: user.name,
          userEmail: user.emailId
        };
        dispatch(
          createWAcomms(
            dataPayload,
            (error, result) => {
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                history.push(
                  `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
                );
              }
            },
            { isSaveAsDraft: true }
          )
        );
      }
    },
    onPrevious: () => {
      const uid = match.params.id;
      if (uid) {
        history.push(
          `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.WA_TYPE}`
        );
      } else {
        history.push(
          `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_WA_TYPE}`
        );
      }
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default SummarySchema;
