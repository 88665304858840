import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import NotificationMessage from 'components/Messages';
import CheckBoxInput from 'components/molecules/CheckBoxInput';
import SmsAdvanceSearch from 'components/SmsAdvanceSearch';
import {WADashboardRow } from 'components/molecules/WADashboardRow';
import { SimpleSelect } from 'components/atoms/SimpleSelect';
import { WAFiltersInputs } from './WAFiltersInputs';
import { AppliedFilters } from './AppliedFilters';

import { resetWAComms, setWACommsLoader } from 'actions/wabriefings/waComms';
import { getBrandCountries, getBrands } from 'actions/dashboard';
import { getWABriefingList } from 'services/waBriefings';
// import {
//   fetchSmsBrandDetails,
//   fetchSmsBrandsForCountries,
//   fetchAllSmsBrands,
//   resetSmsBrandDetails
// } from 'actions/communications/communicationBrandDetails.js';
// import {
//   getBrandCountries,
//   getBrands,
//   getWebsiteTypes
// } from 'actions/dashboard';
// import { resetSmsCampaignDetails } from 'actions/communications/communicationCampaignDetails';
// import { fetchSMSCampaignsList } from 'actions/communications/communicationDashboard';

import { getDateRange } from 'selectors';
import {
  defaultFilters,
  getRequestBodyFromFilters,
  getFiltersCount
} from './dashboardUtils';

import constants from 'constants/config';

const WACampaignDashboard = props => {
  const dispatch = useDispatch();

  const [waCampaigns, setWaCampaigns] = useState({});
  const [page, setPage] = useState(1)
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState('lastUpdatedDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortDropdownValue, setSortDropdownValue] = useState({
    label: 'Updated date - most recent',
    value: 'lastUpdatedDate-desc'
  });
  const [sortClass, setSortClass] = useState('cw-sort--asc');
  const [filters, setFilters] = useState({
    ...defaultFilters,
    lastUpdatedDate: { value: getDateRange('last 30 days'), label: 'Last 30 days' }
  });

  const {
    brands: allBrands,
    countries: allCountries,
    notificationMessage,
    notificationType,
    notificationMessageComp,
    notificationLink,
    notificationCampaign
  } = useSelector(state => ({
    brands: state.dashboard.brands,
    countries: state.dashboard.countries,
    notificationMessage: state.dashboard.notificationMessage,
    notificationType: state.dashboard.notificationType,
    notificationMessageComp: state.dashboard.notificationMessageComp,
    notificationLink: state.dashboard.notificationLink,
    notificationCampaign: state.dashboard.notificationCampaign
  }));
  const { isLoading } = useSelector(state => state.waBriefings);
  const { authorizedUserData } = useSelector(state => state.authorized);

  const getWABriefings = async () => {
    try {
      dispatch(setWACommsLoader(true))
      
      const wabriefings = await getWABriefingList(
        getRequestBodyFromFilters({...filters, sortKey, sortOrder, page,query : searchTerm}, authorizedUserData)
      );
  
      setWaCampaigns(wabriefings);
      setPage(wabriefings?.pagination?.page)
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setWACommsLoader(false))
    }
  };

  useEffect(() => {
    dispatch(getBrandCountries());
    dispatch(getBrands());
  }, []);

  useEffect(() => {
    if (sortKey !== 'lastUpdatedDate') {
      setSortClass('');
    }
    if (sortKey === 'lastUpdatedDate' && sortOrder === 'asc') {
      setSortClass('cw-sort--desc');
    }
    if (sortKey === 'lastUpdatedDate' && sortOrder === 'desc') {
      setSortClass('cw-sort--asc');
    }
  }, [sortKey, sortOrder]);

  useEffect(() => {
    getWABriefings();
  }, [filters, searchTerm, sortKey, sortOrder]);

  useEffect(() => {
    if(waCampaigns?.pagination?.page && waCampaigns?.pagination?.page != page) {
      getWABriefings();
    }
  }, [page]);

  const filtersCount = getFiltersCount(filters);
  const { campaigns = [], pagination = {} } = waCampaigns || {};

  const recordShowStart = (pagination.page - 1) * pagination.limit + 1;
  const recordShowto = Math.min(recordShowStart + pagination.limit - 1, pagination.total);

  const allBrandsList = () => {
    if (Object.keys(allBrands).length > 0) {
      return allBrands.map(function(brand) {
        return { value: brand._id, label: brand.name };
      });
    }
  };

  const allCountriesList = () => {
    if (Object.keys(allCountries).length > 0) {
      return allCountries.map(function(country) {
        return { value: country._id, label: country.name };
      });
    }
  };

  const handleDropDownValue = (selectedOption, action) => {
    // const { limit, pageNumber } = props;
    const optionName = action && action.name;
    setPage(1)
    setFilters(prev => ({
      ...prev,
      [optionName]: selectedOption
    }));
  };

  const handleCheckboxFilter = key => {
    setFilters(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleSearch = query => {
    let { limit, pageNumber } = props; // get from redux instead of props
    setSearchTerm(query);
  };

  const handleSort = e => {
    const [key, order] = e.value.split('-');

    setSortDropdownValue(e);
    setSortKey(key);
    setSortOrder(order);
  };

  const handleClickSort = e => {
    const key = 'lastUpdatedDate';
    const order =
      sortKey === 'lastUpdatedDate'
        ? sortOrder === 'asc'
          ? 'desc'
          : 'asc'
        : 'desc';

    setSortKey(key);
    setSortOrder(order);
    handleSort(
      constants.DASHBOARD_SORT_OPTIONS.find(
        item => item.value === `${key}-${order}`
      )
    );
  };

  const removeFilter = (value, key) => {
    if (Array.isArray(filters[key])) {
      setFilters(prev => ({
        ...prev,
        [key]: prev[key].filter(item => item.value !== value)
      }));
    }

    if (filters[key] && filters[key].value) {
      setFilters(prev => ({
        ...prev,
        [key]: ''
      }));
    }

    if (typeof filters[key] == 'boolean') {
      setFilters(prev => ({
        ...prev,
        [key]: false
      }));
    }
  };

  const clearAllFilters = () => {
    setSortKey('lastUpdatedDate');
    setSortOrder('desc');
    setFilters(defaultFilters);
    setSortDropdownValue({
      label: 'Updated date - most recent',
      value: 'lastUpdatedDate-desc'
    });
  };

  const renderDashboard = () => {
    if (campaigns.length === 0) {
      return (
        <tr>
          <td>
            <span>{'0 campaigns match your search'}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    } else {
      return campaigns.map((campaign, index) => {
        return (
          <WADashboardRow
            key={campaign.uid}
            campaign={campaign}
            index={index}
          />
        );
      });
    }
  };

  return (
    <div className="col-sm-12">
      <div className="cw-section cw-dashboard ml-20">
        <NotificationMessage
          showcomponent={notificationMessage}
          message={notificationMessage}
          type={notificationType}
          link={notificationLink}
          campaignname={notificationCampaign}
          notificationMessageComp={notificationMessageComp}
        />

        <div className="cw-listing--header mb-10 d-flex align-items-center justify-content-between">
          <h2 className="cw-heading--secondary mr-4 d-inline-block">
            WhatsApp Campaigns Dashboard
          </h2>
        </div>
        <div className='d-flex flex-justify-between mt-20'>
        <SmsAdvanceSearch
          searchEvent={handleSearch}
          placeholder="Search using campaign names"
          email
          toggleFilters={() => setIsShowingFilters(prev => !prev)}
        />
         <NavLink
            className="btn btn-primary btn-newcampaign"
            to={`${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_CAMPAIGN}`}
            onClick={() => {
              // resetSmsBrandDetails();
              dispatch(resetWAComms());
            }}
          >
            Create WhatsApp Campaign
          </NavLink>
          </div>

        {isShowingFilters && (
          <WAFiltersInputs
            filters={filters}
            brands={allBrandsList()}
            countries={allCountriesList()}
            handleDropDownValue={handleDropDownValue}
          />
        )}

        <div className="row mb-20 mt-20 align-items-center">
          <div className="col-sm-8 justify-content-between align-items-center">
            {filtersCount > 0 && (
              <>
                <AppliedFilters filters={filters} removeFilter={removeFilter} />

                {filtersCount >= 2 && (
                  <div className="cw-dashboard-filters clearall">
                    <div
                      className="cw-dashboard-filters-label clearalllabel"
                      onClick={clearAllFilters}
                    >
                      Clear all
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="col-sm-4">
            <div className="d-flex justify-content-end">
              <SimpleSelect
                options={constants.DASHBOARD_SORT_OPTIONS}
                selected={sortDropdownValue}
                handleChange={handleSort}
              />

              <CheckBoxInput
                id="showMyCampaigns"
                label="My campaigns"
                className={classNames('email-dashboard-checkbox')}
                labelClass="email-dashboard-checkbox__label"
                value={filters.showMyCampaigns}
                onChange={() => handleCheckboxFilter('showMyCampaigns')}
              />
            </div>
          </div>
        </div>

        <div className="cw-listing--content">
          {isLoading ? (
            <Loader />
          ) : (
            <table className="table table-striped-dashboard mb-40 mt-30">
              <caption>List of campaigns</caption>
              <thead>
                <tr>
                  <th scope="col" data-type="name" style={{ width: '28%' }}>
                    <span className="">Campaign Name</span>
                  </th>
                  <th scope="col">
                    <span className="">Brand</span>
                  </th>
                  <th scope="col" className="max-width-column">
                    <span className="">Audience</span>
                  </th>
                  <th scope="col">
                    <span
                      className={`cw-sort ${sortClass ? sortClass : ''}`}
                      data-type="lastUpdatedDate"
                      onClick={handleClickSort}
                    >
                      Last Updated
                    </span>
                  </th>
                  <th scope="col">
                    <span className="">Status</span>
                  </th>
                  <th scope="col">
                    <span className="">Preview</span>
                  </th>
                </tr>
              </thead>
              <tbody>{renderDashboard()}</tbody>
            </table>
          )}

          {isLoading || !waCampaigns?.campaigns?.length ? (
            <span>&nbsp;</span>
          ) : (
            <Pagination
              totalRecords={pagination.total}
              limit={pagination.limit}
              pageNumber={+page}
              onPageChange={number => setPage(number)}
              recordShowStart={recordShowStart}
              recordShowto={recordShowto}
              sortBy={sortOrder}
              sortKey={sortKey}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WACampaignDashboard;
