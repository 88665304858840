import summarySchema from 'pages/WACampaignSummary/WACampaignSummarySchema';
import campaignAttributes from 'pages/WACampaignAttributes/WACampaignAttributesSchema';
import deliveryScheduleSchema from 'pages/WACampaignSchedule/WACampaignScheduleSchema';
import audienceDefinitionSchema from 'pages/WACampaignAudience/WACampaignAudienceSchema';
// import designSmsSchema from 'pages/DesignSMS/designSmsSchema';

import { transformWADataForValidation } from 'selectors';

export const schemas = {
  summary: summarySchema(null, {}).getSchema(),
  campaignAttributes: campaignAttributes(null, {}).getSchema(),
  deliverySchedule: campaign =>
    deliveryScheduleSchema(null, {
      waType: campaign.waType,
      abTesting: campaign.abTesting
    }).getSchema(),
  audienceDefinition: audienceDefinitionSchema(null, {}).getSchema()
  // designSms: campaign =>
  //   designSmsSchema(null, {
  //     defaultValues: campaign
  //   }).getSchema()
};

export const validateWACampaign = async campaign => {
  let transformedData = transformWADataForValidation(campaign, true);

  const summary = await schemas.summary.isValid(transformedData.summary);

  const campaignAttributes = await schemas.campaignAttributes.isValid(
    transformedData.campaignAttributes,
    campaign.abTesting
  );

  const deliverySchedule = await schemas
    .deliverySchedule(transformedData)
    .isValid(transformedData.deliverySchedule);

  let deliveryScheduleError = null;
  try {
    await schemas
      .deliverySchedule(transformedData)
      .validate(transformedData.deliverySchedule);
  } catch (err) {
    deliveryScheduleError = err;
  }

  const audienceDefinition = await schemas.audienceDefinition.isValid(
    transformedData.audienceDefinition
  );

  // const designSMS = await schemas
  //   .designSms(campaign)
  //   .isValid({...transformedData.designSMS, optOutText: campaign.optOutText, optOutTextFollowUp: campaign.optOutText });

  const dynamicCouponError = validateDynamicCoupon(campaign);

  return {
    summary: !summary,
    campaignAttributes: !campaignAttributes,
    deliverySchedule: !deliverySchedule,
    deliveryScheduleError: deliveryScheduleError,
    audienceDefinition: !audienceDefinition,
    // designSMS: !designSMS,
    dynamicCouponError: !dynamicCouponError
  };
};

export const validateWAContent = (waBriefing, waEditTemplate) => {};

export const validateDynamicCoupon = campaign => {
  const { waAttributes } = campaign;

  if (waAttributes) {
    if (waAttributes?.waCoupon?.type === 'dynamicCoupon') {
      const couponFileUpload = waAttributes?.waCoupon?.couponFileUpload;

      if (couponFileUpload === 'failed') {
        if (waAttributes?.waCoupon?.kanaTicketNumber) {
          return true;
        } else {
          return false;
        }
      }
      if (couponFileUpload === 'success') {
        return true;
      }

      if (couponFileUpload === 'pending' || couponFileUpload === '') {
        return false;
      }
    }
    return true;
  }
  return true;
};
