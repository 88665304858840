import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import moment from 'moment';

const WAChatLTO = ({ data, headerType }) => {
  const showLTO = get(data, 'waLtoDetails.ltoEnable', false);
  const isHeaderImage = headerType === 'headerImage';
  console.log('isHeaderImage: ', isHeaderImage);
  return (
    showLTO && (
      <div
        className={classNames('wa-lto-container', {
          'mar-top': !isHeaderImage
        })}
      >
        <div
          className={classNames('wa-lto-box', {
            'rounded-top': !isHeaderImage,
            'rounded-bottom': true
          })}
        >
          <div className="wa-lto-icon">
            <span className="">
              <i className="zmdi zmdi-card-giftcard"></i>
            </span>
          </div>
          <div className="wa-lto-info">
            <span className="wa-lto-title">
              {get(data, 'waLtoDetails.ltoSummary')}
            </span>
            {get(data, 'waLtoDetails.expirationEnable') && (
              <span className="wa-lto-time">
                Offer ends on{' '}
                {moment(get(data, 'waLtoDetails.expirationDate')).format(
                  'MMM YY'
                )}
              </span>
            )}
            <span className="wa-lto-code">Code BUYONEGETONE</span>
          </div>
        </div>
      </div>
    )
  );
};

export default WAChatLTO;
