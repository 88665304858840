import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormBuilder } from 'components/organisms/FormBuilder';
import ProgressBar from 'components/molecules/ProgressBar';
import Loader from 'components/Loader';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';

import formSchema from './WACampaignAttributesSchema';

import constants from 'constants/config';

const WACampaignAttributes = props => {
  const { isLoading, waCommsData } = useSelector(state => state.waBriefings);
  const [isEdit, setEdit] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const { status } = waCommsData

  useEffect(() => {
    const pathUid = props.match.params.id;
    if (pathUid) {
      setEdit(true);
    }
    if (pathUid) {
      dispatch(getWAcommsByUid(pathUid));
    }
  }, []);

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: waCommsData,
    status : status
  };

  return (
    <div className="col-sm-12 skypink email-campaign-brand-selection">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.CAMPAIN_ATTRIBUTES}
          totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Campaign attributes
          </h2>
        </div>

        <div className="row">
          {isLoading ? <Loader /> : null}

          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps, {})}
              user={authorizedUserData}
              // isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WACampaignAttributes;
