import * as yup from 'yup';
import constants from 'constants/config';
import React from 'react';
import { transformWAPayloadBySchedule } from 'selectors';
import ReactTooltip from 'react-tooltip';
import { EmailRequestInfoTextInput } from 'components/molecules/EmailRequestInfoTextInput';
import { updateWAComms } from 'actions/wabriefings/waComms';


export const LableText = () => {
  return (
    <>
      <ReactTooltip
        id="updateLable_tooltip"
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <span
        className={'cw-icon cw-icon--help'}
        style={{
          display: 'block',
          whiteSpace: 'pre'
        }}
        data-tip="This is the label requestors can use to view performance metrics of their email in Power BI trackers"
        data-for="updateLable_tooltip"
      > <i className='fa fa-question-circle'></i></span>
    </>
  );
};

const CampaignRequestInfoSchema = (
  dispatch,
  { defaultValues, pathUid, closeModal, setToggle, waType, status, isCrossBrand ,requestInfoText,toggle }
) => {

  return {
    formType: 'modal',
    submitBtnLabel: 'Submit',
    btnClassName:'d-flex justify-content-end mt-20',
    modelBtnSwap : true,
    fields: [
      {
        type: 'custom',
        name: 'requestInfoText',
        component: EmailRequestInfoTextInput,
        id: 'requestInfoText',
        label:'Describe your issue',
        emailType: waType,
        rules:yup.string().required('Please describe the information to request')
      },
      {
        type: 'label',
        label: toggle ? "This campaign will be assigned back to the requestor so that they can provide the missing information and will remain in 'Awaiting info' state." :"The campaign will remain assigned to ACS team in Awaiting info state, while they wait for information to be received by contributors.",
        labelClassName: 'mt-n2 email-type-desc-font'
      },

    ],
    onSubmit: (data, { user }) => {
      console.log('data: ', data);
      
      const paramUid = pathUid;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        requestInfoText : data.requestInfoText,
        requestInfoAnswerText : null,
        status:
          constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP === status || constants.WA_CAMPAIGN_STATUS.AWAITING_INFO === status
            ? constants.WA_CAMPAIGN_STATUS.AWAITING_INFO
            : constants.WA_CAMPAIGN_STATUS.SCHEDULED,
        nmiAssignedToRequestor : toggle
      };
      const transformedData = transformWAPayloadBySchedule(dataToSend);
      dispatch(
        updateWAComms(paramUid,
          transformedData,
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              closeModal(true);
            }
          },
          { disableLoader: true, disableStoreUpdate: false}
        )
      );
    },
    onPrevious: () => {
      setToggle(false)
      closeModal(false);
    },
    previousBtnLabel: 'Close',
    disableFormError: true
  };
};

export default CampaignRequestInfoSchema;
