import React from 'react';

const WAChatBody = ({data}) => {
  return data? (
    <div className="message-body">
      {data}
    </div>
  ) : null;
};

export default WAChatBody;
