import React from 'react';
import ReactTooltip from 'react-tooltip';
import errorIcon from '../../../images/error_icon.png';
import validIcon from '../../../images/valid-green.png';

const ErrorIcon = ({ isValid ,id}) => {
  const isValidated = isValid === true || isValid === false;
  return (
    <>
      <ReactTooltip
        id="error_indicator_tooltip"
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip saas_new_master_tooltip"
      />
      <span
        key={id}
        className={'field-validation-icon'}
        style={{ display: 'block', color: '#E9A40A' }}
        data-tip={isValid ? '' : 'Missing Configurations'}
        data-for="error_indicator_tooltip"
      >
        <img
          className={isValidated ? (isValid ? '' : 'hidden') : 'hidden'}
          src={validIcon}
          alt={'Valid'}
        />
        <img
          className={isValidated ? (isValid ? 'hidden' : '') : 'hidden'}
          src={errorIcon}
          alt={'Invalid'}
        />
      </span>
    </>
  );
};

export default ErrorIcon;
