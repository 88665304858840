import React from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

const WAABTestEdgeCaseValidation = ({
  isOpen,
  onCloseModal,
  handleCloseModal,
  onHandleRemoveABTest
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--filePicker-info"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper image-guideline">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={handleCloseModal}
        ></span>
        <div>
          <div className="d-flex mb-20">
            <div className="ml-10">
              <div
                className={
                  'cw-heading--saascampaign send-test-email__title mb-20'
                }
              >
                Confirm A/B test choices
              </div>
              <div className="scrollable email-overlay-scroll">
                <div className="d-flex">
                  <span className="cw-error">
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                  </span>
                  <span className="cw-error">
                    You are trying to turn OFF a previously configured A/B test.
                    If you have already configured subject or email content
                    variants, they will be removed
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse">
            <button
              className="btn btn-outline-secondary"
              onClick={onHandleRemoveABTest}
            >
              Remove A/B test
            </button>
            <button
              className="btn btn-primary mr-10"
              onClick={handleCloseModal}
            >
              Retain A/B test
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WAABTestEdgeCaseValidation;
