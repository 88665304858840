// bannerReducer.js
import {
    OPEN_CW_BANNER_MODAL,
    CLOSE_CW_BANNER_MODAL,
    FETCH_BANNER_REQUEST,
    FETCH_BANNER_SUCCESS,
    FETCH_BANNER_FAILURE,
    SAVE_BANNER_REQUEST,
    SAVE_BANNER_SUCCESS,
    SAVE_BANNER_FAILURE
  } from '../actionTypes';
  
  const initialState = {
    message: '',
    isLoading: false,
    isModalOpen: false,
    error: null
  };
  
  export const adminConfig = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_CW_BANNER_MODAL:
        return { ...state, isModalOpen: true };
      case CLOSE_CW_BANNER_MODAL:
        return { ...state, isModalOpen: false };
      case FETCH_BANNER_REQUEST:
        return { ...state, isLoading: true, error: null };
      case FETCH_BANNER_SUCCESS:
        return { ...state, isLoading: false, message: action.payload };
      case FETCH_BANNER_FAILURE:
        return { ...state, isLoading: false, error: action.error };
      case SAVE_BANNER_REQUEST:
        return { ...state, isLoading: true, error: null };
      case SAVE_BANNER_SUCCESS:
        return { ...state, isLoading: false, message: action.payload, isModalOpen: false };
      case SAVE_BANNER_FAILURE:
        return { ...state, isLoading: false, error: action.error };
    
    
      default:
        return state;
    }
  };
  