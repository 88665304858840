import { updateWAComms } from 'actions/wabriefings/waComms';

import constants from 'constants/config';
import { transformWAPayloadBySchedule } from 'selectors';

const WAEditInfoSchema = (dispatch, { pathUid, closeModal }) => {
  return {
    formType: 'modal',
    submitBtnLabel: 'I want to edit',
    btnClassName: 'd-flex justify-content-end mt-20',
    modelBtnSwap: true,
    fields: [
      {
        type: 'label',
        label:
          'Do you want to edit your campaign and resubmit it? Please note that the campaign will go through the whole workflow again. Once you resubmit, it will be assigned for market-business group approval and then to ACS team for sharing a revised cost estimate',
        id: 'AudienceCountInfo',
        labelClassName: 'mt-n2 email-type-desc-font'
      }
    ],
    onSubmit: (data, { user }) => {
      const paramUid = pathUid;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        status: constants.WA_CAMPAIGN_STATUS.DRAFT,
        isEdited: true
      };

      const transformedData = transformWAPayloadBySchedule(dataToSend);

      dispatch(
        updateWAComms(
          paramUid,
          transformedData,
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              closeModal(false);
            }
          },
          { disableLoader: true }
        )
      );
    },
    onPrevious: () => {
      closeModal(false);
    },
    previousBtnLabel: 'Cancel',
    disableFormError: true
  };
};

export default WAEditInfoSchema;
