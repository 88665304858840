import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import HistoryComponent from 'components/history';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import Layout from 'components/organisms/Layout/Layout';
import NotFound from 'components/NotFound';
import Toolbar from 'components/communications/Toolbar';
import WASummaryCards from 'components/organisms/EmailSummaryCards/WASummaryCards';
import WAOverviewTables from 'components/organisms/WAOverviewTables';
import Alert from 'components/Alert';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import Loader from 'components/Loader';
import DeleteConfirmationModal from './DeleteWACampaignModal';
import { setNotificationMessage } from 'actions/dashboard';
import MobileDeviceFrame from 'components/organisms/MobileDeviceFrame';

import {
  checkSMSCampaignEditRights,
  userHasAwaitingPurchaseOrderPermission,
  userHasResubmitPermission
} from 'selectors';

import print_icon from 'images/print_icon.png';
import feedback_icon from 'images/feedback_icon.png';
import DownloadIcon from 'images/downloadIcon';
import RequestMoreInfoIcon from 'images/requestMoreInfoIcon';

import { getWAcommsByUid } from 'actions/wabriefings/waComms';
import { fetchHistoryLogs } from 'actions/history';
import { openFeedbackForm } from 'actions/feedbackForm';
import {
  deleteWABriefingbyUid,
  downloadWACostEstimate,
  downloadWAPurchaseOrder
} from 'services/waBriefings';

import WAPurchaseOrderModel from 'components/organisms/WAPurchaseOrderModal';
import WAEditModel from 'components/organisms/WAEditModal';

import constants from 'constants/config';
import DownloadLinkBtn from 'components/molecules/DownloadLinkBtn';

const WACampaignOverview = props => {
  const pathUid = props.match.params.id;
  const dispatch = useDispatch();

  const [notFound, setNotFound] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPurchaseOrderModalOpen, setIsPurchaseOrderModalOpen] = useState(
    false
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [errorObject, setErrorObject] = useState({
    message: '',
    isError: false
  });
  const [mobilePreviewData, setMobilePreviewData] = useState();

  const { waCommsData, isLoading } = useSelector(state => state.waBriefings);
  const historyLogs = useSelector(state => state.historyLogs);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const previewRef = useRef();

  useEffect(() => {
    if (pathUid) {
      dispatch(
        getWAcommsByUid(pathUid, (err, _) => {
          if (err) {
            setNotFound(true);
          }
        })
      );
      dispatch(fetchHistoryLogs(pathUid));
    }
  }, []);

  useEffect(() => {
    if (waCommsData.waDesign) {
      const yoData = {
        ...get(waCommsData, 'waDesign', {}),
        brand: get(waCommsData, 'brand')
      };
      setMobilePreviewData(yoData);
    }
  }, [waCommsData]);

  const {
    name,
    status,
    rejectComments,
    requestInfoText,
    nmiAssignedToRequestor,
    requestorsEmail,
    emailCollaborators = {},
    shareCostEstimate = {},
    sharePurchaseOrder = {},
    isEdited = false
  } = waCommsData || {};

  const showPurchaseOrderCta =
    constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER === status;

  const isDraft =
    String(status) === '1' ||
    String(status) === '5' ||
    (nmiAssignedToRequestor === undefined &&
      String(status) === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO) ||
    (String(status) === '8' && nmiAssignedToRequestor === true);

  const isRejected =
    String(status) === constants.WA_CAMPAIGN_STATUS.REJECTED ||
    (nmiAssignedToRequestor === undefined &&
      String(status) === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO) ||
    (String(status) === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO &&
      nmiAssignedToRequestor === true);

  const userHasEditRights = checkSMSCampaignEditRights(
    waCommsData,
    authorizedUserData
  );

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  };

  const shareCostAdditionalComments =
    shareCostEstimate?.additionalcomments || '';
  const shareCostGcsPath = shareCostEstimate?.costFile?.gcsPath || '';
  const sharePurchaseOrderPath = sharePurchaseOrder?.poFile?.gcsPath || '';

  const awaitingPurchaseOrderUserPermission = userHasAwaitingPurchaseOrderPermission(
    requestorsEmail,
    currentUser,
    emailCollaborators
  );

  const userClickPermission =
    awaitingPurchaseOrderUserPermission &&
    checkSMSCampaignEditRights(props && waCommsData, authorizedUserData);

  const handleDownloadCostEstimateClick = (
    link,
    userPermission,
    setIsDownloading
  ) => {
    if (userPermission) {
      const [_, ...fileNameParts] = link.replace('gs://', '').split('/');

      const fileName = fileNameParts.join('/');
      const uid = pathUid;

      if (setIsDownloading) {
        setIsDownloading(true);
      }

      downloadWACostEstimate(uid)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          //setIsDownloading(false);
        })
        .catch(error => {
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          //setIsDownloading(false);
          console.log('error: ', error);
        });

      setMessage('');
      setIsError(false);
    } else {
      setMessage(constants.DOWNLOAD_COST_ESTIMATE_ERR_MSG);
      setIsError(true);
    }
  };

  const handleDownloadPOClick = (link, userPermission, setIsDownloading) => {
    if (userPermission) {
      const [_, ...fileNameParts] = link.replace('gs://', '').split('/');

      const fileName = fileNameParts.join('/');

      if (setIsDownloading) {
        setIsDownloading(true);
      }
      //setIsDownloading(true);

      downloadWAPurchaseOrder(pathUid)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          //setIsDownloading(false);
        })
        .catch(error => {
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          //setIsDownloading(false);
          console.log('error: ', error);
        });
    }
  };

  const handlePurchaseOrderModalClick = userPermission => {
    if (userPermission) {
      setIsPurchaseOrderModalOpen(true);
      setMessage('');
      setIsError(false);
    } else {
      setMessage(constants.UPLOAD_PO_ERR_MSG);
      setIsError(true);
    }
  };

  const goToFirstPage = () => {
    if (userHasEditRights) {
      props.history.push(
        `${constants.WA_BASE_ROUTE}/${pathUid}/${constants.WA_ROUTES.BRAND_DETAILS}`
      );
    } else {
      setErrorObject({
        message: constants.DRAFT_DELETE_PERMISSON_MSG,
        isError: true
      });
    }
  };

  const redirect = (path, lastUpdatedScreen) => {
    if (
      [`${constants.WA_ROUTES.NEW_CAMPAIGN_SUMMARY}`].includes(
        lastUpdatedScreen
      )
    ) {
      props.history.push(path + constants.WA_ROUTES.CAMPAIGN_SUMMARY);
    } else {
      props.history.push(path + lastUpdatedScreen);
    }
  };

  const resumeWhereYouLeft = () => {
    if (userHasEditRights) {
      const path = `${constants.WA_BASE_ROUTE}/${pathUid}/`;
      const { lastUpdatedScreen, status } = waCommsData;
      const isCampaignRejected =
        status === constants.WA_CAMPAIGN_STATUS.REJECTED;

      if (lastUpdatedScreen && !isCampaignRejected) {
        redirect(path, lastUpdatedScreen);
      } else {
        props.history.push(path + constants.WA_ROUTES.BRAND_DETAILS);
      }
    } else {
      setErrorObject({
        message: constants.DRAFT_DELETE_PERMISSON_MSG,
        isError: true
      });
    }
  };

  const deleteWACampaign = () => {
    let campaignName = name || 'Untitled';
    let reqbody = {
      uid: pathUid,
      userEmail: authorizedUserData.emailId,
      userName: authorizedUserData.name
    };

    deleteWABriefingbyUid(reqbody)
      .then(result => {
        dispatch(
          setNotificationMessage(
            `${campaignName} has been deleted.`,
            undefined,
            'warning'
          )
        );
        props.history.push(
          `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
        );
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  const closePurchaseOrderModalClick = () => {
    setIsPurchaseOrderModalOpen(false);
    dispatch(fetchHistoryLogs(pathUid));
  };

  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: pathUid,
        userId: authorizedUserData._id,
        defaultCampaignRequest: 'WA',
        showCampaignRequest: false
      })
    );
  };

  const handleDeleteClick = () => {
    if (userHasEditRights) {
      setIsDeleteModalOpen(true);
    } else {
      setErrorObject({
        message: constants.DRAFT_DELETE_PERMISSON_MSG,
        isError: true
      });
    }
  };

  if (notFound) {
    return <NotFound />;
  } else if (isLoading || isDownloading) {
    return <Loader />;
  } else {
    return (
      <>
        <WAPurchaseOrderModel
          modalIsOpen={isPurchaseOrderModalOpen}
          closeModal={closePurchaseOrderModalClick}
          closePoModelClick={() => setIsPurchaseOrderModalOpen(false)}
          waDetails={waCommsData || {}}
          pathUid={pathUid}
        />
        <WAEditModel
          modalIsOpen={isEditModalOpen}
          closeModal={val => setIsEditModalOpen(val)}
          waDetails={waCommsData || {}}
          pathUid={pathUid}
        />

        <div className="skypink ml-5 mr-5">
          <div className="cw-section cw-overview">
            <div className="draft_button_section_main">
              <Alert
                type={'warning'}
                message={rejectComments ? rejectComments : ''}
                alertType={'rejectcomment'}
                showComponent={status === '5'}
              />
              <Alert
                alertType={'saasWarningAlertMessageWithIcon'}
                textMessage={errorObject.message}
                showComponent={errorObject.isError}
                closable={true}
                onClose={() => {
                  setErrorObject({ message: '', isError: false });
                }}
              />

              {status ===
                constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER && (
                <div className={`row email-summary-font mt-4`}>
                  <div className="col-sm-12">
                    <div
                      className={`alert alert-warning`}
                      role="alert"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER ===
                        status && (
                        <>
                          ACS team has shared a cost estimate (download it{' '}
                          <DownloadLinkBtn
                            onClick={(e, setDWloader) => {
                              e.preventDefault();
                              handleDownloadCostEstimateClick(
                                shareCostGcsPath,
                                userClickPermission,
                                setDWloader
                              );
                            }}
                            label="here"
                            loaderClass="alert-link-loader"
                          />
                          ). Please create a Purchase Order in Coupa and upload
                          it to your campaign.
                          {shareCostAdditionalComments && (
                            <>
                              <br />
                              ACS team comments&#58;{' '}
                              {shareCostAdditionalComments}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <Alert
                alertType={'saasWarningAlertMessageWithIcon'}
                textMessage={message}
                showComponent={isError}
                closable={true}
                onClose={() => setIsError(false)}
              />
              <Alert
                type={'warning'}
                className={'mt-4'}
                message={requestInfoText ? requestInfoText : ''}
                alertType={'requestMoreInfo'}
                showComponent={status === '8'}
                title={
                  nmiAssignedToRequestor
                    ? `ACS team has requested more from requestor  `
                    : `ACS team is awaiting more information internally - there is no next step on requestor `
                }
              />

              <PageHeader
                heading={name || 'Untitled'}
                status={status}
                subHeading={
                  isDraft ? 'Continue creating your campaign' : undefined
                }
                review
              />

              <div className="d-flex justify-content-between">
                <div className="flex-grow-1 cw-print-hide">
                  <Toolbar>
                    {!isDraft && (
                      <Toolbar.Item
                        icon={print_icon}
                        name="Print"
                        onClick={() => window.print()}
                      />
                    )}

                    {constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER ===
                      status && (
                      <Toolbar.DownloadItem
                        svgIcon={DownloadIcon}
                        name="Download cost estimate"
                        onClick={(e, setDWloader) => {
                          e.preventDefault();
                          handleDownloadCostEstimateClick(
                            shareCostGcsPath,
                            userClickPermission,
                            setDWloader
                          );
                        }}
                      />
                    )}

                    {showPurchaseOrderCta && (
                      <Toolbar.Item
                        svgIcon={RequestMoreInfoIcon}
                        name="Send purchase order to ACS"
                        onClick={() =>
                          handlePurchaseOrderModalClick(userClickPermission)
                        }
                      />
                    )}

                    {(isDraft || isRejected) && (
                      <Toolbar.Item
                        icon={feedback_icon}
                        fontIcon="fas fa-trash toolbar-component__icon w-35"
                        name="Delete"
                        onClick={handleDeleteClick}
                      />
                    )}

                    <Toolbar.Item
                      icon={feedback_icon}
                      name="Feedback"
                      onClick={handleOnOpenFeedback}
                    />
                  </Toolbar>
                </div>

                {showPurchaseOrderCta && (
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        handlePurchaseOrderModalClick(userClickPermission)
                      }
                    >
                      Send purchase order to ACS
                    </button>

                    {userHasResubmitPermission(
                      requestorsEmail,
                      currentUser,
                      emailCollaborators
                    ) && (
                      <button
                        className="btn btn btn-outline-secondary mt-10"
                        onClick={() => setIsEditModalOpen(true)}
                      >
                        Edit and resubmit campaign
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            {status === constants.WA_CAMPAIGN_STATUS.AWAITING_FOR_APPROVAL && (
              <div className="mb-30">
                <EmailApproverInfo
                  emailBriefing={waCommsData || {}}
                  userData={authorizedUserData}
                />
              </div>
            )}

            {(isEdited || isRejected) && (
              <div className="d-flex">
                <div className="continue-section-empty"></div>
                <div className="continue-camp-btn-container">
                  <button
                    className="btn btn-primary w-100"
                    onClick={goToFirstPage}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}

            {isDraft && !isEdited && !isRejected && (
              <div className="SMS-continue-camp-btn-container">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-primary resume-email-camp"
                    onClick={resumeWhereYouLeft}
                  >
                    Resume where you left
                  </button>
                  <button
                    className="btn btn-outline-secondary mt-10"
                    onClick={goToFirstPage}
                  >
                    Start over
                  </button>
                </div>
              </div>
            )}

            <Layout
              className="mt-30"
              sidebar={
                <div>
                  <MobileDeviceFrame
                    ref={previewRef}
                    data={mobilePreviewData}
                  />
                </div>
              }
            >
              <WASummaryCards waCampaign={waCommsData} />

              <WAOverviewTables
                editable={false}
                waCampaign={waCommsData}
                downloadTrigger={(d1, setDWloader) => {
                  handleDownloadCostEstimateClick(
                    shareCostGcsPath,
                    userClickPermission,
                    setDWloader
                  );
                }}
                downloadPOTrigger={(d2, setDWloader) => {
                  handleDownloadPOClick(
                    sharePurchaseOrderPath,
                    userClickPermission,
                    setDWloader
                  );
                }}
              />

              <div className="overview-page-history mt-20">
                {historyLogs && historyLogs.length ? (
                  <HistoryComponent
                    historyLogs={historyLogs}
                    isEmailBrief={true}
                  />
                ) : null}
              </div>
            </Layout>
          </div>

          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            closeModal={isOpen => setIsDeleteModalOpen(isOpen)}
            deleteCampaign={deleteWACampaign}
            allowDelete={isDraft || isRejected}
          />
        </div>
      </>
    );
  }
};

export default WACampaignOverview;
