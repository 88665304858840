import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '../../atoms/Input';
import { useFieldArray, useWatch, Controller } from 'react-hook-form';
import { FieldError } from '../../atoms/FieldError';
import { get, set, cloneDeep } from 'lodash';
import Select from 'react-select';
import { FormReactSelect } from '../../molecules/FormReactSelect';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import classNames from 'classnames';

export const WAButtons = ({
  name,
  type,
  rules,
  register,
  label,
  subLabel,
  errors,
  control,
  defaultValue,
  maxRow,
  isMulti,
  ...props
}) => {

  const { fields, append, remove, replace, update, swap } = useFieldArray({
    control,
    name: name,
    defaultValue: defaultValue
  });
  console.log(
    '================== Fields:: =======',
    fields,
    ' defaultValue:: ',
    defaultValue,
    ' Errors ::',
    errors
  ); 
  const watchFieldArray = useWatch({ control, name: name });
  const controlledFields = fields.map((field, index) => {
    return { ...field, ...watchFieldArray[index] };
  });

  const hideAddButton = !(
    controlledFields.length >=
    (isMulti && maxRow ? maxRow : props.waCouponType ? 5 : 4)
  );

  const handleAppend = () => {
    const maxRows = isMulti && maxRow ? maxRow : props.waCouponType ? 5 : 4;
    if (controlledFields.length < maxRows) {
      append({
        type: isMulti
          ? {
              value: '',
              label: ''
            }
          : '',
        buttonName: '',
        buttonValue: ''
      });
      return true;
    }
  };
  const ButtonTypeOptions = [
    { value: 'Website URL', label: 'Website URL (max 2)' }
  ];
  if (props.waCouponType) {
    ButtonTypeOptions.push({
      value: 'Copy coupon code',
      label: 'Copy coupon code (max 1)'
    });
  }
  ButtonTypeOptions.push({
    value: 'Phone number',
    label: 'Phone number (max 1)'
  });
  ButtonTypeOptions.push({
    value: 'Unsubscribe quick reply',
    label: 'Unsubscribe quick reply (max 1)'
  });

  const exampleItems = {
    items: [
      {
        type: {
          value: 'Website URL11',
          label: 'Website URL (max 2)111'
        },
        buttonName: 'aaaaa111',
        buttonValue: 'http://abc.com111',
        id: '99eda84d-4cd2-4b4c-8fa3-44c04b76a0c3'
      },
      {
        type: {
          value: 'Website URL',
          label: 'Website URL (max 2)'
        },
        buttonName: 'aaaaa',
        buttonValue: 'http://abc.com',
        id: '99eda84d-4cd2-4b4c-8fa3-44c04b76a0c3'
      }
    ]
  };

  const [buttonOptions, setbuttonOptions] = useState(ButtonTypeOptions);
  const [rows, setExampleItems] = useState(exampleItems);

  //- react select-----
  const disableOptions = option => {
    if (isMulti) {
      return (
        watchFieldArray?.filter(opt => {
          return opt.type.value === option.value;
        }).length >= 1
      );
    } else {
      if (option.value === 'Website URL') {
        return (
          watchFieldArray?.filter(opt => {
            return opt.type.value === 'Website URL';
          }).length >= 2
        );
      } else {
        return (
          watchFieldArray?.filter(opt => {
            return opt.type.value === option.value;
          }).length >= 1
        );
      }
    }
  };
  //--------

  useEffect(() => {
    // sets the DB value
    props.setValue(name, defaultValue);
  }, [defaultValue]);

  const optionHandler = useCallback(value => {
    const optionToRemove = value;
    const newArray = buttonOptions.filter(
      obj =>
        obj.value !== optionToRemove.value || obj.label !== optionToRemove.label
    );
    setbuttonOptions(newArray);
  });

  /******** added bug WTG-15112 *******/
   const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(defaultValue, `${'carousel'}.messageType`)
  });
  if(messageType && messageType.value === 'carousel' && !isMulti) return null;
  /********bug WTG-15112 ******/

  // sortable -  ---
  //const {items} = rows;
  const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
  });
  const SortableItem = sortableElement(({ value }) => <li>{value}</li>);
  const SortableButtonRow = sortableElement(
    ({ field, rowInd, control, name, reg, compProps }) => {
      const index = rowInd;
      const register = reg;
      const props = compProps;
      console.log('=-=-==-=>>> props::', props);
      return (
        // <li>{rowInd}{field.buttonName}{name}</li>

        <>
          <div key={field.id}>
            <div className="d-flex justify-content-start align-items-center mt-10 wa-buttons-row">
              <div className="col-4 form-group">
                <label>Button type</label>
                <Select
                  {...field}
                  options={buttonOptions.map(option => ({
                    ...option,
                    isDisabled: disableOptions(option)
                  }))}
                  onChange={selectedOption => {
                    props.setValue(`${name}.${index}.type`, selectedOption);
                    props.setValue(`${name}.${index}.buttonValue`, ''); // reset buttonValue on type change
                  }}
                  placeholder="Please select"
                  className=""
                  defaultValue={field.type}
                />
                <FieldError errors={errors} name={`${name}.${index}.type`} />
              </div>
              <div className="col-4 form-group">
                <label>Button Name</label>
                <Input
                  type={type}
                  name={`${name}.${index}.buttonName`}
                  className="mt-10"
                  {...register(`${name}.${index}.buttonName`, {
                    required: true
                  })}
                  defaultValue={get(props.defaultValue, `${index}.buttonName`)}
                  maxLength={25}
                />
                <FieldError
                  errors={errors}
                  name={`${name}.${index}.buttonName`}
                />
              </div>
              <div className="col-3 form-group">
                <Inputvalue
                  control={control}
                  watchValue={`${name}.${index}.type`}
                  name={`${name}`}
                  index={index}
                  register={register}
                  onChangeHandler={optionHandler}
                />
                <FieldError
                  errors={errors}
                  name={`${name}.${index}.buttonValue`}
                />
              </div>
              <div className="col form-group">
                <button
                  type="button"
                  className="btn-circle btn-remove ml-10 mt-30"
                  onClick={() => remove(index)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }
  );

  // const onSortEnd = ({oldIndex, newIndex}) => {
  //   console.log('===== on sort end ::',items, oldIndex, newIndex)
  //   setExampleItems({items:arrayMove(items, oldIndex, newIndex)})
  // };

  // TODO - check new plugin or try using update after sorting fields

  const onSortEndFields = ({ oldIndex, newIndex }) => {
    console.log(
      '===== START on sort end ::',
      controlledFields,
      oldIndex,
      newIndex
    );
    //setExampleItems(arrayMove(fields, oldIndex, newIndex))
    //props.setValue(name, arrayMove(controlledFields, oldIndex, newIndex));
    swap(oldIndex, newIndex);
    console.log(
      '===== ENS on sort end ::',
      controlledFields,
      ' sorted array:',
      arrayMove(controlledFields, oldIndex, newIndex)
    );
  };

  const showCommonErrors = props.showCommonErrors;
  const ButtonRemove = ({ index }) => {
    return (
      <div className='form-group row-action'
        // className={classNames('form-group', {
        //   'row-action-carousal': isMulti,
        //   'row-action': !isMulti
        // })}
      >
        <button
          type="button"
          className="btn-circle btn-remove ml-10 mt-30"
          onClick={() => remove(index)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  };

  return (
    <div className="form-group">
      {/* <SortableContainer onSortEnd={onSortEnd}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value.buttonName} />
        ))}
      </SortableContainer> */}

      {label && (
        <label htmlFor={props.id}>
          {label}
          {subLabel && <div className="description mb-20">{subLabel}</div>}
        </label>
      )}

      {/* <SortableContainer onSortEnd={onSortEndFields}> */}
      {controlledFields.map((field, index) => {
        //return <SortableButtonRow key={`item-${field.id}`} index={index} field={field} rowInd={index} control={control} name={name} reg={register} compProps={props}  />
        const errorMessages = get(errors, `${name}.${index}`);
        const hasError = !!(errors && errorMessages);
        return (
          <div key={index} className="container">
            <div
              className={
                errors && errors.waButtons && errors.waButtons.length
                  ? 'd-flex justify-content-start align-items-center mt-10 wa-buttons-row row errors'
                  : 'd-flex justify-content-start align-items-center mt-10 wa-buttons-row row'
              }
            >
              <div
                className={
                  errorMessages && errorMessages['type']
                    ? 'col-4 form-group error-col'
                    : 'col-4 form-group'
                }
              >
                <label>Button type</label>
                <Select
                  {...field}
                  options={buttonOptions.map(option => ({
                    ...option,
                    isDisabled: disableOptions(option)
                  }))}
                  onChange={selectedOption => {
                    let defaultVal = '';
                    if (selectedOption.value === 'Copy coupon code') {
                      defaultVal = props.waCouponCode;
                    }
                    props.setValue(`${name}.${index}.type`, selectedOption);
                    props.setValue(`${name}.${index}.buttonValue`, defaultVal); // reset buttonValue on type change
                  }}
                  placeholder="Please select"
                  className={
                    errorMessages && errorMessages['type']
                      ? 'cw-error-focus'
                      : ''
                  }
                  defaultValue={
                    get(field, 'type') && get(field, 'type.value')
                      ? get(field, 'type')
                      : ''
                  }
                />
                {!showCommonErrors && (
                  <FieldError errors={errors} name={`${name}.${index}.type`} />
                )}
              </div>
              <div
                className={
                  errorMessages && errorMessages['buttonName']
                    ? 'col-4 form-group error-col'
                    : 'col-4 form-group'
                }
              >
                <label>Button Name</label>
                <Input
                  type={type}
                  name={`${name}.${index}.buttonName`}
                  className={
                    errorMessages && errorMessages['buttonName']
                      ? 'cw-error-focus'
                      : ''
                  }
                  {...register(`${name}.${index}.buttonName`, {
                    required: true
                  })}
                  defaultValue={get(props.defaultValue, `${index}.buttonName`)}
                  maxLength={25}
                />
                {!showCommonErrors && (
                  <FieldError
                    errors={errors}
                    name={`${name}.${index}.buttonName`}
                  />
                )}
              </div>
              <div
                className={
                  errorMessages && errorMessages['buttonValue']
                    ? 'col-4 form-group col-with-button error-col'
                    : 'col-4 form-group col-with-button'
                }
              >
                <Inputvalue
                  control={control}
                  watchValue={`${name}.${index}.type`}
                  name={`${name}`}
                  index={index}
                  register={register}
                  onChangeHandler={optionHandler}
                  className={
                    errorMessages && errorMessages['buttonValue']
                      ? 'cw-error-focus'
                      : ''
                  }
                />
                {!showCommonErrors && (
                  <FieldError
                    errors={errors}
                    name={`${name}.${index}.buttonValue`}
                  />
                )}
              </div>
              {isMulti ? (
                index === 1 ? (
                  <ButtonRemove index={index} />
                ) : null
              ) : (
                <ButtonRemove index={index} />
              )}
              {/* Common Error for each row */}
              {showCommonErrors && hasError && (
                <div className="row">
                  <div className={'col-12 show-common-error pb-2 ml-2'}>
                    <span className={'cw-error'}>
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please specify all button properties
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {/* </SortableContainer> */}

      {hideAddButton && (
        <button
          type="button"
          className="btn btn-primary mt-10"
          onClick={() => handleAppend({ value: '' })}
        >
          Add button
        </button>
      )}
    </div>
  );
};

// TODO: make it external
const Inputvalue = ({
  control,
  watchValue,
  name,
  index,
  register,
  onChangeHandler
}) => {
  const value = useWatch({ control, name: watchValue });
  //console.log('----count -- ',countOptionOccurrences(control,value).length)
  //onChangeHandler(value)
  switch (value.value) {
    case 'Website URL':
      return (
        <>
          <label htmlFor="">{'Website URL'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Target URL"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    case 'Copy coupon code':
      return (
        <>
          <label htmlFor="">{'Copy coupon code'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Coupon Code"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    case 'Phone number':
      return (
        <>
          <label htmlFor="">{'Phone number'}</label>
          <input
            className="form-control"
            type="number"
            placeholder="Phone Number"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={20}
            form="novalidatedform"
          />
        </>
      );
    case 'Unsubscribe quick reply':
      return (
        <>
          <label htmlFor="">{'Unsubscribe quick reply'}</label>
          <input
            className="form-control"
            type="text"
            placeholder="Unsubscribe quick reply"
            {...register(`${name}.${index}.buttonValue`)}
            maxLength={25}
          />
        </>
      );
    default:
      return null;
  }
};
