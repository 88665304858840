import React from 'react';

import GuideLines from 'components/GuideLines';
import constants from 'constants/config';
import MasterDataGuideLines from 'components/MasterDataGuideLines';

export default () => {
  return (
    <div className="cw--home skypink mt-5">
      <div className="row">
        <MasterDataGuideLines
          data={constants.WA_ACS_GUIDE_LINES}
          qnaIndex={true}
          noLineBreak={true}
          ulStyle={{ listStyleType: 'circle', marginLeft: 20 }}
          liStyle={{ listStyleType: 'circle', paddingLeft: 0 }}
          liClassName="unordered-list-item"
          noUlLineBreak={false}
        />
      </div>
    </div>
  );
};
