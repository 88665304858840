import React, { Component } from 'react';
import Loader from './Loader';
import { connect } from 'react-redux';
import {
  getFormatedDate,
  getUserCountryList,
  getCampaignStatusText
} from '../selectors/index';
import CheckboxDropDown from './CheckboxDropDown';
import { getBrandCountries } from '../actions/dashboard';
import axios from 'axios';
import * as XLSX from 'xlsx';
import moment from 'moment';
import constant from '../constants';
import {
  fetchApproverFilterData,
  fetchUserData
} from '../actions/user-management';
import SearchIcon from '../images/find.png';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';
class TabPanelContent extends Component {
  constructor(props) {
    super(props);
    this.uploadExcelRef = React.createRef();
    this.state = {
      selectedCountry: [],
      selectedCampaignFilter: [],
      filterUserData: [],
      selectedCountryArr: [],
      file: null,
      query: '',
      selectedBusinessFilter: []
    };
    this.renderUserData = this.renderUserData.bind(this);
    this._getCountries = this._getCountries.bind(this);
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this._handleCampaignDropDownValue = this._handleCampaignDropDownValue.bind(
      this
    );
    this._handleSearch = this._handleSearch.bind(this);
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handleBusinessGroupDropDownValue = this._handleBusinessGroupDropDownValue.bind(
      this
    );
  }

  componentDidMount() {
    this._setRoleBaseFilters();
    this.props.getBrandCountries();
  }

  _setRoleBaseFilters() {
    if (
      this.props.authorizedUserData &&
      this.roleForFilter.includes(this.props.authorizedUserData.group)
    ) {
      if (
        this.props.authorizedUserData &&
        this.props.authorizedUserData.country &&
        this.props.authorizedUserData.country.length
      ) {
        this.country = getUserCountryList(
          this.props.authorizedUserData.country
        );
        this.setState({ brandcountry: this.country });
      }
    }
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.code + '-' + country._id, label: country.name };
      });
    }
  }

  _getBusinessGroup() {
    let { businessGroup } = this.props;
    if (businessGroup && businessGroup.length > 0) {
      return businessGroup.map(function(bu) {
        return { value: bu._id, label: bu.name };
      });
    }
  }

  getUserDataBasedOnFilter(
    selectedCountryData,
    selectedCampaignData,
    selectedBusinessData
  ) {
    const filter = {
      selectedCountry: selectedCountryData
        ? selectedCountryData
        : this.state.selectedCountryArr,
      selectedCampaign: selectedCampaignData
        ? selectedCampaignData
        : this.state.selectedCampaignFilter,
      selectedBusiness: selectedBusinessData
        ? selectedBusinessData
        : this.state.selectedBusinessFilter,
      sortBy :  this.props.sortBy,
      sortKey :  this.props.sortKey
    };
    if (
      filter &&
      filter.selectedCountry.length === 0 &&
      filter.selectedCampaign.length === 0 &&
      filter.selectedBusiness.length === 0
    ) {
      this.props.fetchUserData('approver', '', '', '', this.state.query);
    } else {
      this.props.fetchApproverFilterData('approver', filter,this.state.query);
    }
  }

  _handleCampaignDropDownValue(selectedOption, action) {
    this.getUserDataBasedOnFilter('', selectedOption);
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedCampaignFilter: selectedOption
      });
    } else {
      this.setState({
        selectedCampaignFilter: selectedOption
      });
    }
  }
  _handleBusinessGroupDropDownValue(selectedOption) {
    this.getUserDataBasedOnFilter('', '', selectedOption);
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedBusinessFilter: selectedOption
      });
    } else {
      this.setState({
        selectedBusinessFilter: selectedOption
      });
    }
  }

  _handleDropDownValue(selectedOption, action) {
    let selectedCoutryData = [];
    if (selectedOption && selectedOption.length) {
      for (const element of selectedOption) {
        let countryId =
          element && element['value'] ? element['value'].split('-')[1] : null;
        if (countryId) {
          selectedCoutryData.push(countryId);
        }
      }
    }
    this.getUserDataBasedOnFilter([...new Set(selectedCoutryData)], '');
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedCountry: selectedOption,
        selectedCountryArr: selectedCoutryData
      });
    } else {
      this.setState({
        selectedCountry: selectedOption,
        selectedCountryArr: selectedCoutryData
      });
    }
  }

  _handleSearch(event) {
    let searchText = this.state.query;
    const filter = {
      selectedCountry: this.state.selectedCountryArr,
      selectedCampaign: this.state.selectedCampaignFilter,
      selectedBusiness: this.state.selectedBusinessFilter,
      sortBy :  this.props.sortBy,
      sortKey :  this.props.sortKey
    };
    if (
      filter &&
      filter.selectedCountry.length === 0 &&
      filter.selectedCampaign.length === 0 &&
      filter.selectedBusiness.length === 0
    ) {
      this.props.fetchUserData('approver', '', '', '', searchText);
    } else {
      this.props.fetchApproverFilterData('approver', filter,searchText);
    }
  }

  renderUserData(userdata, asyncInProgress) {
    const { campaignConfig } = this.props;
    if (asyncInProgress) {
      return (
        <tr>
          <td>
            <Loader />
          </td>
        </tr>
      );
    } else {
      if (!userdata.length) {
        return (
          <tr>
            <td>
              <span className='mt-20 mb-10 ml-10'>{`No approver added.`}</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      } else {
        return userdata.map((user, index) => {
          let lastUpdatedDate = user.lastUpdatedDate
            ? getFormatedDate(user.lastUpdatedDate, false, false)
            : '';
            lastUpdatedDate = lastUpdatedDate ? lastUpdatedDate.split('-')[0] : '';

          return (
            <tr key={index}>
              <>
                <td>
                  {user.country.length
                    ? user.country.map((data, index) => {
                        return <ul key={index}> {data.name} </ul>;
                      })
                    : ''}
                </td>
                {campaignConfig &&
                campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE ? (
                  <td>
                    {user && user.businessGroup && user.businessGroup.length
                      ? user.businessGroup.map((data, index) => {
                          return <ul key={index}> {data.name} </ul>;
                        })
                      : ''}
                  </td>
                ) : null}
              </>
              <th scope="row">{user.name || ''}</th>
              <td>
                <div className="content-email-column">
                  {user.emailId || user.domain}
                </div>
              </td>
              <td>
                {user.accessTo && user.accessTo.length
                  ? user.accessTo.map((data, index) => {
                      return data.emailCampaign ? (
                        <ul key={index}> Email</ul>
                      ) : data.formCampaign ? (
                        <ul key={index}> Data</ul>
                      ) : (
                        ''
                      );
                    })
                  : ''}
              </td>
              <td>{lastUpdatedDate}</td>
            </tr>
          );
        });
      }
    }
  }

  renderUserDataNew(userdata, asyncInProgress) {
    const { campaignConfig } = this.props;
    if (asyncInProgress) {
      return (
        <div className="approverlist-table-row">
          <Loader />
        </div>
      );
    } else {
      if (!userdata.length) {
        return (
          <div className="approverlist-table-row">
            <span className='mt-20 mb-10 ml-10'>{`No approver added.`}</span>
          </div>
        );
      } else {
        return userdata.map((user, index) => {
          let lastUpdatedDate = user.lastUpdatedDate
            ? getFormatedDate(user.lastUpdatedDate, false, false)
            : '';
            lastUpdatedDate = lastUpdatedDate ? lastUpdatedDate.split('-')[0] : '';

          return (
            <div key={index} className="approverlist-table-row">
              <>
                <div className="cell">
                  {user.country.length
                    ? user.country.map((data, index) => {
                        return <ul key={index}> {data.name} </ul>;
                      })
                    : ''}
                </div>
                {campaignConfig &&
                campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE ? (
                  <div className="cell">
                    {user && user.businessGroup && user.businessGroup.length
                      ? user.businessGroup.map((data, index) => {
                          return <ul key={index}> {data.name} </ul>;
                        })
                      : ''}
                  </div>
                ) : null}
              </>
              <div scope="row" className="cell">
                {user.name || ''}
              </div>
              {/* <div className="cell">
                <div className="content-email-column">
                  {user.emailId || user.domain}
                </div>
              </div> */}
              <div className="cell">
                {user.accessTo && user.accessTo.length
                  ? user.accessTo.map((data, index) => {
                      return data.emailCampaign ? (
                        <ul key={index}> Email</ul>
                      ) : data.formCampaign ? (
                        <ul key={index}> Data</ul>
                      ) : data.smsCampaign ? (
                        <ul key={index}> SMS</ul>
                      ) : data.waCampaign ? (
                        <ul key={index}> WhatsApp</ul>
                      ) : (
                        ''
                      )
                    })
                  : ''}
              </div>
              <div className="cell ">{lastUpdatedDate}</div>
            </div>
          );
        });
      }
    }
  }

  _handleOnChange(e) {
    this.setState({ query: e.target.value });
    if(e.target.value === ''){
      let searchText = e.target.value;
      const filter = {
        selectedCountry: this.state.selectedCountryArr,
        selectedCampaign: this.state.selectedCampaignFilter,
        selectedBusiness: this.state.selectedBusinessFilter,
        sortBy :  this.props.sortBy,
        sortKey :  this.props.sortKey
      };
      if (
        filter &&
        filter.selectedCountry.length === 0 &&
        filter.selectedCampaign.length === 0 &&
        filter.selectedBusiness.length === 0
      ) {
        this.props.fetchUserData('approver', '', '', '', searchText);
      } else {
        this.props.fetchApproverFilterData('approver', filter,searchText);
      }
    }
  }

  suggestionMenu(children) {
    return children && children.length ? <></> : <></>;
  }

  render() {
    let { asyncInProgress, campaignConfig, userData } = this.props;
    return (
      <div>
        <div className="mb-10">
          <div className="mr-10 mb-20">
            <div className="d-flex flex-grow-1 align-items-center">
              <Autocomplete
                inputProps={{
                  id: 'campaignIdAutocomplete',
                  className: 'search-input search-input-approver-content cross',
                  placeholder: `Search by approver name or email`,
                  onKeyPress: e => {
                    if (e.key === 'Enter' && this.state.query) {
                      this._handleSearch(e);
                    }
                  }
                }}
                wrapperProps={{
                  className: 'search-input-wrapper'
                }}
                autoHighlight={false}
                value={this.state.query}
                onChange={this._handleOnChange}
                renderMenu={this.suggestionMenu}
                items={[]}
                getItemValue={''}
                renderInput={props => (
                  <div className="input-clear">
                    <input {...props} />
                    {this.state.query.trim().length > 0 && (
                      <span
                        className="input-clear__cross"
                        onClick={() => {
                          this._handleOnChange({ target: { value: '' } });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </span>
                    )}
                  </div>
                )}
                renderItem={[]}
              />
              <button className="search-button" onClick={this._handleSearch}>
                <img src={SearchIcon} alt="Search" />
              </button>
            </div>
            <div className="cw-listing d-flex flex-grow-1 align-items-center"></div>
          </div>
          <div className="d-flex justify-content-between mb-20">
            <div className="d-flex">
              <div className="d-flex">
                <div className="mr-10">
                  <div className="form-group mb-0">
                    <CheckboxDropDown
                      isMulti={true}
                      className={'cw-dashboard-filter approver-readyonly-dropdown'}
                      menuIsOpen={false}
                      name={'brandcountry'}
                      hideSelectedOptions={false}
                      placeholder={'Country'}
                      isDisabled={asyncInProgress}
                      isLoading={asyncInProgress}
                      value={this.state.brandcountry}
                      onChange={this._handleDropDownValue}
                      options={this._getCountries()}
                      isClearable={false}
                    />
                  </div>
                </div>
                <div className="mr-10">
                  <div className="form-group mb-0">
                    <CheckboxDropDown
                      isMulti={true}
                      className={'cw-dashboard-filter approver-readyonly-dropdown'}
                      menuIsOpen={false}
                      name={'businessGroup'}
                      hideSelectedOptions={false}
                      placeholder={'Business group'}
                      isDisabled={asyncInProgress}
                      isLoading={asyncInProgress}
                      value={this.state.brandcountry}
                      onChange={this._handleBusinessGroupDropDownValue}
                      options={this._getBusinessGroup()}
                      isClearable={false}
                    />
                  </div>
                </div>
                <div className="mr-10">
                  <div className="form-group mb-0">
                    <CheckboxDropDown
                      isMulti={true}
                      className={'cw-dashboard-filter approver-readyonly-dropdown'}
                      menuIsOpen={false}
                      name={'campaigngroup'}
                      hideSelectedOptions={false}
                      placeholder={'Campaign group'}
                      isDisabled={asyncInProgress}
                      isLoading={asyncInProgress}
                      value={this.state.selectedCampaignFilter}
                      onChange={this._handleCampaignDropDownValue}
                      options={[
                        {
                          label: 'Data campaign',
                          value: 'formCampaign'
                        },
                        {
                          label: 'Email campaign',
                          value: 'emailCampaign'
                        },
                        {
                          label: 'SMS campaign',
                          value: 'smsCampaign'
                        },
                        {
                          label: 'WhatsApp campaign',
                          value: 'waCampaign'
                        }
                      ]}
                      isClearable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="cw-listing--content">
            {userData &&
            userData.length === 0 &&
            this.props.userData.length != 0 ? (
              <>
                <span>
                  No approvers are available on record for the selected markets.
                  The approval requests for these markets are sent to Global 121
                </span>
              </>
            ) : (
              <>
                {/* <table className="table table-striped table-approverlist mb-40">
                  <thead>
                    <tr>
                      <>
                        <th scope="col">
                          <span className="">Country</span>
                        </th>
                        {campaignConfig &&
                        campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE ? (
                          <th scope="col">
                            <span
                              className="cw-sort cw-sort--asc"
                              data-type="businessGroup"
                              onClick={e =>
                                this.props.sortClick(
                                  e,
                                  this.props.panelType,
                                  this.state.selectedCountryArr,
                                  this.state.selectedCampaignFilter
                                )
                              }
                            >
                              Business group
                            </span>
                          </th>
                        ) : null}
                      </>
                      <th
                        scope="col"
                        data-type="name"
                        onClick={this.sortDashboard}
                      >
                        <span
                          className=""
                          data-type="name"
                          onClick={e =>
                            this.props.sortClick(
                              e,
                              this.props.panelType,
                              this.state.selectedCountryArr,
                              this.state.selectedCampaignFilter
                            )
                          }
                        >
                          Name
                        </span>
                      </th>

                      <th scope="col">
                        <span
                          className=""
                          data-type="emailId"
                          onClick={e =>
                            this.props.sortClick(
                              e,
                              this.props.panelType,
                              this.state.selectedCountryArr,
                              this.state.selectedCampaignFilter
                            )
                          }
                        >
                          {`Email`}
                        </span>
                      </th>
                      <th scope="col">
                        <span className="">Module</span>
                      </th>
                      <th scope="col">
                        <span
                          className="cw-sort cw-sort--asc"
                          data-type="creationDate"
                          onClick={e =>
                            this.props.sortClick(
                              e,
                              this.props.panelType,
                              this.state.selectedCountryArr,
                              this.state.selectedCampaignFilter
                            )
                          }
                        >
                          Date added
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderUserData(userData, this.props.asyncInProgress)}
                  </tbody>
                </table> */}

                <div className="approverlist-table">
                  <div className="approverlist-table-row approverlist-table-head approver-head">
                    <div
                      className="cell cw-sort cw-sort--asc"
                      data-type="country"
                      onClick={e =>
                        this.props.sortClick(
                          e,
                          this.props.panelType,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          this.state.selectedBusinessFilter,
                          this.state.query
                        )
                      }
                    >
                      Country
                    </div>
                    {campaignConfig &&
                    campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE ? (
                      <div
                        className="cell cw-sort cw-sort--asc"
                        data-type="businessGroup"
                        onClick={e =>
                          this.props.sortClick(
                            e,
                            this.props.panelType,
                            this.state.selectedCountryArr,
                            this.state.selectedCampaignFilter,
                            this.state.selectedBusinessFilter,
                            this.state.query
                          )
                        }
                      >
                        Business group
                      </div>
                    ) : null}

                    <div
                      className="cell cw-sort cw-sort--asc"
                      data-type="name"
                      onClick={e =>
                        this.props.sortClick(
                          e,
                          this.props.panelType,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          this.state.selectedBusinessFilter,
                          this.state.query
                        )
                      }
                    >
                      Full name
                    </div>
                    {/* <div
                      className="cw-sort cw-sort--asc cell"
                      data-type="emailId"
                      onClick={e =>
                        this.props.sortClick(
                          e,
                          this.props.panelType,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          this.state.selectedBusinessFilter
                        )
                      }
                    >
                      {`Email`}
                    </div> */}
                    <div
                      className="cell"
                      data-type="module"
                      onClick={e =>
                        this.props.sortClick(
                          e,
                          this.props.panelType,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          this.state.selectedBusinessFilter,
                          this.state.query
                        )
                      }
                    >
                      Module
                    </div>
                    <div
                      className="cell  cw-sort cw-sort--asc"
                      data-type="lastUpdatedDate"
                      onClick={e =>
                        this.props.sortClick(
                          e,
                          this.props.panelType,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          this.state.selectedBusinessFilter,
                          this.state.query
                        )
                      }
                    >
                      Last updated
                    </div>
                  </div>
                  <div className="approverlist-table-body approver-body">
                    {this.renderUserDataNew(
                      userData,
                      this.props.asyncInProgress
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // authorizedUserData: state.authorized.authorizedUserData,
  showLoader: state.dashboard.showLoader,
  countries: state.dashboard.countries,
  userData: state.user.userData || {},
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  fetchApproverFilterData,
  fetchUserData,
  getBrandCountries
})(TabPanelContent);
