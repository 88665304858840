import React, { useEffect, useState } from 'react';
import { useWatch, Controller } from 'react-hook-form';
import { get } from 'lodash';

import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { FormInput } from '../../molecules/FormInput';
import { uploadWACoupon } from 'services/waBriefings';
import ExternalLinkIcon from 'images/external-link.png';
import WACouponGuidelines from 'components/molecules/WACouponGuidlines';
import FileDropZone from 'components/molecules/FileDropZone';
import constants from 'constants/config';

const DynamicCouponCodeTemplate = () => {
  return (
    <a
      href={constants.DYNAMIC_COUPON_CODE_TEMPLATE_LINK}
      target="_blank"
      rel="noopener noreferrer"
      className="guidelines-ext-link"
    >
      dynamic coupon code template
      <img
        src={ExternalLinkIcon}
        alt="External Link"
        className="external-link-icon"
      />
    </a>
  );
};

export const CouponFields = ({
  control,
  watchInput,
  errors,
  name,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger,
  toggleShowDynamicCouponAlert,
  setError,
  clearErrors,
  ...props
}) => {
  const defaultWaCoupon = get(defaultValue, `${name}.waCoupon.couponApplied`);
  const defaultWaCouponType = get(defaultValue, `${name}.waCoupon.type`);
  const defaultWaCouponCode = get(defaultValue, `${name}.waCoupon.code`);
  const defaultKanaTicketNumber = get(
    defaultValue,
    `${name}.waCoupon.kanaTicketNumber`
  );
  // const defaultKnowsAudienceSize = get(
  //   defaultValue,
  //   `${name}.waCoupon.knowsAudienceSize`
  // );
  const defaultCouponFileUpload = 'pending';
  const defaultShareCouponFileViaKana = get(
    defaultValue,
    `${name}.waCoupon.shareCouponFileViaKana`
  );
  const defaultCampaignName = get(defaultValue, 'name');
  const defaultCampaignUid = get(defaultValue, 'uid');

  const [
    waCoupon,
    waCouponType,
    waCouponCode,
    waKanaTicketNumber,
    couponFileUpload,
    shareCouponFileViaKana
  ] = useWatch({
    control,
    name: watchInput,
    defaultValue: [
      defaultWaCoupon,
      defaultWaCouponType,
      defaultWaCouponCode,
      defaultKanaTicketNumber,
      defaultCouponFileUpload,
      defaultShareCouponFileViaKana
    ]
  });

  const [isGuidelinesModalOpen, setIsGuidelinesModalOpen] = useState(false);

  useEffect(() => {
    if (couponFileUpload === 'success') {
      setValue(`${name}.shareCouponFileViaKana`, false);
      setValue(`${name}.kanaTicketNumber`, undefined);
    }
    if (couponFileUpload === 'pending') {
      setValue(`${name}.shareCouponFileViaKana`, false);
      setValue(`${name}.kanaTicketNumber`, undefined);
    }
  }, [couponFileUpload]);

  const handleGuidelinesModal = e => {
    e.preventDefault();
    setIsGuidelinesModalOpen(true);
  };

  if (!waCoupon) {
    return null;
  }

  return (
    <div className="ml-33 pt-0 cw-form-nested mb-20">
      <div className="form-group">
        <label htmlFor="waCouponElement">Select coupon type</label>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.waCouponType`}
              type="radio"
              {...(register && register(`${name}.waCouponType`))}
              id="staticCoupon"
              value="staticCoupon"
              onClick={toggleShowDynamicCouponAlert}
            />
            <label htmlFor="staticCoupon" className="form-check-label">
              <p>Static coupon</p>
              <span className="email-label-subtext d-block">
                Send the same coupon code to all WhatsApp recipient. E.g.
                MAGNUM2OFF
              </span>
            </label>
          </div>

          {waCouponType === 'staticCoupon' && (
            <div className="email-attributes-inputs  cw-form-nested pt-0 ml-33">
              <FormInput
                id="couponCode"
                type="text"
                name={`${name}.couponCode`}
                label="Enter coupon code"
                placeholder="Enter coupon code"
                register={register}
                errors={errors}
                key="couponCode"
                defaultValue={waCouponCode}
                maxLength={50}
                setValue={setValue}
              />
            </div>
          )}

          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.waCouponType`}
              {...(register && register(`${name}.waCouponType`))}
              id="dynamicCoupon"
              value="dynamicCoupon"
              type="radio"
              onClick={toggleShowDynamicCouponAlert}
            />
            <label htmlFor="dynamicCoupon" className="form-check-label">
              <p>Dynamic coupon</p>
              <span className="email-label-subtext d-block">
                Send a unique coupon code to each WhatsApp recipient. E.g.
                JF124XA, CF130XA
              </span>
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.waCouponType`} />
      </div>

      {waCouponType === 'dynamicCoupon' && (
        <>
          <div className="email-attributes-inputs  cw-form-nested pt-0 ml-33">
            <label className="mt-3 mb-1">Please upload your coupon file</label>
            <p className="email-type-desc-font mb-3">
              We will SFTP this file to ACS. You can skip this for now but will
              be required to provide it before you submit your campaign
            </p>
            <Controller
              name={`${name}.couponFileUpload`}
              control={control}
              defaultValue={defaultCouponFileUpload}
              render={({ field }) => (
                <FileDropZone
                  {...field}
                  className="coupon-file-upload"
                  errors={errors}
                  campaignName={defaultCampaignName}
                  campaignUid={defaultCampaignUid}
                  couponCodeTemplate={DynamicCouponCodeTemplate}
                  setError={setError}
                  clearErrors={clearErrors}
                  uploadCoupon={uploadWACoupon}
                  defaultValue={defaultValue}
                  user={props.user}
                />
              )}
            />

            {(shareCouponFileViaKana || couponFileUpload === 'failed') && (
              <div className={'form-check mb-2'} aria-live="polite">
                <Input
                  name={`${name}.shareCouponFileViaKana`}
                  id="shareCouponFileViaKana"
                  {...(register && register(`${name}.shareCouponFileViaKana`))}
                  type="checkbox"
                />
                <label
                  htmlFor={'shareCouponFileViaKana'}
                  className={'form-check-label'}
                >
                  I am unable to upload my coupon file and want to share my
                  coupon codes with a Kana ticket
                </label>
              </div>
            )}
            {shareCouponFileViaKana && (
              <div>
                {/* <label className="mt-2 mb-1">
                  Dynamic coupon next steps
                </label>

                <span className="email-label-subtext d-block mb-3">
                  Please follow these{' '}
                  <button
                    className="btn btn-primary-link"
                    onClick={handleGuidelinesModal}
                  >
                    guidelines
                  </button>{' '}
                  to complete all prerequisites
                </span> */}

                <FormInput
                  id="kanaTicketNumber"
                  type="text"
                  name={`${name}.kanaTicketNumber`}
                  label="Kana ticket number"
                  placeholder="Kana ticket number"
                  register={register}
                  errors={errors}
                  key="kanaTicketNumber"
                  defaultValue={waKanaTicketNumber}
                  maxLength={9}
                  setValue={setValue}
                />

                <div className="mt-n2 email-type-desc-font">
                  Please provide the Kana ticket number used to provide coupon
                  codes to ACS team. You can skip this for now but will be
                  required to provide it before you submit your campaign
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <WACouponGuidelines
        isOpen={isGuidelinesModalOpen}
        handleCloseForm={() => setIsGuidelinesModalOpen(false)}
        defaultTab={1}
      />
    </div>
  );
};
