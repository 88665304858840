import classNames from 'classnames';
import React, {useState, useEffect} from 'react';
import { usePrevious } from 'hooks/usePrevious';

const WAChatHeader = ({ data, hasLTOOffer }) => {
  const [hasImageError, setHasImageError] = useState(false);
  const prevImage =  usePrevious(data);

  useEffect(() => {
    if(data && data.type === 'headerImage' && prevImage){
      if(data.headerImage !== prevImage.headerImage){
        if(hasImageError){
          setHasImageError(false);
        }
      }
    }
  },[data])

  if (!data) {
    return null;
  }

  if (data && data.type === 'headerText') {
    return (
      <div className="header-text-container">
        <span className="header-text">{data.headerText}</span>
      </div>
    );
  }

  const handleError = () => {
    if (!hasImageError) {
      setHasImageError(true);
    }
  }

  if (data && data.type === 'headerImage') {
    return (
      <div
        className={classNames('header-image-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        {data.headerImage && !hasImageError ? (
          <img
            src={data.headerImage}
            className={classNames('img-fluid header-img', {
              'rounded-top': true,
              'rounded-bottom': !hasLTOOffer
            })}
            alt="header-image"
            onError={handleError}
          />
        ) : (
          <div className="image-placeholder">
            <span className="image-placeholder-icon">
              <i className="far fa-image fa-lg"></i>
            </span>
          </div>
        )}
      </div>
    );
  }
};

export default WAChatHeader;
