import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import print_icon from '../../images/print_icon.png';
import preview_icon from '../../images/preview_icon.png';
import clone_icon from '../../images/clone_icon.png';
import edit_icon from '../../images/edit_icon.png';
import permission_icon from '../../images/permission_icon.png';
import feedback_icon from '../../images/feedback_icon.png';
import MarkScheduledIcon from 'images/markScheduleIcon';
import RequestMoreInfoIcon from 'images/requestMoreInfoIcon'
export default class Toolbar extends Component {
  state = {};

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    return (
      <Menu className="toolbar-component">
        {this.props.showPrint ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="print"
                active={activeItem === 'print'}
                onClick={() => window.print()}
                style={{ padding: '2px,13px' }}
              >
                <img
                  src={print_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Print
              </Menu.Item>
            </span>

            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}
        {this.props.onDelete && this.props.isDraft? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="print"
                active={activeItem === 'print'}
                onClick={this.props.onDelete}
                style={{ padding: '2px,13px' }}
              >
                <i
                  className="fas fa-trash toolbar-component__icon"
                  style={{ color: 'var(--color-primary)' }}
                />{' '}
                Delete
              </Menu.Item>
            </span>

            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}
        {this.props.showPreview ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="preview"
                active={activeItem === 'preview'}
                href={this.props.previewHandler}
                target={'_blank'}
              >
                <img
                  src={preview_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Preview
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}
        {this.props.showCloneNonSAAS ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="clone_nonsaas"
                active={activeItem === 'clone_nonsaas'}
                onClick={this.props.nonSaasCloneHandler}
              >
                <img
                  src={clone_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Clone
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}
        {this.props.showCloneSAAS ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="clone_saas"
                active={activeItem === 'clone_saas'}
                onClick={this.props.saasCloneHandler}
              >
                <img
                  src={clone_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Clone
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}

        {this.props.showEditSAAS ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="edit_saas"
                active={activeItem === 'edit_saas'}
                onClick={this.props.saasEditHandler}
              >
                <img
                  src={edit_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Edit
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}

        {this.props.showEditNonSAAS ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="edit_nonsaas"
                active={activeItem === 'edit_nonsaas'}
                onClick={this.props.nonSaasEditHandler}
              >
                <img
                  src={edit_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Edit
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}
        {this.props.showPermission ? (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="permissions"
                active={activeItem === 'permissions'}
                onClick={this.props.permissionsHandler}
              >
                <img
                  src={permission_icon}
                  alt=""
                  style={{ height: '40px', width: '40px' }}
                />{' '}
                Permissions
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        ) : null}
        {this.props.showMarkAsScheduled && (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="markAsScheduled"
                active={activeItem === 'markAsScheduled'}
                onClick={event => {
                  event.preventDefault();
                  this.props.markAsScheduledOnClick();
                }}
              >
                <span className="mr-2"><MarkScheduledIcon/></span> Mark as scheduled
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        )}
        {this.props.showRequestMoreInfo && (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="markAsScheduled"
                active={activeItem === 'markAsScheduled'}
                onClick={event => {
                  event.preventDefault();
                  this.props.onOpenRequestInfo();
                }}
              >
                <span className="mr-2"><RequestMoreInfoIcon/></span> Request more info
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        )}
        
        {this.props.showMarkAsLive && (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="markAsLive"
                active={activeItem === 'markAsLive'}
                onClick={event => {
                  event.preventDefault();
                  this.props.markAsLiveOnClick();
                }}
              >
                <span className="mr-2"><MarkScheduledIcon/></span> Mark as live
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        )}
        {this.props.showEnterABTestResult && (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="enterAbTestResult"
                active={activeItem === 'enterAbTestResult'}
                onClick={event => {
                  event.preventDefault();
                  this.props.enterABTestResultOnClick();
                }}
              >
                <span className="mr-2"><MarkScheduledIcon/></span> {this.props.winningVariant ? 'View' : 'Enter'} A/B test result
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        )}
        {this.props.showFeedbackLink && (
          <>
            <span className="mr-2">
              <Menu.Item
                className="toolbar-component--tool_span"
                name="feedback"
                active={activeItem === 'feedback'}
                onClick={event => {
                  event.preventDefault();
                  this.props.onOpenFeedback();
                }}
              >
                <img src={feedback_icon} alt="feedback_icon" /> Feedback
              </Menu.Item>
            </span>
            <span className="ml-1 toolbar-component--vr"></span>
          </>
        )}
      </Menu>
    );
  }
}
