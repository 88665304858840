import * as yup from 'yup';
import React from 'react';
import {
  setWACommsLoader,
  updateWAComms
} from '../../actions/wabriefings/waComms';
import {
  getCountries,
  getBrandByCountry,
  getBrandDetails
} from 'services/emailBriefings';
import { WACampaignSubmitRedirectURL } from 'selectors';
import constants from '../../constants';
import OptOutWarningInput from './OptOutWarning';

export const getIsEdited = componentProps => {
  return (
    componentProps &&
    componentProps.history &&
    componentProps.history.location &&
    componentProps.history.location.state &&
    componentProps.history.location.state.isEdited
  );
};

const formSchema = (dispatch, componentProps, defaultValues) => {
  const isEdit = componentProps.match.params.id;
  const { status } = componentProps
  const isEdited = getIsEdited(componentProps);
  const { history } = componentProps;

  const brandShape = {
    label: yup.string().required(),
    value: yup.string().required(),
    id: yup.string().required()
  };

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    console.log('data: ', data);
    try {
      const brandDetails = {
        ...data,
        requestersEmail: user.emailId
      };
      const uid = isEdit ? componentProps.match.params.id : false;
      dispatch(
        updateWAComms(uid, brandDetails, (error, result) => {
          if (uid) {
            history.push(
              WACampaignSubmitRedirectURL({
                backToReview: isEdited && backToReview,
                status: componentProps.status,
                uid: uid,
                nextPage: `${constants.WA_ROUTES.WA_TYPE}`
              })
            );
          } else {
            history.push(
              `/campaign-wizard/wa/${constants.WA_ROUTES.NEW_WA_TYPE}`
            );
          }
        })
      );
    } catch (error) {
      console.log('onSubmit error: ', error);
    }
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'reactselect',
        name: 'country',
        id: 'country',
        label: 'Country',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select country')
          .default(undefined),
        options: [],
        defaultValue: defaultValues.country,
        defaultDisabled: true,
        isEdit,
        onLoad: (properties, setProperties) => {
          dispatch(setWACommsLoader(true));
          getCountries()
            .then(response => {
              let countries = response.map(country => {
                return {
                  value: country.code,
                  label: country.name,
                  id: country._id
                };
              });
              setProperties({
                ...properties,
                options: countries,
                disabled: false
              });
              dispatch(setWACommsLoader(false));
            })
            .catch(e => {
              dispatch(setWACommsLoader(false));
            });
        },
        customHelpComponentProps: {
          setIsModelOpen: componentProps.setIsModelOpen
        }
      },
      {
        type: 'reactselect',
        name: 'brand',
        id: 'brand',
        label: 'Brand',
        rules: yup.lazy(value =>
          !Array.isArray(value)
            ? yup
                .object()
                .required('Please select brand')
                .typeError('Please select brand')
            : yup
                .array()
                .of(yup.object())
                .min(1, 'Please select brand')
                .required('Please select brand')
        ),
        options: [],
        isMulti: componentProps.isCrossBrand,
        max: 10,
        defaultDisabled: true,
        isEdit,
        defaultValue: defaultValues.brand,
        watchInput: 'country',
        defaultWatchValue: defaultValues.country,
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value) {
            m_disabled = false;
            dispatch(setWACommsLoader(true));
            getBrandByCountry(value.id).then(res => {
              if (res.brands) {
                let brands = res.brands.map(country => {
                  return {
                    value: country.code,
                    label: country.name,
                    id: country._id
                  };
                });
                setData({ options: brands, disabled: m_disabled });
              }
              dispatch(setWACommsLoader(false));
            });
          }
        }
      },
      {
        type: 'reactselect',
        name: 'language',
        id: 'language',
        label: 'Language',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select language')
          .default(undefined),
        options: [],
        defaultValue: defaultValues.language,
        defaultDisabled: true,
        watchInput: 'country',
        defaultWatchValue: defaultValues.country,
        isEdit,
        onLoad: (properties, setProperties) => {
          // if(defaultValues.language) {
          //   properties.disabled = false
          // }
          getBrandDetails().then(res => {
            if (res.languages) {
              let languages = res.languages.map(language => {
                return {
                  value: language.code,
                  label: language.name,
                  id: language._id
                };
              });
              setProperties({ ...properties, options: languages });
            }
          });
        },
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value) {
            m_disabled = false;
          }
          getBrandDetails().then(res => {
            if (res.languages) {
              let languages = res.languages.map(language => {
                return {
                  value: language.code,
                  label: language.name,
                  id: language._id
                };
              });
              setData({ options: languages, disabled: m_disabled });
            }
          });
        }
      },
      // {
      //   type: 'custom',
      //   component: OptOutWarningInput,
      //   watchInput: ['country', 'brand', 'language'],
      //   dispatch
      // }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default formSchema;
