import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import WAScreen from './WAscreen';

const MobileDeviceFrame = forwardRef(({ className, data }, ref) => {
  const [formData, setFormData] = useState();

  useEffect(() => {
    ref.current = setFormData;
  }, []);

  return (
    <div className={classNames('mobile-device-frame', className)}>
      <div className="device-frame">
        <WAScreen data={data || formData} />
      </div>
    </div>
  );
});

export default MobileDeviceFrame;
