import React, { useState, useRef } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { ReactComponent as DownArrow } from 'images/down-arrow.svg';

export const SimpleSelect = ({ options, selected, handleChange }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setExpanded(false));

  const handleSelect = option => {
    handleChange(option);
    setExpanded(!expanded);
  };

  return (
    <span className="email-custom-select" ref={ref}>
      <div
        className="d-flex align-items-center justify-content-end"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="title">{selected.label}</div>
        <DownArrow className="down-arrow" />
      </div>

      {expanded ? (
        <div className="options">
          {options.map(option => (
            <div
              key={option.value}
              className="option"
              style={{ width: 'auto' }}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      ) : null}
    </span>
  );
};
