import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EmailTabular from '../EmailTabular/EmailTabular';
import { getSMSTabularData } from 'components/communications/data';
import { withRouter } from 'react-router';

import constants from 'constants/config';
import { get } from 'lodash';
import SmsRequestInfoSubmitComp from '../SmsRequestInfoSubmit/SmsRequestInfoSubmit';
import { downloadSMSCostEstimate } from 'services/communicationService';

function CommunicationOverviewTables({
  smsCampaign,
  editable,
  review,
  errors = {},
  match,
  history,
  handleRequestInfoAnswer,
  requestInfoAnswerFlag,
  downloadTrigger,
  downloadPOTrigger
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const campaignConfig = useSelector(state => state.app.campaignConfig);

  const { authorizedUserData } = useSelector(state => state.authorized);

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  };

  if (!smsCampaign) return null;

  const {
    brandDetails,
    SMSType,
    smsCampaignDetails,
    audienceDetails,
    smsDesignProperties,
    deliveryScheduleData,
    campaignAttributes
  } = getSMSTabularData(smsCampaign, campaignConfig, currentUser, review);

  const onClickEdit = page => {
    const uid = match.params.id;
    history.push({
      pathname: `${constants.SMS_BASE_ROUTE}/${uid}/${page}`,
      state: {
        flyoutOpen: true,
        isEdited: review
      }
    });
  };

  const editProps = redirectTo =>
    editable ? { onClickEdit: () => onClickEdit(redirectTo) } : {};

  /**
   * Download cost estimate on click of file name
   */
  const handleDownloadCostEstimateClick = (file, setLoader) => {
    downloadTrigger && downloadTrigger(file, setLoader);
  };
  const downloadCostEstimateProps = () => {
    return {
      handleDownloadCostEstimateClick: (file, setLoader) =>
        handleDownloadCostEstimateClick(file, setLoader)
    };
  };

  /**
   * Download PO on click of file name
   */
  const handleDownloadPOClick = (file, setLoader) => {
    downloadPOTrigger && downloadPOTrigger(file, setLoader);
  };
  const downloadPOProps = () => {
    return { handleDownloadPOClick: (file, setLoader) => handleDownloadPOClick(file, setLoader) };
  };

  return (
    <div className="sms-overview-tables">
      {review ? (
        <>
          <EmailTabular
            data={brandDetails}
            title="Brand summary"
            {...editProps(`${constants.SMS_ROUTES.BRAND_DETAILS}`)}
          />
          <EmailTabular
            data={SMSType}
            title="Campaign type"
            {...editProps(`${constants.SMS_ROUTES.SMS_TYPE}`)}
          />
        </>
      ) : null}

      <EmailTabular
        data={smsCampaignDetails}
        title={'Campaign summary'}
        isError={errors.summary}
        {...editProps(`${constants.SMS_ROUTES.CAMPAIGN_SUMMARY}`)}
        {...downloadCostEstimateProps()}
        {...downloadPOProps()}
      />
      <EmailTabular
        data={campaignAttributes}
        title={'Campaign attributes'}
        isError={errors.campaignAttributes}
        errorDetails={errors.dynamicCouponError}
        {...editProps(`${constants.SMS_ROUTES.CAMPAIN_ATTRIBUTES}`)}
      />
      <EmailTabular
        data={deliveryScheduleData}
        title={'Delivery schedule'}
        isError={errors.deliverySchedule}
        errorDetails={errors.deliveryScheduleError}
        {...editProps(`${constants.SMS_ROUTES.SCHEDULE}`)}
      />
      <EmailTabular
        data={audienceDetails}
        title={'Audience segment'}
        isError={errors.audienceDefinition}
        {...editProps(`${constants.SMS_ROUTES.AUDIENCE}`)}
      />
      <EmailTabular
        data={smsDesignProperties}
        title={'SMS Design'}
        isError={errors.designSMS}
        {...editProps(`${constants.SMS_ROUTES.DESIGN_SMS}`)}
      />
      {smsCampaign && smsCampaign.requestInfoText && requestInfoAnswerFlag ? (
        <SmsRequestInfoSubmitComp
          smsDetails={smsCampaign}
          title={'Response'}
          isError={errors.requestAnswererror}
          handleRequestInfoAnswer={handleRequestInfoAnswer}
        />
      ) : null}
    </div>
  );
}

export default withRouter(CommunicationOverviewTables);
