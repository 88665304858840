import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { get } from 'lodash';

import HistoryComponent from 'components/history';
import NotFound from 'components/NotFound';
import WASummaryCards from 'components/organisms/EmailSummaryCards/WASummaryCards';
import WAOverviewTables from 'components/organisms/WAOverviewTables';
import Toolbar from 'components/communications/Toolbar';
import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import MobileDeviceFrame from 'components/organisms/MobileDeviceFrame';
import Alert from 'components/Alert';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import WALiveModel from 'components/organisms/WALiveModel';
import NotificationMessage from 'components/Messages';
import Loader from 'components/Loader';
import EmailGuidelineText from 'components/organisms/EmailGuidelineText/EmailApproverGuideline';
import WaScheduleModel from 'components/organisms/WaScheduleModel';
import WARequestInfoModel from 'components/organisms/WARequestInfoModel';
import WACostAndRequestInfoModel from 'components/organisms/WACostAndRequestInfoModel';
import MasterDataGuideline from 'components/MasterDataGuideline';

import { getWAcommsByUid } from 'actions/wabriefings/waComms';
import { fetchHistoryLogs } from 'actions/history';
import { openFeedbackForm } from 'actions/feedbackForm';
import {
  downloadWACostEstimate,
  downloadWAPurchaseOrder
} from 'services/waBriefings';

import constants from 'constants/config';

import print_icon from 'images/print_icon.png';
import feedback_icon from 'images/feedback_icon.png';
import MarkScheduledIcon from 'images/markScheduleIcon';
import RequestMoreInfoIcon from 'images/requestMoreInfoIcon';
import DownloadIcon from 'images/downloadIcon';

const WACampainExecution = props => {
  const pathUid = props.match.params.id;
  const { history } = props;
  const dispatch = useDispatch();

  const [notFound, setNotFound] = useState(false);

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isLiveModalOpen, setIsLiveModalOpen] = useState(false);
  const [isACSGuideModalOpen, setIsACSGuideModalOpen] = useState(false);
  const [isRequestInfoModalOpen, setIsRequestInfoModalOpen] = useState(false);
  const [isWACostMoreInfoModalOpen, setIsWACostMoreInfoModalOpen] = useState(
    false
  );
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [mobilePreviewData, setMobilePreviewData] = useState();

  const { waCommsData, isLoading } = useSelector(state => state.waBriefings);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const historyLogs = useSelector(state => state.historyLogs);
  const previewRef = useRef();

  useEffect(() => {
    if (pathUid) {
      dispatch(
        getWAcommsByUid(pathUid, (err, _) => {
          if (err) {
            setNotFound(true);
          }
        })
      );
    }
    dispatch(fetchHistoryLogs(pathUid));
  }, []);

  useEffect(() => {
    if (waCommsData.waDesign) {
      const yoData = {
        ...get(waCommsData, 'waDesign', {}),
        brand: get(waCommsData, 'brand')
      };
      setMobilePreviewData(yoData);
    }
  }, [waCommsData]);

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  };

  if (!waCommsData) return null;

  const {
    uid,
    name,
    status,
    rejectComments,
    nmiAssignedToRequestor,
    requestInfoText,
    requestInfoAnswerText,
    shareCostEstimate = null,
    sharePurchaseOrder = {}
  } = waCommsData || {};

  const isAcsOrAdmin = ['acs', 'admin'].includes(currentUser.group);
  let isDraft =
    status === constants.WA_CAMPAIGN_STATUS.DRAFT ||
    status === constants.WA_CAMPAIGN_STATUS.REJECTED;
  let isRejected = status === constants.WA_CAMPAIGN_STATUS.REJECTED;
  const isAwaitingSetup =
    ((constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP === String(status) &&
      isAcsOrAdmin) ||
      (constants.WA_CAMPAIGN_STATUS.AWAITING_INFO === status &&
        nmiAssignedToRequestor === false)) &&
    shareCostEstimate;

  const showRequestMoreInfoRequestorFlow =
    (constants.WA_CAMPAIGN_STATUS.AWAITING_INFO === status &&
      nmiAssignedToRequestor === true) ||
    !shareCostEstimate;

  const showCostOrRequestMoreInfo =
    constants.WA_CAMPAIGN_STATUS.AWAITING_COST_ESTIMATE === status;

  // let mobilePreviewData = getSMSReviewData(smsProps.smsDetailsOverview);

  const onClickEdit = () => {
    history.push({
      pathname: `${constants.WA_BASE_ROUTE}/${pathUid}/${constants.WA_ROUTES.DESIGN}`,
      state: {
        isEdited: true,
        executionRedesign: true
      }
    });
  };

  const editProps = {};
  if (isAwaitingSetup) {
    editProps.onClickEdit = onClickEdit;
  }

  const continueCampaign = () => {
    history.push(
      `${constants.WA_BASE_ROUTE}/${pathUid}/${constants.WA_ROUTES.BRAND_DETAILS}`
    );
  };

  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: props.authorizedUserData._id,
        defaultCampaignRequest: 'WA',
        showCampaignRequest: false
      })
    );
  };

  const closeLiveModelOnClick = () => {
    setIsLiveModalOpen(false);
  };

  const closeLiveModelClick = () => {
    setIsLiveModalOpen(false);
    history.push(`/campaign-wizard/wa/${pathUid}`);
  };

  const closeCostRequestInfoModelClick = redirect => {
    setIsWACostMoreInfoModalOpen(false);
    if (redirect) {
      dispatch(
        getWAcommsByUid(pathUid, (err, _) => {
          if (err) {
            console.log('getWAcommsByUid', err.message);
          } else {
            history.push(`/campaign-wizard/wa/${pathUid}`);
          }
        })
      );
      dispatch(fetchHistoryLogs(pathUid));
    }
  };

  const closeRequestInfoModelClick = redirect => {
    setIsRequestInfoModalOpen(false);
    if (redirect) {
      history.push(`/campaign-wizard/wa/${pathUid}`);
    }
  };

  const closeScheduleModelClick = ({
    showAlertMessage = false,
    refetchHistory = false
  } = {}) => {
    setIsScheduleModalOpen(false);
    if (showAlertMessage) {
      setAlertMessage(
        constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsScheduled.message
      );
      setAlertType(constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsScheduled.type);
      window.scroll({
        top: -100,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (refetchHistory) {
      dispatch(fetchHistoryLogs(pathUid));
    }
  };

  const handleDownloadPOClick = setIsDownloading => {
    if (sharePurchaseOrder.poFile) {
      const link = sharePurchaseOrder.poFile.gcsPath;
      const [_, ...fileNameParts] = link.replace('gs://', '').split('/');
      const fileName = fileNameParts.join('/');
      if (setIsDownloading) {
        setIsDownloading(true);
      }
      //setIsDownloading(true);
      downloadWAPurchaseOrder(pathUid)
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          //setIsDownloading(false);
        })
        .catch(error => {
          if (setIsDownloading) {
            setIsDownloading(false);
          }
          //setIsDownloading(false);
          console.log('error: ', error);
        });
    }
  };

  const handleDownloadCostEstimateClick = setIsDownloading => {
    const link = shareCostEstimate.costFile.gcsPath;
    const [_, ...fileNameParts] = link.replace('gs://', '').split('/');
    const fileName = fileNameParts.join('/');

    if (setIsDownloading) {
      setIsDownloading(true);
    }
    //setIsDownloading(true);

    downloadWACostEstimate(pathUid)
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        if (setIsDownloading) {
          setIsDownloading(false);
        }
        //setIsDownloading(false);
      })
      .catch(error => {
        if (setIsDownloading) {
          setIsDownloading(false);
        }
        //setIsDownloading(false);
        console.log('error: ', error);
      });
  };

  if (notFound) return <NotFound />;
  if (isLoading || isDownloading) return <Loader />;

  return (
    <>
      <WaScheduleModel
        modalIsOpen={isScheduleModalOpen}
        closeModal={closeScheduleModelClick}
        waDetailsOverview={waCommsData}
        pathUid={uid}
      />

      <WALiveModel
        modalIsOpen={isLiveModalOpen}
        closeModal={closeLiveModelClick}
        closeModelLiveClick={closeLiveModelOnClick}
        waDetailsOverview={waCommsData}
        pathUid={uid}
      />

      <WARequestInfoModel
        modalIsOpen={isRequestInfoModalOpen}
        closeModal={closeRequestInfoModelClick}
        smsDetails={waCommsData}
        pathUid={pathUid}
      />

      <WACostAndRequestInfoModel
        modalIsOpen={isWACostMoreInfoModalOpen}
        closeModal={closeCostRequestInfoModelClick}
        waDetails={waCommsData}
        pathUid={pathUid}
      />

      <Modal
        scrollable={true}
        isOpen={isACSGuideModalOpen}
        onRequestClose={() => setIsACSGuideModalOpen(false)}
        className="cw-modal cw-modal--qnadetail"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          },
          content: {
            maxHeight: '90vh'
          }
        }}
      >
        <MasterDataGuideline
          closeIsEditModal={() => setIsACSGuideModalOpen(false)}
          data={constants.WA_ACS_GUIDE_LINES}
          isEmailCampaign={true}
        />
      </Modal>
      <div className="skypink ml-5 mr-5">
        <div className="cw-section cw-overview">
          <div className="draft_button_section_main">
            <Alert
              type={'warning'}
              message={rejectComments ? rejectComments : ''}
              alertType={'rejectcomment'}
              showComponent={status === constants.WA_CAMPAIGN_STATUS.REJECTED}
            />

            <Alert
              type={'warning'}
              className={'mt-4'}
              message={requestInfoText ? requestInfoText : ''}
              alertType={'requestMoreInfo'}
              showComponent={
                status === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO
              }
              title={
                nmiAssignedToRequestor
                  ? `ACS team has requested more from requestor  `
                  : `ACS team is awaiting more information internally - there is no next step on requestor `
              }
            />

            <Alert
              type={'warning'}
              className={'mb-5'}
              message={requestInfoText ? requestInfoText : ''}
              alertType={'requestMoreInfo'}
              showComponent={
                status === constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP &&
                requestInfoText
              }
              title={`ACS team requested more information`}
            />

            <Alert
              type={'warning'}
              message={requestInfoAnswerText ? requestInfoAnswerText : ''}
              alertType={'requestMoreInfo'}
              showComponent={
                status === constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP &&
                requestInfoAnswerText
              }
              title={`Requestor has provided more information`}
            />

            <NotificationMessage
              showcomponent={alertMessage}
              message={alertMessage}
              type={alertType}
              link={''}
              campaignname={''}
              onClose={() => {
                setAlertMessage('');
                setAlertType('');
              }}
            />

            <PageHeader
              heading={name || 'Untitled'}
              status={status}
              subHeading={
                isDraft ? 'Continue creating your campaign' : undefined
              }
              review
            />

            <div className="toolbar-scroll-fix cw-print-hide">
              <Toolbar>
                {!isDraft && (
                  <Toolbar.Item
                    icon={print_icon}
                    name="Print"
                    onClick={() => window.print()}
                  />
                )}
                {isAwaitingSetup && (
                  <Toolbar.DownloadItem
                    svgIcon={DownloadIcon}
                    name="Download purchase order"
                    onClick={(e, setDWloader) =>
                      handleDownloadPOClick(setDWloader)
                    }
                  />
                )}
                {isAwaitingSetup && (
                  <Toolbar.Item
                    svgIcon={MarkScheduledIcon}
                    name="Mark as scheduled"
                    onClick={() => setIsScheduleModalOpen(true)}
                  />
                )}
                {isAwaitingSetup && (
                  <Toolbar.Item
                    svgIcon={RequestMoreInfoIcon}
                    name="Request more info"
                    onClick={() => setIsRequestInfoModalOpen(true)}
                  />
                )}
                {(showCostOrRequestMoreInfo ||
                  showRequestMoreInfoRequestorFlow) && (
                  <Toolbar.Item
                    svgIcon={RequestMoreInfoIcon}
                    name="Share cost or request info"
                    onClick={() => setIsWACostMoreInfoModalOpen(true)}
                  />
                )}

                {constants.WA_CAMPAIGN_STATUS.SCHEDULED === status && (
                  <Toolbar.Item
                    svgIcon={MarkScheduledIcon}
                    name="Mark as live"
                    onClick={() => setIsLiveModalOpen(true)}
                  />
                )}
                <Toolbar.Item
                  icon={feedback_icon}
                  name="Feedback"
                  onClick={() => handleOnOpenFeedback()}
                />
              </Toolbar>
            </div>
          </div>

          {status === constants.WA_CAMPAIGN_STATUS.AWAITING_FOR_APPROVAL && (
            <div className="mb-30">
              <EmailApproverInfo
                emailBriefing={waCommsData || {}}
                userData={authorizedUserData}
              />
            </div>
          )}

          <div className="ml-2 mb-30">
            <EmailGuidelineText
              isOpenGuidelineModel={e => {
                e.preventDefault();
                setIsACSGuideModalOpen(true);
              }}
              titleText={`Please setup the WhatsApp campaign in ACS using these `}
              guideLineName={`setup guidelines`}
            />
          </div>

          {isDraft && (
            <div className="d-flex">
              <div className="continue-section-empty"></div>
              <div className="continue-camp-btn-container">
                <button
                  className="btn btn-primary w-100"
                  onClick={continueCampaign}
                >
                  Continue
                </button>
              </div>
            </div>
          )}

          <Layout
            className="mt-50"
            sidebar={
              <div>
                <MobileDeviceFrame ref={previewRef} data={mobilePreviewData} />
              </div>
            }
          >
            <WASummaryCards waCampaign={waCommsData} />

            <WAOverviewTables
              editable={false}
              waCampaign={waCommsData}
              downloadTrigger={(d1, setDWloader) =>
                handleDownloadCostEstimateClick(setDWloader)
              }
              downloadPOTrigger={(d2, setDWloader) =>
                handleDownloadPOClick(setDWloader)
              }
            />

            {constants.WA_CAMPAIGN_STATUS.SCHEDULED === status && (
              <BackAndNext
                backTitle=""
                onClickBack={''}
                onClickNext={() => setIsLiveModalOpen(true)}
                nextTitle={'Mark as Live'}
              />
            )}

            {(constants.WA_CAMPAIGN_STATUS.AWAITING_COST_ESTIMATE === status ||
              showRequestMoreInfoRequestorFlow) && (
              <BackAndNext
                backTitle=""
                onClickBack={''}
                onClickNext={() => setIsWACostMoreInfoModalOpen(true)}
                nextTitle={'Share cost or request info'}
              />
            )}

            {isAwaitingSetup && (
              <div className="mb-20">
                <BackAndNext
                  backTitle="Mark as scheduled"
                  onClickBack={() => setIsScheduleModalOpen(true)}
                  nextTitle="Request more info"
                  onClickNext={() => setIsRequestInfoModalOpen(true)}
                  invertStyles={true}
                />
              </div>
            )}

            <div className="overview-page-history mt-20">
              {historyLogs && historyLogs.length ? (
                <HistoryComponent
                  historyLogs={historyLogs}
                  isEmailBrief={true}
                />
              ) : null}
            </div>
          </Layout>
        </div>
      </div>
    </>
  );
};

export default WACampainExecution;
