import React, { Component } from 'react';
import BreadCrumb from './BreadCrumb';
import ReactTooltip from 'react-tooltip';
import '../sass/_promotigo-info.scss';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import HistoryComponent from '../components/history';
import { fetchHistoryLogs } from '../actions/history';
import constant from '../constants';
import { scrollToTop } from 'selectors';
import { setNotificationMessage } from '../actions/dashboard';
import { fetchUserData } from '../actions/authorization';
import { fetchCampaignData } from '../actions/overview';

import {
  getCampaignDetails,
  submitCampaignDetails,
  updateCampaignDetails
} from '../actions/campaignDetails';
class PromotigoInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: 0,
      campaignId: this.props.match.params.id,
      samplesData: {},
      isMandatory: false,
      showErrorMessage: false,
      fileUploadError: false,
      fields: [],
      sample: [],
      formId: '',
      campaignId: '',
      showRadio: false,
      showDropDown: false,
      displayProductSamplesAs: '',
      showErrorOnTop: false,
      instantWinLose: false,
      showAllPurposeError: false
    };
  }

  notificationData = {
    message: '',
    link: '',
    type: ''
  };
  componentDidMount() {
    const campaignDetailURL =
      constant.serviceUrls.CAMPAIGN_MASTER_UPDATE_SERVICE_URL;
    let path = window.location.pathname;
    let uids1 = path.substring(26);
    let uid = uids1.substring(0, 8);
    this.props.fetchHistoryLogs(uid);
    this.props.getCampaignDetails(uid);
    this.updateInstantWinLoseOnPageLoad(uid);
    this.campaignId = this.props.match.params.id;
    this.props.fetchCampaignData(this.campaignId, false, true);
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          this.props.match.params.id
      )
      .then(response => {
        let campaign = response.data[0];
        let language = campaign.language
          ? this.props.campaign.language.code
          : '';
        let fields =
          campaign && campaign.campaignDetail && campaign.campaignDetail.fields;
        let sample =
          campaign &&
          campaign.campaignDetail &&
          campaign.campaignDetail.samples;
        let instantWinLoseFlag =
          campaign &&
          campaign.campaignDetail &&
          campaign.campaignDetail.instantWinLose;

        if (instantWinLoseFlag) {
          this.setState({
            instantWinLose: instantWinLoseFlag
          });
        }
        let samples = undefined;
        if (fields && fields.length > 0) {
          samples = fields.find(ele => ele.dataType === 'samplePrizeSelection');
        }
        samples = samples === undefined ? constant.sampleDetails[0] : samples;
        sample = sample.length === 0 ? [''] : sample;

        let samplesDisplayOptions = campaign.campaignDetail
          .displayProductSamplesAs
          ? campaign.campaignDetail.displayProductSamplesAs
          : constant.displayProductSamplesAs[0];

        this.setState({
          sample: sample,
          fields: fields,
          samplesData: samples,
          displayProductSamplesAs: samplesDisplayOptions
        });
      });
  }

  onChangeRadio = e => {
    let radioValue = e.target.value;
    this.state.samplesData.questionType = radioValue;
    this.setState({
      samplesData: this.state.samplesData
    });
  };

  _addAnswer = e => {
    let isValid = false;
    let samples = this.state.samplesData;
    let answerArr = samples && samples.answers ? samples.answers : [];
    let answerNew = {
      label: '',
      value: `prize-${answerArr.length}`
    };
    answerArr.map((answer, ansIndex) => {
      if (answer && answer.label.trim().length === 0) {
        document.getElementById(`answer_Error_Message_${ansIndex}`) &&
          document
            .getElementById(`answer_Error_Message_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${ansIndex}`)
            .classList.add('cw-error-focus');

        isValid = true;
      }
    });

    if (isValid) {
      return;
    }
    if (samples && samples.answers && samples.answers.length === 12) {
      document.getElementById('answer_Error_Message') &&
        document
          .getElementById('answer_Error_Message')
          .classList.remove('cw-hidden');
    } else {
      samples && samples.answers && samples.answers.push(answerNew);
      samples.answers.forEach((ele, anindex) => {
        ele.label = samples.answers[anindex].label;
        ele.value = `prize-${anindex}`;
      });
      this.setState({
        samplesData: samples
      });
    }
  };

  _addSamples = e => {
    let isValid = false;
    let samples = this.state.sample;
    samples.map((sample, sampleIndex) => {
      if (sample.trim().length === 0) {
        document.getElementById(`sample_Error_Message_${sampleIndex}`) &&
          document
            .getElementById(`sample_Error_Message_${sampleIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${sampleIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${sampleIndex}`)
            .classList.add('cw-error-focus');

        isValid = true;
      }
    });

    if (isValid) {
      return;
    }
    if (samples && samples.length === 12) {
      document.getElementById('sample_Error_Message') &&
        document
          .getElementById('sample_Error_Message')
          .classList.remove('cw-hidden');
    } else {
      samples && samples.push('');

      samples.forEach((ele, anindex) => {
        ele = samples[anindex];
      });
      this.setState({
        samplesData: samples
      });
    }
  };

  handleSamplesLabel = (e, ansIndex) => {
    let fieldLabel = e.target.value;
    let samples = this.state.sample;
    samples[ansIndex] = fieldLabel;
    document.getElementById(`sample_Error_Message_${ansIndex}`) &&
      document
        .getElementById(`sample_Error_Message_${ansIndex}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerTranslatedLabel_${ansIndex}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerTranslatedLabel_${ansIndex}`)
        .classList.remove('cw-error-focus');

    this.setState({
      sample: samples
    });
  };

  handleAnswerLabel = (e, ansIndex) => {
    let fieldLabel = e.target.value;
    let samples = this.state.samplesData;
    samples.answers[ansIndex].label = fieldLabel;
    document.getElementById(`answer_Error_Message_${ansIndex}`) &&
      document
        .getElementById(`answer_Error_Message_${ansIndex}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerTranslatedLabel_${ansIndex}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerTranslatedLabel_${ansIndex}`)
        .classList.remove('cw-error-focus');

    this.setState({
      samplesData: samples
    });
  };

  _deleteAnswer = async indexValue => {
    let samples = this.state.samplesData;
    // let updatedAnswers =
    samples && samples.answers && samples.answers.splice(indexValue, 1);
    document.getElementById('answer_Error_Message') &&
      document
        .getElementById('answer_Error_Message')
        .classList.add('cw-hidden');

    samples.answers.forEach((ele, anindex) => {
      ele.label = samples.answers[anindex].label;
      ele.value = `prize-${anindex}`;
    });
    samples.answers.map((answer, ansIndex) => {
      if (answer && answer.label.trim().length === 0) {
        document.getElementById(`answer_Error_Message_${ansIndex}`) &&
          document
            .getElementById(`answer_Error_Message_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${ansIndex}`)
            .classList.add('cw-error-focus');
      } else {
        document
          .getElementById(`answer_Error_Message_${ansIndex}`)
          .classList.add('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${ansIndex}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`answerTranslatedLabel_${ansIndex}`)
            .classList.remove('cw-error-focus');
      }
    });
    this.setState({
      samplesData: samples
    });
  };

  _deleteSample = async indexValue => {
    let samples = this.state.sample;
    // let updatedAnswers =
    samples && samples.splice(indexValue, 1);
    document.getElementById('sample_Error_Message') &&
      document
        .getElementById('sample_Error_Message')
        .classList.add('cw-hidden');
    samples.forEach((ele, anindex) => {
      ele = samples[anindex];
    });
    samples.map((answer, ansIndex) => {
      if (answer.trim().length === 0) {
        document.getElementById(`sample_Error_Message_${ansIndex}`) &&
          document
            .getElementById(`sample_Error_Message_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${ansIndex}`)
            .classList.add('cw-error-focus');
      } else {
        document
          .getElementById(`sample_Error_Message_${ansIndex}`)
          .classList.add('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${ansIndex}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`answerTranslatedLabel_${ansIndex}`)
            .classList.remove('cw-error-focus');
      }
    });
    this.setState({
      sample: samples
    });
  };

  _handleToolTip = e => {
    let enteredText = e.target.value;
    let samples = this.state.samplesData;
    samples.toolTip = enteredText;
    this.setState({
      samplesData: samples
    });
  };

  _mandatoryQuestionHandler = event => {
    let isChecked = event.target.checked;
    let samples = this.state.samplesData;
    samples.mandatoryToggle = isChecked;
    this.setState({
      samplesData: samples
    });
  };

  _handleMandatoryMessage = e => {
    let enteredText = e.target.value;
    let samples = this.state.samplesData;

    document.getElementById('mandatory_Error_Message') &&
      document
        .getElementById('mandatory_Error_Message')
        .classList.add('cw-hidden');

    document
      .getElementById(`errorMessage`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`errorMessage`)
        .classList.remove('cw-error-focus');

    samples.mandatoryMessage = samples.mandatoryToggle ? enteredText : '';
    this.setState({
      samplesData: samples
    });
  };

  onLabelChange = e => {
    let fieldLabel = e.target.value;
    let samples = this.state.samplesData;
    samples.fieldLabel = fieldLabel;
    document.getElementById('labelError') &&
      document.getElementById('labelError').classList.add('cw-hidden');

    document
      .getElementById(`field_label`)
      .classList.contains('cw-error-focus') &&
      document.getElementById(`field_label`).classList.remove('cw-error-focus');

    this.setState({
      samplesData: samples
    });
  };

  winLooseOnchange = e => {
    let samples = this.state.samplesData;
    samples.instantWinLose = e.target.checked;
    this.setState({
      samplesData: samples
    });
  };

  validateIncentiveField = () => {
    let isValid = true;

    if (this.state.formId.length < 36 || this.state.formId.length > 36) {
      let formIdValue = document.getElementById('formIDText').value;
      if (
        formIdValue.trim === '' ||
        formIdValue.length < 36 ||
        formIdValue.length > 36
      ) {
        isValid = false;
        document
          .getElementById(`formIDText`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`formIDText`).classList.add('cw-error-focus');
        this.setState({ showErrorMessage: true });
      }
    }

    if (!isValid) {
      this.setState({
        showErrorOnTop: true
      });
      scrollToTop();
    } else {
      this.setState({
        showErrorOnTop: false
      });
    }
    return isValid;
  };

  submitIncentiveField = (event, saveAsDraft) => {
    let { campaign } = this.props;
    let dataToSend = {
      uid: this.props.match.params.id,
      instantWinLose: this.state.instantWinLose,
      fields: [...this.state.incentiveFields]
    };
    if (saveAsDraft) {
      dataToSend['saveAsDraft'] = saveAsDraft;
    }

    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
      .then(response => {
        if (response) {
          if (saveAsDraft) {
            this.props.history.push('/campaign-wizard/dashboard');
          } else {
            if (campaign.status === '6') {
              this.props.setApproverEditCampaignStatus(true);
              this.props.setApproverEditCampaignStatusMessage('success');
              this.props.setApproverEditLastUpdatedScreen(
                'saas-incentive-fields'
              );
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.props.match.params.id +
                  '/saas-sorting-page'
              );
            } else {
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.props.match.params.id +
                  '/saas-consent-compliance'
              );
            }
          }
        }
      });
  };

  _handleSubmit = (event, saveAsDraft) => {
    if (saveAsDraft) {
      this.submitIncentiveField(event, saveAsDraft);
    } else {
      if (this.validateIncentiveField()) {
        this.submitIncentiveField(event, saveAsDraft);
      } else {
        scrollToTop();
      }
    }
  };

  showRadioClicked = () => {
    let displaySampleOption = constant.displayProductSamplesAs;
    this.setState({
      showDropDown: false,
      showRadio: true,
      displayProductSamplesAs: displaySampleOption[0]
    });
    this.props.updateCampaignDetails({
      displayProductSamplesAs: displaySampleOption[0]
    });
  };

  showDropDownClicked = () => {
    let displaySampleOption = constant.displayProductSamplesAs;
    this.setState({
      showRadio: false,
      showDropDown: true,
      displayProductSamplesAs: displaySampleOption[1]
    });
    this.props.updateCampaignDetails({
      displayProductSamplesAs: displaySampleOption[1]
    });
  };

  displayProductSamplesAsCheckbox = () => {
    let displaySampleOption = constant.displayProductSamplesAs;
    this.setState({
      showRadio: false,
      showDropDown: false,
      displayProductSamplesAs: displaySampleOption[2]
    });
    this.props.updateCampaignDetails({
      displayProductSamplesAs: displaySampleOption[2]
    });
  };

  handleFormIDInput = event => {
    let formID = event.target.value;
    if (
      formID.length < 36 ||
      formID.length > 36 ||
      ((event.key === 'Backspace' || event.key === 'Delete') &&
        formID.length !== 36)
    ) {
      document
        .getElementById(`formIDText`)
        .classList.contains('cw-error-focus') ||
        document.getElementById(`formIDText`).classList.add('cw-error-focus');
      this.setState({ showErrorMessage: true });
    } else {
      document
        .getElementById(`formIDText`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`formIDText`)
          .classList.remove('cw-error-focus');
      this.setState({ showErrorMessage: false });
      this.setState({ formId: formID });
    }
  };

  handleWinLoseInputChange = e => {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({
        instantWinLose: true
      });
    } else {
      this.setState({
        instantWinLose: false
      });
    }
  };

  updateInstantWinLoseOnPageLoad = uid => {
    const campaignDetailURL =
      constant.serviceUrls.CAMPAIGN_MASTER_UPDATE_SERVICE_URL;
    axios.get(campaignDetailURL + '/' + uid).then(res => {
      const response = res.data;
      let instantWinLoseFlag = response[0].campaignDetail.instantWinLose;
      if (instantWinLoseFlag) {
        this.setState({
          instantWinLose: true
        });
      } else {
        this.setState({
          instantWinLose: false
        });
      }
    });
  };

  formIdSubmission = async event => {
    event.preventDefault();
    let path = window.location.pathname;
    let uids1 = path.substring(26);
    let uid = uids1.substring(0, 8);

    if (!this.validateIncentiveField()) {
      return false;
    }

    const campaignDetailURL = constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL;
    const updateStatusURL =
      constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL;
    let isCompetitions =
      this.props.campaign.incentiveType === 'Competitions' ? true : false;

    let isCampaignACATstandAlone =
      this.props.campaign.websiteType === 'Adobe Classic' ||
      this.props.campaign.websiteType === 'Adobe Templated' ||
      this.props.campaign.websiteType === 'Standalone';

    let isCampaignSaas =
      this.props.campaign.websiteType === 'Adobe SaaS' ||
      this.props.campaign.isPlatformAgnostic ||
      (this.props.campaign.isMasterData &&
        (this.props.campaign.incentiveItPartner === 'promotigo' || this.props.campaign.incentivePromotionalPartner === 'promotigo'));

    let data = {};
    if (isCompetitions && (isCampaignACATstandAlone || isCampaignSaas)) {
      if (
        this.state.formId &&
        !this.props.promotigoFormId &&
        this.state.formId.length === 36 &&
        this.state.formId !== undefined &&
        this.state.formId !== '' &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId
        };
      } else if (
        this.props.promotigoFormId &&
        !this.state.formId &&
        this.props.promotigoFormId.length === 36 &&
        this.props.promotigoFormId !== undefined &&
        this.props.promotigoFormId !== '' &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.props.promotigoFormId
        };
      } else if (
        this.state.formId &&
        this.props.promotigoFormId &&
        this.props.promotigoFormId.length === this.state.formId.length &&
        this.state.formId !== '' &&
        this.state.formId !== undefined &&
        this.state.formId.length === 36 &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId || this.props.promotigoFormId
        };
      }
    } else if (
      !isCompetitions &&
      isCampaignACATstandAlone &&
      this.state.sample &&
      this.state.sample.length === 1
    ) {
      if (
        this.state.formId &&
        !this.props.promotigoFormId &&
        this.state.formId.length === 36 &&
        this.state.formId !== undefined &&
        this.state.formId !== '' &&
        this.state.sample !== '' &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId
        };
      } else if (
        this.state.formId &&
        this.props.promotigoFormId &&
        this.props.promotigoFormId.length === this.state.formId.length &&
        this.state.formId !== '' &&
        this.state.formId !== undefined &&
        this.state.formId.length === 36 &&
        !this.state.showErrorMessage &&
        this.state.sample !== ''
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId || this.props.promotigoFormId
        };
      }
      if (
        data === {} &&
        document.getElementById('formIDText').value.trim().length === 36
      ) {
        data = {
          uid: uid,
          promotigoFormId: document.getElementById('formIDText').value
        };
      }
    } else if (
      !isCompetitions &&
      isCampaignACATstandAlone &&
      this.state.sample.length > 1
    ) {
      if (
        this.state.formId.length === 36 &&
        !this.props.promotigoFormId &&
        this.state.sample.filter(elem => elem === '').length !==
          this.state.sample.length &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId
        };
      } else if (
        this.props.promotigoFormId &&
        this.props.promotigoFormId.length === 36 &&
        this.state.formId.length === 0 &&
        this.state.sample.filter(elem => elem === '').length !==
          this.state.sample.length &&
        this.state.sample.filter(elem => elem === undefined).length !==
          this.state.sample.length &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.props.promotigoFormId
        };
      } else if (
        this.state.formId.length === 36 &&
        this.props.promotigoFormId &&
        this.props.promotigoFormId.length === 36 &&
        this.state.sample.filter(elem => elem === '').length !==
          this.state.sample.length &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId:
            this.state.formId === this.props.promotigoFormId
              ? this.state.formId || this.props.promotigoFormId
              : this.state.formId
        };
      }
    } else if (
      !isCompetitions &&
      isCampaignSaas &&
      this.state.samplesData &&
      this.state.samplesData.answers
    ) {
      let samplesIndex = this.state.fields.findIndex(
        ele => ele.dataType === 'samplePrizeSelection'
      );
      let fields = this.state.fields;
      samplesIndex = samplesIndex === -1 ? fields.length : samplesIndex;
      fields[samplesIndex] = this.state.samplesData;
      if (
        this.state.formId &&
        !this.props.promotigoFormId &&
        this.state.formId.length === 36 &&
        this.state.formId !== undefined &&
        this.state.formId !== '' &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId
        };
      } else if (
        this.props.promotigoFormId &&
        this.state.formId.length === 0 &&
        this.props.promotigoFormId !== '' &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.props.promotigoFormId
        };
      } else if (
        this.state.formId &&
        this.props.promotigoFormId &&
        this.props.promotigoFormId.length === this.state.formId.length &&
        this.state.formId !== '' &&
        this.state.formId !== undefined &&
        this.state.formId.length === 36 &&
        !this.state.showErrorMessage
      ) {
        data = {
          uid: uid,
          promotigoFormId: this.state.formId || this.props.promotigoFormId
        };
      }
    } else {
      window.scroll({
        top: -100,
        left: 0,
        behavior: 'smooth'
      });
      this.setState({ showErrorOnTop: true });
    }

    let updateStatusData = {
      uid: uid,
      status: '3',
      lastUpdatedScreen: 'promotigo-info',
      userName: this.props.userName,
      userEmail: this.props.userEmail
    };
    if (data.promotigoFormId && data.promotigoFormId.length === 36) {
      let res = await axios
        .post(campaignDetailURL, data)
        .then(response => {
          if (response.status === 201) {
            let response = axios
              .post(updateStatusURL, updateStatusData)
              .then(response => {
                if (response.status === 204) {
                  this.notificationData.message =
                    ' Success! Your campaign is now being automatically generated and you will shortly receive an email with setup instructions.';
                  this.notificationData.link =
                    '/campaign-wizard/campaign/' + uid;
                  this.notificationData.type = 'success';
                  this.notificationData.campaign = this.props.campaign.name;
                  this.props.setNotificationMessage(
                    this.notificationData.message,
                    this.notificationData.link,
                    this.notificationData.type,
                    this.notificationData.campaign
                  );
                  this.props.history.push('/campaign-wizard/dashboard');
                }
              });
          }
        })
        .catch(err => {
          window.scroll({
            top: -100,
            left: 0,
            behavior: 'smooth'
          });
          this.setState({ showErrorOnTop: true });
        });
    } else {
      window.scroll({
        top: -100,
        left: 0,
        behavior: 'smooth'
      });
      this.setState({ showErrorOnTop: true });
    }
  };

  saveAsDraft = async event => {
    event.preventDefault();
    let path = window.location.pathname;
    let uids1 = path.substring(26);
    let uid = uids1.substring(0, 8);

    const campaignDetailURL = constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL;
    let isCompetitions =
      this.props.campaign.incentiveType === 'Competitions' ? true : false;

    let isCampaignACATstandAlone =
      this.props.campaign.websiteType === 'Adobe Classic' ||
      this.props.campaign.websiteType === 'Adobe Templated' ||
      this.props.campaign.websiteType === 'Standalone';

    let isCampaignTypeSASS =
      this.props.campaign.websiteType === 'Adobe SaaS' ||
      this.props.campaign.isPlatformAgnostic;

    let data = {
      uid: uid
    };

    if (isCompetitions && (isCampaignACATstandAlone || isCampaignTypeSASS)) {
      data['instantWinLose'] = this.state.instantWinLose;
      if (this.state.formId && this.state.formId.length === 36) {
        data['promotigoFormId'] = this.state.formId;
      }
    } else if (!isCompetitions && isCampaignACATstandAlone) {
      if (this.state.sample && this.state.sample.length > 0) {
        data['samples'] = this.state.sample;
      }
      if (this.state.sample && this.state.sample.length > 1) {
        data['displayProductSamplesAs'] = this.state.displayProductSamplesAs;
      }
      if (this.state.formId && this.state.formId.length === 36) {
        data['promotigoFormId'] = this.state.formId;
      }
    } else if (!isCompetitions && isCampaignTypeSASS) {
      let samplesObj = this.state.fields.filter(
        e => e.dataType === 'samplePrizeSelection'
      );
      if (samplesObj.length > 0) {
        let updatedFields = this.state.fields;
        this.state.fields.map((elem, index) => {
          if (elem.dataType === 'samplePrizeSelection') {
            updatedFields[index] = this.state.samplesData;
            this.setState({
              fields: updatedFields
            });
          }
        });
        data['fields'] = this.state.fields;
      } else {
        if (this.state.samplesData) {
          let updatedFields = this.state.fields;
          updatedFields.push(this.state.samplesData);
          data['fields'] = updatedFields;
        }
      }
      if (this.state.formId && this.state.formId.length === 36) {
        data['promotigoFormId'] = this.state.formId;
      }
    }

    await axios
      .post(campaignDetailURL, data)
      .then(response => {
        if (response.status === 201) {
          this.notificationData.message = 'Promotigo form ID saved.';
          this.notificationData.link = '/campaign-wizard/campaign/' + uid;
          this.notificationData.type = 'warning';
          this.notificationData.campaign = this.props.campaign.name;
          this.props.setNotificationMessage(
            this.notificationData.message,
            this.notificationData.link,
            this.notificationData.type,
            this.notificationData.campaign
          );
          this.props.history.push('/campaign-wizard/dashboard');
        }
      })
      .catch(err => {
        window.scroll({
          top: -100,
          left: 0,
          behavior: 'smooth'
        });
        this.setState({ showErrorOnTop: true });
      });
  };

  render() {
    let displaySampleOption = constant.displayProductSamplesAs;
    let { campaign } = this.props;
    let displayProductSamplesAs = this.state.displayProductSamplesAs;
    let isCampaignACATstandAlone =
      campaign.websiteType === 'Adobe Classic' ||
      campaign.websiteType === 'Adobe Templated' ||
      campaign.websiteType === 'Standalone';
    let isCampaignTypeSASS =
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType === 'Adobe SaaS') ||
      campaign.isPlatformAgnostic;

    let isCompetitions =
      this.props.campaign.incentiveType === 'Competitions' ? true : false;
    return (
      <>
        <div className="cw-section cw-overview">
          <div className="col-sm-12 mb-30">
            <div className="row mb-30">
              <div className="col-sm-6">
                <NavLink
                  className="cw-color--primary cw-breadcrum cw-print-hide"
                  to={'/campaign-wizard'}
                >
                  <i className="fas fa-angle-left"></i> Campaigns
                </NavLink>
              </div>
            </div>
          </div>
          {this.state.showErrorOnTop ? (
            <div className="cw-section">
              <div className="row">
                <div className="alert alert-danger col-sm-12">
                  <p>Please enter the missing field(s).</p>
                </div>
              </div>
            </div>
          ) : null}
          {!isCampaignTypeSASS ? (
            <ReactTooltip
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
          ) : null}
          <div className="col-sm-12 mb-30">
            <div className="row">
              <div className="col-sm-12">
                <div className="cw-section--title mb-4">
                  <h2 className="cw-heading--secondary">
                    <span>Update Promotigo ID</span>
                  </h2>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="mb-20">
                  <h3 className="cw-heading--primary">
                    Step 1: Create your order on Promotigo
                  </h3>
                </div>
                <div className="mb-4">
                  <ul className="ml-20">
                    <li>
                      Incentive based campaigns require Promotigo setup, in
                      addition to Campaign Wizard setup
                    </li>
                    <li>
                      Please visit{' '}
                      <a target="_blank" href="https://campaign.promotigo.com">
                        Promotigo <i className="fas fa-external-link-alt"></i>
                      </a>{' '}
                      to setup your incentive campaign
                    </li>
                    <li>
                      You will receive a Promotigo ID, a unique identifier for
                      your campaign in the Promotigo
                    </li>
                    <li>
                      Update the Promotigo ID to your Campaign Wizard request
                    </li>
                  </ul>
                </div>
                <div className="mb-20">
                  <h3 className="cw-heading--primary">
                    Step 2: Enter Promotigo ID
                  </h3>
                </div>

                <div className="">
                  <ReactTooltip
                    place="right"
                    type="info"
                    multiline={true}
                    className="cw-tooltip"
                  />

                  <div className="row mb-10">
                    <label className="col-sm-5" htmlFor="formIDText">
                      Promotigo form ID
                    </label>
                    <span
                      className="float-right cw-icon cw-icon--help mr-2"
                      data-tip="Enter the Form ID received from Promotigo."
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: '43%' }}
                        id="formIDText"
                        name="formIDText"
                        onChange={this.handleFormIDInput}
                        onKeyUp={this.handleFormIDInput}
                        defaultValue={this.props.promotigoFormId}
                      />
                    </div>
                    {this.state.showErrorMessage ? (
                      <div className="col-sm-5 cw-error">
                        Please enter a valid form ID of 36 characters
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-40">
                  <p>
                    Note: If you need to change samples/competitions details,
                    you can edit the campaign after submitting the Promotigo ID.
                    Please ensure details match with what is configured in
                    Promotigo
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 ">
            <div className="cw-form">
              <button
                type="submit"
                id="submit_promotigo_info"
                className="btn btn-primary cw-promotigo-btn-submit"
                onClick={this.formIdSubmission}
              >
                Submit
              </button>

              <a
                href="#"
                className="cw-save-to-draft mt-40"
                onClick={this.saveAsDraft}
              >
                Save and exit
              </a>
            </div>
          </div>
          <br />
          <div className="col-sm-12">
            <div className="mb-10"></div>
            <div className="mb-4">
              <em>
                Once your Promotigo form ID information has been submitted, your
                form will be automatically generated and you will receive an{' '}
                email with further setup instructions.
              </em>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          {this.props.historyLogs.length ? (
            <HistoryComponent historyLogs={this.props.historyLogs} />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  historyLogs: state.historyLogs,
  campaign: state.campaignDetails,
  promotigoFormId: state.campaignDetails.promotigoFormId,
  samples: state.campaignDetails.samples,
  isRadioButtonReq: state.campaignDetails.isRadioButtonReq,
  instantWinLose: state.campaignDetails.instantWinLose,
  displayProductSamplesAs: state.campaignDetails.displayProductSamplesAs
});

export default connect(mapStateToProps, {
  getCampaignDetails,
  submitCampaignDetails,
  fetchUserData,
  fetchCampaignData,
  fetchHistoryLogs,
  setNotificationMessage,
  updateCampaignDetails
})(PromotigoInformation);
