import axios from 'axios';
import _, { orderBy } from 'lodash';
import constant from '../constants';
import { filterToQuery } from '../selectors';
const httpProtocolPrefix =
  process.env.NODE_ENV === 'development' ? 'http' : 'https';

// TODO : change according to the WA dashboard filter api
export async function getWABriefingList(filters = {}) {
  const response = await axios.get(
    `${constant.serviceUrls.WA_COMMS_URL}/list${filterToQuery(filters)}`
  );
  
  return response.data;
}

export async function createWABriefing(data) {
  try {
    const response = await axios.post(constant.serviceUrls.WA_COMMS_URL, data);
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function getWABriefingbyUid(uid, options) {
  try {
    if (!uid) {
      throw new Error('uid is required!');
    }
    let response = await axios.get(
      `${constant.serviceUrls.WA_COMMS_URL}/${uid}` + filterToQuery(options)
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function deleteWABriefingbyUid(data) {
  try {
    if (!data.uid) {
      throw new Error('uid is required!');
    }
    let response = await axios.post(
      `${constant.serviceUrls.WA_COMMS_URL}/delete`,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function updateWABriefing(uid, data) {
  try {
    let response = await axios.post(
      `${constant.serviceUrls.WA_COMMS_URL}/${uid}`,
      data
    );
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function downloadWACostEstimate(uid) {
  try {
    let response = await axios.get(
      `${constant.serviceUrls.DOWNLOAD_WA_COST_ESTIMATE}?uid=${uid}`,
      { responseType: 'blob' }
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function downloadWAPurchaseOrder(uid) {
  try {
    let response = await axios.get(
      `${constant.serviceUrls.DOWNLOAD_WA_PURCHASE_ORDER}?uid=${uid}`,
      { responseType: 'blob' }
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function uploadWACoupon(data) {
  try {
    const response = await axios.post(
      constant.serviceUrls.WA_COMMS_URL + '/couponupload',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('uploadWACoupon e: ', e.message);
    throw e;
  }
}

export async function waUploadFileWithStatusService(data, jsonData) {
  try {
    let response = await axios
    .post(`${constant.serviceUrls.WA_COMMS_URL}/updateFileUploadAndStatus`, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    return response.data;
  } catch (e) {
    throw e;
  }
}
