import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { WAPreviewOverlay } from './WAPreviewOverlay';

import { getFormatedDate } from 'selectors';
import constants from 'constants/config';

import previewImg from 'images/dashboard-list-preview.svg';

export const WADashboardRow = ({ campaign = {}, index }) => {
  const history = useHistory();

  const [isWaPreviewOpen, setIsWAPreviewOpen] = useState(false);
  const [selectedWaForPreview, setSelectedWaForPreview] = useState({});

  const { authorizedUserData } = useSelector(state => state.authorized);

  const {
    brand,
    name,
    uid,
    lastUpdatedDate,
    country,
    status,
    communicationDetail,
    requestorsEmail,
    deliverySchedule = {},
    shareCostEstimate = {}
  } = campaign;

  const consumerCount = deliverySchedule?.consumerCount;
  const audienceCount = shareCostEstimate?.audienceCount;
  const expectedVolume =
    consumerCount ||
    audienceCount //||
    // (communicationDetail && communicationDetail.expectedVolume);

  const lastUpdateDate = getFormatedDate(lastUpdatedDate, false, false).split(
    '-'
  )[0];

  const statusClassName = classNames({
    'cw-print-hide': true,
    badge: true,
    'campaign-status-badge': true,
    'badge-success': String(status) === '2' || String(status) === '7',
    'badge-primary': String(status) === '1',
    'badge-warning': String(status) === '3',
    'badge-email-awaiting-setup':
      String(status) === '4' || String(status) === '9',
    'badge-email-schedule': String(status) === '6',
    'badge-email-reject': String(status) === '5',
    'badge-email-awaiting-info': String(status) === '8',
    'badge-email-awaiting-purchase_order': String(status) === '10'
  });

  const getWaRedirectLink = () => {
    const role = get(authorizedUserData, 'group', 'requestor');
    if (
      ['admin', 'approver'].includes(role) &&
      status === constants.WA_CAMPAIGN_STATUS.AWAITING_FOR_APPROVAL
    ) {
      return `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.APPROVAL}`;
    } else if (
      ['admin', 'acs'].includes(role) &&
      (status === constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP ||
        status === constants.WA_CAMPAIGN_STATUS.SCHEDULED ||
        status === constants.WA_CAMPAIGN_STATUS.AWAITING_COST_ESTIMATE)
    ) {
      return `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.EXECUTION}`;
    } else {
      return `${constants.WA_BASE_ROUTE}/${uid}`;
    }
  };

  const onSelectClick = e => {
    e.preventDefault();
    history.push(getWaRedirectLink());
  };

  const handleCloseWaPreview = () => {
    setIsWAPreviewOpen(false);
    setSelectedWaForPreview({});
  };
  return (
    <>
      <tr key={index}>
        <td>
          <a
            href={getWaRedirectLink()}
            aria-label={name}
            className="one-line dark-text"
            onClick={e => {
              onSelectClick(e);
            }}
          >
            {name || 'Untitled'}
          </a>
        </td>
        <td className="max-width-column">
          <div className="d-flex flex-column ">
            <span className="dark-text one-line">{brand?.name || ''}</span>
            <span className="light-text">{country?.name || ''}</span>
          </div>
        </td>
        <td className="dark-text center one-line max-width-column">
          {expectedVolume || ''}
        </td>
        <td className="dark-text center">{lastUpdateDate}</td>
        <td className="center">
          <span className={statusClassName}>
            {constants.emailStatus[status]}
          </span>
        </td>
        <td className="center">
          <button
            className="inline-button template-btn"
            onClick={() => {
              setIsWAPreviewOpen(true);
              setSelectedWaForPreview(uid);
            }}
          >
            <img src={previewImg} alt="preview" className="preview-icon" />
          </button>
        </td>
      </tr>

      <WAPreviewOverlay
        isOpen={isWaPreviewOpen}
        onPreviewSelect={() => {
          history.push(`${constants.WA_BASE_ROUTE}/${uid}`);
        }}
        selectedWaForPreview={selectedWaForPreview}
        handleCloseForm={handleCloseWaPreview}
        primaryButtonTitle="View"
        campaignName={name || 'Untitled'}
        requestorsEmail={requestorsEmail}
        brand={brand}
        status={status}
        country={country}
        campaignUpdatedAt={lastUpdatedDate}
        campaign={campaign}
      />
    </>
  );
};
