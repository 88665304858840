import welcomeEmailImg from '../images/welcome.png';
import newsLetterEmailImg from '../images/newsletter.png';
import coreInfoEmailImg from '../images/core-info.png';
import birthdayEmailImg from '../images/birthday-icon.svg';
import otherEmailImg from '../images/other.png';
import newsLetterSMSImg from '../images/newsletterSMS1.png';
import generalSMSImg from '../images/generalSMS1.png';

import parentingAndFamilyImg from '../images/parentingAndFamily.png';
import planetNatureAndSocietyImg from '../images/planetNatureAndSociety.png';
import homeAndHygieneImg from '../images/homeAndHygiene.png';
import foodAndReciepesImg from '../images/foodAndReciepes.png';
import beautyAndStylingImg from '../images/beautyAndStyling.png';
import wellnessNutritionAndActiveLifestyleImg from '../images/wellnessNutritionAndActiveLifestyle.png';

import seasonal from '../images/email-secondary-themes/seasonal.svg';
import monthly121 from '../images/email-secondary-themes/monthly121.svg';
import campaignAwareness from '../images/email-secondary-themes/campaignAwareness.svg';
import campaignEngagement from '../images/email-secondary-themes/campaignEngagement.svg';
import events from '../images/email-secondary-themes/events.svg';
import newProductAwareness from '../images/email-secondary-themes/newProductAwareness.svg';
import productRecommendations from '../images/email-secondary-themes/productRecommendations.svg';
import promoIncentives from '../images/email-secondary-themes/promoIncentives.svg';
import salesAndCommerce from '../images/email-secondary-themes/salesAndCommerce.svg';
import quizAndSurvey from '../images/email-secondary-themes/quizAndSurvey.svg';
import recipeInspiration from '../images/email-secondary-themes/recipeInspiration.svg';
import tipsAndTricks from '../images/email-secondary-themes/tipsAndTricks.svg';
import activismAndAction from '../images/email-secondary-themes/activismAndAction.svg';
import purpose from '../images/email-secondary-themes/purpose.svg';
import sustainability from '../images/email-secondary-themes/sustainability.svg';
import privacyAndPolicy from '../images/email-secondary-themes/privacyAndPolicy.svg';
import ExternalLinkIcon from '../images/external-link.png';

let _ = require('lodash');
let { getDateRange } = require('../selectors/index');

const INCENTIVE_UPLOAD_MAX_FILE_SIZE = '25000';
const consentTypes = {
  unileverEmailConsent: 'unileverEmailConsent',
  unileverSMSConsent: 'unileverSMSConsent',
  brandSMSConsent: 'brandSMSConsent',
  brandEmailConsent: 'brandEmailConsent',
  legalAgeConsent: 'legalAgeConsent',
  privacyNotice: 'privacyNotice',
  additionalSubscription: 'additionalSubscription',
  additionalSubscription1: 'additionalSubscription1',
  contentInterestAreas: 'contentInterestAreas',
  termsAndConditions: 'termsAndConditions',
  legalDisclaimer: 'legalDisclaimer',
  unileverEmailOnlyConsent: 'unileverEmailOnlyConsent',
  unileverSMSOnlyConsent: 'unileverSMSOnlyConsent',
  unileverOnlineOnlyConsent: 'unileverOnlineOnlyConsent',
  combinedUnileverAndBrandEmailConsent: 'combinedUnileverAndBrandEmailConsent',
  unileverWhatsAppConsent: 'unileverWhatsAppConsent',
  brandWhatsAppConsent: 'brandWhatsAppConsent',
  multiChannelUnileverConsent: 'multiChannelUnileverConsent'
};

const EMAIL_SCHEDULE_OPTIONS = {
  ON_SIGNUP_OPTION: {
    label: 'On sign-up',
    value: 'onSignUp'
  },

  ON_BIRTHDAY_OPTION: {
    label: 'On birthday',
    value: 'onBirthday'
  },

  OTHER_OPTION: {
    label: 'Other',
    value: 'other'
  },

  SEVEN_DAYS_BEFORE_BIRTHDAY_OPTION: {
    label: '7 days before birthday',
    value: 'sevenDaysBeforeBirthday'
  },

  FIXED_DATE_IN_BIRTHDAY_MONTH_OPTION: {
    label: 'Fixed date in a birthday month',
    value: 'fixedDateInBirthdayMonth'
  }
};

const SMS_COST_AND_REQUEST_INFO_OPTIONS = {
  SMS_SHARE_COST_ESTIMATE_OPTION: {
    label: 'Share cost estimate',
    value: 'shareCostEstimate',
    subLabel:
      'Share an estimate of audience count and SMS costs with requestor of the campaign so that they can create a Purchase Order'
  },

  SMS_REQUEST_MORE_INFO_OPTION: {
    label: 'Request more information',
    value: 'requestMoreInfo',
    subLabel:
      'Ask for any missing/incomplete information or corrections to the campaign'
  }
};

const {
  SMS_SHARE_COST_ESTIMATE_OPTION,
  SMS_REQUEST_MORE_INFO_OPTION
} = SMS_COST_AND_REQUEST_INFO_OPTIONS;

const WA_COST_AND_REQUEST_INFO_OPTIONS = {
  WA_SHARE_COST_ESTIMATE_OPTION: {
    label: 'Share cost estimate',
    value: 'shareCostEstimate',
    subLabel:
      'Share an estimate of audience count and WA costs with requestor of the campaign so that they can create a Purchase Order'
  },

  WA_REQUEST_MORE_INFO_OPTION: {
    label: 'Request more information',
    value: 'requestMoreInfo',
    subLabel:
      'Ask for any missing/incomplete information or corrections to the campaign'
  }
};

const {
  WA_SHARE_COST_ESTIMATE_OPTION,
  WA_REQUEST_MORE_INFO_OPTION
} = WA_COST_AND_REQUEST_INFO_OPTIONS;

const {
  ON_SIGNUP_OPTION,
  ON_BIRTHDAY_OPTION,
  SEVEN_DAYS_BEFORE_BIRTHDAY_OPTION,
  FIXED_DATE_IN_BIRTHDAY_MONTH_OPTION,
  OTHER_OPTION
} = EMAIL_SCHEDULE_OPTIONS;

let constants = {
  LOCALSTORAGE_NAME: 'campaignwizard',
  TinyMCEapiKey: 'qu7qvd479o6awpunip87ztwactvdeqzjka9bs0xo1u19rfpo',
  //localhost key -  lmcmqr2wbzoymil2a8iww2t7eneq0muck46vei6t7a19os3s
  campaignStatus: {
    DRAFT: '1',
    PROCESSING: '2',
    COMPLETED: '3',
    REJECTED: '4',
    DELETED: '5',
    WAITING_APPROVAL: '6',
    WAITING_ID: '7',
    WAITING_API_DETAILS: '8',
    CANCELLED: '9',
    WAITING_FORM_ID: '12'
  },
  questionTypes: {
    FREE_TEXT: 'Free text',
    FILE_UPLOAD: 'File upload',
    PROFILE: 'Profile',
    UNIQUE_ENTRY_CODE: 'Unique entry code'
  },
  regex: {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    serviceId: /^([a-zA-Z0-9_-]){0,25}$/,
    url: /https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
    ageOfConsent: /1[0-9]|[2-9]\d+|[1-9]\d{3,}/,
    campaignId: /^(CN|PN)[0-9]{6}$/,
    qna: /^[0-9]+$/,
    brandEmailId: /^(SVC|BRAND)\_[A-Z]{2}\_[A-Z0-9]{6}\_(EML|SMS)$/,
    brandEmailConsent: /^(SVC|BRAND)\_[A-Z]{2}\_[A-Z0-9]{6}\_(EML)$/,
    brandSMSConsent: /^(SVC|BRAND)\_[A-Z]{2}\_[A-Z0-9]{6}\_(SMS)$/,
    unileverEmailConsent: /^(UNILEVER)\_[A-Z]{2}\_(EML)$/,
    unileverSMSConsent: /^(UNILEVER)\_[A-Z]{2}\_(SMS)$/,
    unileverEmailOnlyConsent: /^(UNILEVER)\_[A-Z]{2}\_(EMLONLY)$/,
    unileverSMSOnlyConsent: /^(UNILEVER)\_[A-Z]{2}\_(SMSONLY)$/,
    unileverOnlineOnlyConsent: /^(UNILEVER)\_[A-Z]{2}\_(ONLINEONLY)$/,
    numeric: /^\d*\.?\d*$/,
    emailDomain: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+com$/g,
    unileverId: /^(UNILEVER)$/,
    //prodUrl:/(http(s):)?(\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\-a-zA-Z]+$/
    prodUrl: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[a-zA-Z0-9\-\._~:/?#[\]\&'\(\),;=.]+$/,
    emailAddress: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    smsBodyURL: /^((?:https?:\/\/)?[^.\/]+(?:\.[^.\/]+)+(?:\/.*)?)$/g,
    unileverWhatsAppConsent: /^(UNILEVER)\_[A-Z]{2}\_(WHATSAPP)$/
  },
  paths: {
    DASHBOARD: '/campaign-wizard/dashboard',
    HOMEPAGE: '/campaign-wizard'
  },
  editorPermissions: {
    EVERYONE: 'everyone',
    ONLY_REQUESTOR: 'onlyMe',
    SELECTED_USERS: 'selectedUsers'
  },
  editorPermissionsLabel: {
    EVERYONE: 'Everyone',
    ONLY_REQUESTOR: 'Only requestor',
    REQUESTED_USERS: 'Requestor and selected users'
  },
  userTabIndex: [
    'approver',
    'masterdata',
    'acs',
    'gigya',
    'prm',
    'creativeagency',
    'executionagency',
    'requester',
    'admin'
  ],
  maxAllowedGigyaCssFileSize: 5000,
  roles: {
    APPROVER: 'approver',
    MASTERDATA: 'masterdata',
    GIGYA: 'gigya',
    PRM: 'prm',
    REQUESTOR: 'requestor',
    ADMIN: 'admin'
  },
  roleBasedPages: {
    approver: 'approval',
    masterdata: 'masterdata',
    gigya: 'gigya',
    admin: 'approval'
  },
  consentVersions: [
    {
      value: 'brandUnileverVersion1',
      label: 'Version 1 - Brand & Unilever explicit consents'
    },
    {
      value: 'brandUnileverVersion2',
      label: 'Version 2 - Brand & Unilever implicit consents'
    },
    {
      value: 'enterpriseConsentsVersion3',
      label: 'Version 3 - Enterprise consents'
    }
  ],
  consentTypes,
  consentLabels: {
    brandUnileverVersion1: 'Version 1 - Brand & Unilever Consents',
    brandUnileverVersion2: 'Version 2 - Brand & Unilever Consents',
    enterpriseConsentsVersion3: 'Version 3 - Enterprise Consents'
  },
  defaultConsents: {
    age_consent: '',
    privacy_policy: '',
    unilever_email: '',
    email_only: '',
    sms_only: '',
    online_only: ''
  },
  UNEDITABLE_CONSENTS: [
    consentTypes.unileverEmailConsent,
    consentTypes.unileverSMSConsent,
    consentTypes.unileverEmailOnlyConsent,
    consentTypes.unileverSMSOnlyConsent,
    consentTypes.unileverOnlineOnlyConsent,
    consentTypes.legalAgeConsent,
    consentTypes.privacyNotice
  ],
  NONRESETTABLE_CONSETS: [
    consentTypes.additionalSubscription,
    consentTypes.additionalSubscription1,
    consentTypes.termsAndConditions,
    consentTypes.legalDisclaimer
  ],
  DEFAULT_EDITABLE_CONSETS: [
    consentTypes.termsAndConditions,
    consentTypes.legalDisclaimer
  ],
  DEFAULT_CONSENTS: [consentTypes.legalAgeConsent, consentTypes.privacyNotice],
  QA_QUESTION_TYPES: [
    {
      answers: [
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        },
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        }
      ],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Single Choice Question',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Single choice',
      toolTip: '',
      translatedId: '',
      value: 'singleChoice'
    },
    {
      answers: [
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        },
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        }
      ],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Multiple Choice Question',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Multiple choice',
      toolTip: '',
      translatedId: '',
      value: 'multipleChoice',
      maxAnswerLimit: 'No Limit'
    },

    {
      answers: [],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Free Text Question',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Free text',
      toolTip: '',
      translatedId: '',
      value: 'freeText'
    },
    {
      answers: [],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Free Text Area',
      mandatoryToggle: false,
      mandatoryMessage: '',
      numberOfLines: 5,
      questionLabel: '',
      questionType: 'Free text',
      toolTip: '',
      translatedId: '',
      value: 'freeTextArea'
    }
  ],
  INCENTIVE_FIELDS: [
    {
      dataType: 'entryCode',
      label: 'Unique entry code',
      type: 'incentiveFields',
      fieldLabel: '',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: ''
    },
    {
      dataType: 'fileUpload',
      fieldLabel: '',
      maxFileSize: INCENTIVE_UPLOAD_MAX_FILE_SIZE,
      type: 'incentiveFields',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: '',
      label: 'File Upload',
      validationMessage: '',
      failedUploadMessage: '',
      values: [
        {
          key: 'jpg',
          value: 'jpg',
          isChecked: true
        },
        {
          key: 'jpeg',
          value: 'jpeg',
          isChecked: true
        },
        {
          key: 'tiff',
          value: 'tiff',
          isChecked: true
        },
        {
          key: 'tif',
          value: 'tif',
          isChecked: true
        },
        {
          key: 'png',
          value: 'png',
          isChecked: true
        },
        {
          key: 'Video (3gp, 3g2, mp4, flv, mkv, avi, mov)',
          value: '3gp, 3g2, mp4, flv, mkv, avi, mov',
          isChecked: true
        }
      ]
    }
  ],
  SMS_CAMPAIGN_STATUS: [
    { label: 'Draft', value: '1' },
    { label: 'Submitted', value: '2' },
    { label: 'Awaiting approval', value: '3' },
    { label: 'Awaiting cost estimate', value: '9' },
    { label: 'Awaiting purchase order', value: '10' },
    { label: 'Awaiting setup', value: '4' },
    { label: 'Awaiting info', value: '8' },
    { label: 'Rejected', value: '5' },
    { label: 'Scheduled', value: '6' },
    { label: 'Live', value: '7' }
  ],
  roleBasedStatus: {
    approver: {
      label: 'Awaiting Approval',
      status: '6'
    },
    masterdata: {
      label: 'Awaiting Master Data',
      status: '7'
    },
    gigya: {
      label: 'Awaiting Gigya keys',
      status: '8'
    },
    admin: {
      label: 'Awaiting Approval',
      status: '6'
    },
    requestor: {
      label: 'Awaiting Promotigo ID',
      value: '12'
    }
  },
  adminStatusDropDownOptions: [
    {
      label: 'Cancelled',
      value: '9'
    },
    {
      label: 'Completed',
      value: '3'
    },
    {
      label: 'Published',
      value: '11'
    },
    {
      label: 'Draft',
      value: ['1', '4']
    },
    {
      label: 'Awaiting Approval',
      value: '6'
    },
    {
      label: 'Awaiting Master Data',
      value: '7'
    },
    {
      label: 'Awaiting Promotigo ID',
      value: '12'
    }
  ],
  statusDropDownOptions: [
    {
      label: 'Cancelled',
      value: '9'
    },
    {
      label: 'Completed',
      value: '3'
    },
    {
      label: 'Published',
      value: '11'
    },
    {
      label: 'Draft',
      value: ['1', '4']
    },
    {
      label: 'Processing',
      value: ['2', '6', '7', '8']
    },
    {
      label: 'Awaiting Promotigo ID',
      value: '12'
    }
  ],
  lastUpdatedData: [
    {
      value: getDateRange('last 7 days'),
      label: 'Last 7 days'
    },
    {
      value: getDateRange('last 14 days'),
      label: 'Last 14 days'
    },
    {
      value: getDateRange('last 30 days'),
      label: 'Last 30 days'
    },
    {
      value: getDateRange('last 60 days'),
      label: 'Last 60 days'
    },
    {
      value: getDateRange('last 90 days'),
      label: 'Last 90 days'
    },
    {
      value: getDateRange('last month'),
      label: 'Last calendar month'
    },
    {
      value: getDateRange('this year'),
      label: 'This year'
    },
    {
      value: getDateRange('this and last year'),
      label: 'This year + last calendar year'
    },
    {
      value: getDateRange('past year'),
      label: 'Last calendar year'
    },
    {
      value: 'All time',
      label: 'All time'
    }
  ],
  consents: [
    {
      optInType: 'legalAgeConsent',
      tsKey: 'age_consent',
      label: 'Age Consent'
    },
    {
      optInType: 'privacyNotice',
      tsKey: 'privacy_policy',
      label: 'Privacy Policy'
    },
    {
      optInType: 'brandEmailConsent',
      tsKey: 'brand_email',
      label: 'Brand Email'
    },
    {
      optInType: 'brandSMSConsent',
      tsKey: 'brand_sms',
      label: 'Brand SMS'
    },
    {
      optInType: 'unileverEmailConsent',
      tsKey: 'unilever_email',
      label: 'Unilever Email'
    },
    {
      optInType: 'unileverSMSConsent',
      tsKey: 'unilever_sms',
      label: 'Unilever SMS'
    },
    {
      optInType: 'additionalSubscription',
      tsKey: '',
      label: 'Additional Opt-ins (Regular Comms.) 1'
    },
    {
      optInType: 'additionalSubscription1',
      tsKey: '',
      label: 'Additional Opt-ins (Regular Comms.) 2'
    },
    {
      optInType: 'termsAndConditions',
      tsKey: '',
      label: 'Terms and Conditions'
    },
    {
      optInType: 'legalDisclaimer',
      tsKey: '',
      label: 'Disclaimer'
    },
    {
      optInType: 'unileverEmailOnlyConsent',
      tsKey: 'email_only',
      label: 'Unilever Email Only'
    },
    {
      optInType: 'unileverSMSOnlyConsent',
      tsKey: 'sms_only',
      label: 'Unilever SMS Only'
    },
    {
      optInType: 'unileverOnlineOnlyConsent',
      tsKey: 'online_only',
      label: 'Unilever Online Advertising'
    },
    {
      optInType: 'combinedUnileverAndBrandEmailConsent',
      tsKey: 'unilever_email', // mapped with unilever email
      label: 'Combined Unilever and brand email consent'
    },
    {
      optInType: 'unileverWhatsAppConsent',
      tsKey: 'unilever_whatsapp',
      label: 'Unilever WhatsApp'
    },
    {
      optInType: 'brandWhatsAppConsent',
      tsKey: 'brand_whatsapp',
      label: 'Brand WhatsApp'
    }
  ],
  profileFields: [
    {
      dataType: 'honorificPrefix',
      label: 'Honorific Prefix',
      translatedLabel: 'Honorific Prefix',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your honorific prefix',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '1',
      campaignType: 'Direct'
    },
    {
      dataType: 'firstName',
      label: 'First Name',
      translatedLabel: 'First Name',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your first name',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '2',
      campaignType: 'Direct'
    },
    {
      dataType: 'middleName',
      label: 'Middle Name',
      translatedLabel: 'Middle Name',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your middle name',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '3',
      campaignType: 'Direct'
    },
    {
      dataType: 'lastName',
      label: 'Last Name',
      translatedLabel: 'Last Name',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your last name',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '4',
      campaignType: 'Direct'
    },
    {
      dataType: 'gender',
      label: 'Gender',
      translatedLabel: 'Gender',
      type: 'field',
      toolTip: '',
      hintText: '',
      values: [
        {
          key: 'F',
          value: 'Female',
          translatedValue: 'Female',
          isChecked: true
        },
        {
          key: 'M',
          value: 'Male',
          translatedValue: 'Male',
          isChecked: true
        },
        {
          key: 'N',
          value: 'Not Applicable',
          translatedValue: 'Not Applicable',
          isChecked: true
        },
        {
          key: 'O',
          value: 'Other',
          translatedValue: 'Other',
          isChecked: true
        },
        {
          key: 'U',
          value: 'Unknown',
          translatedValue: 'Unknown',
          isChecked: true
        }
      ],
      mandatoryMessage: 'Please specify your gender',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '5',
      campaignType: 'Direct'
    },
    {
      dataType: 'dateOfBirth',
      label: 'Date of Birth',
      translatedLabel: 'Date of Birth',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your date of birth',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '6',
      campaignType: 'Direct'
    },
    {
      dataType: 'email',
      label: 'Email Address',
      translatedLabel: 'Email Address',
      type: 'field',
      toolTip: '',
      hintText: '',
      byDefault: 'Yes',
      mandatory: 'Yes',
      mandatoryMessage: 'Please enter your email address',
      regex: true,
      language: 'en',
      defaultDisplayOrder: '7',
      campaignType: 'Direct'
    },
    {
      dataType: 'mobileNumber',
      label: 'Mobile Number',
      translatedLabel: 'Mobile Number',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your mobile number',
      regex: true,
      language: 'en',
      defaultDisplayOrder: '8',
      campaignType: 'Direct'
    },
    {
      dataType: 'houseNameOrNumber',
      label: 'House Name or Number',
      translatedLabel: 'House Name or Number',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your house name or number',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '9',
      campaignType: 'Direct'
    },
    {
      dataType: 'addressLine1',
      label: 'Address Line 1',
      translatedLabel: 'Address Line 1',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter address line 1',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '10',
      campaignType: 'Direct'
    },
    {
      dataType: 'addressLine2',
      label: 'Address Line 2',
      translatedLabel: 'Address Line 2',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter address line 2',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '11',
      campaignType: 'Direct'
    },
    {
      dataType: 'cityName',
      label: 'City',
      translatedLabel: 'City',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your city',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '12',
      campaignType: 'Direct'
    },
    {
      dataType: 'postalCode',
      label: 'Postal Code',
      translatedLabel: 'Postal Code',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your postal code',
      regex: true,
      language: 'en',
      defaultDisplayOrder: '13',
      campaignType: 'Direct'
    },
    {
      dataType: 'stateOrProvince',
      label: 'State or Province',
      translatedLabel: 'State or Province',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your state or province',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '14',
      campaignType: 'Direct'
    },
    {
      dataType: 'addressCountry',
      label: 'Country',
      translatedLabel: 'Country',
      type: 'field',
      toolTip: '',
      hintText: '',
      mandatoryMessage: 'Please enter your country',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '15',
      campaignType: 'Direct'
    },

    {
      dataType: 'heading',
      label: 'Heading',
      translatedLabel: 'Heading',
      type: 'field',
      toolTip: '',
      hintText: '',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '16',
      campaignType: 'Direct'
    },
    {
      dataType: 'paragraph',
      label: 'Paragraph',
      translatedLabel: 'Paragraph',
      type: 'field',
      toolTip: '',
      hintText: '',
      regex: false,
      language: 'en',
      defaultDisplayOrder: '17',
      campaignType: 'Direct'
    }
  ],
  messagesFields: [
    {
      dataType: 'submit',
      label: 'Submit',
      translatedLabel: 'Submit',
      type: 'message',
      toolTip: '',
      hintText: '',
      regex: false,
      language: 'en',
      campaignType: 'Direct'
    },
    {
      dataType: 'submissionError',
      label: 'Submission Error',
      translatedLabel: 'Submission failed. Please try again.',
      type: 'message',
      toolTip: '',
      hintText: '',
      regex: false,
      language: 'en',
      campaignType: 'Direct'
    },
    {
      dataType: 'campaignExpiryMessage',
      label: 'Campaign Expiry Message',
      translatedLabel:
        'Campaign expired. This campaign is no longer available.',
      type: 'message',
      toolTip: '',
      hintText: '',
      regex: false,
      language: 'en',
      campaignType: 'Direct'
    },
    {
      dataType: 'mandatoryFieldIndicatorText',
      label: 'Mandatory Fields Indicator Text',
      translatedLabel: 'Fields marked with an asterisk are required.',
      type: 'message',
      toolTip: '',
      hintText: '',
      regex: false,
      language: 'en',
      campaignType: 'Direct'
    }
  ],
  incentiveFields: [
    {
      dataType: 'entryCode',
      label: 'Entry Code',
      type: 'incentiveFields',
      fieldLabel: '',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: ''
    },
    {
      dataType: 'fileUpload',
      fieldLabel: '',
      maxFileSize: INCENTIVE_UPLOAD_MAX_FILE_SIZE,
      type: 'incentiveFields',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: '',
      label: 'File Upload',
      validationMessage: '',
      failedUploadMessage: '',
      values: [
        {
          key: 'jpg',
          value: 'jpg',
          isChecked: true
        },
        {
          key: 'jpeg',
          value: 'jpeg',
          isChecked: true
        },
        {
          key: 'tiff',
          value: 'tiff',
          isChecked: true
        },
        {
          key: 'tif',
          value: 'tif',
          isChecked: true
        },
        {
          key: 'png',
          value: 'png',
          isChecked: true
        },
        {
          key: 'Video (3gp, 3g2, mp4, flv, mkv, avi, mov)',
          value: '3gp, 3g2, mp4, flv, mkv, avi, mov',
          isChecked: true
        }
      ]
    }
  ],
  sampleDetails: [
    {
      answers: [
        {
          label: '',
          value: 'prize-0'
        }
      ],
      dataType: 'samplePrizeSelection',
      questionType: 'Single choice',
      label: 'Sample Details',
      fieldLabel: '',
      type: 'incentiveFields',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: ''
    }
  ],
  competitionDetails: [
    {
      dataType: 'competitionDetail',
      label: 'Competition Details',
      type: 'incentiveFields',
      instantWinLose: false
    }
  ],
  dashboardRoleFilter: ['approver', 'masterdata', 'gigya', 'prm', 'admin'],
  allowedMarketsForDoubleOptin: ['AT', 'DE'],
  epsilonMarket: ['US'],
  displayProductSamplesAs: ['radio', 'dropdown', 'checkbox', ''],
  userManagementLinkAccessProfile: 'admin',
  SIMPLE_SIGNUP_TEXT: 'Simple sign-up',
  CAMPAIGN_SAVED_AS_DRAFT_TEXT: 'Your campaign has been saved as draft',
  CAMPAIGN_QA_TYPE: 'Sign-up with Questions & Answers',
  apiResponseText: {
    DOMAIN_ALREADY_EXISTS: 'Domain already exist',
    DOMAIN_DELETION_SUCCESS: 'Domain deleted successfully'
  },
  EMAIL_SUBJECT_BRAND_COUNTRY_CREATION:
    'Request for new Brand or Country from Campaign Wizard',
  FIELDS_MAX_LENGTH: {
    disclaimerText: 1000,
    descriptionText: 200,
    dpiaReferenceNo: 5
  },
  USER_NOT_IN_GROUP_ERROR:
    'Sorry this email was not found in LDAP, please delete the email and raise a Kana ticket.',
  MANDATORY_AUTHORING_ERROR_MSG:
    'You need to add at least one email address for authoring access.',
  EMPTY_AUTHORING_EMAIL_ERROR:
    'Please enter an email address or remove this field',
  PRM_TITLE_TEXT:
    'As the PRM keys have been removed, the use the global keys per region is used when making requests. Please raise a KANA ticket to get the PRM details.',
  SAAS_MESSAGES: {
    regularCommunicationInfoMessage: [
      'Add a regular communication if you want to create an extra communication/opt-in channel for the consumer. This additional opt-in preference will appear alongside the brand and Unilever consent.',
      'Example: an ongoing weekly or monthly communication on a selected topic that is not brand related (e.g. monthly mean planner).'
    ],
    campignLeadTimeText: {
      incentiveCampaign:
        'Please note, Incentive Campaigns require a 15 day lead time.',
      directCampaign:
        'Please note, Direct Campaigns require an 8 day lead time.'
    },
    saasCampaignTypeMessage: [
      'The new Campaign Wizard workflow that provisions form widgets, powered by advanced authoring and configurations, is available for direct simple sign-up forms along with additional Q&A attributes. The rest of the form types will be available soon!'
    ],
    aemDiscontinuationMessage: [
      'AEM forms will not be available to be created through Campaign Wizard effective 1 Jan 2023. Please use the new form widgets as this is the revised easy to use global solution.'
    ],
    nonSaasCampaignTypeMessage: [
      'GIGYA preset forms are now discontinued. You can choose for Campaign Wizard to auto-create your form in your AEM platform on the next step. Alternatively, you can go back to the previous step and choose ‘website type’ as ‘Adobe SaaS’ to author and create your own form within Campaign Wizard and use it on any web platform'
    ],
    otherCampaignTypeMessage: [
      'GIGYA preset forms are now discontinued. If you need a form, you can go back and choose ‘website type’ as ‘Adobe SaaS’ to author and create your own form within Campaign Wizard and use it on any web platform'
    ],
    configureFormFields: ['Please select your form fields'],
    personaliseFields: [
      'Select standard consumer profile fields your sign-up form and specify their'
    ],
    personaliseFieldsOptional: [
      'We pre-populate default consumer facing labels for most fields and messages. You can choose these or specify custom based on your preferred tone of voice.'
    ],
    genericFieldsInfo: [
      'You can now customise the form submission experience for your users.'
    ],
    duplicateOptionsWarningInfo: [
      'The option you attempted to select from the dropdown is already selected. Please author the already selected option as this option can be added only once to be displayed on the form.'
    ],
    mobileNumberCollectionMessage: [
      'Collecting this consent enables our brands to communicate with our consumers via SMS. Please ensure you are also collecting mobile numbers.'
    ],
    smsConsentCollectionMessage: [
      'If you are collecting mobile numbers, please ask for SMS consent to be able to communicate with consumers via SMS'
    ],
    ageConsentInfoMessage: [
      'Please make sure that the age in the statement is similar to the age of consent provided above.'
    ],
    legalAgeValidationInfoMessage: [
      'This the error that consumers will see if they try to select a date of birth that makes their age lower than the legal age consent.'
    ],
    saasSortingMessage: [
      'You now have more options to customise the campaign',
      'experience for your users on Adobe SaaS websites.'
    ],
    consentInfoMessage: [
      'View and choose legal and consents copy approved for your market.'
    ],
    consentsUpdatedToEnterpriseMessage:
      'Version 1 and 2 consents are no longer supported in Campaign Wizard. You have been defaulted to Enterprise consents',
    consentWarningMessage: [
      'Please use approved consents copy we have populated for you. Any changes to statements should be avoided unless they are approved by DPOs.'
    ],
    consentInfoStatement: [
      'Please note all your statements and error messages are recommended to be in the language selected. We may pre-populate some details for you. You can use them or choose to specify your custom details. The consent fields can be added only once to be displayed on the form.'
    ],
    previewInfoMessage: [
      'You can now preview your form in Campaign Wizard, based on the inputs provided on previous screens. Please note that you will be able to use this form on your website once the entire workflow is done and the campaign status turns to ‘Completed’.'
    ],
    brandDetailsInfoMessage: [
      'You can now preview and generate fully-functional direct simple sign-up forms within Campaign Wizard and use them as widgets on any platform.To enable this feature, we would prompt you to provide more information for your form. Please choose ‘Adobe SaaS’ as the website type to start now! '
    ],
    editSinglePageFieldsInfoMsg: [
      'Addition of most of the consumer data fields are not allowed in  edit journey for audit purposes. To add new fields, create a new form and submit for approval.'
    ],
    editFieldsInfoMsg: [
      'You can only edit or remove existing fields. Addition of new fields is not allowed while editing a previously completed request.'
    ],
    editQnAInfoMsg: [
      'You can only edit or remove existing Q&As. Addition of new Q&As is not allowed.'
    ],
    mandateCommChannelValidationMessage: [
      // "Either email address or mobile number field should exist on a sign up form. Please select at-least one of these as a mandatory field.",
      'Please mark either email or mobile as a mandatory field'
    ],
    consentValidationMessage: [
      //"Please provide Age Consent and Privacy Policy statements. Both of these consent statements should be displayed on the form.",
      'Age consent and privacy policy are mandatory.'
    ],
    approvalInfoMessage: [
      'You can now preview forms before approving a request.'
    ],
    completedInfoMessage: ['The form is now ready for use.'],
    consentUnavailableMessage: [
      'Legal consents copy for this version in not available in central records for your brand and market. You can select another consents version or add consent fields manually below.'
    ],
    consentUnavailableMessageEC:
      'Legal consents copy for this version in not available in central records for your brand and market. You can add consent fields manually below',
    questionAnswersInfoMessage: [
      'Please do not request or select PII fields as master data questions/answers. PII fields include first name, last name, email, phone number, address fields, name prefix, name suffix, date or birth and gender. These are standard fields that do not need master data IDs.'
    ],
    nonSaasPiWarning: [
      'Please do not request or select PII fields as master data questions/answers. PII fields include first name, last name, email, phone number, address fields, name prefix, name suffix, date or birth and gender. These are standard fields that do not need master data IDs. However, we let you select few of these fields under the ‘Profile’ question type below but these will not be associated to any master data.'
    ],
    addQuestionMessage: ['Please add at-least one question'],
    incentiveInfoMessage: [
      'You can now personalise the incentive fields in your forms to give your users more customised experience.'
    ],
    fileUploadWarningInfo: [
      'Maximum of three file upload fields can be added to your form'
    ],
    newMarketInfoMessage: [
      'US brands can now request and generate form widgets for their websites on Adobe SaaS (These can be used on other platforms as well)'
    ],
    newBrandDetailsMessage: [
      'Creating sign-up forms has never been easier! You can now create and preview sign-up forms in Campaign Wizard and embed them as widgets on any website, on any platform. Please choose form widgets on the next page to proceed.'
    ],
    newFormtypeMessage: [
      'Creating sign-up forms has never been easier! You can now create and preview sign-up forms in Campaign Wizard and embed them as widgets on any website, on any platform. Please choose form widgets to proceed.'
    ],
    incentiveEditMessage:
      'You can only edit or remove existing incentive fields.',
    qnaNewUpdateMessage: [
      'Browse our new master list of Q&As curated to guide data enrichment initiatives for each business group. These are not only mapped to Unilever’s data model to directly enrich our super segments but are also ready for use with already created master data IDs.'
    ],
    reviewPageUpdateMessage: [
      'You can now preview your form before submitting your request. This form will be ready to embed on your website once the campaign status turns completed.'
    ],
    sampleDetailsWarningMsg: [
      'If you are trying to edit samples information, please ensure details are consistent with order created in Promotigo.'
    ],
    multiChannelToolTipMessage: 'Use this field to show a single consent statement with all channels listed. You can use this only if you are a non-GDPR country and the usage has been approved by your local DPO. GDPR countries cannot use this field and instead have to use separate consent statements per channel. View'
  },
  CONFIGURE_FORM_FIELDS: {
    configurationMissingMessage: [
      'Some of your fields have missing configurations or properties. Please expand the highlighted fields to fix errors'
    ],
    emailConsentMissingMessage: ['Please select Unilever email consent'],
    smsConsentMissingMessage: [
      'Please select Unilever SMS consent as you are collecting user mobile phone numbers'
    ],
    emailAddressFieldMissingMesg: [
      'You selected email consent, please add email address field also'
    ],
    mobileNumberFieldMissingMesg: [
      'You selected SMS consent, please add mobile number field also'
    ],
    whatsAppConsentMissingMessage: [
      'Please select either SMS consent or Unilever WhatsApp consent as you are collecting user mobile phone numbers'
    ],
    mobileNumberFieldMissingMesgForWhatsapp: [
      'You selected WhatsApp consent, please add mobile number field also'
    ],
    multiEmailConsentMissingErrMessage: [
      'Please select email consent as a standalone channel consent or as part of the multi-channel consent. Email consent is mandatory when email field is selected'
    ]
  },
  CONSENT_STATEMENT_MESSAGE:
    'The consent has now been made implied and will always be true on form submission. You are required to copy over the consent text to privacy policy text area field to display all implied consents as a single text block, else the consent text will not be displayed on the form.',
  AGE_STATEMENT_WARNING:
    'Please ensure correct legal age for your market is specified in the statement',
  GIGYA_DECOMMISSION_MESSAGE:
    'GIGYA preset forms are now discontinued. If you need a form, you can create a form request by choosing ‘website type’ as ‘Adobe SaaS’ to author and create your own form within Campaign Wizard and use it on any web platform.',
  INCENTIVE_HEADER_MESSAGE:
    'Please note that you need to confirm all the form fields and their properties in Campaign Wizard. This includes the labels and the messages your users will see on the form. This gives you more control on defining the form experience.',
  SMS_CAMPAIGN_DEFAULT_LANGUAGE: 'English',
  SYSTEM_ERROR_MESSAGE:
    'There was a problem with the page. Please try again after sometime.',
  CW_HOMEPAGE_HEADING:
    'Campaign Wizard helps brands drive engagement and penetration, by orchestrating the setup to capture valuable consumer data and leverage it for 1:1 relevant relationships',
  CW_HOMEPAGE_FORM_OVERVIEW_TEXT:
    'Capture first-party data at scale, through online sign-up touch points, create value exchange opportunities via competitions and samples',
  CW_HOMEPAGE_SMS_OVERVIEW_TEXT:
    'Leverage consumer data to drive brand performance and improve relevancy through SMS communications',
  FORM_SUBMISSION_ERROR:
    'Your campaign submission has failed. Please try submitting your campaign again. If the issue persists, then please raise a KANA ticket.',

  FORM_WIDGET_INTEGRATION_GUIDE:
    'https://ce-platform.atlassian.net/wiki/spaces/CPDF/pages/18374623481/Forms+Widget+Integration+Guide',

  RTL_LANGUAGE_CODES: ['ar', 'he', 'ps', 'fa', 'sd', 'ur', 'yi'],
  DATA_COLLECTION_API_NEXT_STEPS: [
    'Temporarily a',
    'ticket needs to be raised to enable data collection V3 API to accept consumer data from the website which will contain your sign-up form. This is a mandatory step, required once per website (URL) – brand x market combination.'
  ],
  SAMPLE_OPTIONS: {
    singleSample: 'Allow users to select only one sample',
    multipleSample: 'Allow users to select more than one sample'
  },
  SAAS_EDIT_MESSAGES: [
    {
      heading: 'You can edit',
      values: [
        'Form field labels, including any error or info messages.',
        'Make fields mandatory or vice versa.',
        'Remove fields or reorder them.',
        'Add or modify consents and legal copy.'
      ],
      alertText:
        'Do not use labels that change the context of consumer data. For e.g. Do not rename "Your first name" to "Your child’s name"'
    },
    {
      heading: 'You cannot edit',
      values: [
        'Brand-market context or campaign type.',
        'Add new form fields.',
        'Request new master data.'
      ],
      alertText: ''
    }
  ],
  EDIT_MASTERDATA_MESSAGE: [
    {
      values: [
        'You cannot edit a master data request. Please create a new request to generate additional master data IDs.'
      ]
    }
  ],
  SAMPLE_OPTIONS: {
    singleSample: 'Allow users to select only one sample',
    multipleSample: 'Allow users to select more than one sample'
  },
  CAMPAIGN_MESSAGE: {
    platformAgnosticMessage:
      'Create ready to use forms in minutes and embed them on any website. ',
    aemMessage:
      'Choose basic configurations and get a baseline form in AEM. May require greater authoring effort in AEM'
  },
  CAMPAIGN_OPTION_MESSAGE: {
    simpleSignUp: 'Acquire consented consumers through simple sign up',
    competitions: ' Requires Promotigo setup after this request is submitted',
    competitionsNew: 'Offer competition entries to consumers who sign up.',
    samples: ' Requires Promotigo setup after this request is submitted',
    sampleNew: 'Provide samples to consumers who sign up.',
    masterDataOnly:
      'Get master data IDs for custom integrations or offline data ingestion. Master data IDs include Campaign IDs, brand codes, Q&A IDs, etc.',
    masterDataOnlyNew:
      'Get master data IDs for offline ingestion, custom forms and integrations.'
  },
  LEGAL_AGE_VALUES: ['13', '14', '15', '16', '17', '18', '19', '20'],
  APPROVAL_GUIDE_LINES: {
    title: 'Guidelines for approval',
    subTitle:
      'Review the campaign and approve or reject the request using the guidelines below',
    guideLine: [
      {
        text:
          'An approved PRA reference should be available and should match the context of this campaign.'
      },
      {
        text: 'Questions and answers',
        subText: [
          {
            text:
              'PII information should not be requested as questions and answers. ',
            isKnowMore: true,
            toolTipText:
              'First name, last name, email address, date of birth, gender,  mobile number, house number, address, city, postal code, state, country,  honorific prefix, honorific suffix are all standard fields and do not require and master data to enable data collection.',
            knowMoreText: 'Know more'
          },
          {
            text:
              'Any sensitive data should only be collected for valid reasons approved by Data Privacy/ legal. ',
            isKnowMore: true,
            toolTipText:
              'The special category of personal information of data subjects that is sensitive, the exposure of which could significantly impact the rights and freedoms of data subjects & potentially be used against them for unlawful discrimination. It includes genetics, ethnic origin, biometrics, sexual orientation, sex life, politics, health, pregnancy, and trade union membership.',
            knowMoreText: 'What’s sensitive data?'
          },
          {
            text:
              'Questions and answers should be built using the master Q&A list. In case a new question and/or answer is requested, please ensure it is a valid request, not a duplicate or a rephrased version of an already available master Q&A. You can check the ',
            isKnowMore: true,
            knowMoreText: 'existing master list of Q&As here',
            isModal: true
          }
        ]
      },
      {
        text:
          'Any additional market level considerations should also inform your decision to approve or reject.'
      },
      {
        text:
          'Ensure that any rejections are accompanied by comments that explain the corrections that need to be made.'
      }
    ]
  },

  ctaToLink: {
    aemCampaignFormLinkText: 'View AEM form here',
    otherPlatformLinkText: 'View Other platform guide here',
    nextStepOtherPlatformLinkText: 'View next steps for other platforms here',
    aem404LinkText: 'View 404 page here',
    thankYouLinkText: 'View thank you page here',
    campaignJSONLinkText: 'View campaign JSON here',
    ACNextStepsLinkText: 'View AC guide here',
    ATNextStepsLinkText: 'View AT guide here',
    instantWinPageLinkText: 'View instant win page here',
    instantLosePageLinkText: 'View instant lose page here'
  },
  MASTER_GUIDE_LINES: {
    title: 'Guidelines for master data',
    subTitle:
      'Please review and provision the request. Use the guidelines below.',
    guideLine: [
      {
        text:
          'PII information should not be requested as questions and answers. ',
        isKnowMore: true,
        toolTipText:
          'First name, last name, email address, date of birth, gender,  mobile number, house number, address, city, postal code, state, country,  honorific prefix, honorific suffix are all standard fields and do not require and master data to enable data collection.',
        knowMoreText: 'Know more'
      },
      {
        text:
          'Any sensitive data should only be collected for valid reasons approved by Data Privacy/ legal. ',
        isKnowMore: true,
        toolTipText:
          'The special category of personal information of data subjects that is sensitive, the exposure of which could significantly impact the rights and freedoms of data subjects & potentially be used against them for unlawful discrimination. It includes genetics, ethnic origin, biometrics, sexual orientation, sex life, politics, health, pregnancy, and trade union membership.',
        knowMoreText: 'What’s sensitive data?'
      },
      {
        text:
          'Questions and answers should be built using the master Q&A list. In case a new question and/or answer is requested, please ensure it is a valid request, not a duplicate or a rephrased version of an already available master Q&A. You can check the ',
        isKnowMore: true,
        knowMoreText: 'existing master list of Q&As here',
        isModal: true
      },
      {
        text:
          'Ensure that any rejections are accompanied by comments that explain the corrections that need to be made.'
      }
    ]
  },
  WEBSITETYPE_SAAS: 'Adobe SaaS',
  ADDITIONAL_SUBSCRIPTION: 'additionalSubscription',
  SIGNUP_WITH_QA: 'Sign-up with Questions & Answers',
  QNA_GUIDE_LINES: {
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle:
      'Please review the guidelines before selecting or requesting the questions.',
    guideLine: [
      {
        text:
          'An approved PRA reference should be available and should match the context of this campaign. '
      },
      {
        text:
          'PII information should not be requested as questions and answers.'
      },
      {
        text:
          'Any sensitive data should only be collected for valid reasons approved by Data Privacy/ legal.'
      },
      {
        text:
          'Questions and answers should be built using the master Q&A list. In case a new question and/or answer is requested, please ensure it is a valid request, not a duplicate or a rephrased version of an already available master Q&A.'
      },
      {
        text:
          'Any additional market level considerations should also inform your decision to approve or reject.'
      },
      {
        text:
          'When you request a new question, please specify if it is for data enrichment or operational purposes.'
      },
      {
        text:
          'Please expect a higher turnaround time of upto 30 days for your Campaign Wizard request to be fulfilled if you request a new data enrichment question. The extended timelines are due to stricter data governance processes that Consumer data Bureau follow to review all new master data requests and to prevent new IDs for Q&As that may already be active. We strongly recommend that you review the existing curated master list to find Q&As that may fit your enrichment goals.'
      }
    ]
  },
  QNA_DEFINITIONS: {
    title: 'Definitions',
    titleClass: 'cw-heading--saascampaign mt-20',
    guideLine: [
      {
        text:
          'PII: First name, last name, email address, date of birth, gender,  mobile number, house number, address, postal code, city, state, country,  honorific prefix, honorific suffix are all standard fields and do not require and master data to enable data collection.'
      },
      {
        text:
          'Sensitive data: The special category of personal information of data subjects that is sensitive, the exposure of which could significantly impact the rights and freedoms of data subjects & potentially be used against them for unlawful discrimination. It includes genetics, ethnic origin, biometrics, sexual orientation, sex life, politics, health, pregnancy, and trade union membership.'
      },
      {
        text:
          'Enrichment questions: Any data that helps Unilever improve its understanding of a consumer to enrich or segment audience such as consumer interests, needs, preferences and behavior as well as use of the product, product benefits, attributes. For e.g. What is your favorite type of cuisine? What are your hobbies and interests?'
      },
      {
        text:
          'Operational questions: Consumer information required to help ensure the campaign works or to fulfill consumer requests, such as receipts, purchase date, SKU of the purchased product.'
      }
    ]
  },
  CONSENT_GUIDELINES: {
    title: 'Changes to consent statements',
    titleClass: 'cw-heading--saascampaign mt-20',
    guideLine: [
      {
        text:
          'Campaign Wizard has disabled any manual updates or addition of consent texts to ensure better governance and adherence to DPO approved consent statements in central records.'
      },
      {
        text: `Please raise a <a href='https://unilever.service-now.com/cex?id=sc_cat_item&sys_id=0394a8b71b28555021f3b8c8dc4bcbb9' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a>, should you require the consent text to be added, corrected or updated.`,
        replaceText: true
      },
      {
        text:
          'Once the consent statement is changed or added in central records after approval by DPOs, it will reflect in Campaign Wizard with 1 day and will be available for all your future campaigns setup.'
      }
    ]
  },
  CIA_ENABLED_MARKETS: ['GB'],
  CIA_FIELD_INFO_MESSAGE: [
    'Content Interest Areas will render on the sign up form as a question to consumers to understand the kind of content they are interested in. Consumers will be presented with an optional question and can choose multiple interest areas. The labels of the field and values will remain fixed as of now but will display in local language of the form'
  ],
  UGC_TEXT_INFO_MESSAGE: [
    'Use this field to collect entries from consumers, including user generated content like recipes, their stories, etc.'
  ],
  KANA_URL:
    'https://unilever.service-now.com/cex?id=csm_get_help&sys_id=fb1e37031ba13890d26d8732f54bcb98',

  SAAS_V3_ENDPOINT_TOOLTIP_TEXT:
    'This is the URL to the new consumer data collection V3 API and is provided as supplementary information, should you need it for any custom integration. The form widget you have received from Campaign Wizard is fully-functional and integrated with the V3 API and there is no next step for you with regards to this URL.',
  NONSAAS_V3_ENDPOINT_TOOLTIP_TEXT:
    'This is the URL to the new consumer data collection V3 API where the consumer data is posted.',
  MASTER_DATA_SAAS_V3_ENDPOINT_TOOLTIP_TEXT:
    'This is the URL to the new consumer data collection V3 API and is provided as supplementary information, should you need it for any custom integration.',

  SAAS_V3_API_KEY_TOOLTIP_TEXT:
    'Your API key is now activated to send consumer data using the new V3 API. Your form widget is already integrated with latest data collection APIs and is pre-configured to use this key.<br/> Please note that the key will be deactivated if there are no sign ups for your brand x market in the next 60 days. If this happens, you will have to raise a Kana ticket again, as per instructions in the form widget integration guide.',
  NONSAAS_V3_API_KEY_TOOLTIP_TEXT:
    'Your API key is now activated to send consumer data using the new V3 API and needs to be passed in the request headers.<br/> Please note that the key will be deactivated if there are no sign ups for your brand x market in the next 60 days. If this happens, you will have to raise a Kana ticket again, as per instructions in the V3 API documentation.',
  MASTER_DATA_SAAS_V3_API_KEY_TOOLTIP_TEXT:
    'Your API key is now activated to send consumer data using the new V3 API.<br/> Please note that the key will be deactivated if there are no sign ups for your brand x market in the next 60 days. If this happens, you will have to raise a Kana ticket again, as per instructions in the form widget integration guide.',

  SAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT:
    'The website URL(s) you provided are now whitelisted and enabled to send consumer data to Unilever’s profile stores.<br/> Your API keys are also activated and will remain so unless no sign up happens from your brand and market in the next 60 days. If the key gets deactivated, raise a Kana ticket as per instructions in the form widget integration guide.',
  NONSAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT:
    'The website URL(s) you provided are now whitelisted and enabled to send consumer data to Unilever’s profile stores.<br/> Your API keys are also activated and will remain so unless no sign up happens from your brand and market in the next 60 days. If the key gets deactivated, raise a Kana ticket as per instructions in the V3 API documentation. ',

  DISABLED_INFRA_STATUS_TOOLTIP_TEXT:
    'We have not been able to activate your API keys and/or whitelist your website(s) to send consumer data to profile stores. We request you to raise a KANA ticket via the link provided and it will self-resolve in a few seconds to enable data collection for your brand.<br/> Please note that this is an additional security measure to allow identified websites to send consumer data to Unilever’s profile stores.',

  WEBSITE_URL_TOOLTIP_TEXT:
    'Please provide the URLs of the website on which you intend to embed your sign-up form.  We now collect website URLs to whitelist (allow) them to send consumer data to Unilever’s profile stores. This eliminates the need for you to raise a separate KANA ticket.<br/> Please note that this is an additional security measure to allow identified websites to send consumer data to the Unilever marketing cloud and is required once per brand-market-domain/web URL combination.',
  BRAND_DETAILS_TOOLTIP_TEXT:
    'This is a security measure that whitelists your website to send data to Unilever’s marketing cloud. We are working towards eliminating the Kana step and automate it as part of the sign-up form request in Campaign Wizard.',
  BUSINESS_FILTERS: [
    'Beauty & Wellbeing',
    'General',
    'Home Care',
    'Ice Cream',
    'Nutrition',
    'Operational',
    'Personal Care'
  ],

  BUSINESS_FILTERS_OVERRIDES: [
    {
      key: 'HC',
      displayName: 'Home Care'
    }
  ],
  COUNTRY_US: 'United State of America',
  PII_FIELDS_DESCRIPTION:
    'PII fields include first name, last name, email, phone number, address fields, name prefix, name suffix, date of birth and gender. These are standard fields that do not need master data IDs.',

  QNA_MASTER_DATA_TILE_MSG:
    'You have requested new master data to be created. Please expect a higher turnaround time of upto 30 days for your request to be reviewed and fulfilled.',
  QNA_MASTER_DATA_TILE_SUB_MSG:
    'The extended timelines are due to stricter data governance processes that Consumer data Bureau follow to review all new master data requests and to prevent new IDs for Q&As that may already be active. We strongly recommend that you review the existing curated master list to find Q&As that may fit your enrichment goals.',
  FREE_TEXT_WARNING:
    'We do not recommend you ask free text questions for data enrichment purposes as these are not easily processable to generate useful insight.  Please limit the usage of free text to operational questions, for e.g. judging competition entries.',
  FREE_TEXT_AREA_WARNING:
    'We do not recommend you ask free text questions for data enrichment as these are not easily processable to generate useful insight. Please limit the usage of free text areas to operational questions for incentive campaigns. For e.g., collecting recipes and stories to judge competition entries.',
  FREE_TEXT_AREA_BLUE_WARNING: [
    'Free text areas let consumers add multiple lines of text as compared to single line text accepted by a free text field.'
  ],
  FORMAT_DATE_YYYY_MM_DD: 'YYYY-MM-DD',
  FORMAT_DATE_DD_MM_YY: 'DD/MM/YY',
  NEW_QNA_DUPLICATE_TEXT_TOOLTIP:
    'Duplicate question or answer request includes any request for new questions and answers which are rephrased, reworded versions of Q&As that already exist in the master list. <br/> Please ensure that you always try to select Q&As in the master list if they imply the same meaning as the consumer data you want to collect. In any case, you can reword the consumer facing labels to reflect the brand tone of voice.',
  QUALIFYING_TOGGLE_TOOLTIP:
    'A question marked as qualifying can be used to judge your entries for a competition or a sample. You can mark upto 5 questions as qualifying, after which the toggle will get disabled. Note that qualifying questions are also known as entry questions.',
  FREE_TEXT_ENRICH_WARNING:
    'We do not recommend you ask free text questions for data enrichment purposes as these are not easily processable to generate useful insight. To avoid your request from being rejected, please mark your question as operational, if applicable or configure this as a single or multi-choice question.',
  campaignRequestTypes: {
    DIRECT_MESSAGING: 'Direct messaging',
    SIMPLE_SIGNUP: 'Simple sign up',
    MASTER_DATA_ONLY: 'masterdata',
    MASTER_DATA_REQUEST: 'Master data request',
    INCENTIVE: 'Incentive',
    COMPITITIONS: 'Competitions',
    INCENTIVE_COMPITITIONS: 'Incentive competitions',
    PRODUCT_SAMPLE: 'Product samples',
    INCENTIVE_SAMPLE: 'Incentive samples'
  },
  CAMPAIGN_TIMELINE_STEPS: [
    {
      id: 'request_submitted',
      stepName: 'Request submitted',
      status: ['1', '2', '4']
    },
    {
      id: 'awaiting_market_approval',
      stepName: 'Awaiting market approval',
      status: ['6']
    },
    {
      id: 'awaiting_master_data',
      stepName: 'Awaiting master data',
      status: ['7']
    },
    {
      id: 'awaiting_promotigo_form_id',
      stepName: 'Awaiting Promotigo form ID',
      status: ['12']
    },
    {
      id: 'completed',
      stepName: 'Completed',
      altStepName: 'Published',
      status: ['3']
    }
  ],
  CAMPAIGN_HISTORY_PROGRESS_STEP_MAP: {
    request_submitted: 'Campaign submitted',
    awaiting_market_approval: 'Campaign approved',
    awaiting_master_data: 'Master data IDs assigned',
    awaiting_promotigo_form_id: 'Promotigo information collected',
    completed: 'Campaign completed'
  },
  BRAND_DETAIL_SECTION_1: {
    title: 'What are form widgets',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: null,
    guideLine: [
      {
        text:
          'Campaign Wizard now lets you create form widgets, which in simple terms are plug and play sign up forms that you can embed on any website on any platform (even if they are not on Adobe Experience Manager).'
      },
      {
        text:
          'Using our easy to use interface, you can now create these forms within a span of minutes. You will be able to specify your form fields, field properties, consent statements to get the form you absolutely need.'
      },
      {
        text:
          'All the input that you give will be converted into a ready to use, fully functional form widget that you can preview in Campaign Wizard even before you submit your request, so that you are absolutely sure of your form output.'
      },
      {
        text:
          'These forms will be fully configured with all master data IDs and will have built in integrations to send data to Unilever’s consumer profile stores, so all you have to worry about is embedding them on your sites.'
      },
      {
        text:
          'Campaign Wizard will also automatically do your security settings, i.e. activate API keys and whitelist your website URLs so that you are all set to collect consumer data.'
      }
    ]
  },
  BRAND_DETAIL_SECTION_2: {
    title: 'Who can create these',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: null,
    guideLine: [
      {
        text:
          'Anyone can create these forms through our guided easy to use journeys as all the technological nuances are abstracted. You do not need a webmaster to create these forms.'
      },
      {
        text:
          'Any website, any platform can now get these fully functional forms and do not have to worry about custom integrations anymore.'
      }
    ]
  },
  BRAND_DETAIL_SECTION_3: {
    title: 'How to create these forms',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: null,
    guideLine: [
      {
        text: 'When you create you form, choose the form type as ‘form widget’.'
      },
      {
        text:
          'Specify all input we ask of you to construct your form; choose your form fields, use our default field labels or specify custom, mark fields mandatory or otherwise, select consent statements and sort order.'
      },
      {
        text: 'Preview your form and submit your request.'
      },
      {
        text: 'We will process your request to seek your market’s approval.'
      },
      {
        text:
          'If we detect that your campaign request requires new master data to be setup, our automations will assign that to the correct teams.'
      },
      {
        text:
          'Once the setup is complete, we will notify you using our inbuilt email automations.'
      },
      {
        text:
          'At this stage, you will be all set to use the form we provide you. All you need to do is to style it and plug into your website, using a simple integration guide that you will see on your campaign page.'
      }
    ]
  },
  EMAIL_REVIEW_ERROR_MESSAGE:
    'Please specify missing details in sections highlighted below',
  emailType: {
    welcomeEmail: 'Welcome email',
    newsletter: 'Newsletter',
    coreinfo: 'Core information',
    birthdayEmail: 'Birthday email',
    otherMarketingEmails: 'Others'
  },
  emailTypeFilter: [
    {
      label: 'Welcome email',
      value: 'welcomeEmail'
    },
    {
      label: 'Newsletter',
      value: 'newsletter'
    },
    {
      label: 'Core information',
      value: 'coreinfo'
    },
    {
      label: 'Other marketing emails',
      value: 'otherMarketingEmails'
    }
  ],
  EMAIL_TYPE_OPTIONS: [
    {
      id: 'welcome',
      label: 'Welcome email',
      labelClass: 'select-email-label',
      value: 'welcomeEmail',
      description:
        'Acknowledge consumers for their interest in your brand and set the tone for future communications',
      imageIcon: welcomeEmailImg
    },
    {
      id: 'newsletter',
      label: 'Newsletter',
      labelClass: 'select-email-label',
      value: 'newsletter',
      imageIcon: newsLetterEmailImg,
      description:
        'Improve advocacy by sharing curated information about your brand, purpose and products'
    },
    {
      id: 'coreinfo',
      label: 'Core information',
      labelClass: 'select-email-label',
      value: 'coreinfo',
      imageIcon: coreInfoEmailImg,
      description:
        'Inform consumers about key policy changes, privacy updates and others'
    },
    {
      id: 'birthdayEmail',
      label: 'Birthday email',
      labelClass: 'select-email-label',
      value: 'birthdayEmail',
      imageIcon: birthdayEmailImg,
      description:
        'Strengthen relationship with your consumers and boost engagement'
    },
    {
      id: 'others',
      label: 'Others',
      labelClass: 'select-email-label',
      value: 'otherMarketingEmails',
      imageIcon: otherEmailImg,
      description:
        'Any other consumer emails that do not fit in the above categories'
    }
  ],

  WELCOME_LIST: {
    options: [ON_SIGNUP_OPTION, OTHER_OPTION],
    defaultOption: ON_SIGNUP_OPTION
  },
  NEWSLETTER_LIST: {
    options: [ON_SIGNUP_OPTION, OTHER_OPTION],
    defaultOption: ON_SIGNUP_OPTION
  },
  COREINFO_LIST: {
    options: [ON_SIGNUP_OPTION, OTHER_OPTION],
    defaultOption: ON_SIGNUP_OPTION
  },
  BIRTHDAY_LIST: {
    options: [
      ON_BIRTHDAY_OPTION,
      SEVEN_DAYS_BEFORE_BIRTHDAY_OPTION,
      FIXED_DATE_IN_BIRTHDAY_MONTH_OPTION,
      OTHER_OPTION
    ],
    defaultOption: SEVEN_DAYS_BEFORE_BIRTHDAY_OPTION
  },
  OTHER_LIST: {
    options: [ON_SIGNUP_OPTION, OTHER_OPTION],
    defaultOption: ON_SIGNUP_OPTION
  },

  // TODO: depricate emailStatus and start using EMAIL_STATUS
  emailStatus: {
    1: 'Draft',
    2: 'Submitted',
    3: 'Awaiting approval',
    4: 'Awaiting setup',
    5: 'Rejected',
    6: 'Scheduled',
    7: 'Live',
    8: 'Awaiting info',
    9: 'Awaiting cost estimate',
    10: 'Awaiting purchase order'
  },
  EMAIL_STATUS: {
    DRAFT: '1',
    SUBMITTED: '2',
    AWAITING_FOR_APPROVAL: '3',
    AWAITING_SETUP: '4',
    AWAITING_INFO: '8',
    REJECTED: '5',
    SCHEDULED: '6',
    LIVE: '7'
  },
  ACTION_TYPES: {
    SAVE_AS_TEMPLATE: 'saveAsTemplate',
    NEXT: 'next',
    BACK: 'back',
    CHANGE_TEMPLATE: 'changeTemplate',
    HTML_CHANGES_DESCRIPTION_SUBMIT: 'submittedFromAcsModal',
    SEND_EMAIL: 'sendEmail',
    DOWNLOAD_TEMPLATE_IMAGE: 'downloadTemplateImg',
    TRIGGER_VALIDATION: 'triggerValidation'
  },
  EMAIL_APPROVAL_PAGE_ACCESS_ROLES: ['admin', 'approver'],
  EMAIL_EXECUTION_PAGE_ACCESS_ROLES: ['acs', 'admin'],
  EMAIL_STATIC_COUPON_ACCESS_ROLES: ['acs', 'approver', 'admin'],
  EMAIL_DYNAMIC_COUPON_UPLOAD_ROLES: ['acs', 'admin'],
  SMS_DYNAMIC_COUPON_UPLOAD_ROLES: ['acs', 'admin'],
  SMS_PURCHASE_ORDER_ACCESS_ROLES: ['acs', 'admin'],
  SMS_RESUBMIT_ROLES: ['admin'],
  EMAIL_PRIVILEGED_ACCESS: [
    'binay.kumar@publicissapient.com',
    'harshank@langoor.com',
    'deeksha.puri@publicissapient.com',
    'nikhaar.suri@publicissapient.com',
    'anurag.sood@unilever.com',
    'rubby.singh@unilever.com',
    'charlotte.green@unilever.com',
    'nikhaar.suri@unilever.com'
  ],
  WA_PRIVILEGED_ACCESS: [
    'binay.kumar@publicissapient.com',
    'harshank@langoor.com',
    'nikhaar.suri@publicissapient.com',
    'anurag.sood@unilever.com',
    'rubby.singh@unilever.com',
    'charlotte.green@unilever.com',
    'nikhaar.suri@unilever.com',
    'deeksha.puri@unilever.com',
    'binay.kumar2@unilever.com',
    'nikhaar.suri@unilever.com',
    'harshank.kahar2@unilever.com',
    'ambaliya.jevinmanubhai@publicissapient.com'
  ],
  PREVIOUS_MASTER_KEYS: [
    'previousMasterId',
    'previousMasterValue',
    'previousMasterTranslatedId'
  ],
  INCENTIVE_VIDEO_OPTION_KEY: 'Video (3gp, 3g2, mp4, flv, mkv, avi, mov)',
  INCENTIVE_VIDEO_OPTION_VALUE: '3gp, 3g2, mp4, flv, mkv, avi, mov',
  INCENTIVE_UPLOAD_MAX_FILE_SIZE: '25000',
  FEEDBACK_SUBMITTED_LAST_DAYS: 45,
  CONSENTS_KEY_LABEL_MAP: {
    legalAgeConsent: 'Age Consent',
    privacyNotice: 'Privacy Policy',
    brandEmailConsent: 'Brand Email',
    brandSMSConsent: 'Brand SMS',
    unileverEmailConsent: 'Unilever Email',
    unileverSMSConsent: 'Unilever SMS',
    contentInterestAreas: 'Content Interest Areas',
    additionalSubscription: 'Additional Opt-ins 1',
    additionalSubscription1: 'Additional Opt-ins 2',
    termsAndConditions: 'Terms and Conditions',
    legalDisclaimer: 'Disclaimer',
    unileverEmailOnlyConsent: 'Unilever Email Only',
    unileverSMSOnlyConsent: 'Unilever SMS Only',
    unileverOnlineOnlyConsent: 'Unilever Online Advertising',
    combinedUnileverAndBrandEmailConsent: 'Combined Unilever and brand consent',
    unileverWhatsAppConsent: 'Unilever WhatsApp',
    brandWhatsAppConsent: 'Brand WhatsApp',
    unileverWhatsAppOnlyConsent: 'Unilever WhatsApp Only Consent',
    multiChannelUnileverConsent: 'Multi Channel Unilever Consent'
  },
  QNA_FAQ_LINK:
    'https://unilever.sharepoint.com/:w:/r/sites/DMCHub/Shared%20Documents/01.%20Playbooks,%20Guides%20%26%20Demos/06.%20121%20Marketing/QA%20Pairs/QA%20launch%20FAQs.docx?d=w60fea30921a849acb06f3437679b5555&csf=1&web=1&e=Y9vIlt',
  EMAIL_BRIEFING_ACCESS_DENIED_MESG:
    'Sorry, you do not have access to this page.',
  BEE_EDITOR_SETUP: {
    mergeTags: [
      {
        name: 'First Name',
        value:
          '<span class="email-cw-hidden"><%= context.profile.firstName %></span>',
        previewValue: 'John'
      },
      {
        name: 'Last Name',
        value:
          '<span class="email-cw-hidden"><%= context.profile.lastName %></span>',
        previewValue: 'Travolta'
      },
      {
        name: 'Title',
        value:
          '<span class="email-cw-hidden"><%= context.profile.salutation %></span>',
        previewValue: 'Title'
      },
      {
        name: 'Brand Of Origin Name',
        value:
          '<span class="email-cw-hidden"><%= context.profile.cusBrandOfOriginName %></span>',
        previewValue: 'Brand Of Origin Name'
      },
      {
        name: 'Brand’s sender email address',
        value: `<span class="email-cw-hidden"><%@ value object='branding' xpath='mailParameters/senderAddress' %></span>`,
        previewValue: 'brand@branddomain.com'
      },
      {
        name: 'Dynamic coupon code',
        value:
          '<span class="email-cw-hidden"><%= context.targetData.couponCode %></span>',
        previewValue: 'Dynamic coupon code'
      }
    ],
    specialLinks: [
      {
        type: 'Personalized links',
        label: 'Unsubscribe',
        // prettier-ignore
        link: "<%@ include fragment='uniUnsubscribeRedirectURL' %>"
      },
      {
        type: 'View in Browser',
        label: 'Click here',
        // prettier-ignore
        link: "<%@ include fragment='MirrorPageUrl' %>"
      }
    ]
  },
  EMAIL_PROGRESS_BAR_TOT_PAGE: 10,
  EMAIL_JOURNEY_PAGE_NUMBERS: {
    NEW_CAMPAIGN: 1,
    EMAIL_TYPE: 2,
    CAMPAIGN_SUMMARY: 3,
    // COLLABORATORS: 4,
    CAMPAIN_ATTRIBUTES: 4,
    SCHEDULE: 5,
    AUDIENCE: 6,
    EMAIL_PROPERTIES: 7,
    DESIGN_YOUR_EMAIL: 8,
    REVIEW: 10
  },
  EMAIL_SAVE_ROW_CATEGORIES: [
    {
      label: 'Headers',
      value: 'headers'
    },
    {
      label: 'Footers',
      value: 'footers'
    },
    {
      label: 'Marketing blocks',
      value: 'marketingBlocks'
    },
    {
      label: 'Galleries',
      value: 'galleries'
    },
    {
      label: 'Coupons',
      value: 'coupons'
    }
  ],
  EMAIL_TEMPLATE_CONTEXT: [
    {
      id: 'generic',
      label: 'Generic',
      value: 'generic'
    },
    {
      id: 'brandSpecific',
      label: 'Brand specific',
      value: 'brandSpecific'
    },
    {
      id: 'crossBrand',
      label: 'Cross brand emails',
      value: 'crossBrand'
    }
  ],
  EMAIL_ATTRIBUTES: [
    {
      id: 'staticCoupon',
      label: 'Includes static coupons',
      value: 'staticCoupon'
    },
    {
      id: 'dynamicCoupon',
      label: 'Includes dynamic coupons',
      value: 'dynamicCoupon'
    }
  ],
  EMAIL_SAVE_ROW_CATEGORIES_BRAND_EXCLUDE: [
    'marketingBlocks',
    'galleries',
    'coupons'
  ],
  EMAIL_CAMPAIGN_TIMELINE_STEPS: function() {
    return [
      {
        id: 'submitted',
        stepName: 'Submitted',
        status: [
          this.EMAIL_STATUS.DRAFT,
          this.EMAIL_STATUS.SUBMITTED,
          this.EMAIL_STATUS.REJECTED
        ]
      },
      {
        id: 'awaitingMarketApproval',
        stepName: 'Assigned for market approval',
        stepDescription: 'Market approvers will review your campaign',
        status: [this.EMAIL_STATUS.AWAITING_FOR_APPROVAL]
      },
      {
        id: 'awaitingSetup',
        stepName: 'Assigned for setup',
        stepDescription:
          'ACS team will review and test your email campaign for deliverability, rendering and accessibility',
        status: [this.EMAIL_STATUS.AWAITING_SETUP]
      },
      {
        id: 'scheduled',
        stepName: 'Scheduled',
        stepDescription:
          'ACS team will setup your email in ACS and schedule it to start on the desired date',
        status: [this.EMAIL_STATUS.SCHEDULED]
      },
      {
        id: 'live',
        stepName: 'Live',
        stepDescription:
          'ACS team will mark your campaign live once email deliveries start',
        status: [this.EMAIL_STATUS.LIVE]
      }
    ];
  },
  EMAIL_AMPAIGN_HISTORY_PROGRESS_STEP_MAP: {
    submitted: 'Campaign submitted',
    awaitingMarketApproval: 'Approved by market',
    awaitingSetup: 'Campaign scheduled',
    scheduled: 'Campaign scheduled',
    live: 'Campaign live'
  },
  EMAIL_APPROVER_GUIDE_LINES: {
    title: 'Guidelines for approval',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: 'Please review the email campaign using the guidelines below.',
    guideLine: [
      {
        text:
          'Ensure this is a valid and known request from a brand within your market.'
      },
      {
        text:
          'Check that the campaign objective is clearly defined and aligns to the content of the email.'
      },
      {
        text:
          'Review the email for unwanted content, such as spammy language, misleading claims, offensive imagery, or inappropriate links.'
      },
      {
        text:
          'Review that email has unsubscribe, privacy policy and cookie policy links in the footer.'
      },
      {
        text:
          'Any additional market level considerations should also inform your decision to approve or reject.'
      },
      {
        text:
          'Supplement any rejections with clear comments that explain the corrections that need to be made.'
      }
    ]
  },
  EMAIL_SECTIONS: {
    tilte: 'Email sections',
    subTitle: 'Please ensure the following sections exist in your emails',
    guideLine: [
      {
        heading: 'Subject lines',
        text:
          'Create subject lines that capture consumer attention, invoke interest and curiosity. Highlight the most important message of your email in max 60 characters, personalize with recipient first name wherever possible and A/B test to learn and optimize. To ensure your subject lines do not cause your email to be flagged as  spam, avoid use of too many punctuations, exclamations, question marks, all capitalized letters and extreme promotional language'
      },
      {
        heading: 'Preheaders',
        text:
          'Preheaders are short snippets of muted text that appear below or next to the email subject line and add context, specially on mobile devices where subject lines tend to be shorter. Create short and action oriented preheaders that complement but do not repeat the subject line. In the absence of preheaders, recipients may end up viewing email text as preheaders, which in usual cases could be something like "View this email in browser"'
      },
      {
        heading: 'Header',
        text:
          'Always include a header with a legal disclaimer indicating the use of cookies. Include a mirror link to enable recipients to view email in browser in case email fails to load. The header should also include your brand logo. In case you are sending a cross or a multi-brand email to leverage enterprise consented consumer data, the branding should be of Unilever. Please refer to our footer tiles as reference'
      },
      {
        heading: 'Footer',
        text: `Include a branded footer with links to your social media handles, Unilever logo and local address. Ensure your footer has links to Unilever’s privacy policy, cookie policy and an Unsubscribe link that lets subscribed users opt out of receiving marketing communications from Unilever brands. Please refer to our footer tiles as reference. The footer should include a note to remind recipients to add the brand sender email address (E.g. “Add 'brand sender email address' to your address book”) and a disclaimer to indicate which brand the consumer signed up to. For e.g. “You are receiving this email because you have consented to receive offers and information from Unilever brands through Knorr”`
      },
      {
        heading: 'Note',
        text:
          'Do not manually enter unsubscribe link, mirror link, and personalized fields like first name, brand of origin, brand sender email address and instead use our special links and personalized fields available to be selected through the inbuilt text toolbar. Please refer to our content checklist to learn how to configure personalized fields and special links correctly'
      }
    ]
  },
  EMAIL_CONTENT_CHECKLIST: {
    guideLine: [
      {
        heading: 'Images',
        text:
          "Images and GIFs can be configured within the 'image' content block. Please use public images and GIFs from Unilever's asset bank TAB only. Refer to the image guidelines section. Add a descriptive alt text of up to 90 words to all images and icons. In case of background images, provide a fallback background color. This ensures that email renders properly in case a background image fails to load in an inbox."
      },
      {
        heading: 'Links and buttons',
        text:
          'Ensure all URLs for text links, buttons, image links are added and open valid web pages. Please use <strong>special links feature</strong> to create <strong>mirror links</strong> (view email in browser links) and <strong>unsubscribe links</strong> personalized to each recipient. To create a special link, enter the link text within the editor canvas and select the text. You will then see an inline text toolbars. Click on “special links” toolbar option to open a list of available special links and choose the one that is applicable. This will automatically convert you selected text into a dynamic special link. In certain cases, where lets say, you want to make an image a special link, the menu may be available in the right properties panel. You can alternatively use one of our saved header or footer tiles that come pre-configured with special links',
        richText: true
      },
      {
        heading: 'Personalized fields',
        text:
          'Personalized fields include fields like first name, brand of origin, brand of origin email, etc. that are dynamic and personalized to each email recipient. To configure personalized fields like first name within your email, click on the content block where you would like to include such a field. You will then see an inline text toolbar. Click on “personalized fields” menu item within the toolbar and pick a personalized field. In the test emails that you send from Campaign Wizard, the field may or may not be replaced by a default value (e.g. First name gets replaced by John) but when the actual email is sent out to recipients from ACS, the fields will be replaced with actual consumer specific values from their profile information'
      }
    ]
  },
  EMAIL_TESTING_CHECKLIST: {
    guideLine: [
      {
        heading: 'Preview',
        text: 'Test your email previews in desktop and mobile viewports'
      },
      {
        heading: 'Send test',
        text: 'Send an email as test to yourself and your collaborators'
      },
      {
        heading: 'Accessibility',
        text: 'Ensure email is accessibility compliant. See Unilever’s ',
        externalLink:
          'https://accessibilityhub.unilever.com/standards-and-guidelines/email',
        externalLinkText: 'email accessibility guidelines'
      },
      {
        heading: 'URLs',
        text: 'Ensure all URLs are added for buttons, text and image links'
      },
      {
        text:
          'Please note that after you submit your campaign request, ACS team will run more detailed tests using Email on Acid to checking rendering across email clients and accessibility compliance'
      }
    ]
  },
  EMAIL_DESIGN_CHECKLIST: {
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: 'Please follow this checklist to meet email design standards.',
    guideLine: [
      {
        text:
          'Always start designs with defining overall email design properties in the settings tab of the editor right panel, including content area width, fonts, background colors, etc. Specify a title for your email and ensure the language is pre-populated.'
      },
      {
        text:
          'After settings, drag and drop tiles into the design canvas to simplify and fasten the email design process. Tiles are ready to use structures or mini templates that can be replaced with content relevant to your brand and objectives.'
      },
      {
        text:
          'Add a header with legal text. Please use our header tiles as references. Please include a link to view email in browser within the header. Please create this link using special links only.'
      },
      {
        text:
          'Please configure images using public URLs from TAB, Unilever’s asset bank only. Add a descriptive alt text of up to 90 words to all images and icons.'
      },
      {
        text:
          'Ensure all URLs for text links, buttons, image links are added and open valid web pages. Specify a label attribute value for all links within the email. Select the content block with the link and find the attributes section in the content properties within the right panel.'
      },
      {
        text:
          'Provide a fallback background color when using background images so that email looks fine even if a background image fails to load in an inbox.'
      },
      {
        text:
          'Add footers with social account links, address, privacy policy, cookie notice and unsubscribe links.  Use our footer tiles as references. Please use special links only to create unsubscribe links. Special links are select and drop links that can be added from inline text toolbars that appear within the editor canvas.'
      },
      {
        text: 'Test your email previews in desktop and mobile viewports.'
      }
    ]
  },
  EMAIL_ACS_GUIDE_LINES: {
    title: 'Guidelines for email setup',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle:
      'Please setup the email campaign in ACS using the guidelines below.',
    guideLine: [
      {
        heading: 'Changes to email HTML',
        text:
          'As an ACS team member, you have the permissions to edit and correct the email HTML within Campaign Wizard, should such a need arise basis various test conditions below and otherwise. Please ensure all HTML changes are done within Campaign Wizard before generating the final export. Do not make any localized changes to the HTML in ACS.'
      },
      {
        heading: 'Test the email using Email on Acid',
        text: `Run tests for deliverability, spam, responsiveness, rendering across email clients/devices and email accessibility. See Unilever's <a href='https://accessibilityhub.unilever.com/standards-and-guidelines/email' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> email accessibility guidelines<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a>. In addition, test the email using the test tools provided within CW.`,
        replaceText: true
      },
      {
        heading: 'Review legal content',
        text:
          'Review that email has cookie disclaimer in the header. Ensure that the email has mirror link and unsubscribe links created using dynamic special links. Ensure email footer also has dynamic brand sender email and brand of origin created using personalized fields. The email footer should also have  privacy and cookie policy links.'
      },
      {
        heading: 'Review email links',
        text:
          'Ensure all CTAs (buttons, text links, image links etc.) have valid target URLs and do not contain any ULID parameters that may sometimes appear when a requestor copies a URL from an email previously sent from ACS.'
      },
      {
        heading: 'Review Images/files',
        text:
          'Ensure all images/GIFs have TAB URLs and a valid alt text. If an image is used for a background, ensure an alternate fallback color is provided to retail visual appeal of the email in case background image fails to load.'
      },
      {
        heading: 'Cross brand email guidelines',
        text:
          'If the campaign is cross-brand, please ensure the email branding is that of Unilever and not of any specific brand. Brand specific content can appear between Unilever branded header and footer.'
      },
      {
        heading: 'A/B test guidelines',
        text:
          'If a campaign has an A/B test, ensure that A/B/C variants for email subject or content, as applicable, are created by requestor. Ensure there are no duplicate variants. Please update the winning variant in Campaign Wizard, once the winner is available.'
      },
      {
        heading: 'Coupon guidelines',
        text:
          'Review static coupon code is provided within the campaign specs and mentioned in the email. In case of dynamic coupons, ensure the dynamic coupon personalized field placeholder is used in email.'
      },
      {
        heading: 'Detailed guidelines',
        text:
          'A more detailed level of guidance is available for email content and design, A/B tests, cross brand emails and coupons via the header toolbar on email editor page. Please review and follow them on a regular basis.'
      },
      {
        heading: 'Getting in touch with the requestor',
        text: `To request corrections or more details from requestor, move the campaign to "request more information" state.`
      }
    ]
  },
  EMAIL_DASHBOARD_FILTER_MAP: {
    showMyCampaigns: 'My campaigns'
  },
  EMAIL_SEND_TEST_MAX_ATTEMPTS: 6,
  EMAIL_EDITOR_LANGUAGES_LIST: [
    'en-US',
    'es-ES',
    'fr-FR',
    'it-IT',
    'pt-BR',
    'id-ID',
    'ja-JP',
    'zh-CN',
    'zh-HK',
    'de-DE',
    'da-DK',
    'sv-SE',
    'pl-PL',
    'hu-HU',
    'ru-RU',
    'ko-KR',
    'nl-NL',
    'fi-FI',
    'cs-CZ',
    'ro-RO',
    'nb-NO',
    'sl-SI'
  ],
  EMAIL_RTL_LANGUAGES: [
    'ar',
    'arc',
    'arz',
    'ckb',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ps',
    'sd',
    'ur',
    'uz_AF',
    'yi'
  ],
  EMAIL_OVERLAY_BACKGROUND: 'rgba(157, 157, 157, 0.6)',
  EMAIL_CUSTOM_AUDIENCE_GUIDELINE: {
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle:
      'If your email audience requires segmentation based on questions and answers data (needs, preferences, concerns, etc.) or past email engagement criteria that is over 6 months old, then please follow the steps below to create your audience',
    subTitleClass: 'mb-15',
    guideLine: [
      {
        text:
          'Request your market’s or business group’s audience analysts or audience specialists to create the desired audience in CDSP (previously Insights).'
      },
      {
        text:
          'If you can’t find anyone matching this role, please reach out to your Measurement Specialist or Operations Manager to help you connect with the point of contact'
      },
      {
        text:
          'Once the specialists create an audience, a file containing audience data will be passed over from CDSP to Adobe Campaign Standard (email execution tool) through SFTP, automatically'
      },
      {
        text:
          'Please note the file name and specify it in the ‘audience file name’ field in Campaign Wizard'
      },
      {
        text:
          'When ACS team receives your campaign request, they will search for the file using the name you provide and use it to build your email audience'
      }
    ],
    notes: [
      {
        text:
          'Note: Question and answer data implies any consumer data collected as Q&A pairs identified by unique question and answer master data IDs',
        className: 'mb-10-important'
      }
    ]
  },
  EMAIL_COLLABORATORS_PERMISSION_TYPE: {
    everyone: 'Everyone',
    requestor: 'Requestor',
    requestorAndSelectedUsers: 'Requestor and selected users'
  },

  EMAIL_CAMPAIGN_APPROVAL_MESG: {
    marketApproved: {
      message:
        'Thank you, the campaign is now approved and assigned to execution team for setup',
      type: 'success'
    },
    acsScheduled: {
      message:
        'Thank you, the campaign is now scheduled. We have notified the requestor and will send a reminder notification to you on the scheduled go live date',
      type: 'success'
    },
    acsLive: {
      message:
        'Thank you, the campaign is now live. We have also notified the requestor.',
      type: 'success'
    }
  },
  /*NOTIFICATIONS: [
    {
      // message: `Campaign Wizard is now accessible only via Unilever SSO, to users with unilever.com email addresses. If you are or have an agency partner, who will need to use Campaign Wizard, please request a Unilever email address via <a href="https://unilever.service-now.com/esc?id=sc_cat_item&sys_id=af760322db76d010d21b055cd396198c" target="_blank" rel="noopener noreferrer">Una</a>`,
      message :`Campaign Wizard's email editor is temporarily unavailable. Our teams are trying to resolve this and restore functionality. We appreciate your patience and will update you when the editor is back online.`,
      enabled: true,
      startDate: '21/12/2023',
      endDate: '31/12/2024'
    }
  ],*/
  CAMPAIGN_TYPE_GUIDE_LINES: {
    guideLine: [
      {
        strongText: 'Online real time data collection',
        subText: [
          {
            strongText: 'Form widgets',
            text:
              ' - You can create a fully functional sign up form widget in Campaign Wizard in a span of minutes. We will ask you select your form fields through our intuitive interface and Campaign Wizard will do the magic to generate a ready to use form, fully configured and integrated with Unilever’s profile stores. All you will need to do is embed the form widget on your website with the help of a webmaster. Please choose ‘Form widget’ option to proceed with such a request'
          },
          {
            strongText: 'Custom forms',
            text:
              ' - If you want to build a custom form with custom requirements that may not be met by CW form widgets, you will require master data IDs and will need your tech teams to integrate your customized forms with Unilever’s platform V3 APIs that send consumer data to UL profile stores. In this case, you will need Campaign Wizard to provide you the master data IDs, links to API documentation and necessary market approvals. Please choose ‘Master data’ option to proceed with such a request'
          }
        ]
      },
      {
        strongText: 'Offline data ingestion',
        subText: [
          {
            text:
              'If you are a brand, who wants to ingest already collected consumer data offline into GIGYA from another system, you will require master data IDs as well as market approvals to request offline ingestion. Please choose ‘Master data’ option to proceed with such a request'
          }
        ]
      }
    ]
  },
  MASTER_DATA_GUIDE_LINES: {
    guideLine: [
      {
        text:
          'If you are a brand, who wants to ingest already collected consumer data offline into GIGYA from another system, or want to build customized forms and integrate with our platform APIs to ensure all data flows into GIGYA, you will need master data IDs'
      },
      {
        text:
          'Master data IDs are unique identifiers of attributes required for consumer data collection and include',
        subText: [
          {
            strongText: 'Brand code',
            text: ' - A unique code identifying your brand'
          },
          {
            strongText: 'Sign up ID',
            text:
              ' - An ID identifying your data collection initiative uniquely and lets you attribute your acquired consumers to a particular initiative. This was formerly known as campaign ID'
          },
          {
            strongText: 'Question and Answer IDs',
            text:
              '- A unique ID for each question and their answers that you may ask consumers to understand their needs and preferences. The ID remains unique for all brands and for all instances irrespective of the way the Q&As are worded on consumer facing interfaces'
          }
        ]
      }
    ]
  },
  EMAIL_SENDTEST_VALID_DOMAINS: ['unilever.com'],
  PRA_LEARN_MORE_LINK:
    'https://unilever.sharepoint.com/sites/PrivacyDataGovernanceShop/SitePages/PrivacyRiskAssessmentOverview.aspx',
  EMAIL_AB_TESTING_CONSTANTS: {
    subjectLine: 'Subject line',
    emailContent: 'Email content',
    sendTime: 'Send time',
    openRate: 'Open rate',
    clickThroughRate: 'Click through rate',
    '2-variants-50-50': '2 variants | 50:50 split',
    '2-variants-10-10-80': '2 variants | 10:10:80 split',
    '3-variants-5-5-5-85': '3 variants | 5:5:5:85 split'
  },
  EMAIL_SENDTEST_VALID_DOMAINS: ['unilever.com'],
  EMAIL_AB_TEST_DURATION_CHECK_LIST: [
    '2-variants-10-10-80',
    '3-variants-5-5-5-85'
  ],
  EMAIL_AB_SPLIT_VARIANT_MAP: {
    '2-variants-50-50': '2',
    '2-variants-10-10-80': '2',
    '3-variants-5-5-5-85': '3'
  },
  EMAIL_AB_TEST_PREVIEW_GUIDELINE: {
    guideLine: [
      {
        text:
          'The editor design canvas by default shows all content variants in one email'
      },
      {
        text:
          'To view all individual variants, click on preview button in the top toolbar and use the controls within the preview overlay to view all individual email variants'
      }
    ]
  },
  EMAIL_AB_TEST_RESULT_GUIDELINE: {
    guideLine: [
      {
        text:
          'Once you submit your campaign and it goes live, ACS team will update the winning variant in Campaign Wizard and will share detailed results via email'
      },
      {
        text:
          'If you have chosen a 10:10:80 or 5:5:5:85 split, ACS team will ensure that the remaining 80/85% audience receive the winning variant'
      }
    ]
  },
  EMAIL_STATIC_COUPON_GUIDELINES: {
    guideLine: [
      {
        text:
          'Brand managers are required to obtain the required number of coupon codes from retailers or coupon providers'
      },
      {
        text:
          'All email recipients will receive the same coupon code to avail the offer on your retailer website'
      },
      {
        text:
          'Enter the static coupon code in your email body and make it prominent'
      },
      {
        text:
          'Always mention the terms of the offer and other relevant information including expiry date, the channel of redemption and any other information that will let your recipients easily avail the offer'
      }
    ]
  },
  EMAIL_DYNAMIC_COUPON_GUIDELINES: {
    guideLine: [
      {
        text:
          'When coupons are dynamic, each recipient will receive a unique coupon code to avail offers on your preferred retailer website'
      },
      {
        text:
          'Brand managers are required to obtain the required number of coupon codes from retailers or coupon providers'
      },
      {
        text: `To determine the number of coupon codes that may be needed, raise this <a href='https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> Kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a>  to request an estimate of your audience size from ACS team. Within the Kana ticket, the subject  should be 'ACS Coupon | Request audience count' and the description should include the link to CW campaign (if already being crafted)`,
        replaceText: true
      },
      {
        text: `Once the coupon codes are available, ensure they are in the <a href='https://unilever.sharepoint.com/:x:/r/sites/UnileverDigitalHub/Shared%20Documents/05.%20121/ACS%20Execution%20Agencies/ACS%20Coupon%20Workflow/Dynamic%20Coupon%20Code%20Template.csv?d=w16033eccb6b542c69202c5f80dcddb8d&csf=1&web=1&e=wmIW06' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'>dynamic coupon code template<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a> and upload them via Campaign Wizard when we prompt you. Campaign Wizard will send these coupons directly to ACS team so when they receive your campaign, they will already have coupons ready`,
        replaceText: true
      },
      {
        text: `In case, coupons fail to upload via CW, we will prompt you to raise <a href='https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> Kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a> with the coupons file. The Kana subject should be 'ACS Coupon | Request for codes loading' and should include the link to your CW campaign`,
        replaceText: true
      },
      {
        text:
          'Within your email body being crafted in Campaign Wizard, please include a dynamic coupon placeholder. You can do this by opening the text toolbar within the email design editor to select personalized fields and then Dynamic coupon code field. You can alternatively use our preset dynamic coupon tiles. Please note that you will not see the actual coupon codes when you send test email via Campaign Wizard'
      },
      {
        text:
          'In your email, always mention the terms of the offer and other relevant information including expiry date, the channel of redemption and any other information that will let your recipients easily avail the offer'
      },
      {
        text:
          'Once this campaign request is submitted, ACS team will setup the workflow to map each recipient to a unique coupon code. The personalized dynamic coupon field in your email body will then be replaced by a coupon code unique to each of your recipients when they receive the email'
      },
      {
        text:
          'Please note that data on whether coupons were actually redeemed should be provided by the coupon provider or retailer'
      }
    ]
  },
  EMAIL_AB_TEST_TEST_OVERVIEW_GUIDELINE: {
    guideLine: [
      {
        heading: '2 variants | 50:50 split',
        text:
          'Your audience will be randomly split into two halves and will receive one of the two email variants. You will be able to apply your learnings to the next campaign'
      },
      {
        heading: '2 variants | 10:10:80 split',
        text:
          'Email variants will be sent to 2 randomly selected sample splits that are 10% each of your audience size. The test will run for a given test duration and the winning variant will be sent to the remaining 80% audience'
      },
      {
        heading: '3 variants | 5:5:5:85 split',
        text:
          'Email variants will be sent to 3 randomly selected sample splits that are 5% each of your audience size. The test will run for a given test duration and the winning variant will be sent to the remaining 85% audience'
      }
    ]
  },
  EMAIL_ABTEST_ERROR_MESSAGE:
    'You have turned ON an AB test on email content/subject line but have not configured all content/subject variations. Please go back to create variants',
  EMAIL_GUIDELINES_STATUS: {
    INITIALIZE: 'initialize',
    IN_PROGRESS: 'inprogress',
    HAS_ERRORS: 'hasErrors',
    SUCCESS: 'success'
  },
  EMAIL_ABTEST_VARIANT_MAP: {
    variantA: 'variant A',
    variantB: 'variant B',
    variantC: 'variant C'
  },
  EMAIL_ABTEST_DURATION_OPTIONS: [
    {
      label: '2 hours',
      value: '2-hrs'
    },
    {
      label: '3 hours',
      value: '3-hrs'
    },
    {
      label: '1 day',
      value: '1-day'
    }
  ],
  EMAIL_ABTEST_FILTER_OPTIONS: [
    {
      label: 'A/B test on subject',
      value: 'subjectLine'
    },
    {
      label: 'A/B test on content',
      value: 'emailContent'
    },
    {
      label: 'A/B test on send time',
      value: 'sendTime'
    }
  ],
  EMAIL_ABTEST_VARIANT_OPTIONS: [
    {
      label: 'Variant A',
      value: 'variantA',
      id: 'variantA'
    },
    {
      label: 'Variant B',
      value: 'variantB',
      id: 'variantB'
    },
    {
      label: 'Variant C',
      value: 'variantC',
      id: 'variantC'
    }
  ],
  USER_AUTOLOGOUT_TIMEOUT: 1000 * 60 * 60 * 6, //1hr = 1000 * 60 * 60
  USER_FORCELOGOUT_TIMEOUT: { amount: 48, unit: 'h' }, // h:hours, m: minites, d: days, w:weeks, M: months, s:seconds
  USER_ROLES_LABEL_MAP: {
    masterdata: 'Master data',
    acs: 'ACS',
    requestor: 'Requestor',
    admin: 'Admin',
    approver: 'Approver'
  },
  EMAIL_PRIMARY_THEME: [
    {
      label: 'Parenting & Family',
      value: 'parentingAndFamily',
      id: 'parentingAndFamily',
      labelClass: 'select-email-content-label',
      imageIcon: parentingAndFamilyImg
    },
    {
      label: 'Planet, Nature & Society',
      value: 'planetNatureAndSociety',
      id: 'planetNatureAndSociety',
      labelClass: 'select-email-content-label',
      imageIcon: planetNatureAndSocietyImg
    },
    {
      label: 'Home & Hygiene',
      value: 'homeAndHygiene',
      id: 'homeAndHygiene',
      labelClass: 'select-email-content-label',
      imageIcon: homeAndHygieneImg
    },
    {
      label: 'Food & Recipes',
      value: 'foodAndReciepes',
      id: 'foodAndReciepes',
      labelClass: 'select-email-content-label',
      imageIcon: foodAndReciepesImg
    },
    {
      label: 'Beauty & Styling',
      value: 'beautyAndStyling',
      id: 'beautyAndStyling',
      labelClass: 'select-email-content-label',
      imageIcon: beautyAndStylingImg
    },
    {
      label: 'Wellness, Nutrition & Active Lifestyle',
      value: 'wellnessNutritionAndActiveLifestyle',
      id: 'wellnessNutritionAndActiveLifestyle',
      labelClass: 'select-email-label',
      imageIcon: wellnessNutritionAndActiveLifestyleImg
    }
  ],
  EMAIL_SECONDARY_THEMES: [
    {
      label: 'Seasonal',
      value: 'seasonal',
      id: 'seasonal',
      imageIcon: seasonal
    },
    {
      label: 'Monthly 121',
      value: 'monthly121',
      id: 'monthly121',
      imageIcon: monthly121
    },
    {
      label: 'Campaign Awareness',
      value: 'campaignAwareness',
      id: 'campaignAwareness',
      imageIcon: campaignAwareness
    },
    {
      label: 'Campaign Engagement',
      value: 'campaignEngagement',
      id: 'campaignEngagement',
      imageIcon: campaignEngagement
    },
    {
      label: 'Events',
      value: 'events',
      id: 'events',
      imageIcon: events
    },
    {
      label: 'New Product Awareness',
      value: 'newProductAwareness',
      id: 'newProductAwareness',
      imageIcon: newProductAwareness
    },
    {
      label: 'Product Recommendations',
      value: 'productRecommendations',
      id: 'productRecommendations',
      imageIcon: productRecommendations
    },
    {
      label: 'Promo/ Incentives',
      value: 'promoIncentives',
      id: 'promoIncentives',
      imageIcon: promoIncentives
    },
    {
      label: 'Sales and Commerce',
      value: 'salesAndCommerce',
      id: 'salesAndCommerce',
      imageIcon: salesAndCommerce
    },
    {
      label: 'Quiz and Survey',
      value: 'quizAndSurvey',
      id: 'quizAndSurvey',
      imageIcon: quizAndSurvey
    },
    {
      label: 'Recipe Inspiration',
      value: 'recipeInspiration',
      id: 'recipeInspiration',
      imageIcon: recipeInspiration
    },
    {
      label: 'Tips and Tricks',
      value: 'tipsAndTricks',
      id: 'tipsAndTricks',
      imageIcon: tipsAndTricks
    },
    {
      label: 'Activism and Action',
      value: 'activismAndAction',
      id: 'activismAndAction',
      imageIcon: activismAndAction
    },
    {
      label: 'Purpose',
      value: 'purpose',
      id: 'purpose',
      imageIcon: purpose
    },
    {
      label: 'Sustainability',
      value: 'sustainability',
      id: 'sustainability',
      imageIcon: sustainability
    },
    {
      label: 'Privacy & Policy',
      value: 'privacyAndPolicy',
      id: 'privacyAndPolicy',
      imageIcon: privacyAndPolicy
    }
  ],
  EMAIL_COUPON_TYPE: {
    dynamicCoupon: 'dynamic coupon',
    staticCoupon: 'static coupon'
  },
  EMAIL_COUPON_FILTER_OPTIONS: [
    {
      label: 'Static coupons',
      value: 'staticCoupon'
    },
    {
      label: 'Dynamic coupons',
      value: 'dynamicCoupon'
    }
  ],
  WA_HEADERS: [
    {
      label: 'None',
      value: 'none',
      id: 'none',
      labelClass: 'select-email-content-label',
      imageIcon: parentingAndFamilyImg
    },
    {
      label: 'Text',
      value: 'text',
      id: 'text',
      labelClass: 'select-email-content-label',
      imageIcon: planetNatureAndSocietyImg
    },
    {
      label: 'Image',
      value: 'image',
      id: 'image',
      labelClass: 'select-email-content-label',
      imageIcon: homeAndHygieneImg
    },
    {
      label: 'Video',
      value: 'video',
      id: 'video',
      labelClass: 'select-email-content-label',
      imageIcon: foodAndReciepesImg
    },
    {
      label: 'Document',
      value: 'document',
      id: 'document',
      labelClass: 'select-email-content-label',
      imageIcon: beautyAndStylingImg
    },
    {
      label: 'Location',
      value: 'location',
      id: 'location',
      labelClass: 'select-email-label',
      imageIcon: wellnessNutritionAndActiveLifestyleImg
    }
  ],
  MASTER_DATA_OFFLINE_GUIDE_LINK:
    'https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fconnected.unilever.com%2Fen%2Fplatforms-tools%2Ftools%2Fdata-capture-solutions%2Fcollecting-offline-data-into-the-umc.html&data=05%7C01%7Cnikhaar.suri%40publicissapient.com%7C126f906cc5114eba53ee08dbad26c2d9%7Cd52c9ea17c2147b182a333a74b1f74b8%7C0%7C0%7C638294154209360433%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=fm36oI57Jg%2F34abv5bN6k0Ox8J3HMD5SbOo9zOaoh1c%3D&reserved=0',
  MASTER_DATA_CUSTOM_GUIDE_LINK:
    'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/9574713/API+documentation',
  IMAGE_TYPES: [
    {
      id: 'jpg',
      label: 'JPG',
      value: 'jpg'
    },
    {
      id: 'png',
      label: 'PNG',
      value: 'png'
    },
    {
      id: 'gif',
      label: 'GIF',
      value: 'gif'
    },
    {
      id: 'svg',
      label: 'SVG',
      value: 'svg'
    }
  ],
  FILE_TYPES: [
    {
      id: 'pdf',
      label: 'PDF',
      value: 'pdf'
    }
  ],
  deliveryLablesMap: {
    mainLabel: 'Delivery label (main send)',
    ABVariantA: 'Delivery label (Variant A of A/B test)',
    ABVariantB: 'Delivery label (Variant B of A/B test)',
    ABVariantC: 'Delivery label (Variant C of A/B test)',
    followUpLabel: 'Delivery label (follow up email)',
    smsFollowUpLabel: 'Delivery label (follow up SMS)'
  },
  deliveryLablesToCaptionMap: {
    mainLabel: 'Main send',
    ABVariantA: 'Variant A',
    ABVariantB: 'Variant B',
    ABVariantC: 'Variant C',
    followUpLabel: 'Follow up'
  },
  deliveryLabelToTrackingLabelMap: {
    ABVariantA: 'variantA',
    ABVariantB: 'variantB',
    ABVariantC: 'variantC'
  },
  EMAIL_SAVE_ROW_CATEGORY_MAP: {
    headers: 'headers',
    footers: 'footers',
    marketingBlocks: 'marketing blocks',
    galleries: 'galleries',
    coupons: 'coupons'
  },
  EMAIL_SAVE_ROW_CATEGORY_MAP_SINGULAR: {
    headers: 'header',
    footers: 'footer',
    marketingBlocks: 'marketing block',
    galleries: 'gallery',
    coupons: 'coupon'
  },
  // new -> type -> summary -> attributes -> schedule -> audience -> design -> review
  SMS_PROGRESS_BAR_TOT_PAGE: 8,
  SMS_JOURNEY_PAGE_NUMBERS: {
    NEW_CAMPAIGN: 1,
    SMS_TYPE: 2,
    CAMPAIGN_SUMMARY: 3,
    CAMPAIN_ATTRIBUTES: 4,
    SCHEDULE: 5,
    AUDIENCE: 6,
    DESIGN_SMS: 7,
    REVIEW: 8
  },
  SMS_BASE_ROUTE: '/campaign-wizard/communications',
  SMS_ROUTES: {
    NEW_CAMPAIGN: 'newcommunications',
    SMS_TYPE: 'communicationsmstype',
    CAMPAIGN_SUMMARY: 'communicationdetails',
    CAMPAIN_ATTRIBUTES: 'communication-attributes',
    SCHEDULE: 'communication-schedule',
    AUDIENCE: 'communication-audience',
    DESIGN_SMS: 'communicationdesign',
    REVIEW: 'communicationreview',
    BRAND_DETAILS: 'communicationbrandpage',
    CONFIRMATION_PAGE: 'communicationconfirmation',
    APPROVAL: 'approval',
    DASHBOARD: 'smsdashboard',
    EXECUTION: 'execution',
    NEW_SMS_TYPE: 'new-communicationsmstype',
    NEW_CAMPAIGN_SUMMARY: 'new-communicationdetails'
  },
  AB_VARIANT_MAP_IMG_DWNLOAD: {
    variantA: 'VariantA',
    variantB: 'VariantB',
    variantC: 'VariantC'
  },
  REPORT_DOWNLOAD_CRITERIA: [
    {
      value: 'all_cw_data',
      label: 'All CW data',
      toolTipData:
        'This report will give an excel extract of key information about all CW campaigns.',
      allowedRoles: ['admin']
    },
    {
      value: 'new_qna_english',
      label: 'New Q&A English',
      toolTipData:
        'This report will give an extract all campaigns where atleast one new question or answer master data (English) has been requested. This will not include any requests for new translated questions or answers master data.',
      allowedRoles: ['admin']
    },
    {
      value: 'cw_feedback',
      label: 'CW Feedback',
      toolTipData:
        'This report will give an extract of all feedback received on CW.',
      allowedRoles: ['admin']
    },
    {
      value: 'cw_approvers',
      label: 'CW approvers list',
      toolTipData:
        'This report will give a list of all market approvers for CW.',
      allowedRoles: ['admin']
    },
    {
      value: 'cw_form_fields_report',
      label: 'CW form fields report',
      toolTipData:
        'This report will give an extract of all campaigns with form fields in CW.',
      allowedRoles: ['admin']
    },
    {
      value: 'cw_major_enrichment_questions',
      label: 'New question request',
      toolTipData:
        'This report will give an extract of all campaigns where atleast 1 net new question is requested.',
      allowedRoles: ['admin', 'masterdata']
    }
  ],
  SMS_STATUS: {
    DRAFT: '1',
    SUBMITTED: '2',
    AWAITING_FOR_APPROVAL: '3',
    AWAITING_SETUP: '4',
    AWAITING_INFO: '8',
    REJECTED: '5',
    SCHEDULED: '6',
    LIVE: '7',
    AWAITING_COST_ESTIMATE: '9',
    AWAITING_PURCHASE_ORDER: '10'
  },
  SMS_APPROVER_GUIDE_LINES: {
    title: 'Guidelines for approval',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: 'Please review the SMS campaign using the guidelines below.',
    guideLine: [
      {
        text:
          'Ensure this is a valid and known request from a brand within your market.'
      },
      {
        text:
          'Check that the campaign objective is clearly defined and aligns to the content of the SMS.'
      },
      {
        text:
          'Review the SMS for unwanted content, such as spammy language, misleading claims, offensive imagery, or inappropriate links.'
      },
      {
        text: 'Review that the SMS body content has opt out instructions.'
      },
      {
        text:
          'Any additional market level considerations should also inform your decision to approve or reject.'
      },
      {
        text:
          'Supplement any rejections with clear comments that explain the corrections that need to be made.'
      }
    ]
  },
  // change following once updated guidelines are available
  SMS_ACS_GUIDE_LINES: {
    title: 'Guidelines for SMS setup',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: '',
    guideLine: [
      {
        strongText: 'SMS body content',
        subText: [
          {
            text:
              'Check SMS delivery template has been used for the correct brand'
          },
          {
            text:
              'Shorten URLs in body using Bitly link shortening & logic has been implemented in-line with supplied brief '
          },
          {
            text: `Validate SMS delivery content for length & character type- The length of a single SMS message is restricted to 160 characters if it is in GSM encoding and only 70 characters SMS delivery content has been tested to highlight any invalid GSM 03.38 characters using <a href='https://thesmsworks.co.uk/unicode-detector' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> SMS Unicode Detector<div class='sms-link-wrapper'><img src=${ExternalLinkIcon} alt="External Link" class="sms-external-link-icon" /></div></a>`,
            replaceText: true
          },
          {
            text:
              'Confirm the text in the message is correct based on the brief'
          },
          {
            text:
              'If coupons are used, review static coupon code is provided within the campaign specs and mentioned in the email. In case of dynamic coupons, ensure the dynamic coupon personalized field placeholder is used in SMS body content and a CSV file containing coupon codes has been uploaded to ACS via CW'
          }
        ]
      },
      {
        strongText: 'SMS delivery',
        subText: [
          {
            text:
              'Ensure ACS Contactable audience has been used as the starting point for selection (except welcome SMS)'
          },
          {
            text: 'Ensure the audience is correct based on the brief'
          },
          {
            text:
              'If using a recurring delivery, “No aggregation” has been selected'
          },
          {
            text: 'SMS delivery naming convention aligns to CDSP requirements'
          },
          {
            text: `“From” field within SMS delivery has been pre-populated and hasn’t been changed`
          }
        ]
      },
      {
        strongText: 'Send test SMS',
        subText: [
          {
            text:
              'Send a test SMS to campaign requestor for sign off on sender name/number, SMS content and & short URL generated by Bitly'
          }
        ]
      },
      {
        strongText: 'Getting in touch with the requestor',
        subText: [
          {
            text: `To request corrections or more details from requestor, move the campaign to “request more information” state`
          }
        ]
      }
    ]
  },
  SMS_TYPE_OPTIONS: [
    {
      id: 'welcome',
      label: 'Welcome SMS',
      labelClass: 'select-email-label',
      value: 'welcomeSMS',
      description:
        'Acknowledge consumers for their interest in your brand and set the tone for future communications',
      imageIcon: welcomeEmailImg
    },
    {
      id: 'newsletter',
      label: 'Newsletter',
      labelClass: 'select-email-label',
      value: 'newsletter',
      imageIcon: newsLetterSMSImg,
      description:
        'Improve advocacy by sharing curated information about your brand, purpose and products'
    },
    {
      id: 'coreinfo',
      label: 'Core information',
      labelClass: 'select-email-label',
      value: 'coreinfo',
      imageIcon: coreInfoEmailImg,
      description:
        'Inform consumers about key policy changes, privacy updates and others'
    },
    {
      id: 'birthdaySMS',
      label: 'Birthday SMS',
      labelClass: 'select-email-label',
      value: 'birthdaySMS',
      imageIcon: birthdayEmailImg,
      description:
        'Strengthen relationship with your consumers and boost engagement'
    },
    {
      id: 'general',
      label: 'General',
      labelClass: 'select-email-label',
      value: 'generalSMS',
      imageIcon: generalSMSImg,
      description:
        'Any other consumer SMS that do not fit in the above categories'
    }
  ],
  SMS_TYPE_LABELS: {
    welcomeSMS: 'Welcome SMS',
    newsletter: 'Newsletter',
    coreinfo: 'Core information',
    birthdaySMS: 'Birthday SMS',
    generalSMS: 'General'
  },
  SMS_AUDIENCE_OPTIONS: [
    {
      label: `All consumers who signed up to {0} in {1}`,
      value: 'allConsentedContactableConsumers'
    },
    {
      label: `Consumers linked to {2}(s)`,
      value: 'consumersLinkedToCampaignIds'
    },
    {
      label: 'Custom criteria',
      value: 'customCriteria'
    }
  ],
  SMS_CUSTOM_AUDIENCE_GUIDELINE: {
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle:
      'If your SMS audience requires segmentation based on questions and answers data (needs, preferences, concerns, etc.) or past SMS engagement criteria that is over 6 months old, then please follow the steps below to create your audience',
    subTitleClass: 'mb-15',
    guideLine: [
      {
        text:
          "Request your markets or business group's audience analysts or audience specialists to create the desired audience in CDSP (previously Insights)."
      },
      {
        text:
          "If you can't find anyone matching this role, please reach out to your Measurement Specialist or Operations Manager to help you connect with the point of contact"
      },
      {
        text:
          'Once the specialists create an audience, a file containing audience data will be passed over from CDSP to Adobe Campaign Standard (SMS execution tool) through SFTP, automatically'
      },
      {
        text:
          "Please note the file name and specify it in the 'audience file name' field in Campaign Wizard"
      },
      {
        text:
          'When ACS team receives your campaign request, they will search for the file using the name you provide and use it to build your SMS audience'
      }
    ],
    notes: [
      {
        text:
          'Note: Question and answer data implies any consumer data collected as Q&A pairs identified by unique question and answer master data IDs',
        className: 'mb-10-important'
      }
    ]
  },
  SMS_STATIC_COUPON_GUIDELINES: {
    guideLine: [
      {
        text:
          'Brand managers are required to obtain the required number of coupon codes from retailers or coupon providers'
      },
      {
        text:
          'All SMS recipients will receive the same coupon code to avail the offer on your retailer website'
      },
      {
        text:
          'Enter the static coupon code in your SMS body and make it prominent'
      },
      {
        text:
          'Always mention the terms of the offer and other relevant information including expiry date, the channel of redemption and any other information that will let your recipients easily avail the offer'
      }
    ]
  },
  SMS_DYNAMIC_COUPON_GUIDELINES: {
    guideLine: [
      {
        text:
          'When coupons are dynamic, each recipient will receive a unique coupon code to avail offers on your preferred retailer website'
      },
      {
        text:
          'Brand managers are required to obtain the required number of coupon codes from retailers or coupon providers'
      },
      {
        text: `To determine the number of coupon codes that may be needed, raise this <a href='https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> Kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a>  to request an estimate of your audience size from ACS team. Within the Kana ticket, the subject  should be 'ACS Coupon | Request audience count' and the description should include the link to CW campaign (if already being crafted)`,
        replaceText: true
      },
      {
        text: `Once the coupon codes are available, ensure they are in the <a href='https://unilever.sharepoint.com/:x:/r/sites/UnileverDigitalHub/Shared%20Documents/05.%20121/ACS%20Execution%20Agencies/ACS%20Coupon%20Workflow/Dynamic%20Coupon%20Code%20Template.csv?d=w16033eccb6b542c69202c5f80dcddb8d&csf=1&web=1&e=wmIW06' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'>dynamic coupon code template<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a> and upload them via Campaign Wizard when we prompt you. Campaign Wizard will send these coupons directly to ACS team so when they receive your campaign, they will already have coupons ready`,
        replaceText: true
      },
      {
        text: `In case, coupons fail to upload via CW, we will prompt you to raise <a href='https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> Kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a> with the coupons file. The Kana subject should be 'ACS Coupon | Request for codes loading' and should include the link to your CW campaign`,
        replaceText: true
      },
      {
        text:
          'Within your SMS body being crafted in Campaign Wizard, please include a dynamic coupon placeholder. You can do this by opening the text toolbar within the SMS design editor to select personalized fields and then Dynamic coupon code field. You can alternatively use our preset dynamic coupon tiles. Please note that you will not see the actual coupon codes when you send test SMS via Campaign Wizard'
      },
      {
        text:
          'In your SMS, always mention the terms of the offer and other relevant information including expiry date, the channel of redemption and any other information that will let your recipients easily avail the offer'
      },
      {
        text:
          'Once this campaign request is submitted, ACS team will setup the workflow to map each recipient to a unique coupon code. The personalized dynamic coupon field in your SMS body will then be replaced by a coupon code unique to each of your recipients when they receive the SMS'
      },
      {
        text:
          'Please note that data on whether coupons were actually redeemed should be provided by the coupon provider or retailer'
      }
    ]
  },
  SMS_FEEDBACKOPTIONS: [
    { label: 'Suggestion', value: 'suggestion' },
    { label: 'Compliment', value: 'compliment' },
    { label: 'Complaint', value: 'complaint' },
    { label: 'New request', value: 'newFeatureRequest' }
  ],
  SMS_CAMPAIGN_TIMELINE_STEPS: function() {
    return [
      {
        id: 'submitted',
        stepName: 'Submitted',
        status: [
          this.SMS_STATUS.DRAFT,
          this.SMS_STATUS.SUBMITTED,
          this.SMS_STATUS.REJECTED
        ]
      },
      {
        id: 'awaitingMarketApproval',
        stepName: 'Assigned for market approval',
        stepDescription: 'Market approvers will review your campaign',
        status: [this.SMS_STATUS.AWAITING_FOR_APPROVAL]
      },
      {
        id: 'awaitingCostEstimate',
        stepName: 'Awaiting cost estimate',
        stepDescription:
          'Campaign will be assigned to ACS team to share estimated audience count and campaign costs',
        status: [this.SMS_STATUS.AWAITING_COST_ESTIMATE]
      },
      {
        id: 'awaitingPurchaseOrder',
        stepName: 'Awaiting purchase order',
        stepDescription:
          'Requestors will receive cost estimate from global ACS team and will have to create a purchase order in Coupa and upload it to Campaign Wizard',
        status: [this.SMS_STATUS.AWAITING_PURCHASE_ORDER]
      },
      {
        id: 'awaitingSetup',
        stepName: 'Assigned for setup',
        stepDescription:
          'ACS team will review and test your SMS campaign for deliverability, rendering and accessibility',
        status: [this.SMS_STATUS.AWAITING_SETUP]
      },
      {
        id: 'scheduled',
        stepName: 'Scheduled',
        stepDescription:
          'ACS team will setup your SMS in ACS and schedule it to start on the desired date',
        status: [this.SMS_STATUS.SCHEDULED]
      },
      {
        id: 'live',
        stepName: 'Live',
        stepDescription:
          'ACS team will mark your campaign live once SMS deliveries start',
        status: [this.SMS_STATUS.LIVE]
      }
    ];
  },
  SMS_CAMPAIGN_HISTORY_PROGRESS_STEP_MAP: {
    submitted: 'Campaign submitted',
    awaitingMarketApproval: 'Approved by market',
    awaitingSetup: 'Campaign scheduled',
    scheduled: 'Campaign scheduled',
    live: 'Campaign live'
  },
  DYNAMIC_COUPON_CODE_TEMPLATE_LINK:
    'https://unilever.sharepoint.com/:x:/r/sites/UnileverDigitalHub/Shared%20Documents/05.%20121/ACS%20Execution%20Agencies/ACS%20Coupon%20Workflow/Dynamic%20Coupon%20Code%20Template.csv?d=w16033eccb6b542c69202c5f80dcddb8d&csf=1&web=1&e=wmIW06',
  EMAIL_COUPON_CODE_KANA_LINK:
    'https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957',
  SMS_DESIGN_GUIDE_LINES: {
    title: 'Character length guidelines',
    titleClass: 'cw-text--quinary cw-qna-faq-link primary-text mt-15',
    titleWrapperClass: 'text-right',
    cheveronClass: 'primary-text',
    bodyClass: 'mt-4',
    containerClass: 'mb-2',
    guideLine: [
      {
        text:
          'Each SMS can have a max of 160 GSM characters  (English letters, numbers and some special characters). Extended GSM characters (e.g. ^, {, }, [, ~, ], €, |, \\) take 2 character spaces.'
      },
      {
        text:
          'The limit is reduced to 70 characters if atleast one Unicode character is used (e.g. emojis, ✓ © § ❦ ; ¢ €, etc.).'
      },
      {
        text:
          'If message exceeds max length, it will be split into multiple parts and charged accordingly. In a multi-part SMS, the limit per SMS reduces to 153 GSM characters or 67 characters if a Unicode character exists.'
      },
      {
        text:
          'Dynamic first names, coupon codes, URLs, and opt-out text add to message length.'
      },
      {
        text: `ACS team will replace URLs in your SMS body with Bitly shortened URLs of 14-20 characters (e.g. bit.ly/3SohPbH).`,
        replaceText: true
      },
      {
        text:
          'It is recommended to limit SMS to 75-115 characters or 3-4 lines to accommodate dynamic content.'
      },
      {
        text: `Please use <a href='https://thesmsworks.co.uk/unicode-detector' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> SMS Unicode Detector<div class='sms-link-wrapper'><img src=${ExternalLinkIcon} alt="External Link" class="sms-external-link-icon" /></div></a> to verify max length and detect Unicode characters in your SMS.`,
        replaceText: true
      }
    ]
  },
  SMS_BODY_CONTENT_MAX_LENGTH: 1024,
  SAMPLE_BITLY_URL: 'bit.ly/SaMpLE1',
  SMS_COST_AND_REQUEST_INFO_DROP_DOWN_LIST: {
    options: [SMS_SHARE_COST_ESTIMATE_OPTION, SMS_REQUEST_MORE_INFO_OPTION]
    // defaultOption: SMS_SHARE_COST_ESTIMATE_OPTION
  },
  SMS_CAMPAIGNS_ONBOARDING_GUIDANCE_LINK:
    'https://unilever.sharepoint.com/:f:/r/sites/DMCHub/Shared%20Documents/10.%20Playbooks,%20Guides%20%26%20Demos/06.%20121%20Marketing/Onboarding?csf=1&web=1&e=OxI9Mi',
  DOWNLOAD_COST_ESTIMATE_ERR_MSG:
    'Sorry, only requestors and campaign collaborators can download cost estimate.',
  UPLOAD_PO_ERR_MSG:
    'Sorry, only requestors and campaign collaborators can send purchase order to ACS.',
  GSM_AlPHABETS_REGEX:
    '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$',
  EXTENDED_GSM_CHARACTERS: ['^', '{', '}', '\\', '[', '~', ']', '|', '€'],
  SMS_GSM_CHARACTERS_LIMIT: 160,
  SMS_GSM_SEGMENT_LIMIT: 153,
  SMS_UNICODE_CHARACTER_LIMIT: 70,
  SMS_UNICODE_SEGMENT_LIMIT: 67,
  WA_PROGRESS_BAR_TOT_PAGES: 8,
  WA_JOURNEY_PAGE_NUMBERS: {
    NEW_CAMPAIGN: 1,
    WA_TYPE: 2,
    CAMPAIGN_SUMMARY: 3,
    CAMPAIN_ATTRIBUTES: 4,
    SCHEDULE: 5,
    AUDIENCE: 6,
    DESIGN: 7,
    REVIEW: 8
  },
  WA_BASE_ROUTE: '/campaign-wizard/wa',
  WA_ROUTES: {
    BRAND_DETAILS: 'brand-details',
    WA_TYPE: 'type',
    CAMPAIGN_SUMMARY: 'details',
    CAMPAIN_ATTRIBUTES: 'attributes',
    SCHEDULE: 'schedule',
    AUDIENCE: 'audience',
    DESIGN: 'design',
    REVIEW: 'review',
    CONFIRMATION_PAGE: 'confirmation',
    APPROVAL: 'approval',
    DASHBOARD: 'wa-dashboard',
    EXECUTION: 'execution',
    NEW_CAMPAIGN: 'new-wa-campaign',
    NEW_WA_TYPE: 'new-campaign-type',
    NEW_CAMPAIGN_SUMMARY: 'new-campaign-details',
    WA_GUIDELINES_PAGE: 'setup-guidelines'
  },
  WA_CAMPAIGN_STATUS: {
    DRAFT: '1',
    SUBMITTED: '2',
    AWAITING_FOR_APPROVAL: '3',
    AWAITING_SETUP: '4',
    AWAITING_INFO: '8',
    REJECTED: '5',
    SCHEDULED: '6',
    LIVE: '7',
    AWAITING_COST_ESTIMATE: '9',
    AWAITING_PURCHASE_ORDER: '10'
  },
  WA_STATUS_OPTIONS: [
    { label: 'Draft', value: '1' },
    { label: 'Submitted', value: '2' },
    { label: 'Awaiting approval', value: '3' },
    { label: 'Awaiting cost estimate', value: '9' },
    { label: 'Awaiting purchase order', value: '10' },
    { label: 'Awaiting setup', value: '4' },
    { label: 'Awaiting info', value: '8' },
    { label: 'Rejected', value: '5' },
    { label: 'Scheduled', value: '6' },
    { label: 'Live', value: '7' }
  ],
  WA_TYPE_OPTIONS: [
    {
      id: 'welcome',
      label: 'Welcome WhatsApp',
      labelClass: 'select-email-label',
      value: 'welcomeWA',
      imageIcon: welcomeEmailImg,
      description:
        'Acknowledge consumers for their interest in your brand and set the tone for future communications'
    },
    {
      id: 'newsletter',
      label: 'Newsletter',
      labelClass: 'select-email-label',
      value: 'newsletter',
      imageIcon: newsLetterSMSImg,
      description:
        'Improve advocacy by sharing curated information about your brand, purpose and products'
    },
    {
      id: 'coreinfo',
      label: 'Core information',
      labelClass: 'select-email-label',
      value: 'coreinfo',
      imageIcon: coreInfoEmailImg,
      description:
        'Inform consumers about key policy changes, privacy updates and others'
    },
    {
      id: 'birthdayWA',
      label: 'Birthday WhatsApp',
      labelClass: 'select-email-label',
      value: 'birthdayWA',
      imageIcon: birthdayEmailImg,
      description:
        'Strengthen relationship with your consumers and boost engagement'
    },
    {
      id: 'general',
      label: 'General',
      labelClass: 'select-email-label',
      value: 'generalWA',
      imageIcon: generalSMSImg,
      description:
        'Any other consumer WhatsApp that do not fit in the above categories'
    }
  ],
  WA_TYPE_LABELS: {
    welcomeWA: 'Welcome WhatsApp',
    newsletter: 'Newsletter',
    coreinfo: 'Core information',
    birthdayWA: 'Birthday WhatsApp',
    generalWA: 'General'
  },
  WA_AUDIENCE_OPTIONS: [
    {
      label: `All consumers who signed up to {0} in {1}`,
      value: 'allConsentedContactableConsumers'
    },
    {
      label: `Consumers linked to {2}(s)`,
      value: 'consumersLinkedToCampaignIds'
    },
    {
      label: 'Custom criteria',
      value: 'customCriteria'
    }
  ],
  WA_CUSTOM_AUDIENCE_GUIDELINE: {
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle:
      'If your WhatsApp audience requires segmentation based on questions and answers data (needs, preferences, concerns, etc.) or past WhatsApp engagement criteria that is over 6 months old, then please follow the steps below to create your audience',
    subTitleClass: 'mb-15',
    guideLine: [
      {
        text:
          "Request your markets or business group's audience analysts or audience specialists to create the desired audience in CDSP (previously Insights)."
      },
      {
        text:
          "If you can't find anyone matching this role, please reach out to your Measurement Specialist or Operations Manager to help you connect with the point of contact"
      },
      {
        text:
          'Once the specialists create an audience, a file containing audience data will be passed over from CDSP to Adobe Campaign Standard (WhatsApp execution tool) through SFTP, automatically'
      },
      {
        text:
          "Please note the file name and specify it in the 'audience file name' field in Campaign Wizard"
      },
      {
        text:
          'When ACS team receives your campaign request, they will search for the file using the name you provide and use it to build your WhatsApp audience'
      }
    ],
    notes: [
      {
        text:
          'Note: Question and answer data implies any consumer data collected as Q&A pairs identified by unique question and answer master data IDs',
        className: 'mb-10-important'
      }
    ]
  },

  WA_CAMPAIGN_TIMELINE_STEPS: function() {
    return [
      {
        id: 'submitted',
        stepName: 'Submitted',
        status: [
          this.WA_CAMPAIGN_STATUS.DRAFT,
          this.WA_CAMPAIGN_STATUS.SUBMITTED,
          this.WA_CAMPAIGN_STATUS.REJECTED
        ]
      },
      {
        id: 'awaitingMarketApproval',
        stepName: 'Assigned for market approval',
        stepDescription: 'Market approvers will review your campaign',
        status: [this.WA_CAMPAIGN_STATUS.AWAITING_FOR_APPROVAL]
      },
      {
        id: 'awaitingCostEstimate',
        stepName: 'Awaiting cost estimate',
        stepDescription:
          'Campaign will be assigned to ACS team to share estimated audience count and campaign costs',
        status: [this.WA_CAMPAIGN_STATUS.AWAITING_COST_ESTIMATE]
      },
      {
        id: 'awaitingPurchaseOrder',
        stepName: 'Awaiting purchase order',
        stepDescription:
          'Requestors will receive cost estimate from global ACS team and will have to create a purchase order in Coupa and upload it to Campaign Wizard',
        status: [this.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER]
      },
      {
        id: 'awaitingSetup',
        stepName: 'Assigned for setup',
        stepDescription:
          'ACS team will review and test your WhatsApp campaign for deliverability, rendering and accessibility',
        status: [this.WA_CAMPAIGN_STATUS.AWAITING_SETUP]
      },
      {
        id: 'scheduled',
        stepName: 'Scheduled',
        stepDescription:
          'ACS team will setup your WhatsApp in ACS and schedule it to start on the desired date',
        status: [this.WA_CAMPAIGN_STATUS.SCHEDULED]
      },
      {
        id: 'live',
        stepName: 'Live',
        stepDescription:
          'ACS team will mark your campaign live once WhatsApp deliveries start',
        status: [this.WA_CAMPAIGN_STATUS.LIVE]
      }
    ];
  },
  WA_CAMPAIGN_HISTORY_PROGRESS_STEP_MAP: {
    submitted: 'Campaign submitted',
    awaitingMarketApproval: 'Approved by market',
    awaitingSetup: 'Campaign scheduled',
    scheduled: 'Campaign scheduled',
    live: 'Campaign live'
  },

  WA_APPROVER_GUIDE_LINES: {
    title: 'Guidelines for approval',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: 'Please review the WhatsApp campaign using the guidelines below.',
    guideLine: [
      {
        text:
          'Ensure this is a valid and known request from a brand within your market.'
      },
      {
        text:
          'Check that the campaign objective is clearly defined and aligns to the content of the WhatsApp.'
      },
      {
        text:
          'Review the WhatsApp for unwanted content, such as spammy language, misleading claims, offensive imagery, or inappropriate links.'
      },
      {
        text: 'Review that the WhatsApp body content has opt out instructions.'
      },
      {
        text:
          'Any additional market level considerations should also inform your decision to approve or reject.'
      },
      {
        text:
          'Supplement any rejections with clear comments that explain the corrections that need to be made.'
      }
    ]
  },
  WA_AB_TESTING_CONSTANTS: {
    subjectLine: 'Subject line',
    waContent: 'WhatsApp content',
    sendTime: 'Send time',
    openRate: 'Open rate',
    clickThroughRate: 'Click through rate',
    '2-variants-50-50': '2 variants | 50:50 split',
    '2-variants-10-10-80': '2 variants | 10:10:80 split',
    '3-variants-5-5-5-85': '3 variants | 5:5:5:85 split'
  },
  WA_ABTEST_FILTER_OPTIONS: [
    {
      label: 'A/B test on subject',
      value: 'subjectLine'
    },
    {
      label: 'A/B test on content',
      value: 'waContent'
    },
    {
      label: 'A/B test on send time',
      value: 'sendTime'
    }
  ],
  // change link and text here
  WA_ACS_GUIDE_LINES: {
    title: 'Guidelines for WhatsApp setup',
    titleClass: 'cw-heading--saascampaign mt-15',
    subTitle: '',
    guideLine: [
      {
        strongText: 'WhatsApp body content',
        subText: [
          {
            text:
              'Check WhatsApp delivery template has been used for the correct brand'
          },
          {
            text:
              'Shorten URLs in body using Bitly link shortening & logic has been implemented in-line with supplied brief '
          },
          {
            text: `Validate WhatsApp delivery content for length & character type- The length of a single WhatsApp message is restricted to 160 characters if it is in GSM encoding and only 70 characters WhatsApp delivery content has been tested to highlight any invalid GSM 03.38 characters using <a href='https://thesmsworks.co.uk/unicode-detector' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> WhatsApp Unicode Detector<div class='sms-link-wrapper'><img src=${ExternalLinkIcon} alt="External Link" class="sms-external-link-icon" /></div></a>`,
            replaceText: true
          },
          {
            text:
              'Confirm the text in the message is correct based on the brief'
          },
          {
            text:
              'If coupons are used, review static coupon code is provided within the campaign specs and mentioned in the email. In case of dynamic coupons, ensure the dynamic coupon personalized field placeholder is used in WhatsApp body content and a CSV file containing coupon codes has been uploaded to ACS via CW'
          }
        ]
      },
      {
        strongText: 'WhatsApp delivery',
        subText: [
          {
            text:
              'Ensure ACS Contactable audience has been used as the starting point for selection (except welcome WhatsApp)'
          },
          {
            text: 'Ensure the audience is correct based on the brief'
          },
          {
            text:
              'If using a recurring delivery, “No aggregation” has been selected'
          },
          {
            text:
              'WhatsApp delivery naming convention aligns to CDSP requirements'
          },
          {
            text: `“From” field within WhatsApp delivery has been pre-populated and hasn’t been changed`
          }
        ]
      },
      {
        strongText: 'Send test WhatsApp',
        subText: [
          {
            text:
              'Send a test WhatsApp to campaign requestor for sign off on sender name/number, WhatsApp content and & short URL generated by Bitly'
          }
        ]
      },
      {
        strongText: 'Getting in touch with the requestor',
        subText: [
          {
            text: `To request corrections or more details from requestor, move the campaign to “request more information” state`
          }
        ]
      }
    ]
  },
  WA_COST_AND_REQUEST_INFO_DROP_DOWN_LIST: {
    options: [WA_SHARE_COST_ESTIMATE_OPTION, WA_REQUEST_MORE_INFO_OPTION]
    // defaultOption: SMS_SHARE_COST_ESTIMATE_OPTION
  },
  DASHBOARD_SORT_OPTIONS: [
    {
      label: 'Updated date - most recent',
      value: 'lastUpdatedDate-desc'
    },
    {
      label: 'Updated date - oldest first',
      value: 'lastUpdatedDate-asc'
    },
    {
      label: 'Delivery date - earliest first',
      value: 'triggerSortDate-asc'
    },
    {
      label: 'Country A-Z',
      value: 'country-asc'
    },
    {
      label: 'Brand A-Z',
      value: 'brand-asc'
    }
  ],
  WA_STATIC_COUPON_GUIDELINES: {
    guideLine: [
      {
        text:
          'Brand managers are required to obtain the required number of coupon codes from retailers or coupon providers'
      },
      {
        text:
          'All WhatsApp recipients will receive the same coupon code to avail the offer on your retailer website'
      },
      {
        text:
          'Enter the static coupon code in your WhatsApp body and make it prominent'
      },
      {
        text:
          'Always mention the terms of the offer and other relevant information including expiry date, the channel of redemption and any other information that will let your recipients easily avail the offer'
      }
    ]
  },
  WA_DYNAMIC_COUPON_GUIDELINES: {
    guideLine: [
      {
        text:
          'When coupons are dynamic, each recipient will receive a unique coupon code to avail offers on your preferred retailer website'
      },
      {
        text:
          'Brand managers are required to obtain the required number of coupon codes from retailers or coupon providers'
      },
      {
        text: `To determine the number of coupon codes that may be needed, raise this <a href='https://udhd.service-now.com/csm?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> Kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a>  to request an estimate of your audience size from ACS team. Within the Kana ticket, the subject  should be 'ACS Coupon | Request audience count' and the description should include the link to CW campaign (if already being crafted)`,
        replaceText: true
      },
      {
        text: `Once the coupon codes are available, ensure they are in the <a href='https://unilever.sharepoint.com/:x:/r/sites/UnileverDigitalHub/Shared%20Documents/05.%20121/ACS%20Execution%20Agencies/ACS%20Coupon%20Workflow/Dynamic%20Coupon%20Code%20Template.csv?d=w16033eccb6b542c69202c5f80dcddb8d&csf=1&web=1&e=wmIW06' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'>dynamic coupon code template<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a> and upload them via Campaign Wizard when we prompt you. Campaign Wizard will send these coupons directly to ACS team so when they receive your campaign, they will already have coupons ready`,
        replaceText: true
      },
      {
        text: `In case, coupons fail to upload via CW, we will prompt you to raise <a href='https://udhd.service-now.com/csm?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=01e9ec38dbcd0410982dee71ca961957' target='_blank' rel='noopener noreferrer' class='alert-knowmore-message guidelines-ext-link'> Kana ticket<div class='external-link-wrapper'> <img src=${ExternalLinkIcon} alt="External Link" class="external-link-icon" /></div></a> with the coupons file. The Kana subject should be 'ACS Coupon | Request for codes loading' and should include the link to your CW campaign`,
        replaceText: true
      },
      {
        text:
          'Within your WhatsApp body being crafted in Campaign Wizard, please include a dynamic coupon placeholder. You can do this by opening the text toolbar within the WhatsApp design editor to select personalized fields and then Dynamic coupon code field. You can alternatively use our preset dynamic coupon tiles. Please note that you will not see the actual coupon codes when you send test WhatsApp via Campaign Wizard'
      },
      {
        text:
          'In your WhatsApp, always mention the terms of the offer and other relevant information including expiry date, the channel of redemption and any other information that will let your recipients easily avail the offer'
      },
      {
        text:
          'Once this campaign request is submitted, ACS team will setup the workflow to map each recipient to a unique coupon code. The personalized dynamic coupon field in your WhatsApp body will then be replaced by a coupon code unique to each of your recipients when they receive the WhatsApp'
      },
      {
        text:
          'Please note that data on whether coupons were actually redeemed should be provided by the coupon provider or retailer'
      }
    ]
  },
  DRAFT_DELETE_PERMISSON_MSG:'Only requestor and collaborators of the campaign are allowed to edit/delete draft campaigns',

  MULTI_CHANNEL_GUIDE_LINES: {
    GDPR_COUNTRIES: {
      guideLine: [
        {
          text:
            'Atleast one of email address or mobile number fields is mandatory on sign up forms'
        },
        {
          text:
            'GDPR countries are required to collect explicit separate consent for each marketing channel'
        },
        {
          text:
            'In all GDPR countries except Italy, the following consent options are available',
          subText: [
            {
              text: 'Unilever email'
            },
            {
              text: 'Unilever SMS'
            },
            {
              text: 'Unilever WhatsApp'
            }
          ]
        },
        {
          text:
            'In countries where it is legally required to unbundle consents (currently <strong>Italy</strong>)',
          richText: true,
          subText: [
            {
              text: 'Unilever email only'
            },
            {
              text: 'Unilever SMS only'
            },
            {
              text: 'Unilever online advertising only'
            }
          ]
        },
        {
          text: `Email field should always be accompanied by 'Unilever email consent'`
        },
        {
          text: `Mobile field should always be accompanied by 'Unilever SMS consent' and 'Unilever WhatsApp consent'`
        },
        {
          text: `Each marketing consent should be displayed as separate mandatory checkbox statement for consumers`
        },
        {
          text: `Marketing consents may be optional in certain cases where an incentive is offered to a consumer in exchange for sign up`
        },
        {
          text: `Age consent is mandatory on all forms and serves as a declaration by consumer that they are a certain minimum age at which they can lawfully consent to the processing of their personal data in a given country`
        },
        {
          text: `Privacy policy should be mandatorily displayed on all forms`
        }
      ]
    },
    NON_GDPR_COUNTRIES: {
      guideLine: [
        {
          text:
            'Atleast one of email address or mobile number fields is mandatory on sign up forms'
        },
        {
          text:
            'Non-GDPR countries can collect explicit separate consent for each marketing channel OR combine channel level marketing consents into a <strong>single multi-channel consent statement</strong>, i.e. a single checkbox for consumers on sign up forms that includes consent to all channels (Email, SMS, WhatsApp).',
          richText: true
        },
        {
          text:
            'In non-GDPR countries, consent can be displayed in the following ways',
          subText: [
            {
              text: 'Separate channel consents',
              subText: [
                {
                  text: 'Unilever email'
                },
                {
                  text: 'Unilever SMS'
                },
                {
                  text: 'Unilever WhatsApp'
                },
                {
                  text:
                    'Consumers will have to consent to each channel separately'
                }
              ]
            },
            {
              text: 'Combined multi-channel consents',
              subText: [
                {
                  text:
                    'Unilever email + SMS + WhatsApp consent (if a form has both email address and mobile number fields)'
                },
                {
                  text:
                    'Unilever SMS + WhatsApp consent (if a form has only mobile number field)'
                },
                {
                  text:
                    'Consumers will have to tick one marketing consent checkbox to consent to all marketing channels'
                }
              ]
            }
          ]
        },
        {
          text: `Email field should always be accompanied by 'Unilever email consent'. Mobile field should always be accompanied by 'Unilever SMS consent' and 'Unilever WhatsApp consent'. Alternatively, a combined multi-channel consent can be used`
        },
        {
          text: `Marketing consents may be optional in certain cases where an incentive is offered to a consumer in exchange for sign up`
        },
        {
          text: `Age consent is mandatory on all forms and serves as a declaration by consumer that they are a certain minimum age at which they can lawfully consent to the processing of their personal data in a given country`
        },
        {
          text: 'Privacy policy should be mandatorily displayed on all forms'
        }
      ]
    },
    UPDATE_CONSENT_STATEMENT: {
      guideLine: [
        {
          text:
            'You cannot modify marketing consent statements, age consent and privacy policy while creating sign up forms in CW as these are DPO governed'
        },
        {
          text:
            'You can still add/request modification to consent statements by raising a ticket in <a href="https://unilever.service-now.com/cex?id=sc_cat_item&sys_id=0394a8b71b28555021f3b8c8dc4bcbb9" target="_blank" rel="noopener noreferrer">UNA</a> after alignment with local DPO. Please note that the consent statement will be updated for all brands in the country.',
          richText : true,
            subText: [
            {
              text:
                'This UNA ticket has a template attached. Specify your consent statements in the template. '
            },
            {
              text: 'Upload the template to UNA'
            },
            {
              text: 'Upload the DPO approval'
            },
            {
              text: 'Submit the UNA ticket'
            },
            {
              text:
                'Once the UNA ticket is resolved, it will take 1 working day for the updated statements to start showing up in CW'
            },
            {
              text:
                'The updated consent statements will show up for all new sign up form requests for a given country'
            },
            {
              text: `To update statements in any previously created forms, you will have to edit your sign up form in CW and click on 'Fetch latest approved consents' link within the consents fields and re-submit the campaign`
            }
          ]
        },
        {
          text:
            'Please note, the following consent and compliance statements should be updated to central repository via the <a href="https://unilever.service-now.com/cex?id=sc_cat_item&sys_id=0394a8b71b28555021f3b8c8dc4bcbb9" target="_blank" rel="noopener noreferrer">UNA</a> ticket',
            richText : true,
            subText: [
            {
              text: 'All countries',
              subText: [
                {
                  text: 'Age consent'
                },
                {
                  text: 'Privacy policy'
                }
              ]
            },
            {
              text: 'GDPR countries',
              subText: [
                {
                  text: 'Unilever email consent'
                },
                {
                  text: 'Unilever SMS consent'
                },
                {
                  text: 'Unilever WhatsApp consent'
                }
              ]
            },
            {
              text:
                'GDPR countries that are legally required to unbundle online advertising consent (e.g. Italy)',
              subText: [
                {
                  text: 'Unilever email only'
                },
                {
                  text: 'Unilever SMS only'
                },
                {
                  text: 'Unilever online advertising only'
                }
              ]
            },
            {
              text: 'Non-GDPR countries',
              subText: [
                {
                  text:
                    'Channel level consents, similar to GDPR countries, AND/OR'
                },
                {
                  text: 'Combined multi-channel consents',
                  subText: [
                    {
                      text:
                        'Unilever email + SMS + WhatsApp consent (to be used if a form has both email address and mobile number fields)'
                    },
                    {
                      text:
                        'Unilever email + SMS (to be used if a form has both email address and mobile number fields but WhatsApp consent is NOT available in the country)'
                    },
                    {
                      text:
                        'Unilever SMS + WhatsApp consent ( to be used if a form has only mobile number field)'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
};

export default constants;
