import React from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import {
  EmailSummaryCard,
  EmailSummaryHeading,
  EmailSummaryText
} from '../EmailSummaryCard/EmailSummaryCard';
import {
  getWADeliveryCondition,
  getSubmittedWACampaignScheduleDates
} from 'selectors';
import constants from 'constants/config';

import CampaignIcon from 'images/campaign.png';
import DeliveryIcon from 'images/delivery.png';
import AudienceIcon from 'images/audience.png';
import externalLink from 'images/external-link.png';
import DeliveryLabel from 'images/delivery-label-icon.svg';
import { ReactComponent as ResendCouponIcon } from 'images/resend-icon.svg';
import { ReactComponent as AbTestingIcon } from 'images/abTestingIcon.svg';
import { ReactComponent as EmailCouponIcon } from 'images/email-coupon.svg';

export default function WASummaryCards({ className = '', waCampaign }) {
  if (!waCampaign) return null;

  const {
    brand,
    country,
    name,
    communicationDetail = {}, // rename
    waType,
    audienceDefinition,
    deliverySchedule,
    status,
    shareCostEstimate,
    nmiAssignedToRequestor,
    waAttributes,
    abTesting
  } = waCampaign;

  const couponApplied = waAttributes?.waCoupon?.couponApplied;
  const couponType = waAttributes?.waCoupon?.type;

  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  const resendWA =
    deliverySchedule?.sendAfter?.value &&
    deliverySchedule?.sendAfter?.value !== 'DontSend';

  const consumerCount = deliverySchedule?.consumerCount;
  const audienceCount = shareCostEstimate?.audienceCount;
  
  const showCostFileField =
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_PURCHASE_ORDER ||
    status === constants.WA_CAMPAIGN_STATUS.AWAITING_SETUP ||
    status === constants.WA_CAMPAIGN_STATUS.SCHEDULED ||
    status === constants.WA_CAMPAIGN_STATUS.LIVE ||
    status === constants.WA_CAMPAIGN_STATUS.SUBMITTED ||
    (status === constants.WA_CAMPAIGN_STATUS.AWAITING_INFO &&
      !nmiAssignedToRequestor);
  const expectedVolume =
    communicationDetail && communicationDetail.expectedVolume;
  const dateObject = getSubmittedWACampaignScheduleDates(
    communicationDetail && communicationDetail.smsDetails
  ); // change

  const mapAbTestLabel = {
    subjectLine: 'subject',
    waContent: 'content',
    sendTime: 'send time'
  };

  const deliveryLabelTooltip = () => (
    <>
      <i
        className="fas fa-info-circle ml-1"
        data-for="value-tooltip"
        data-tip="Delivery label can be used to filter the WhatsApp campaign<br/> performance metrics on the campaign tracking dashboard"
        style={{ cursor: 'pointer', fontSize: 'var(--text-base-size)' }}
      ></i>
      <ReactTooltip
        id="value-tooltip"
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
    </>
  );

  return (
    <>
      <div className={`d-flex mb-30 ${className}`}>
        <EmailSummaryCard
          icon={CampaignIcon}
          title="Campaign"
          showBottomText={couponApplied}
          bottomIcon={EmailCouponIcon}
          label={`Includes ${constants.EMAIL_COUPON_TYPE[couponType]}`}
          style={{ flex: 1.4 }}
        >
          <EmailSummaryHeading>{name || 'Untitled'}</EmailSummaryHeading>
          <EmailSummaryText className="email-summary-card__bold">
            {constants.WA_TYPE_LABELS[waType]}
          </EmailSummaryText>
          <EmailSummaryText>
            {brand?.label || ''}
            ,&nbsp;
            {country?.label || ''}
          </EmailSummaryText>
        </EmailSummaryCard>

        <EmailSummaryCard
          icon={DeliveryIcon}
          title="Delivery"
          showBottomText={resendWA}
          bottomIcon={ResendCouponIcon}
          label="Resend to non-openers"
          style={{ flex: 1, marginLeft: 12 }}
        >
          {status === constants.WA_CAMPAIGN_STATUS.SUBMITTED ? (
            <EmailSummaryText key={dateObject.date1} className="one-line">
              {dateObject.date1}
            </EmailSummaryText>
          ) : (
            getWADeliveryCondition(deliverySchedule, isABTestOnSendTime).map(text => (
              <EmailSummaryText key={text} className="one-line">
                {text}
              </EmailSummaryText>
            ))
          )}
        </EmailSummaryCard>

        <EmailSummaryCard
          icon={AudienceIcon}
          title="Audience"
          showBottomText={abTesting && abTesting.performABTests}
          bottomIcon={AbTestingIcon}
          label={`Includes A/B test on ${
            mapAbTestLabel[abTesting?.abTestElements]
          }`}
          style={{ flex: 1, marginLeft: 12 }}
        >
          <>
            <EmailSummaryText className="line-clamp-3">
              {audienceDefinition?.sendWATo?.type?.label}
            </EmailSummaryText>
            <EmailSummaryText className="mt-2">
              {(consumerCount || audienceCount || expectedVolume) &&
              showCostFileField ? (
                <div className="consumer-count-container">
                  <span className="consumer-count">
                    {consumerCount
                      ? consumerCount
                      : audienceCount
                      ? audienceCount
                      : expectedVolume}
                  </span>{' '}
                  consumers
                  <span
                    className="icon"
                    data-tip="This is the expected count of consumers to whom your WhatsApp will be sent, as provided by ACS team. For actual performance metrics, refer to the Power BI reports"
                    data-for="waConsumerCount"
                  >
                    &nbsp;<i className="fas fa-question-circle"></i>
                  </span>
                  <ReactTooltip
                    id="waConsumerCount"
                    place="bottom"
                    type="info"
                    multiline={true}
                    className="cw-tooltip cw-email-tooltip"
                  />
                </div>
              ) : null}
            </EmailSummaryText>
          </>
        </EmailSummaryCard>
      </div>

      {status === constants.WA_CAMPAIGN_STATUS.LIVE && (
        <div className="mt-2 mb-3">
          <EmailSummaryCard
            icon={DeliveryLabel}
            title="Track your WhatsApp performance"
          >
            <EmailSummaryText className="font-weight-bold my-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.powerbi.com/groups/me/apps/120c9b97-4133-47e9-8e38-97345453c93e/reports/b6741911-8a36-406b-ab37-325df0caf1e5/ReportSectionab5cf0116397112fce51?experience=power-bi&bookmarkGuid=Bookmark7ea03df196770f9421ba"
                aria-label="PowerBI dashboard link"
              >
                ACS WhatsApp campaign tracking report
                <div className="external-link-wrapper">
                  <img
                    src={externalLink}
                    alt="External Link"
                    className="external-link-icon"
                  />
                </div>
              </a>
            </EmailSummaryText>

            <EmailSummaryText>
              <div className="email-summary-card__title">Delivery labels</div>
              {typeof deliverySchedule?.deliveryLabel === 'string' ? (
                <>
                  {/* <strong>Main send: </strong> */}
                  {deliverySchedule?.deliveryLabel}
                  {deliveryLabelTooltip()}
                </>
              ) : (
                deliverySchedule?.deliveryLabel?.map(label => (
                  <p>
                    {/* <strong>
                      {constants.deliveryLablesToCaptionMap[label.type]}
                    </strong>
                    :  */}
                    {label.value} {deliveryLabelTooltip()}
                  </p>
                ))
              )}
            </EmailSummaryText>
          </EmailSummaryCard>
        </div>
      )}
    </>
  );
}
