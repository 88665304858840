import React from 'react';

import { sortArray } from 'selectors';

import DeleteIcon from 'images/times.png';

export const AppliedFilters = ({ filters, removeFilter }) => {
  const {
    country,
    brand,
    waType,
    status,
    lastUpdatedDate,
    abTesting,
    waWithIncentives,
    waContentThemes,
    waContentSecondaryThemes,
    showMyCampaigns
  } = filters;

  const orderedFilters = {
    country,
    brand,
    waType,
    status,
    abTesting,
    waWithIncentives,
    waContentThemes,
    waContentSecondaryThemes,
    showMyCampaigns
  };

  if (lastUpdatedDate) {
    orderedFilters['lastUpdatedDate'] = [lastUpdatedDate];
  }

  return Object.keys(orderedFilters).map((keyName, i) => {
    if (orderedFilters[keyName].length) {
      return orderedFilters[keyName]
        .sort(sortArray('label'))
        .map((item, indx) => {
          return (
            <div key={orderedFilters[keyName][indx]['value']} className="cw-dashboard-filters">
              <div className="cw-dashboard-filters-label">
                {keyName === 'lastUpdatedDate'
                  ? orderedFilters[keyName][indx]['value']
                  : orderedFilters[keyName][indx]['label']}
              </div>
              <div
                className="cw-dashboard-filters-remove"
                onClick={() =>
                  removeFilter(orderedFilters[keyName][indx]['value'], keyName)
                }
              >
                <img src={DeleteIcon} alt="Remove filter icon" />
              </div>
            </div>
          );
        });
    } else if (typeof orderedFilters[keyName] === 'boolean') {
      if (orderedFilters[keyName]) {
        return (
          <div className="cw-dashboard-filters">
            <div className="cw-dashboard-filters-label">My campaigns</div>
            <div
              className="cw-dashboard-filters-remove"
              onClick={() => removeFilter(orderedFilters[keyName], keyName)}
            >
              <img src={DeleteIcon} alt="Remove filter icon" />
            </div>
          </div>
        );
      }
    } else {
      return '';
    }
  });
};
