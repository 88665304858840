import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from 'components/organisms/FormBuilder';
import ProgressBar from 'components/molecules/ProgressBar';
import Loader from 'components/Loader';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import formSchema from './WACampaignSummarySchema';
import { Redirect } from 'react-router-dom';
import constants from 'constants/config';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';

const WACampaignSummary = props => {
  const [isEdit, setEdit] = useState(false);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const waCampaignDetails = useSelector(state => state.waBriefings);
  const dispatch = useDispatch();
  const pathUid = props.match.params.id;
  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      // fetch from wa details from the server
      dispatch(getWAcommsByUid(pathUid));
    }
  }, []);

  if (!waCampaignDetails) return null;
  const { isLoading, waCommsData } = waCampaignDetails;
  const { uid, waType, brand, country, language } = waCommsData;

  if (!pathUid && (!country || !brand || !language) && !waType) {
    return (
      <Redirect
        to={`/campaign-wizard/${constants.WA_ROUTES.WA_BASE_ROUTE}/${constants.WA_ROUTES.NEW_CAMPAIGN}`}
      />
    );
  }

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: uid !== pathUid ? {} : { ...waCommsData } || {},
    campaignDetails: waCommsData,
    waType: waType,
    waCampaignDetails: waCommsData
  };

  return (
    <div className="col-sm-12 skypink email-campaign-brand-selection">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.CAMPAIGN_SUMMARY}
          totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Campaign summary
          </h2>
        </div>

        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              user={authorizedUserData}
              isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WACampaignSummary;
