import React from 'react';
import groupIcon from '../images/groupIcon.png';
import ExternalLinkIcon from '../images/external-link.png';
import ReactHtmlParser from 'react-html-parser';

const MultiGuideLines = props => {
  const {
    ulStyle = {},
    ulClassName = '',
    noSubTitleLineBreak,
    noUlLineBreak
  } = props;
  return (
    <div className="guideline">
      <ul
        className={`ml-20 ${
          props.qnaIndex ? 'qna_guide_ul' : 'item_guide_ul'
        } ${ulClassName}`}
        style={ulStyle}
      >
        {props.data.guideLine.map((elem, index, array) => {
          if (elem.replaceText) {
            return (
              <>
                <li
                  className={`brandidetails`} style={{ fontWeight: '' }}
                >
                  {elem.heading ? (
                    <span className="font-weight-bold guideline-heading">
                      {elem.heading} :{' '}
                    </span>
                  ) : null}
                  {ReactHtmlParser(elem.text)}
                </li>
                {noUlLineBreak ? null : index !== array.length - 1 ? (
                  <br />
                ) : null}
              </>
            );
          }

          return (
            <>
              <li
                  className={`brandidetails`} style={{ fontWeight: '' }}
              >
                {elem.strongText ? <strong>{elem.strongText}</strong> : null}
                {elem.heading ? (
                  <span className="font-weight-bold guideline-heading">
                    {elem.heading} :{' '}
                  </span>
                ) : null}
                {elem.text
                  ? elem.richText
                    ? ReactHtmlParser(elem.text)
                    : elem.text
                  : null}
                {elem.subText ? (
                  <>
                    <ul className="ml-20">
                      {elem.subText.map(e => {
                        return (
                          <li
                          className="brandidetails"
                          style={{ color: '#000000 !important' }}
                          >
                            {e.strongText ? (
                              <strong>{e.strongText}</strong>
                            ) : null}
                            {e.text}
                            {e.isKnowMore ? (
                              <a
                                className={
                                  'alert-knowmore-message guidelines-ext-link'
                                }
                              >
                                {e.knowMoreText}
                              </a>
                            ) : null}
                            {e.subText ? (
                              <>
                                <ul className="ml-20">
                                  {e.subText.map(item => {
                                    return (
                                      <li
                                      className="brandidetails"
                                      style={{ color: '#000000 !important' }}
                                      >
                                        {item.strongText ? (
                                          <strong>{e.strongText}</strong>
                                        ) : null}
                                        {item.text}
                                        {item.isKnowMore ? (
                                          <a
                                            className={
                                              'alert-knowmore-message guidelines-ext-link'
                                            }
                                          >
                                            {item.knowMoreText}
                                          </a>
                                        ) : null}
                                        {item && item.subText ? (
                                          <>
                                            <ul className="ml-20">
                                              {item.subText.map(it => {
                                                return (
                                                  <li
                                                  className="brandidetails"
                                                  style={{ color: '#000000 !important' }}
                                                  >
                                                    {it.text}
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </>
                                        ) : null}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : null}
                {elem.externalLink ? (
                  <>
                    <a
                      href={elem.externalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={'alert-knowmore-message guidelines-ext-link'}
                    >
                      {elem.externalLinkText}
                      <div className="external-link-wrapper">
                        <img
                          src={ExternalLinkIcon}
                          alt="External Link"
                          className="external-link-icon"
                        />
                      </div>
                    </a>
                  </>
                ) : null}
                {elem.isKnowMore ? (
                  <a className={'alert-knowmore-message guidelines-ext-link'}>
                    {elem.knowMoreText}
                  </a>
                ) : null}
              </li>
              {noUlLineBreak ? null : index !== array.length - 1 ? (
                <br />
              ) : null}
            </>
          );
        })}
      </ul>
      {props.noLineBreak ? '' : <br />}
      {props.data.notes &&
        props.data.notes.map((note, index) => (
          <p key={index} className={note.className ? note.className : ''}>
            {note.text}
          </p>
        ))}
    </div>
  );
};

export default MultiGuideLines;
