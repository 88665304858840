import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import { FormReactSelect } from '../FormReactSelect';
import { FormLabel } from '../FormLabel';
import { FormInput } from '../FormInput';
import { FormTextArea } from '../FormTextArea';
import SmsCostFileDropZone from '../SmsCostFileDropZone';

const WAPurchaseOrderInput = ({
  control,
  watchInput,
  errors,
  name,
  dateFormat,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger,
  emailType,
  onFileUpload,
  setError,
  clearErrors
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="pt-2 mb-10">
      <>
        <FormInput
          id={`${name}.poNumber`}
          type="number"
          name={`${name}.poNumber`}
          label="PO Number"
          key="poNumber"
          register={register}
          errors={errors}
          defaultValue={get(defaultValue, `${name}.poNumber`)}
          setValue={setValue}
        />
        <Controller
          id={`${name}.poFile`}
          name={`${name}.poFile`}
          label={`Upload purchase order`}
          control={control}
          errors={errors}
          setValue={setValue}
          //defaultValue={defaultValue}
          render={({ field }) => (
            <SmsCostFileDropZone
              {...field}
              label={`Upload purchase order`}
              onFileUpload={onFileUpload}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />
          )}
        />

        <div className="form-group " aria-live="polite">
          <label
            htmlFor={`${name}.additionalcomments`}
          >{`Additional comments`}</label>
          <div>
            <FormTextArea
              errors={errors}
              name={`${name}.additionalcomments`}
              setValue={setValue}
              register={register}
              placeholder={'Please explain the reason'}
              defaultValue={defaultValue}
            />
            <FormLabel
              id={`additionalcommentsInfo`}
              label={'Please use this field to provide any additional info.'}
              key={`additionalcommentsInfo`}
              className={`mt-n2 email-type-desc-font`}
            />
          </div>
        </div>

        <FormLabel
          id={`btnInfo`}
          label={
            'ACS team will be notified of the PO confirmation via an email and will begin setting up your campaign.'
          }
          key={`btnInfo`}
          className={`mt-n2 email-type-desc-font`}
        />
      </>
    </div>
  );
};

export default WAPurchaseOrderInput;
