// Masterdata Reducer
import moment from 'moment';
import {
	GET_CAMPAIGN,
	ASYNC_IN_PROGRESS,
	UPDATE_GIGYA_DETAILS,
	GIGYA_DETAILS_SUBMITTED,
	GIGYA_DRAFT_SUBMITTED
} from '../actionTypes';

const initialState = {
	asyncInProgress: false,
	campaign: {},
	campaignDetails: {},
	brandoptin: {},
	unileveroptin: {},
	profileStore: {},
	additionalOptins: [],
	questionAnswerList: [],
	gigyaFormName: '',
	gigyaStartScreenUrl: '',
	gigyaCssFileUrl: {},
	gigyaQAProfileStoreKey: '',
	gigyaProductionProfileStoreKey: '',
	gigyaScreenSetName: '',
	gigyaPreferenceCentreScreenSetName: '',
	dataSubmitted: false,
	dataUpdated: false,
	isDraftState: false,
	newFileUploaded: false,
	datauploaded: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_CAMPAIGN: {
			let campaign = {
				campaign: action.campaign,
				campaignDetails: action.campaign.campaignDetail,
				brandoptin: (action.campaign.campaignDetail && action.campaign.campaignDetail.optins[0]) || {},
				unileveroptin: (action.campaign.campaignDetail && action.campaign.campaignDetail.optins[1]) || {},
				profileStore: (action.campaign.campaignDetail && action.campaign.campaignDetail.profileStore) || {},
				additionalOptins:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.additionalOptinList) || [],
				questionAnswerList:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.questionAnswerList) || [],
				gigyaFormName:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaFormName) ||
					'',
				gigyaStartScreenUrl:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaStartScreenUrl) ||
					'',
				gigyaCssFileUrl:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaCssFileUrl) ||
					{},
				   gigyaQAProfileStoreKey:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaQAProfileStoreKey) ||
					'',
				gigyaProductionProfileStoreKey:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaProductionProfileStoreKey) ||
					'',
				gigyaScreenSetName:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaScreenSetName) ||
					'',
				gigyaPreferenceCentreScreenSetName:
					(action.campaign.campaignDetail &&
						action.campaign.campaignDetail.profileStore &&
						action.campaign.campaignDetail.profileStore.gigyaPreferenceCentreScreenSetName) ||
					'', 
				datauploaded: true
			};

			if(campaign.campaignDetails && !campaign.campaignDetails.isAlwaysOn){
				campaign.campaignDetails.endDateTime = campaign.campaignDetails.endDate
			}
			return { ...state, ...campaign };
		}
		case ASYNC_IN_PROGRESS: {
			let asyncInProgress = { asyncInProgress: action.isLoading };
			return { ...state, ...asyncInProgress };
		}
		case UPDATE_GIGYA_DETAILS: {
			let newobj = { ...state, ...action.details };
			let dataUpdated = { ...newobj, ...{ dataUpdated: true } };

			return { ...state, ...dataUpdated };
		}
		case GIGYA_DETAILS_SUBMITTED: {
			let datasubmitted = {
				asyncInProgress: action.isLoading,
				dataSubmitted: action.dataSubmitted
			};

			return { ...state, ...datasubmitted };
		}
		case GIGYA_DRAFT_SUBMITTED: {
			let draftsubmitted = { isDraftState: action.isDraftState };
			return { ...state, ...draftsubmitted };
		}

		default:
			return state;
	}
}
