import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import {
  closeBannerModal,
  fetchBannerMessage,
  saveBannerMessage
} from '../../actions/adminConfig';
import constants from '../../constants';
import { TextArea } from 'components/atoms/TextArea';
import Loader from 'components/Loader';
const CWBannerModal = () => {
  const dispatch = useDispatch();
  const { message, isModalOpen, isLoading } = useSelector(
    state => state.adminConfig
  );
  const [text, setText] = useState('');

  useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchBannerMessage());
    }
  }, [isModalOpen, dispatch]);

  useEffect(() => {
    setText(message);
  }, [message]);

  const handleSave = () => {
    dispatch(saveBannerMessage(text));
  };

  const handleCancel = () => {
    setText(message);
    dispatch(closeBannerModal());
  };
  return (
    <Modal
      scrollable={true}
      isOpen={isModalOpen}
      onRequestClose={handleCancel}
      className="cw-modal cw-modal--branddetail_new_overlay"
      contentLabel="CW Banner Message"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="guideline-wrapper--header">
        <p className={`title`}>{`CW banner message`}</p>
        <i className="guideline-wrapper-close-icon" onClick={handleCancel}></i>
      </div>

      {isLoading ? (
       <Loader/>
      ) : (
        <>
          <div className="mt-20" aria-live="polite">
            <TextArea
              value={text}
              defaultValue={text}
              disabled={isLoading}
              onChange={e => setText(e.target.value)}
              placeholder="Enter banner message here"
            />
          </div>
          <button
            className="btn btn-primary btn-custom-width mb-10 cw-print-hide text-nowrap  mr-1 mt-10"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save
          </button>
          <button
            className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide mr-1 mt-10"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
        </>
      )}
    </Modal>
  );
};

export default CWBannerModal;
