import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldError } from 'components/atoms/FieldError';
import ExternalLinkTag from '../ExternalLinkTag';
import constants from 'constants/config';
import classNames from 'classnames';
import {get} from 'lodash';
import { hasPersmissionToUploadEmailCouponFile, hasPersmissionToUploadSMSCouponFile } from 'selectors'

const getSuccessText = (value, defaultValue, { fileName } = { fileName: false }) => {
  if(value === 'success'){
    let file = get(defaultValue, 'couponFileTrack.couponFileName', 'example.csv')
    return fileName ? file : ''
  } else {
    return null
  }
}

const getSuccessValuePath = (defaultValue) => {
  let path = "emailAttributes.emailCoupon.couponFileUpload"
  if(defaultValue.smsAttributes){
    path = "smsAttributes.smsCoupon.couponFileUpload"
  } 

  if(defaultValue.waAttributes) {
    path = "waAttributes.waCoupon.couponFileUpload"
  }

  return path
}

function uploadFile(
  files,
  {
    setLoader,
    setError,
    setFileName,
    onChange,
    campaignName,
    campaignUid,
    currentUser,
    setConnectionError,
    clearErrors,
    setSuccess,
    uploadCoupon,
    value
  }
) {
  if (files.length) {
    var formData = new FormData();
    formData.append('file', files[0]);
    formData.append('campaignName', campaignName);
    formData.append('campaignUid', campaignUid);
    formData.append('userEmail', currentUser.emailId)
    formData.append('userName', currentUser.name)
    setLoader(true);
    setSuccess(null);
    clearErrors();
    onChange('pending');
    return uploadCoupon(formData)
      .then(result => {
        if (result) {
          setLoader(false);
          onChange('success');
          setSuccess(result.message);
          setFileName(result.fileName)
        }
      })
      .catch(error => {
        setLoader(false);
        if (error.response.data.type === 'connection-error') {
          //setConnectionError(true);
          onChange('failed');
          setError({
            type: 'connection-error',
            message: "connection error"
          });
        } else {
          onChange(value);
          setError({
            type: 'validation-error',
            message: error.response.data.error
          });
        }
      });
  }
  return null;
}
export default React.forwardRef(
  (
    {
      onDropAction = () => {},
      className,
      campaignName,
      campaignUid,
      couponCodeTemplate: CouponCodeTemplate,
      name,
      errors,
      onChange,
      setError,
      clearErrors,
      value,
      uploadCoupon,
      defaultValue,
      user,
      isSms
    },
    ref
  ) => {
    //const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(get(defaultValue, getSuccessValuePath(defaultValue)));
    const [loader, setLoader] = useState(false);
    const [fileName, setFileName] = useState(getSuccessText(value, defaultValue, { fileName: true }))
    const [connectionError, setConnectionError] = useState(false);
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);

    const currentUser = {
      emailId: get(user, 'emailId', ''),
      group: get(user, 'group', ''),
      name: get(user, 'name', ''),
    }

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
      // check upload permissions
      const isUploadAllowed = !isSms 
        ? hasPersmissionToUploadEmailCouponFile(defaultValue.requestorsEmail, currentUser, defaultValue.emailCollaborators)
        : hasPersmissionToUploadSMSCouponFile(defaultValue.requestorsEmail, currentUser, defaultValue.emailCollaborators)

      if(!isUploadAllowed) {
        console.log("no permision")
        setError(name, {
          type: 'permission-denied',
          message: 'Sorry, it seems like you do not have permission to upload coupon files. Only requestors and collaborators of this campaign are permitted to send coupon files to ACS.'
        })
        return
      }

      // Do something with the files
      onDropAction(acceptedFiles);
      uploadFile(acceptedFiles, {
        setLoader,
        setError: err => setError(name, err),
        setFileName,
        onChange,
        campaignName,
        campaignUid,
        currentUser,
        setConnectionError,
        clearErrors: () => clearErrors(name),
        setSuccess: mesg => setSuccess(mesg),
        uploadCoupon,
        value
      });
      fileRejections.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            setError(name, {
              type: 'file-max-size',
              message: `Your file should be a CSV with a max size of 50 MB. Please review your file and try again`
            });
          }

          if (err.code === 'file-invalid-type') {
            setError(name, {
              type: 'invalid-file-type',
              message: `File should be of type CSV`
            });
          }
        });
      });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        'text/csv': ['.csv']
      },
      multiple: false,
      maxSize: 50000000 // 50 mb size limit
    });

    return (
      <div className="file-upload-zone-wrap">
        <div {...getRootProps()} className={classNames(className, {
          isError: hasError,
          loading: loader
        })}>
          <input {...getInputProps()} />
          {loader ? (
            <div className="drop-help-text">
              <span className="file-upload-icon">
                <i className="fas fa-upload"></i>
              </span>
              <p className="main-text">Sending coupon file to ACS  
                <span style={{animationDelay: 0}}>.</span>
                <span style={{animationDelay: '200ms'}}>.</span>
                <span style={{animationDelay: '400ms'}}>.</span>
              </p>
            </div>
          ) : isDragActive ? (
            <div className="drop-help-text">
              <span className="file-upload-icon">
                <i className="fas fa-upload"></i>
              </span>
              <p className="main-text">Drop the files here ...</p>
            </div>
          ) : (
            <div className="drop-help-text">
              <span className="file-upload-icon">
                <i className="fas fa-upload"></i>
              </span>
              <p className="main-text">Browse and choose coupon files</p>
              <p className="sub-text">File should be max 50MB in CSV format</p>
            </div>
          )}
        </div>
        <p className="email-type-desc-font mb-2">
              Coupon codes should be max 50 MB in a CSV file as per the{' '}
              {<CouponCodeTemplate />}.
        </p>
        {!hasError && success && (success === 'success' || success.includes(fileName)) && <p className="success-text">
        <span style={{ wordBreak: 'break-all' }}>
          We have sent your coupon codes securely to ACS.<br/>
          Your coupons file was renamed to <strong>"{fileName}"</strong>.<br/>
          You can always upload a new file. Any previously uploaded files will be lost.
        </span>  
        </p>}
        {errorMessages?.type === 'connection-error'? <div className="d-flex">
            <span className="cw-error ">
              <i className="fas fa-exclamation-triangle mr-10"></i>
            </span>
            <p className="cw-error">
              Sorry, we are unable to send your coupons to ACS at this time.
              Please try again later. This is unusual but if it still fails,
              please raise a{' '}
              {
                <ExternalLinkTag href={constants.EMAIL_COUPON_CODE_KANA_LINK} className="guidelines-ext-link">
                  Kana ticket
                </ExternalLinkTag>
              }{' '}
              with your coupon codes in the {<CouponCodeTemplate />}. The Kana
              subject should be 'ACS Coupon | Request for codes loading' and
              should include the link to your CW campaign
            </p>
          </div> : <FieldError name={name} errors={errors} />}
      </div>
    );
  }
);
