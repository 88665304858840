import React from 'react';
import classNames from 'classnames';

const LinkButton = ({ className, onClick, children, type ,disabled}) => {
  return (
    <button
      className={classNames('cw-link-btn', className)}
      type={type || "button"}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { LinkButton };
