import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { FormBuilder } from 'components/organisms/FormBuilder';
import ProgressBar from 'components/molecules/ProgressBar';
import Loader from 'components/Loader';
import { CustomButtons } from 'pages/EmailCampaignAudience';

import formSchema from './WACampaignTypeSchema';
import { getWAcommsByUid } from 'actions/wabriefings/waComms';

import constants from 'constants/config';

const WACampaignType = props => {
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);

  const { waCommsData, isLoading } = useSelector(state => state.waBriefings);
  const { authorizedUserData } = useSelector(state => state.authorized);

  const pathUid = props.match.params.id;
  const { uid, waType } = waCommsData || {};

  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      dispatch(getWAcommsByUid(pathUid));
    }
  }, []);

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: pathUid
      ? uid !== pathUid
        ? {}
        : { waType }
      : { waType } || {},
    campaignDetails: waCommsData
  };

  return (
    <div className="col-sm-12 skypink email-campaign-brand-selection">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          pageNo={constants.WA_JOURNEY_PAGE_NUMBERS.WA_TYPE}
          totalPage={constants.WA_PROGRESS_BAR_TOT_PAGES}
        />

        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Select WhatsApp type
          </h2>
        </div>

        <div className="row">
          {isLoading ? <Loader /> : null}

          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              user={authorizedUserData}
              isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WACampaignType;
