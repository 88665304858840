import React, { forwardRef, useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';
import { FormDatePicker } from '../FormDatePicker';
import { FormReactSelect } from '../FormReactSelect';
import moment from 'moment';
import classNames from 'classnames';
import fixedDateAndTimeImg from '../../../images/fixedDateAndTime.svg';
import dynamicTriggerImg from '../../../images/dynamicTrigger.svg';
import SmsTriggerTypeInfoModal from './SmsTriggerTypeInfoModal';
import { DateTimeInput } from './DateTimeInput';
import { DONT_SEND } from '../EmailScheduleInput';
import WinningVariantIndicator from 'components/atoms/WinningVariantIndicator';
import {
  getSMSScheduleTriggerOptions,
  getSubmittedSMSCampaignScheduleDates
} from '../../../selectors';
import { FormInput } from '../FormInput';
import { EnhancedTextArea } from 'components/molecules/EnhancedTextArea';
import CharCountTextareaWrapper from 'components/molecules/CharCountInput/CharCountWraper';
import { Icon } from 'semantic-ui-react';
import GenerateImageInput from './GenerateImageInput';

const WaToggleHeaderTextField = ({
  control,
  watchInput,
  errors,
  name,
  register,
  dateFormat,
  defaultValue,
  setValue,
  abTesting
}) => {
  let callBackFn = null;

  console.log('toggle header view ', defaultValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setTabImageUrl] = useState();
  const defaultType = get(defaultValue, 'type');
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });
  const currentDate = moment();

  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  console.log('>>>>>>>>>>>>>>>>>>> trigger type', triggerType);

  const handleSubmitImagePath = (url, frm) => {
    console.log('called---', url, frm);
    setIsModalOpen(false);
    setTabImageUrl(url);
    setValue(`${name}.headerImage`, url);
  };

  return triggerType === 'headerNone' ? (
    ''
  ) : triggerType === 'headerText' ? (
    <div className="pt-0">
      <div className="pt-2 ml-33 pt-0 cw-form-nested mb-20">
        <label>
          Header text
          <EnhancedTextArea
            name={`${name}.headerText`}
            id="headerText"
            defaultValue={(defaultValue && defaultValue['headerText']) || ''}
            control={control}
            label=""
            errors={errors}
            maxLength={60}
            maxLengthDisplay={60}
            setValue={setValue}
            flow="wa"
            isDynamicCoupon={false}
            optOutTextLength={0}
            insertingURLMain={false}
            hideInsertUrl={true}
            fnameOnlyOnce={true}
          />
        </label>
      </div>
    </div>
  ) : triggerType === 'headerImage' ? (
    <>
      <Controller
        name={`${name}.headerImage`}
        control={control}
        defaultValue={get(defaultValue, `headerImage`)}
        render={({ field }) => (
          <GenerateImageInput
            {...field}
            errors={errors}
            defaultValue={defaultValue}
            setValue={setValue}
            fieldPrefix={`${name}`}
          />
        )}
      />
    </>
  ) : triggerType === 'headerVideo' ? (
    <>
      <p>headerVideo show</p>
    </>
  ) : triggerType === 'headerDocument' ? (
    <>
      <p>headerDocument</p>
    </>
  ) : null;
};

const WaToggleRadioInput = forwardRef(
  ({ name, value, waType, onChange }, ref) => {
    console.log(
      '>>>>>> WaToggleRadioInput: name-',
      name,
      ' value:',
      value,
      ' waType:',
      waType
    );
    const handleOnChange = e => {
      onChange(e.target.value);
    };
    return (
      <div className="themes-container d-flex flex-wrap">
        <label
          htmlFor="headerNone"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerNone'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              type="radio"
              id="headerNone"
              value="headerNone"
              checked={value === 'headerNone'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="dont" size="large" />
              <p className="theme-label text-size-small">{'None'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerText"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerText'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              type="radio"
              id="headerText"
              value="headerText"
              checked={value === 'headerText'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="font" size="large" />
              <p className="theme-label text-size-small">{'Text'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerImage"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerImage'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerImage"
              value="headerImage"
              type="radio"
              checked={value === 'headerImage'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="image outline" size="large" />
              <p className="theme-label text-size-small">{'Image'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerVideo"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerVideo'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerVideo"
              value="headerVideo"
              type="radio"
              checked={value === 'headerVideo'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="file video outline" size="large" />
              <p className="theme-label text-size-small">{'Video'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor="headerDocument"
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerDocument'
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="headerDocument"
              value="headerDocument"
              type="radio"
              checked={value === 'headerDocument'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="file alternate outline" size="large" />
              <p className="theme-label text-size-small">{'Document'}</p>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

export const WaToggleInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  waType,
  abTesting,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const getSelectedOption = value => {
    console.log('=========== 1111 ==========');
    if (value === 'newsletter' || value === 'coreinfo') {
      return 'fixedDateAndtime';
    } else if (value === 'welcomeSMS' || value === 'birthdaySMS') {
      return 'dynamicTrigger';
    } else {
      return null;
    }
  };
  console.log(
    '------- toggle default value found -------',
    defaultValue,
    waType
  );
  useEffect(() => {
    if (defaultValue) {
      if (
        get(defaultValue, 'type') === 'fixedDateAndtime' &&
        (waType === 'welcomeSMS' || waType === 'birthdaySMS')
      ) {
        setValue(`${name}.type`, 'dynamicTrigger');
      } else {
        setValue(`${name}.type`, get(defaultValue, 'waHeader.type'));
      }
    } else {
      if (waType) {
        const selectedOption = getSelectedOption(waType);
        if (selectedOption) {
          setValue(`${name}.type`, selectedOption);
        }
      }
    }
  }, [defaultValue, waType]);

  const messageType = useWatch({
    control,
    name: `${'carousel'}.messageType`,
    defaultValue: get(defaultValue, `${'carousel'}.messageType`)
  });
  if (messageType && messageType.value === 'carousel') return null;

  return (
    <div className="form-group email-secondary-theme" aria-live="polite">
      <label htmlFor={props.id}>{label}</label>

      <div>
        <Controller
          name={`${name}.type`}
          control={control}
          // defaultValue={get(defaultValue, `${name}.type`)}
          defaultValue={defaultValue?.waHeader?.type || 'headerNone'}
          render={({ field }) => (
            <WaToggleRadioInput {...field} waType={waType} />
          )}
        />
        <WaToggleHeaderTextField
          control={control}
          watchInput={`${name}.type`}
          errors={errors}
          register={register}
          name={name}
          dateFormat={props.dateFormat}
          defaultValue={defaultValue?.waHeader}
          setValue={setValue}
          abTesting={abTesting}
        />
      </div>

      <FieldError errors={errors} name={`${name}.type`} />
    </div>
  );
};
