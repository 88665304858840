import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import moment from 'moment';

import {
  getCampaignDetails,
  updateCampaignDetails,
  submitCampaignDetails,
  resetSubmissionState,
  getCampaignCookiePrivacyUrl,
  validateCampignName
} from '../actions/campaignDetails';
import { fetchCampaignData, cancelCampaignChanges } from '../actions/overview';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
} from '../actions/app';
import { getBrandCountries } from '../actions/brandDetails';
import { setNotificationMessage } from '../actions/dashboard';
import { isAEMTypeCampaign, epsilonMarket } from '../selectors/index';
import DateTimeField from 'react-datetime';
import {
  getFormatedDate,
  str2bool,
  checkMarketSupportedForDoubleOptin,
  getFormatedDateNew,
  getNewFormatedDate,
  getNewFormatedTime
} from '../selectors';
import constant from '../constants';
//import allowedMarketsForDoubleOptin from '../constants';
import { saturate } from 'polished';
import BreadCrumb from './BreadCrumb';
import validators from '../utilities/validators';
import CampaignCancelModel from './CampaignCancelModel';
import Modal from 'react-modal';
import constants from 'constants/config';
import Alert from './Alert';
import Select from 'react-select';

class CampaignDetails extends Component {
  constructor(props) {
    super(props);

    this.boolean = {
      true: true,
      false: false
    };
    this.state = {
      campaignId: '',
      startDate: '',
      showMessageAtTop: false,
      reRenderEndDate: false,
      endDateRequired: false,
      formElements: [],
      matchProps: props.match,
      showDefaultCookiePrivacyUrl: true,
      showMail: false,
      customCampaignId: undefined,
      isCancelModalOpen: false,
      prodUrl: constant.featureFlags.INFRA_AUTO_PROVISIONING_DEFAULT_URL_DISABLE
        ? constant.INFRA_AUTO_PROVISIONING_DEFAULT_URL
        : null,
      nonProdUrl: [''],
      migrationOption: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      migrationValue: null,
      OfflineCustomOptions: [
        {
          label: 'Offline ingestion (from another system to GIGYA)',
          value: 'offlineInjection'
        },
        {
          label:
            'Build a custom form or a custom integration not supported by CW',
          value: 'customInjection'
        }
      ],
      offlineInectionOtion: [
        {
          label: 'Facebook lead gen',
          value: 'Facebook lead gen'
        },
        {
          label: 'Physical storefront (Offline)',
          value: 'Physical storefront (Offline)'
        },
        {
          label: 'Migrating to global profile store from a local profile store',
          value: 'Migrating to global profile store from a local profile store'
        },
        {
          label: 'Promotions vendor partner database',
          value: 'Promotions vendor partner database'
        },
        {
          label: 'Other',
          value: 'Other'
        }
      ],
      offlineCustomInjectionError : false,
      offlineConsumerDataError : false,
      customDescriptionError : false,
      customDescriptionWorldLimitError : false,
    };

    this.formElements = {
      name: this.props.name || '',
      additionalOptinList: this.props.additionalOptinList || [],
      additionalConsentFlag: this.props.addConsentOptinFlag,
      dpiaConfirmationFlag: this.props.dpiaConfirmationFlag,
      description: this.props.description || '',
      cookiePolicyUrl: this.props.cookiePolicyUrl || '',
      privacyPolicyUrl: this.props.privacyPolicyUrl || '',
      ageOfConsent: this.props.ageOfConsent || '',
      brandUrl: this.props.brandUrl || '',
      emailConfirmationFlag: this.props.emailConfirmationFlag || '',
      emailSenderName: this.props.emailSenderName || '',
      smsSenderName: this.props.smsSenderName || '',
      startDate: this.props.startDate || '',
      endDate: this.props.endDate || '',
      endDateTime: this.props.endDateTime || '',
      dpiaReferenceNo: this.props.dpiaReferenceNo || '',
      disclaimerText: this.props.disclaimerText || '',
      isTrackingRequired:
        this.props.isTrackingRequired !== null &&
        this.props.isTrackingRequired !== undefined
          ? this.props.isTrackingRequired
          : true,
      isCustomTrackingId:
        this.props.isCustomTrackingId !== null
          ? this.props.isCustomTrackingId
          : false,
      customCampaignId: this.props.campaign.campaignId || '',
      showEmailChannel: this.props.showEmailChannel || null,
      showSMSChannel: this.props.showSMSChannel || null,
      autogenerate: this.props.autogenerate || '',
      offlineInjection:
        this.props.offlineInjection !== null &&
        this.props.offlineInjection !== undefined
          ? this.props.offlineInjection
          : true,
      customInjection:
        this.props.customInjection !== null &&
        this.props.customInjection !== undefined
          ? this.props.customInjection
          : false,
      incentiveItPartner:
        this.props.incentiveItPartner !== null &&
        this.props.incentiveItPartner !== undefined
          ? this.props.incentiveItPartner
          : '',
      vendorPartner:
        this.props.vendorPartner !== null &&
        this.props.vendorPartner !== undefined
          ? this.props.vendorPartner
          : '',
      isMigrationRequest:
        this.props.isMigrationRequest !== null &&
        this.props.isMigrationRequest !== undefined
          ? this.props.isMigrationRequest
          : '',
      masterDataInjection:
          this.props.masterDataInjection !== null &&
          this.props.masterDataInjection !== undefined
            ? this.props.masterDataInjection
            : '',
      masterDataCollectionPartner:
          this.props.masterDataCollectionPartner !== null &&
          this.props.masterDataCollectionPartner !== undefined
            ? this.props.masterDataCollectionPartner
            : '',
      masterDataOtherCollectionPartner:
          this.props.masterDataOtherCollectionPartner !== null &&
          this.props.masterDataOtherCollectionPartner !== undefined
            ? this.props.masterDataOtherCollectionPartner
            : '',
    };
    if (
      (this.props.campaignType &&
        this.props.campaignType.toLowerCase() === 'incentive') ||
      this.props.tncUrl
    ) {
      this.formElements['tncUrl'] = this.props.tncUrl || '';
    }

    if (this.props.doubleOptinReq === 'yes' || this.props.redirectionUrl) {
      this.formElements['redirectionUrl'] = props.redirectionUrl || '';
    }

    this.errorFields = [];
    this.isValid = false;
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handlebackButton = this._handlebackButton.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleInputOnBlur = this._handleInputOnBlur.bind(this);
    this._handleendDate = this._handleendDate.bind(this);
    this._handlestartDate = this._handlestartDate.bind(this);
    this._customCampaignIdChange = this._customCampaignIdChange.bind(this);
    this._isValidStartDate = this._isValidStartDate.bind(this);
    this._isValidEndDate = this._isValidEndDate.bind(this);
    this._cancelApproverEdit = this._cancelApproverEdit.bind(this);
    this._validateFields = this._validateFields.bind(this);

    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
    this._showCampaignLeadTimeMessage = this._showCampaignLeadTimeMessage.bind(
      this
    );
    this._validateCampaignName = this._validateCampaignName.bind(this);
    this._validateCampaignNameField = this._validateCampaignNameField.bind(
      this
    );

    this._visibleDate = this._visibleDate.bind(this);
    this.maxlengthCheck = this.maxlengthCheck.bind(this);
    this._handleProdUrl = this._handleProdUrl.bind(this);

    this._handleNonProdUrl = this._handleNonProdUrl.bind(this);
    this.showHideValidationMessages = this.showHideValidationMessages.bind(
      this
    );
    this._handleMasterDataChange = this._handleMasterDataChange.bind(this);
    this._handleIncentiveItChange = this._handleIncentiveItChange.bind(this);
    this.regex = {
      email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
      ageOfConsent: /1[0-9]|[2-9]\d+|[1-9]\d{3,}/
    };
    this.incentiveItPartner = React.createRef(null);
    this.vendorPartner = React.createRef(null);
    this._handlePraIdChange = this._handlePraIdChange.bind(this);
    this._handleIncentiveVendorPartner = this._handleIncentiveVendorPartner.bind(
      this
    );
    this._migrationFieldRequest = this._migrationFieldRequest.bind(this);
    this._handleCombinedValidation = this._handleCombinedValidation.bind(this);
    this._handleOfflineCustomDropdown = this._handleOfflineCustomDropdown.bind(
      this
    );
    this._handleConsumerDataDropdown = this._handleConsumerDataDropdown.bind(
      this
    );
    this._handleCustomDescriptionChange = this._handleCustomDescriptionChange.bind(
      this
    );
    this._handleOfflineOtherDescription = this._handleOfflineOtherDescription.bind(
      this
    );
    this._handleendTime = this._handleendTime.bind(this);
    this.masterDataInjection = React.createRef(null);
    this.masterDataCollectionPartner = React.createRef(null);
    this.masterDataOtherCollectionPartner = React.createRef(null);
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.formElements.uid = this.campaignId;
    this.setState(state => {
      return { ...state, ...{ campaignId: this.campaignId } };
    });
    this.props.getCampaignDetails(this.campaignId);
    this.props.getBrandCountries(this.campaignId);
    this.props.fetchCampaignData(this.campaignId);
    let { country } = this.props;
    let marketCode = country && country.value.split('-')[0];
    let allowedMarketsForDoubleOptin = constant.allowedMarketsForDoubleOptin;
    let isMarketSupported = checkMarketSupportedForDoubleOptin(
      marketCode,
      allowedMarketsForDoubleOptin
    );
    setTimeout(() => {
      this.setState({
        prodUrl: this.props.campaignProductionUrl
          ? this.props.campaignProductionUrl
          : constant.featureFlags.INFRA_AUTO_PROVISIONING_DEFAULT_URL_DISABLE
          ? constant.INFRA_AUTO_PROVISIONING_DEFAULT_URL
          : '',
        nonProdUrl:
          this.props.campaignNonProdUrls &&
          this.props.campaignNonProdUrls.length
            ? this.props.campaignNonProdUrls
            : ['']
      });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    this.formElements = this.state.formElements;
    this.startDate = this.props.startDate;
    if (
      !this.props.approverEditCampaign &&
      this.props.isCampaignEdited &&
      prevProps.isCampaignEdited != this.props.isCampaignEdited
    ) {
      this.props.fetchEditedCampaignLogs(this.campaignId);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.campaignLoaded) {
      state.formElements = {
        name: props.name || '',
        addConsentOptinFlag: props.addConsentOptinFlag,
        additionalOptinList: props.additionalOptinList || [],
        dpiaConfirmationFlag: props.dpiaConfirmationFlag,
        description: props.description || '',
        cookiePolicyUrl:
          !props.cookieUrlFieldUpdated && !props.cookiePolicyUrl
            ? props.cookieUrl
            : props.cookiePolicyUrl,
        privacyPolicyUrl:
          !props.privacyUrlFieldUpdated && !props.privacyPolicyUrl
            ? props.privacyUrl
            : props.privacyPolicyUrl,
        ageOfConsent: props.ageOfConsent || '',
        brandUrl: props.brandUrl || '',
        requestersEmail: props.requestersEmail || '',
        emailSenderName: props.emailSenderName || '',
        smsSenderName: props.smsSenderName || '',
        startDate: props.startDate || '',
        endDate: props.endDate || '',
        endDateTime: props.endDateTime || '',
        dpiaReferenceNo: props.dpiaReferenceNo || '',
        disclaimerText: props.disclaimerText || '',
        isTrackingRequired:
          props.isTrackingRequired !== null &&
          props.isTrackingRequired !== undefined
            ? props.isTrackingRequired
            : true,
        isCustomTrackingId:
          props.isCustomTrackingId !== null ? props.isCustomTrackingId : false,
        customCampaignId:
          state.customCampaignId !== undefined
            ? state.customCampaignId
            : props.campaign.campaignId === null
            ? ''
            : props.campaign.campaignId,
        showEmailChannel: props.showEmailChannel || null,
        showSMSChannel: props.showEmailChannel || null,
        offlineInjection: props.offlineInjection || false,
        customInjection: props.customInjection || false,
        incentiveItPartner: props.incentiveItPartner || '',
        vendorPartner: props.vendorPartner || '',
        isMigrationRequest: props.isMigrationRequest || '',
        masterDataInjection: props.masterDataInjection || '',
        masterDataCollectionPartner: props.masterDataCollectionPartner || '',
        masterDataOtherCollectionPartner: props.masterDataOtherCollectionPartner || '',
      };
      if (
        (props.campaignType &&
          props.campaignType.toLowerCase() === 'incentive') ||
        props.tncUrl
      ) {
        state.formElements['tncUrl'] = props.tncUrl || '';
      }

      if (props.doubleOptinReq === 'yes' || props.redirectionUrl) {
        state.formElements['redirectionUrl'] = props.redirectionUrl || '';
      }
    }

    /* redirect to dashboard once campaign details saved as draft */
    if (props.dataSaved) {
      let notificationData = {};
      notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
      notificationData.link = '';
      notificationData.type = 'warning';
      notificationData.campaign = '';

      props.setNotificationMessage(
        notificationData.message,
        notificationData.link,
        notificationData.type,
        notificationData.campaign
      );

      props.history.push('/campaign-wizard/dashboard');
    }
    if (props.pushstate) {
      props.resetSubmissionState(false);
      if (props.approverEditCampaign) {
        props.setApproverEditCampaignStatusMessage('success');
        props.history.push(
          '/campaign-wizard/campaign/' + props.match.params.id + '/approval'
        );
      } else {
        let isWebsiteTypeAdobeSaaS =
          props.isPlatformAgnostic ||
          props.websiteType === 'Adobe SaaS' ||
          constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN;
        const nextRoute =
          props.campaignType === 'Incentive'
            ? props.websiteType === 'Standalone' && props.autogenerate
              ? 'authoringaccess'
              : props.type === 'Simple sign-up'
              ? 'review'
              : 'questionsanswers'
            : props.websiteType === 'Standalone' && props.autogenerate
            ? 'authoringaccess'
            : 'consentpreferences';
        let saasDetailRoute = constant.featureFlags
          .ENABLE_SINGLE_PAGE_FIELD_SELECTION
          ? 'configure-form-fields'
          : 'saas-profile-details';
        props.history.push(
          '/campaign-wizard/campaign/' +
            props.match.params.id +
            '/' +
            (isWebsiteTypeAdobeSaaS ? saasDetailRoute : nextRoute)
        );
      }
    }
    return null;
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push('/campaign-wizard/campaign/' + this.campaignId);
  }
  async _validateCampaignName(campaignName, fieldName) {
    if (campaignName) {
      let isNameValid = await this.props.validateCampignName(
        campaignName,
        this.campaignId
      );

      if (!isNameValid) {
        if (document.querySelector('[data-custom-msg=' + fieldName + ']')) {
          this.errorFields = this._addErrorFields(this.errorFields, fieldName);
          document
            .querySelector('[data-custom-msg=' + fieldName + ']')
            .classList.remove('cw-hidden');

          document
            .getElementById(`${fieldName}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`${fieldName}`)
              .classList.add('cw-error-focus');
        }
      } else {
        if (document.querySelector('[data-custom-msg=' + fieldName + ']')) {
          this.errorFields = this._filterErrorFields(
            this.errorFields,
            fieldName
          );

          document
            .querySelector('[data-custom-msg=' + fieldName + ']')
            .classList.add('cw-hidden');

          document
            .getElementById(`${fieldName}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`${fieldName}`)
              .classList.remove('cw-error-focus');
        }
      }
      return true;
    }
  }

  _validateCampaignNameField(event) {
    //test_qna_validation001
    event.persist();
    event.preventDefault();
    if (
      !event.target.value &&
      event.target.dataset &&
      !event.target.dataset.required
    ) {
      if (
        document.querySelector('[data-custom-msg=' + event.target.name + ']')
      ) {
        this.errorFields = this._addErrorFields(
          this.errorFields,
          event.target.name
        );
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');
      }
    }
    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateFields(event);
    }
    this._validateCampaignName(event.target.value, event.target.name);
  }

  _customCampaignIdChange(event) {
    document
      .getElementById(`customCampaignId`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`customCampaignId`)
        .classList.remove('cw-error-focus');
    this.setState({ customCampaignId: event.target.value });
  }

  _handleInputOnBlur(event) {
    let errorType = event.target.dataset.customErrorType;
    if (errorType === 'maxlength') {
      if (
        event.target.value.length &&
        event.target.value.length >
          constant.FIELDS_MAX_LENGTH[event.target.name]
      ) {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${event.target.name}`)
            .classList.add('cw-error-focus');
      } else {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      }
    }

    if (event.target.dataset.customError === 'true') {
      let errorType = event.target.dataset.customErrorType;
      if (errorType === 'maxlength') {
        if (
          event.target.value.length &&
          event.target.value.length >
            constant.FIELDS_MAX_LENGTH[event.target.name]
        ) {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.remove('cw-hidden');
          document
            .getElementById(`${event.target.name}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`${event.target.name}`)
              .classList.add('cw-error-focus');
        } else {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.add('cw-hidden');

          document
            .getElementById(`${event.target.name}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`${event.target.name}`)
              .classList.remove('cw-error-focus');
        }
      } else {
        // Email Format Validtion
        let regexPattern;
        regexPattern = constant.regex[event.target.dataset.customErrorType];
        if (regexPattern.test(event.target.value)) {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.add('cw-hidden');
          document
            .getElementById(`${event.target.name}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`${event.target.name}`)
              .classList.remove('cw-error-focus');
        } else {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.remove('cw-hidden');
          document
            .getElementById(`${event.target.name}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`${event.target.name}`)
              .classList.add('cw-error-focus');
        }
      }
    }

    if (
      !event.target.value &&
      event.target.dataset &&
      !event.target.dataset.required
    ) {
      if (
        document.querySelector('[data-custom-msg=' + event.target.name + ']')
      ) {
        this.errorFields = this._addErrorFields(
          this.errorFields,
          event.target.name
        );
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      }
    }
    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event);
    }
  }

  maxlengthCheck(event) {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    let updatedText;
    if (name in constant.FIELDS_MAX_LENGTH) {
      if (value.length > constant.FIELDS_MAX_LENGTH[name]) {
        document
          .querySelector("[data-custom-msg='" + name + "']")
          .classList.remove('cw-hidden');
        updatedText = value.slice(0, constant.FIELDS_MAX_LENGTH[name]);
      } else {
        document
          .querySelector("[data-custom-msg='" + name + "']")
          .classList.add('cw-hidden');
        updatedText = value;
      }
      return updatedText;
    }
  }

  _handleInputChange(event) {
    let detailsToUpdate = {
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };
    this.formElements[event.target.name] = event.target.value;
    if (event.target.name === 'isTrackingRequired') {
      detailsToUpdate['isCustomTrackingId'] = false;
      detailsToUpdate[event.target.name] = str2bool(event.target.value);
    }
    if (event.target.name === 'isCustomTrackingId')
      detailsToUpdate[event.target.name] = str2bool(event.target.value);
    if (event.target.name === 'disclaimerText') {
      detailsToUpdate = { [event.target.name]: this.maxlengthCheck(event) };
    }
    if (event.target.name === 'isAlwaysOn' && event.target.checked) {
      document
        .querySelector('#endDate')
        .setAttribute('placeholder', 'Select an end date');
      if (
        document.querySelector("[data-control='endDate']") &&
        !document
          .querySelector("[data-control='endDate']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='endDate']")
          .classList.add('cw-hidden');
      }

      document.getElementById(`endDate`).classList.contains('cw-error-focus') &&
        document.getElementById(`endDate`).classList.remove('cw-error-focus');

      // for end time
        document
        .querySelector('#endDateTime')
        .setAttribute('placeholder', 'Select an end time');
      if (
        document.querySelector("[data-control='endDateTime']") &&
        !document
          .querySelector("[data-control='endDateTime']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='endDateTime']")
          .classList.add('cw-hidden');
      }

      document.getElementById(`endDateTime`).classList.contains('cw-error-focus') &&
        document.getElementById(`endDateTime`).classList.remove('cw-error-focus');
      //set default tracking to No
    }
    if (
      event.target.name === 'doubleOptinReq' &&
      (this.props.doubleOptinReq === 'no' || !this.props.doubleOptinReq)
    ) {
      this.props.updateCampaignDetails({ redirectionUrl: null });
    }

    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
  }

  _handleMasterDataChange(event) {
    let detailsToUpdate = {
      [event.target.name]: str2bool(event.target.value)
    };
    this.formElements[event.target.name] = str2bool(event.target.value);
    if (event.target.name === 'offlineInjection') {
      detailsToUpdate['customInjection'] = false;
      detailsToUpdate[event.target.name] = str2bool(event.target.value);
    }
    if (event.target.name === 'customInjection') {
      detailsToUpdate['offlineInjection'] = false;
      detailsToUpdate[event.target.name] = str2bool(event.target.value);
    }
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    if (
      document.querySelector(
        "[data-control='" + 'masterDataInjection' + "']"
      )
    ) {
      if (
        !document
          .querySelector("[data-control='" + 'masterDataInjection' + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + 'masterDataInjection' + "']")
          .classList.add('cw-hidden');
      }
    }
  }

  _handleIncentiveItChange(event) {
    let detailsToUpdate = {
      incentiveItPartner: event.target.value
    };
    this.incentiveItPartner.current = event.target.value;
    this.formElements['incentiveItPartner'] = event.target.value;
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    if (
      document.querySelector("[data-control='" + 'incentiveItPartner' + "']")
    ) {
      if (
        !document
          .querySelector("[data-control='" + 'incentiveItPartner' + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + 'incentiveItPartner' + "']")
          .classList.add('cw-hidden');
      }
    }
  }
  _handlePraIdChange(event) {
    const re = /^[0-9\b]+$/;
    let detailsToUpdate = {
      [event.target.name]: event.target.value
    };
    this.formElements[event.target.name] = event.target.value;
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    if (event.target.value === '' || re.test(event.target.value)) {
      document
        .getElementById(`${event.target.name}_err`)
        .classList.contains('cw-hidden') ||
        document
          .getElementById(`${event.target.name}_err`)
          .classList.add('cw-hidden');

      document
        .getElementById(`${event.target.name}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${event.target.name}`)
          .classList.remove('cw-error-focus');
    } else {
      document
        .getElementById(`${event.target.name}_err`)
        .classList.contains('cw-hidden') &&
        document
          .getElementById(`${event.target.name}_err`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`${event.target.name}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${event.target.name}`)
          .classList.add('cw-error-focus');
    }
  }

  _handleendDate(moment) {
    let event = { target: { name: 'endDate', value: '' } };
    //setting isTracking Required to true
    //this.props.updateCampaignDetails({ isTrackingRequired: true });
    document.getElementById(`endDate`).classList.contains('cw-error-focus') &&
      document.getElementById(`endDate`).classList.remove('cw-error-focus');
    this._handleDateTimeValdation(event, moment);
  }

  _handleendTime(moment) {
    let event = { target: { name: 'endDateTime', value: '' } };
    //setting isTracking Required to true
    //this.props.updateCampaignDetails({ isTrackingRequired: true });
    document.getElementById(`endDateTime`).classList.contains('cw-error-focus') &&
      document.getElementById(`endDateTime`).classList.remove('cw-error-focus');
    this._handleDateTimeValdation(event, moment);
  }

  _handlestartDate(moment) {
    let event = { target: { name: 'startDate', value: '' } };
    document.getElementById(`startDate`).classList.contains('cw-error-focus') &&
      document.getElementById(`startDate`).classList.remove('cw-error-focus');
    this._handleDateTimeValdation(event, moment);
    this.setState(state => {
      return {
        ...state,
        ...{
          startDate: moment._isValid ? moment.utc().format() : '',
          reRenderEndDate: true,
          endDateRequired: true
        }
      };
    });
  }

  _handleProdUrl(event) {
    document.getElementById(`prodUrl`).classList.contains('cw-error-focus') &&
      document.getElementById(`prodUrl`).classList.remove('cw-error-focus');

    !document
      .querySelector("[data-control='" + 'prodUrl' + "']")
      .classList.contains('cw-hidden') &&
      document
        .querySelector("[data-control='" + 'prodUrl' + "']")
        .classList.add('cw-hidden');

    this.hideValidationMessages('custom', 'prodUrl');

    this.setState({
      prodUrl: event.target.value
    });
  }

  _handleNonProdUrl(event, index) {
    document
      .getElementById(`non_prod_url_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`non_prod_url_${index}`)
        .classList.remove('cw-error-focus');

    this.hideValidationMessages('custom', `non_prod_url_${index}`);
    this.hideValidationMessages('custom', `non_prodUrl_valid_${index}`);

    document.getElementById(`add_non_prod_url_${index}`) &&
      document
        .getElementById(`add_non_prod_url_${index}`)
        .classList.contains('non-prod-url-top') &&
      document
        .getElementById(`add_non_prod_url_${index}`)
        .classList.remove('non-prod-url-top');

    let nonprodUrlarr = this.state && this.state.nonProdUrl;
    nonprodUrlarr[index] = event.target.value;
    this.setState({
      nonProdUrl: nonprodUrlarr
    });
  }

  addNonProdUrl() {
    let nonprodUrlarr = this.state && this.state.nonProdUrl;
    let isValidUrl = true;
    if (nonprodUrlarr && nonprodUrlarr.length) {
      nonprodUrlarr.forEach((elm, index) => {
        if (
          document.getElementById(`non_prod_url_${index}`).value.trim()
            .length === 0
        ) {
          isValidUrl = false;
          this.showHideValidationMessages('custom', `non_prod_url_${index}`);

          document
            .getElementById(`non_prod_url_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`non_prod_url_${index}`)
              .classList.add('cw-error-focus');

          document
            .getElementById(`add_non_prod_url_${index}`)
            .classList.contains('non-prod-url-top') ||
            document
              .getElementById(`add_non_prod_url_${index}`)
              .classList.add('non-prod-url-top');
        } else if (
          document.getElementById(`non_prod_url_${index}`).value.trim()
            .length != 0 &&
          constant.regex.prodUrl instanceof RegExp &&
          !constant.regex.prodUrl.test(
            document.getElementById(`non_prod_url_${index}`).value.trim()
          )
        ) {
          isValidUrl = false;
          this.showHideValidationMessages(
            'custom',
            `non_prodUrl_valid_${index}`
          );
          document
            .getElementById(`non_prod_url_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`non_prod_url_${index}`)
              .classList.add('cw-error-focus');

          document
            .getElementById(`add_non_prod_url_${index}`)
            .classList.contains('non-prod-url-top') ||
            document
              .getElementById(`add_non_prod_url_${index}`)
              .classList.add('non-prod-url-top');
        } else {
          this.hideValidationMessages('custom', `non_prodUrl_valid_${index}`);
          document
            .getElementById(`non_prod_url_${index}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`non_prod_url_${index}`)
              .classList.remove('cw-error-focus');

          document.getElementById(`add_non_prod_url_${index}`) &&
            document
              .getElementById(`add_non_prod_url_${index}`)
              .classList.contains('non-prod-url-top') &&
            document
              .getElementById(`add_non_prod_url_${index}`)
              .classList.remove('non-prod-url-top');
        }
      });
    }
    if (isValidUrl) {
      nonprodUrlarr.push('');
      this.setState({
        nonProdUrl: nonprodUrlarr
      });
    }
  }

  deleteNonProdUrl(e, index) {
    this.hideValidationMessages('custom', `non_prod_url_${index}`);
    let nonprodUrlarr = this.state && this.state.nonProdUrl;
    if (nonprodUrlarr && nonprodUrlarr.length) {
      nonprodUrlarr.forEach((elm, index) => {
        this.hideValidationMessages('custom', `non_prod_url_${index}`);
        this.hideValidationMessages('custom', `non_prodUrl_valid_${index}`);
        document
          .getElementById(`non_prod_url_${index}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`non_prod_url_${index}`)
            .classList.remove('cw-error-focus');

        document.getElementById(`add_non_prod_url_${index}`) &&
          document
            .getElementById(`add_non_prod_url_${index}`)
            .classList.contains('non-prod-url-top') &&
          document
            .getElementById(`add_non_prod_url_${index}`)
            .classList.remove('non-prod-url-top');
      });
    }
    nonprodUrlarr && nonprodUrlarr.length && nonprodUrlarr.splice(index, 1);
    this.setState({
      nonProdUrl: nonprodUrlarr
    });
  }

  _handleDateTimeValdation(event, moment) {
    if (moment._isValid) {
      event.target.value = moment._d;
    }
    this.formElements[event.target.name] = event.target.value;
    this._validateField(event.target.name);
    this.props.updateCampaignDetails({
      [event.target.name]: event.target.value
    });
    this.props.editedCampaignDetails({
      [event.target.name]: event.target.value
    });
  }

  async _handleSubmit(event, saveAsDraft) {
    event.preventDefault();
    let { campaign } = this.props;
    let editCampaign = this.props.editCampaign || this.props.isCampaignEdited;
    let isEdited = this.props.campaign.isEdited;
    let isCampaignTypeSASS =
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS') ||
      campaign.isPlatformAgnostic ||
      (campaign && campaign.isMasterData);

    if (!saveAsDraft) {
      await this._validateForm(event);
    } else {
      this.isValid = true;
    }

    if (
      !isEdited &&
      !saveAsDraft &&
      isCampaignTypeSASS &&
      !this._handleCombinedValidation()
    ) {
      this.isValid = false;
    }
    if (this.isValid) {
      let updatedFormData = {};
      for (const field in this.refs) {
        if (field === 'startDate' || field === 'endDate') {
          updatedFormData[field] = this.refs[field].props.value
            ? this.refs[field].props.value
            : this.refs[field].props.inputProps.placeholder ===
                'Select an end date' ||
              this.refs[field].props.inputProps.placeholder ===
                'Select a start date and time'
            ? ''
            : this.refs[field].props.inputProps.placeholder;
        } else if (field === 'isTrackingRequired') {
          updatedFormData[field] = this.formElements[field];
        } else if (field === 'isCustomTrackingId') {
          updatedFormData[field] = this.formElements[field];
        } else if (field === 'customCampaignId') {
          updatedFormData[field] = this.formElements[field];
        } else if (field === 'disclaimerText') {
          updatedFormData[field] = this.refs[field].value.slice(
            0,
            constant.FIELDS_MAX_LENGTH['disclaimerText']
          );
        } else if (field === 'masterDataInjection') {
          updatedFormData[field] = this.formElements[field];
        } else if (field === 'masterDataCollectionPartner') {
          updatedFormData[field] = this.formElements[field];
        } else if (field === 'masterDataOtherCollectionPartner') {
          updatedFormData[field] = this.formElements[field];
        } else if (
          field === 'incentiveItPartner' &&
          this.props.campaignType &&
          this.props.campaignType.toLowerCase() === 'incentive'
        ) {
          updatedFormData[field] = this.formElements[field];
        } else if (field === 'endDateTime') {
          console.log('this.refs[field].props.value: ', this.refs[field].props.value);
          console.log('this.refs[field].props.inputProps.placeholder: ', this.refs[field].props.inputProps.placeholder);
          updatedFormData[field] = this.refs[field].props.value
            ? moment.utc(this.refs[field].props.value).format('hh:mm A')
            : this.refs[field].props.inputProps.placeholder ===
                'Select an end time' ? ''
            : this.refs[field].props.inputProps.placeholder;
            // updatedFormData[field]
            console.log('updatedFormData[field]: ', typeof updatedFormData[field], updatedFormData[field]);
        } else {
          updatedFormData[field] = this.refs[field].value;
        }
      }
      if(!updatedFormData['masterDataOtherCollectionPartner']){
        updatedFormData['masterDataOtherCollectionPartner'] = null
      }
      if(updatedFormData['masterDataInjection'] === 'customInjection'){
        updatedFormData['masterDataCollectionPartner'] = null
      }
      updatedFormData.uid = this.campaignId;
      updatedFormData.lastUpdatedScreen = 'details';
      updatedFormData.cookiePolicyUrl = updatedFormData.cookiePolicyUrl
        ? updatedFormData.cookiePolicyUrl.toLowerCase()
        : '';
      updatedFormData.privacyPolicyUrl = updatedFormData.privacyPolicyUrl
        ? updatedFormData.privacyPolicyUrl.toLowerCase()
        : '';
      updatedFormData.tncUrl = updatedFormData.tncUrl
        ? updatedFormData.tncUrl.toLowerCase()
        : '';
      if (!this.props.approverEditCampaign) {
        updatedFormData.requestersEmail = this.props.authorizedUserData[
          'emailId'
        ];
      }
      if (!isAEMTypeCampaign(campaign)) {
        this.props.updateCampaignDetails({
          autogenerate: false
        });
        updatedFormData['autogenerate'] = false;
      }
      updatedFormData['campaignProductionUrl'] = this.state.prodUrl;
      if (
        this.state &&
        this.state.nonProdUrl &&
        this.state.nonProdUrl.length > 0
      ) {
        let nonProdUrlArr = [];
        this.state.nonProdUrl.forEach(elm => {
          if (elm.trim().length) {
            nonProdUrlArr.push(elm);
          }
        });
        updatedFormData['campaignNonProdUrls'] = nonProdUrlArr;
      }

      //let isWebsiteTypeAdobeSaaS = this.props.websiteType === 'Adobe SaaS';
      if (
        constant.featureFlags.ENABLE_CAMPAIGN_ID_GOVERNANCE &&
        !editCampaign
      ) {
        updatedFormData['isTrackingRequired'] = true;
      }
      if (
        !editCampaign &&
        this.props.campaign &&
        !this.props.campaign.isMasterData
      ) {
        updatedFormData['isMigrationRequest'] = this.state.migrationValue
          ? this.state.migrationValue.value
          : this.props.isMigrationRequest
          ? this.props.isMigrationRequest
          : null;
      }
      this.props.submitCampaignDetails(
        updatedFormData,
        saveAsDraft,
        this.props.userName,
        this.props.userEmail,
        editCampaign,
        !this.props.approverEditCampaign ? this.props.editCampaignDetails : {}
      );
      if (!this.props.approverEditCampaign && this.props.isCampaignEdited) {
        this.props.saveEditedCampaignLogs(
          this.campaignId,
          this.props.editCampaignDetails
        );
      }
    } else {
      this.setState({ showMessageAtTop: true });
      this._scrollToTop();
    }
  }

  _scrollToTop() {
    setTimeout(() => {
      document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }

  _handlebackButton(event) {
    event.preventDefault();
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/type'
    );
  }

  _validateFields = () => {
    let regexPattern = constant.regex['url'];
    let editCampaign = this.props.campaign.isEdited;
    if (!editCampaign && this.props.startDate) {
      if (
        moment(this.props.startDate).format(constants.FORMAT_DATE_YYYY_MM_DD) <
        moment()
          .utc()
          .format(constants.FORMAT_DATE_YYYY_MM_DD)
      ) {
        if (
          document.querySelector("[data-custom-msg='startDate']") &&
          document
            .querySelector("[data-custom-msg='startDate']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-custom-msg='startDate']")
            .classList.remove('cw-hidden');
        }
        this.errorFields = this._addErrorFields(this.errorFields, 'startDate');
      }
    }

    if (this.props.startDate && this.props.endDate && !this.props.isAlwaysOn) {
      if (
        moment(this.props.endDate).format(constants.FORMAT_DATE_YYYY_MM_DD) <
        moment(this.props.startDate).format(constants.FORMAT_DATE_YYYY_MM_DD)
      ) {
        if (
          document.querySelector("[data-custom-msg='endDate']") &&
          document
            .querySelector("[data-custom-msg='endDate']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-custom-msg='endDate']")
            .classList.remove('cw-hidden');

          document
            .getElementById(`endDate`)
            .classList.contains('cw-error-focus') ||
            document.getElementById(`endDate`).classList.add('cw-error-focus');
        }
        this.errorFields = this._addErrorFields(this.errorFields, 'endDate');
      }
    }

    if (this.props.startDate && !this.props.endDate && !this.props.isAlwaysOn) {
      if (
        document.querySelector("[data-control='endDate']") &&
        document
          .querySelector("[data-control='endDate']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='endDate']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`endDate`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`endDate`).classList.add('cw-error-focus');
      }
      this.errorFields = this._addErrorFields(this.errorFields, 'endDate');
    }

    if (this.props.startDate && !this.props.endDateTime && !this.props.isAlwaysOn) {
      if (
        document.querySelector("[data-control='endDateTime']") &&
        document
          .querySelector("[data-control='endDateTime']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='endDateTime']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`endDateTime`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`endDateTime`).classList.add('cw-error-focus');
      }
      this.errorFields = this._addErrorFields(this.errorFields, 'endDateTime');
    }

    //Validate if both smsChannel & emailChannel is unchecked
    if (!this.props.smsChannel && !this.props.emailChannel) {
      if (
        document.querySelector("[data-control='emailChannel&smsChannel']") &&
        document
          .querySelector("[data-control='emailChannel&smsChannel']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='emailChannel&smsChannel']")
          .classList.remove('cw-hidden');
      }
      this.errorFields = this._addErrorFields(
        this.errorFields,
        'emailChannel&smsChannel'
      );
    }
  };

  async _validateForm(event) {
    let formElements = this.formElements;
    this.errorFields = [];
    for (var key in formElements) {
      if (
        formElements.hasOwnProperty(key) &&
        key !== 'disclaimerText' &&
        key !== 'isTrackingRequired' &&
        key !== 'isCustomTrackingId' &&
        key !== 'customCampaignId' &&
        key !== 'customInjection' &&
        key !== 'offlineInjection'
      ) {
        let selector = document.querySelector("[name='" + key + "']");
        if (selector) {
          if (formElements[key] && formElements[key] !== false) {
            if (selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.remove('error');
            }

            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                !document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.add('cw-hidden');
              }
            }
            if (document.querySelector("[data-custom-msg='" + key + "']")) {
              if (
                !document
                  .querySelector("[data-custom-msg='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-custom-msg='" + key + "']")
                  .classList.add('cw-hidden');
              }
            }

            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (key !== 'endDate' && key !== 'endDateTime') {
              if (!selector.parentElement.classList.contains('error')) {
                selector.parentElement.classList.add('error');
              }

              if (document.querySelector("[data-control='" + key + "']")) {
                if (
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.remove('cw-hidden');

                    document
                    .getElementById(`${key}`) && (document
                    .getElementById(`${key}`)
                    .classList.contains('cw-error-focus') ||
                    document
                      .getElementById(`${key}`)
                      .classList.add('cw-error-focus'));
                }
              }
              if (document.querySelector("[data-custom-msg='" + key + "']")) {
                if (
                  !document
                    .querySelector("[data-custom-msg='" + key + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-custom-msg='" + key + "']")
                    .classList.add('cw-hidden');

                  document
                    .getElementById(`${key}`)
                    .classList.contains('cw-error-focus') &&
                    document
                      .getElementById(`${key}`)
                      .classList.remove('cw-error-focus');
                }
              }
              this.errorFields = this._addErrorFields(this.errorFields, key);
            } else {
              if (!this.props.isAlwaysOn) {
                if (!selector.parentElement.classList.contains('error')) {
                  selector.parentElement.classList.add('error');
                }

                if (document.querySelector("[data-control='" + key + "']")) {
                  if (
                    document
                      .querySelector("[data-control='" + key + "']")
                      .classList.contains('cw-hidden')
                  ) {
                    document
                      .querySelector("[data-control='" + key + "']")
                      .classList.remove('cw-hidden');

                    document
                      .getElementById(`${key}`)
                      .classList.contains('cw-error-focus') ||
                      document
                        .getElementById(`${key}`)
                        .classList.add('cw-error-focus');
                  }
                }
                if (document.querySelector("[data-custom-msg='" + key + "']")) {
                  if (
                    !document
                      .querySelector("[data-custom-msg='" + key + "']")
                      .classList.contains('cw-hidden')
                  ) {
                    document
                      .querySelector("[data-custom-msg='" + key + "']")
                      .classList.add('cw-hidden');

                    document
                      .getElementById(`${key}`)
                      .classList.contains('cw-error-focus') &&
                      document
                        .getElementById(`${key}`)
                        .classList.remove('cw-error-focus');
                  }
                }
                this.errorFields = this._addErrorFields(this.errorFields, key);
              } else {
                this.errorFields = this._filterErrorFields(
                  this.errorFields,
                  key
                );
              }
            }
          }
        }
      } else {
        if (
          key === 'customCampaignId' &&
          document.getElementById('customCampaignId') &&
          document.getElementById('customCampaignId').value.trim().length === 0
        ) {
          document.getElementById('customCampaignId') &&
            document
              .getElementById('customCampaignId')
              .classList.add('cw-error-focus');

          if (document.querySelector("[data-control='" + key + "']")) {
            if (
              !document
                .querySelector("[data-control='" + key + "']")
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector("[data-control='" + key + "']")
                .classList.add('cw-hidden');
            }
          }
        }
      }
    }
    this._validateFields();
    this.validators = validators;
    for (let key in formElements) {
      if (
        key !== 'customCampaignId' ||
        (key === 'customCampaignId' && formElements['isCustomTrackingId'])
      ) {
        if (
          formElements.hasOwnProperty(key) &&
          (formElements[key] || key === 'customCampaignId') &&
          this.validators.hasOwnProperty(key)
        ) {
          this.validators[key].rules.forEach(rule => {
            switch (rule.validation) {
              case 'regex': {
                if (
                  rule.test instanceof RegExp &&
                  !rule.test.test(formElements[key])
                ) {
                  this.showHideValidationMessages(rule.type, key);
                } else this.hideValidationMessages(rule.type, key);
                break;
              }
              case 'maxlength': {
                let maxAllowedLength = constant.FIELDS_MAX_LENGTH[key];
                if (
                  formElements[key].length &&
                  formElements[key].length > maxAllowedLength
                ) {
                  //this.showHideValidationMessages(rule.type,key);
                }
                break;
              }
              default:
                break;
            }
          });
        }
      }

      if (
        key === 'dpiaReferenceNo' &&
        formElements['dpiaReferenceNo'] &&
        !(this.props.campaign && this.props.campaign.isEdited)
      ) {
        const re = /^[0-9\b]+$/;
        if (
          formElements['dpiaReferenceNo'] === '' ||
          re.test(formElements['dpiaReferenceNo'])
        ) {
          document
            .getElementById(`dpiaReferenceNo_err`)
            .classList.contains('cw-hidden') ||
            document
              .getElementById(`dpiaReferenceNo_err`)
              .classList.add('cw-hidden');

          this.errorFields = this._filterErrorFields(this.errorFields, key);
        } else {
          document
            .getElementById(`dpiaReferenceNo_err`)
            .classList.contains('cw-hidden') &&
            document
              .getElementById(`dpiaReferenceNo_err`)
              .classList.remove('cw-hidden');
          document
            .getElementById(`dpiaReferenceNo`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`dpiaReferenceNo`)
              .classList.add('cw-error-focus');

          this.errorFields = this._addErrorFields(this.errorFields, key);
        }
      }
      if(this.props.isMasterData && key === 'masterDataInjection'){
        if(!this.props.masterDataInjection){
          this.setState({
            offlineCustomInjectionError : true
          })
          this.errorFields = this._addErrorFields(this.errorFields, key);
        }else if(this.props.masterDataInjection === 'offlineInjection' &&!this.props.masterDataCollectionPartner){
          this.setState({
            offlineConsumerDataError : true
          })
          this.errorFields = this._addErrorFields(this.errorFields, key);
        }  
        if(this.props.masterDataInjection === 'customInjection'){
          if(!this.props.masterDataOtherCollectionPartner){
            this.setState({
              customDescriptionError : true
            })
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }else {
            const inputText = this.props.masterDataOtherCollectionPartner;
            const words = inputText.split(' ');
            // Ensure that the number of words doesn't exceed the limit
            if (words.length > 300) {
              this.errorFields = this._addErrorFields(this.errorFields, key);
             this.setState({
              customDescriptionWorldLimitError : true
             })
            }
          }
        }
        if(this.props.masterDataCollectionPartner === 'Other'){
          if(!this.props.masterDataOtherCollectionPartner){
            this.setState({
              customDescriptionError : true
            })
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }
    //validate campaign
    if (formElements['name']) {
      await this._validateCampaignName(formElements['name'], 'name');
    }

    if (!this.props.editCampaign && !this.props.isCampaignEdited) {
      if (
        document.getElementById(`prodUrl`) &&
        document.getElementById(`prodUrl`).value.trim().length === 0
      ) {
        let key = 'prodUrl';
        if (document.querySelector("[data-control='" + key + "']")) {
          document
            .querySelector("[data-control='" + key + "']")
            .classList.contains('cw-hidden') &&
            document
              .querySelector("[data-control='" + key + "']")
              .classList.remove('cw-hidden');

          this.hideValidationMessages('custom', 'prodUrl');
        }

        !document
          .getElementById(`prodUrl`)
          .classList.contains('cw-error-focus') &&
          document.getElementById(`prodUrl`).classList.add('cw-error-focus');
        this.errorFields = this._addErrorFields(this.errorFields, 'prodUrl');
      } else {
        if (
          constant.regex.prodUrl instanceof RegExp &&
          !constant.regex.prodUrl.test(this.state.prodUrl.trim())
        ) {
          document.getElementById(`prodUrl`) &&
            !document
              .getElementById(`prodUrl`)
              .classList.contains('cw-error-focus') &&
            document.getElementById(`prodUrl`).classList.add('cw-error-focus');
          this.showHideValidationMessages('custom', 'prodUrl');
        } else {
          this.hideValidationMessages('custom', 'prodUrl');
        }
      }
      if (this.state && this.state.nonProdUrl.length) {
        this.state.nonProdUrl.forEach((ele, index) => {
          if (ele.trim().length) {
            if (
              constant.regex.prodUrl instanceof RegExp &&
              !constant.regex.prodUrl.test(ele.trim())
            ) {
              document.getElementById(`add_non_prod_url_${index}`) &&
                (document
                  .getElementById(`add_non_prod_url_${index}`)
                  .classList.contains('non-prod-url-top') ||
                  document
                    .getElementById(`add_non_prod_url_${index}`)
                    .classList.add('non-prod-url-top'));

              document
                .getElementById(`non_prod_url_${index}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`non_prod_url_${index}`)
                  .classList.add('cw-error-focus');
              this.showHideValidationMessages(
                'custom',
                `non_prodUrl_valid_${index}`
              );
            } else {
              document.getElementById(`add_non_prod_url_${index}`) &&
                document
                  .getElementById(`add_non_prod_url_${index}`)
                  .classList.contains('non-prod-url-top') &&
                document
                  .getElementById(`add_non_prod_url_${index}`)
                  .classList.remove('non-prod-url-top');
              this.hideValidationMessages(
                'custom',
                `non_prodUrl_valid_${index}`
              );
            }
          }
          // else {
          //   document
          //   .getElementById(`non_prod_url_${index}`)
          //   .classList.contains("cw-error-focus") ||
          //   document
          //     .getElementById(`non_prod_url_${index}`)
          //     .classList.add("cw-error-focus");
          //   this.showHideValidationMessages('custom', `non_prod_url_${index}`);

          // }
        });
      }
    }
    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.setState({ showMessageAtTop: true });
      this._scrollToTop();
    }
  }
  hideValidationMessages(type, key) {
    if (key) {
      let control = type === 'original' ? 'data-control' : 'data-custom-msg';
      document.querySelector('[' + control + "='" + key + "']") &&
        document
          .querySelector('[' + control + "='" + key + "']")
          .classList.add('cw-hidden');
    }
  }
  showHideValidationMessages(type, key) {
    if (key) {
      let control = type === 'original' ? 'data-control' : 'data-custom-msg';
      if (
        document.querySelector('[' + control + "='" + key + "']") &&
        document
          .querySelector('[' + control + "='" + key + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector('[' + control + "='" + key + "']")
          .classList.remove('cw-hidden');
      }
      this.errorFields = this._addErrorFields(this.errorFields, key);
    }
  }
  _validateField(event) {
    if (event && event.hasOwnProperty('target')) {
      if (
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.contains('error')
      ) {
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.remove('error');
        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${event.target.name}`)
            .classList.add('cw-error-focus');
      }
      if (
        document.querySelector("[data-control='" + event.target.name + "']") &&
        !document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.add('cw-hidden');
        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      }
      if (
        document.querySelector(
          "[data-custom-msg='" + event.target.name + "']"
        ) &&
        !document
          .querySelector("[data-custom-msg='" + event.target.name + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-custom-msg='" + event.target.name + "']")
          .classList.add('cw-hidden');
        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      }

      if (event.target.name !== 'endDate') {
        if (
          !document
            .querySelector("[data-control='" + event.target.name + "']")
            .classList.contains('cw-hidden')
        ) {
          document
            .querySelector("[data-control='" + event.target.name + "']")
            .classList.add('cw-hidden');
          document
            .getElementById(`${event.target.name}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`${event.target.name}`)
              .classList.remove('cw-error-focus');
        }
      }
    } else {
      if (
        document.querySelector("[data-control='" + event + "']") &&
        !document
          .querySelector("[data-control='" + event + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + event + "']")
          .classList.add('cw-hidden');
      }

      if (
        document.querySelector("[data-custom-msg='" + event + "']") &&
        !document
          .querySelector("[data-custom-msg='" + event + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-custom-msg='" + event + "']")
          .classList.add('cw-hidden');
      }
    }
  }

  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }

  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _isValidStartDate(current) {
    let campaignStartDays =
      this.props.campaignType &&
      this.props.campaignType.toLowerCase() === 'incentive'
        ? '-1'
        : '-1';
    let countrySelected =
      this.props &&
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    let isEpsilonMarket =
      countrySelected && epsilonMarket(countrySelected) ? true : false;
    campaignStartDays = isEpsilonMarket ? '-1' : campaignStartDays;
    var yesterday = DateTimeField.moment().add(campaignStartDays, 'day');
    return current.isAfter(yesterday);
  }

  _visibleDate() {
    let campaignStartDays =
      this.props.campaignType &&
      this.props.campaignType.toLowerCase() === 'incentive'
        ? '-1'
        : '-1';
    var nextAvailableDate = moment().add(campaignStartDays, 'day');
    return nextAvailableDate;
  }

  _visibleDate() {
    let campaignStartDays =
      this.props.campaignType &&
      this.props.campaignType.toLowerCase() === 'incentive'
        ? '15'
        : '8';
    var nextAvailableDate = moment()
      .add(campaignStartDays, 'day')
      .utc();

    return nextAvailableDate;
  }

  _isValidEndDate(current) {
    return current.isAfter(this.state.startDate);
  }

  _cancelApproverEdit() {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  }

  _showCampaignLeadTimeMessage = campaignType => {
    let campignLeadTimeText = [];
    campignLeadTimeText.push(
      campaignType && campaignType.toLowerCase() === 'incentive'
        ? constant.SAAS_MESSAGES.campignLeadTimeText.incentiveCampaign
        : constant.SAAS_MESSAGES.campignLeadTimeText.directCampaign
    );
    return null; //THIS LINE HAS BEEN COMMENTED AS A PART OF WTG-11919 <Alert alertType="saasInfoMessage" textMessage={campignLeadTimeText} />
  };

  renderInput(props, openCalendar) {
    if (props.name === 'endDate' || props.name === 'endDateTime') {
      props.disabled = this.props.isAlwaysOn;
      props.value = this.props.isAlwaysOn ? '' : props.value;
    }
    return (
      <div className="input-group date" id="datePickerStart">
        <input {...props} autoComplete='off'/>
        <div className="input-group-append">
          <span
            className="input-group-text cw-icon cw-icon--cal"
            id="cw-icon--cal"
            onClick={!this.props.isAlwaysOn ? openCalendar : null}
          >
            <i className="fas fa-calendar-alt"></i>
          </span>
        </div>
      </div>
    );
  }

  onChangeRadio = event => {
    this.props.updateCampaignDetails({
      emailConfirmationFlag: !this.props.emailConfirmationFlag
    });
  };

  onChangeAutogenerateRadio = event => {
    this.props.updateCampaignDetails({
      autogenerate: !this.props.autogenerate
    });
  };

  _handleIncentiveVendorPartner(event) {
    let detailsToUpdate = {
      vendorPartner: event.target.value
    };
    this.vendorPartner.current = event.target.value;
    this.formElements['vendorPartner'] = event.target.value;
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    if (document.querySelector("[data-control='" + 'vendorPartner' + "']")) {
      if (
        !document
          .querySelector("[data-control='" + 'vendorPartner' + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + 'vendorPartner' + "']")
          .classList.add('cw-hidden');
      }
    }
  }

  _migrationFieldRequest(selectedOption) {
    document
      .getElementById('isMigrationRequestDiv')
      .classList.contains('cw-error-focus') &&
      document
        .getElementById('isMigrationRequestDiv')
        .classList.remove('cw-error-focus');

    document.getElementById('migrationFieldMessage').classList.add('cw-hidden');

    this.setState({
      migrationValue: selectedOption
    });
    let detailsToUpdate = {
      isMigrationRequest: selectedOption
    };
    this.formElements['isMigrationRequest'] = selectedOption;
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
  }

  _validIsMigrationRequest() {
    let isValid = true;
    if (
      this.props.campaign &&
      !this.props.campaign.isMasterData &&
      this.state.migrationValue == null && this.props.isMigrationRequest == null
    ) {
      document
        .getElementById('isMigrationRequestDiv')
        .classList.add('cw-error-focus');
      document
        .getElementById('migrationFieldMessage')
        .classList.remove('cw-hidden');
      isValid = false;
    }
    return isValid;
  }

  _handleCombinedValidation() {
    const isMigrationValid = this._validIsMigrationRequest();
    return isMigrationValid;
  }

  _handleOfflineOtherDescription(event) {
    let detailsToUpdate = {
      masterDataOtherCollectionPartner: event.target.value
    };
    this.formElements['masterDataOtherCollectionPartner'] = event.target.value;
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    this.setState({
      customDescriptionError : false
    })
  }

  _handleOfflineCustomDropdown(selectedOption) {
    let detailsToUpdate = {};
    this.formElements['masterDataInjection'] = selectedOption.value;
    detailsToUpdate['masterDataInjection'] = selectedOption.value;
    this.masterDataInjection.current =  selectedOption.value;
    this.formElements['masterDataCollectionPartner'] = ''
    detailsToUpdate['masterDataCollectionPartner'] = ''
    this.formElements['masterDataOtherCollectionPartner'] = ''
    detailsToUpdate['masterDataOtherCollectionPartner'] = ''
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    this.setState({
      offlineCustomInjectionError : false
    });
  }

  _handleConsumerDataDropdown(selectedOption) {
    let detailsToUpdate = {};
    this.formElements['masterDataCollectionPartner'] = selectedOption.value;
    detailsToUpdate['masterDataCollectionPartner'] = selectedOption.value;
    this.masterDataCollectionPartner.current =  selectedOption.value;
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
    this.setState({
      offlineConsumerDataError : false
    })
  }

  _handleCustomDescriptionChange(e) {
    this.masterDataOtherCollectionPartner.current = e.target.value;
    this.formElements['masterDataOtherCollectionPartner'] = e.target.value;
    this.props.updateCampaignDetails(this.formElements);
    this.props.editedCampaignDetails(this.formElements);
    this.setState({
      customDescriptionError : false
    })
    const inputText = e.target.value;
    const words = inputText.split(' ');
    // Ensure that the number of words doesn't exceed the limit
    if (words.length > 300) {
      this.setState({
        customDescriptionWorldLimitError : true
      })
    }else {
      this.setState({
        customDescriptionWorldLimitError : false
      })
    }
  }

  render() {
    let editCampaign = this.props.campaign.isEdited;
    let doubleOptinReqYes = 'yes';
    let doubleOptinReqNo = 'no';
    let {
      dpiaConfirmationFlag,
      isAlwaysOn,
      campaignType,
      emailConfirmationFlag,
      smsChannel,
      emailChannel,
      showEmailChannel,
      showSMSChannel,
      autogenerate,
      type,
      doubleOptinReq,
      country,
      redirectionUrl,
      campaign,
      campaignDetails
    } = this.props;

    let isCampaignTypeSASS =
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS') ||
      campaign.isPlatformAgnostic ||
      (campaign && campaign.isMasterData);

    let isStandAloneClassic =
      this.props.websiteType === 'Standalone' ||
      this.props.websiteType === 'Adobe Classic';

    let trackingRequired =
      campaignDetails && campaignDetails.isTrackingRequired;
    let marketCode = country && country.value.split('-')[0];
    let allowedMarketsForDoubleOptin = constant.allowedMarketsForDoubleOptin;
    let isMarketSupported = checkMarketSupportedForDoubleOptin(
      marketCode,
      allowedMarketsForDoubleOptin
    );

    if (isMarketSupported && doubleOptinReq === undefined) {
      this.props.updateCampaignDetails({
        doubleOptinReq: doubleOptinReqYes
      });
    }
    if (doubleOptinReq === 'no') {
      this.props.updateCampaignDetails({
        redirectionUrl: null
      });
    }

    //  let isQnA = type && type === "Sign-up with Questions & Answers";
    // isStandAloneClassic =
    //  this.props.websiteType === "Standalone" ||
    //  this.props.websiteType === "Adobe Classic"

    let isWebsiteTypeAEM =
      (this.props.websiteType === 'Standalone' ||
        this.props.websiteType === 'Adobe Classic' ||
        this.props.websiteType === 'Adobe Templated') &&
      this.props.autogenerate;

    let showAutogenerateOption = classNames({
      'cw-hidden': !isWebsiteTypeAEM
    });

    let dipaClass = classNames({
      'ml-4': true,
      'cw-subCategory': true,
      'cw-hidden': !dpiaConfirmationFlag
    });

    let emailClass = classNames({
      'ml-4': true,
      'cw-subCategory': true,
      'cw-hidden': !emailConfirmationFlag
    });

    let smsClass = classNames({
      'ml-4': true,
      'cw-subCategory': true,
      'cw-hidden': !smsChannel
    });

    let isTrackingRequired =
      this.props.isTrackingRequired === undefined
        ? true
        : this.props.isTrackingRequired;
    let isCustomTrackingId =
      this.props.isCustomTrackingId === undefined
        ? false
        : this.props.isCustomTrackingId;

    let offlineInjection =
      this.props.offlineInjection === undefined
        ? false
        : this.props.offlineInjection;

    let customInjection =
      this.props.customInjection === undefined
        ? false
        : this.props.customInjection;

    let customCampaignId =
      this.state.customCampaignId !== undefined
        ? this.state.customCampaignId
        : this.props.campaign.campaignId === null
        ? ''
        : this.props.campaign.campaignId;
    let isCampaignIncentive = campaignType === 'Incentive' ? true : false;
    let bothChannelSelected =
      this.props.emailChannel && this.props.smsChannel & isTrackingRequired;
    let channelMessageClass = classNames({
      'cw-hidden': !bothChannelSelected
    });

    let isMarketSupportedClass = classNames({
      'cw-hidden': !isMarketSupported
    });

    let defaultDoubleOptins =
      (doubleOptinReq === undefined && isMarketSupported) ||
      (doubleOptinReq && isMarketSupported)
        ? doubleOptinReqYes
        : !doubleOptinReq ||
          !isMarketSupported ||
          (!doubleOptinReq && !isMarketSupported)
        ? doubleOptinReqNo
        : doubleOptinReqYes;

    let isDoubleOptinsReq = classNames({
      'cw-hidden':
        doubleOptinReq === doubleOptinReqNo ||
        defaultDoubleOptins === doubleOptinReqNo
    });

    let redirectionUrlHtmlWithValidation = (
      <input
        type="text"
        className="form-control"
        name="redirectionUrl"
        id="redirectionUrl"
        ref="redirectionUrl"
        data-required="true"
        data-custom-error="true"
        data-custom-error-type="url"
        aria-describedby="redirectionUrl"
        value={this.props.redirectionUrl || ''}
        onChange={this._handleInputChange}
        onBlur={this._handleInputOnBlur}
      />
    );
    let redirectionUrlHtmlWithOutValidation = (
      <input
        type="text"
        className="form-control"
        name="redirectionUrl"
        id="redirectionUrl"
        ref="redirectionUrl"
        data-custom-error="true"
        data-custom-error-type="url"
        aria-describedby="redirectionUrl"
        value={this.props.redirectionUrl || ''}
        onChange={this._handleInputChange}
        onBlur={this._handleInputOnBlur}
      />
    );
    let isWebsiteTypeAdobeSaaS =
      this.props.isPlatformAgnostic || this.props.websiteType === 'Adobe SaaS';
    editCampaign = editCampaign && isWebsiteTypeAdobeSaaS;
    let countrySelected =
      this.props &&
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;

    let isEpsilonMarket =
      countrySelected && epsilonMarket(countrySelected) ? true : false;

    return (
      <div className="col-sm-12">
        <Modal
          scrollable={true}
          isOpen={this.state.isCancelModalOpen}
          onRequestClose={this._closeIsCancelModal}
          className="cw-modal cw-modal--branddetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <CampaignCancelModel
            closeIsCancelModal={this._closeIsCancelModal}
            isCancelContinue={this._cancelSaasCampaign}
            data={[]}
          />
        </Modal>
        <div className="cw-section ml-20">
          <BreadCrumb
            match={this.state.matchProps}
            editCampaign={
              this.props.editCampaign || this.props.isCampaignEdited
            }
            hideBreadCrumb={this.props.approverEditCampaign || editCampaign}
            campaignType={campaignType}
          />

          <div
            className={
              this.state.showMessageAtTop
                ? 'alert alert-danger'
                : 'alert alert-danger cw-hidden'
            }
            role="alert"
          >
            <strong>
              There was a problem with the page. Errors are listed below{' '}
            </strong>
          </div>
          {/* {this.props.serviceError ? <Error errorMessageList={this.props.serviceErrorMessage} /> : null} */}

          <div className="cw-section--title mb-20">
            <h2 className="cw-heading--secondary mb-30">Campaign Details</h2>
            <p className="cw-heading--saascampaign">
              Tell us about your campaign
            </p>
          </div>
          <div className="row">
            <div className="col-sm-7">
              <div className="cw-section--content">
                <form className="cw-form" onSubmit={this._handleSubmit}>
                  {/* Campaign name */}
                  <div className="form-group">
                    <label htmlFor="name">
                      <span>Campaign Name</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      maxLength="50"
                      ref="name"
                      disabled={editCampaign}
                      aria-describedby="campaignnamehelp"
                      data-required="true"
                      value={this.props.name || ''}
                      onChange={this._handleInputChange}
                      onBlur={this._validateCampaignNameField}
                    />
                    <span className="cw-error cw-hidden" data-control="name">
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter campaign name.
                    </span>
                    <span className="cw-error cw-hidden" data-custom-msg="name">
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Sorry this campaign name is already taken, please try
                      again.
                    </span>
                  </div>

                  {/* Campaign description */}

                  <div className="form-group">
                    <label htmlFor="description">
                      <span>Campaign Description</span>
                      <ReactTooltip
                        id={'campaignDescription'}
                        place="right"
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-for="campaignDescription"
                        data-tip="Description of the purpose of the campaign (what will this campaign deliver), provide high level expectations of number of consumers you hope to contact in one go? 5K to 10K emails or drip feed communications, if you require an automatic welcome/or thank you email on sign up."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      name="description"
                      id="description"
                      ref="description"
                      rows="3"
                      maxLength="500"
                      data-required="true"
                      value={this.props.description || ''}
                      onChange={this._handleInputChange}
                      onBlur={this._handleInputOnBlur}
                    ></textarea>
                    <span
                      className="cw-error cw-hidden"
                      data-control="description"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter campaign description.
                    </span>
                  </div>
                  {/* Why do you need master data? */}
                  {this.props.isMasterData ? (
                    <>
                      <div className="form-group mt-10 ">
                        <label htmlFor="masterDataInjection">
                          <span>Why do you need master data?</span>
                        </label>
                       
                          <Select
                            className={classNames('cw-select--custom', {
                              "cw-error-focus" :this.state.offlineCustomInjectionError
                            })}
                            name={'promotigoVendor'}
                            label={'promotigoVendor'}
                            placeholder={'Please select'}
                            options={this.state.OfflineCustomOptions}
                            onChange={this._handleOfflineCustomDropdown}
                            ref="masterDataInjection"
                            value={
                              this.props &&
                              this.props.masterDataInjection != null &&
                              this.state.OfflineCustomOptions.find(
                                e =>
                                  e.value == this.props.masterDataInjection
                              )
                            }
                          />
                        <span
                          className={classNames('cw-error',{
                            'cw-hidden' : !this.state.offlineCustomInjectionError
                          })}
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please select a reason for master data request.
                        </span>
                      </div>
                      {this.props.masterDataInjection ===
                      'offlineInjection' ? (
                        <div className="ml-33 pt-0 cw-promotigosubCategory">
                          <div className="form-group mt-10 ">
                            <label htmlFor="offlineData">
                              <span>
                                Where was this consumer data collected?
                              </span>
                            </label>
                              <Select
                                 className={classNames('cw-select--custom', {
                                  "cw-error-focus" :this.state.offlineConsumerDataError
                                })}
                                name={'offlineInectionOtion'}
                                label={'offlineInectionOtion'}
                                placeholder={'Please select'}
                                ref={"masterDataCollectionPartner"}
                                options={this.state.offlineInectionOtion}
                                onChange={this._handleConsumerDataDropdown}
                                value={
                                  this.props &&
                                  this.props.masterDataCollectionPartner != null &&
                                  this.state.offlineInectionOtion.find(
                                    e =>
                                      e.value == this.props.masterDataCollectionPartner
                                  )
                                }
                              />
                            <span
                               className={classNames('cw-error',{
                                'cw-hidden' : !this.state.offlineConsumerDataError
                              })}
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please specify the source of consumer data.
                            </span>
                          </div>
                          {this.props.masterDataCollectionPartner &&
                          this.props.masterDataCollectionPartner === 'Other' ? (
                            <div className="form-group">
                              <label htmlFor="customOtherDescription">
                                <span>
                                Please specify
                                </span>
                              </label>
                              <div  className={classNames('cw-select--custom', {
                                  "cw-error-focus" :this.state.customDescriptionError
                                })}>
                              <input
                                type="text"
                                className="form-control"
                                name="customOtherDescription"
                                id="customOtherDescription"
                                maxLength="50"
                                ref="masterDataOtherCollectionPartner"
                                aria-describedby="campaignnamehelp"
                                data-required="true"
                                value={this.props.masterDataOtherCollectionPartner || ''}
                                onChange={this._handleOfflineOtherDescription}
                              />
                              </div>
                              <span
                                 className={classNames('cw-error',{
                                  'cw-hidden' : !this.state.customDescriptionError
                                })}
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please specify the source of consumer data.
                              </span>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        ''
                      )}
                      {this.props.masterDataInjection ===
                      'customInjection' ? (
                        <>
                          <div className="mb-10">
                            <label htmlFor="customeDescription">
                              <span>
                                Please explain the experience of the custom form
                                or integration you are building
                              </span>
                            </label>
                            <div  className={classNames('cw-select--custom', {
                                  "cw-error-focus" :this.state.customDescriptionError || this.state.customDescriptionWorldLimitError
                                })}>
                            <textarea
                              className={'form-control'}
                              name="customeDescription"
                              id="customeDescription"
                              ref="masterDataOtherCollectionPartner"
                              onChange={this._handleCustomDescriptionChange}
                              rows="3"
                              value={this.props.masterDataOtherCollectionPartner}
                            ></textarea>
                            </div>
                            <span
                              className={classNames('cw-error',{
                                'cw-hidden' : !this.state.customDescriptionError
                              })}
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Kindly provide details about your experience.
                            </span>
                            <span
                              className={classNames('cw-error',{
                                'cw-hidden' : !this.state.customDescriptionWorldLimitError
                              })}
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter max 300 words.
                            </span>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      {/* <div className="form-check mb-30">
                        <span className="mt-10">
                          Why do you need master data?
                        </span>

                        <div className="form-check mb-10 mt-10">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="offlineInjection"
                            name="offlineInjection"
                            ref="offlineInjection"
                            value={true}
                            disabled={editCampaign}
                            checked={
                              //isTrackingRequired && !isCustomTrackingId
                              // offlineInjection
                              // this.formElements['offlineInjection']
                              offlineInjection && !customInjection
                            }
                            onChange={this._handleMasterDataChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="offlineInjection"
                          >
                            <p>
                              Offline ingestion (from another system to GIGYA)
                            </p>
                          </label>
                        </div>
                        <div className="form-check mb-10">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="customInjection"
                            name="customInjection"
                            ref="customInjection"
                            value={true}
                            disabled={editCampaign}
                            checked={
                              //!isTrackingRequired && !isCustomTrackingId
                              !offlineInjection && customInjection
                              //customInjection
                              //this.formElements['customInjection']
                            }
                            onClick={this._handleMasterDataChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customInjection"
                          >
                            <p>Build a custom form or a custom integration</p>
                          </label>
                        </div>
                        <span
                          className="cw-error cw-hidden"
                          data-control="masterDataInjection"
                          id="masterDataInjection"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please select reason of master data request.
                        </span>
                      </div> */}
                    </>
                  ) : null}
                  {
                    <>
                      {/* Campaign dates and times */}
                      <div className="cw-form--legend mb-15">
                        <p className="cw--tertiary mb-15">
                          Campaign Start and End Date
                        </p>
                        <strong>
                          {isEpsilonMarket
                            ? null
                            : this._showCampaignLeadTimeMessage(
                                this.props.campaignType
                              )}
                        </strong>
                        {/* Start Date & Time */}
                        <div className="form-group">
                          <label htmlFor="startDate">
                            Start Date & Time (UTC)
                          </label>

                          {this.props.editCampaign ||
                          this.props.isCampaignEdited ? (
                            <DateTimeField
                              inputProps={{
                                name: 'startDate',
                                id: 'startDate',
                                className: 'form-control',
                                'aria-describedby': 'startDateandtime',
                                placeholder: this.props.startDate
                                  ? getNewFormatedDate(this.props.startDate)
                                  : 'Select a start date and time',
                                'aria-label': 'Select a start date and time',
                                'data-required': 'true'
                              }}
                              dateFormat={constants.FORMAT_DATE_DD_MM_YY}
                              timeFormat={false}
                              ref="startDate"
                              utc={true}
                              // value={moment(this.props.endDate ? this.props.endDate : "").format("DD/MM/YYYY HH:mm A zz")}
                              onChange={this._handlestartDate}
                              onkeyDown="return false"
                              renderInput={this.renderInput}
                            />
                          ) : (
                            <DateTimeField
                              inputProps={{
                                name: 'startDate',
                                id: 'startDate',
                                className: 'form-control',
                                'aria-describedby': 'startDateandtime',
                                placeholder: this.props.startDate
                                  ? getNewFormatedDate(this.props.startDate)
                                  : 'Select a start date and time',
                                'aria-label': 'Select a start date and time',
                                'data-required': 'true'
                              }}
                              dateFormat={constants.FORMAT_DATE_DD_MM_YY}
                              timeFormat={false}
                              ref="startDate"
                              viewDate={this._visibleDate()}
                              isValidDate={this._isValidStartDate}
                              utc={true}
                              // value={moment(this.props.endDate ? this.props.endDate : "").format("DD/MM/YYYY HH:mm A zz")}
                              onChange={this._handlestartDate}
                              onkeyDown="return false"
                              renderInput={this.renderInput}
                            />
                          )}

                          <span
                            className="cw-error cw-hidden"
                            data-control="startDate"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a start date &amp; time.
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="startDate"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Start date should be greater than current datetime.
                          </span>
                        </div>
                        <div className="form-group mb-20">
                          <label htmlFor="endDate">End Date (UTC)</label>

                          {this.props.isAlwaysOn ? (
                            <>
                              <DateTimeField
                                inputProps={{
                                  name: 'endDate',
                                  id: 'endDate',
                                  className: 'form-control',
                                  'aria-describedby': 'endDate',
                                  placeholder:
                                    !this.props.isAlwaysOn && this.props.endDate
                                      ? getNewFormatedDate(this.props.endDate)
                                      : 'Select an end date',
                                  'aria-label': 'Select an end date',
                                  'data-required': 'true'
                                }}
                                dateFormat={constants.FORMAT_DATE_DD_MM_YY}
                                timeFormat={false}
                                ref="endDate"
                                isValidDate={this._isValidEndDate}
                                viewDate={this._visibleDate()}
                                utc={true}
                                // value={moment(this.props.endDate ? this.props.endDate : "").format("DD/MM/YYYY HH:mm A zz")}
                                onChange={this._handleendDate}
                                renderInput={this.renderInput}
                              />
                              <span
                                className="cw-error cw-hidden"
                                data-control="endDate"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter an end date
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg="endDate"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                End date should be greater than start date.
                              </span>
                            </>
                          ) : (
                            <>
                              {this.props.editCampaign ||
                              this.props.isCampaignEdited ? (
                                <DateTimeField
                                  inputProps={{
                                    name: 'endDate',
                                    id: 'endDate',
                                    className: 'form-control',
                                    'aria-describedby': 'endDate',
                                    placeholder: this.props.endDate
                                      ? getNewFormatedDate(this.props.endDate)
                                      : 'Select an end date',
                                    'aria-label': 'Select an end date',
                                    'data-required': 'true'
                                  }}
                                  dateFormat={constants.FORMAT_DATE_DD_MM_YY}
                                  timeFormat={false}
                                  ref="endDate"
                                  // viewDate={this._visibleDate()}
                                  // isValidDate={this._isValidStartDate}
                                  utc={true}
                                  // value={moment(this.props.endDate ? this.props.endDate : "").format("DD/MM/YYYY HH:mm A zz")}
                                  onChange={this._handleendDate}
                                  renderInput={this.renderInput}
                                />
                              ) : (
                                
                                <DateTimeField
                                  inputProps={{
                                    name: 'endDate',
                                    id: 'endDate',
                                    className: 'form-control',
                                    'aria-describedby': 'endDate',
                                    placeholder: this.props.endDate
                                      ? getNewFormatedDate(this.props.endDate)
                                      : 'Select an end date',
                                    'aria-label': 'Select an end date',
                                    'data-required': 'true'
                                  }}
                                  dateFormat={constants.FORMAT_DATE_DD_MM_YY}
                                  timeFormat={false}
                                  ref="endDate"
                                  viewDate={this._visibleDate()}
                                  isValidDate={this._isValidStartDate}
                                  utc={true}
                                  // value={moment(this.props.endDate ? this.props.endDate : "").format("DD/MM/YYYY HH:mm A zz")}
                                  onChange={this._handleendDate}
                                  renderInput={this.renderInput}
                                />
                                
                                
                              )}
                              <span
                                className="cw-error cw-hidden"
                                data-control="endDate"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter an end date.
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg="endDate"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                End date should be greater than start date.
                              </span>
                            </>
                          )}
                        </div>
                        <div className="form-group mb-20">
                          <label htmlFor="endDateTime">End Time (UTC)</label>

                          {this.props.isAlwaysOn ? (
                            <>
                              <DateTimeField
                                  inputProps={{
                                    name: 'endDateTime',
                                    id: 'endDateTime',
                                    className: 'form-control',
                                    'aria-describedby': 'endDateTime',
                                    placeholder:
                                    !this.props.isAlwaysOn && this.props.endDateTime
                                      ? getNewFormatedTime(this.props.endDateTime)
                                      : 'Select an end time',
                                    'aria-label': 'Select an end time',
                                    'data-required': 'true'
                                  }}
                                  dateFormat={false}
                                  timeFormat={"hh:mm A"}
                                  ref="endDateTime"
                                  utc={true}
                                  //value={this.props.endDateTime}
                                  // value={moment(this.props.endDate ? this.props.endDate : "").format("DD/MM/YYYY HH:mm A zz")}
                                  onChange={this._handleendTime}
                                  renderInput={this.renderInput}
                                />
                              <span
                                className="cw-error cw-hidden"
                                data-control="endDateTime"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter an end time.
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg="endDateTime"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                End date should be greater than start date.
                              </span>
                            </>
                          ) : (
                            <>
                              {this.props.editCampaign ||
                              this.props.isCampaignEdited ? (
                                <DateTimeField
                                  inputProps={{
                                    name: 'endDateTime',
                                    id: 'endDateTime',
                                    className: 'form-control',
                                    'aria-describedby': 'endDateTime',
                                    placeholder: this.props.endDateTime
                                      ? getNewFormatedTime(this.props.endDateTime)
                                      : 'Select an end time',
                                    'aria-label': 'Select an end time',
                                    'data-required': 'true'
                                  }}
                                  dateFormat={false}
                                  timeFormat={"hh:mm A"}
                                  ref="endDateTime"
                                  utc={true}
                                  //value={this.props.endDateTime}
                                  value={moment.utc(this.props.endDateTime ? this.props.endDateTime : "")}
                                  onChange={this._handleendTime}
                                  renderInput={this.renderInput}
                                />
                              ) : (
                                <DateTimeField
                                  inputProps={{
                                    name: 'endDateTime',
                                    id: 'endDateTime',
                                    className: 'form-control',
                                    'aria-describedby': 'endDateTime',
                                    placeholder: this.props.endDateTime
                                      ? getNewFormatedTime(this.props.endDateTime)
                                      : 'Select an end time',
                                    'aria-label': 'Select an end time',
                                    'data-required': 'true'
                                  }}
                                  dateFormat={false}
                                  timeFormat={"hh:mm A"}
                                  ref="endDateTime"
                                  utc={true}
                                  //value={this.props.endDateTime}
                                  value={moment.utc(this.props.endDateTime ? this.props.endDateTime : "")}
                                  onChange={this._handleendTime}
                                  renderInput={this.renderInput}
                                />
                              )}
                              <span
                                className="cw-error cw-hidden"
                                data-control="endDateTime"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter an end time.
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg="endDateTime"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                End date should be greater than start date.
                              </span>
                            </>
                          )}
                        </div>
                        {this.props.campaignType &&
                        this.props.campaignType.toLowerCase() === 'incentive' &&
                        this.props.incentiveType &&
                        this.props.incentiveType.toLowerCase() ===
                          'product samples' ? (
                          <>
                            <div className="form-check mb-30">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={isAlwaysOn ? 'true' : 'false'}
                                id="isAlwaysOn"
                                ref="isAlwaysOn"
                                name="isAlwaysOn"
                                checked={isAlwaysOn || ''}
                                onChange={this._handleInputChange}
                                onBlur={this._handleInputOnBlur}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isAlwaysOn"
                              >
                                No end date, make it an always on campaign
                              </label>
                            </div>
                          </>
                        ) : null}
                        {this.props.campaignType &&
                        this.props.campaignType.toLowerCase() ===
                          'direct messaging' ? (
                          <div className="form-check mb-30">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={isAlwaysOn ? 'true' : 'false'}
                              id="isAlwaysOn"
                              ref="isAlwaysOn"
                              name="isAlwaysOn"
                              checked={isAlwaysOn || ''}
                              onChange={this._handleInputChange}
                              onBlur={this._handleInputOnBlur}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isAlwaysOn"
                            >
                              No end date, make it an always on campaign
                            </label>
                          </div>
                        ) : null}
                        {isCampaignTypeSASS &&
                        this.props.campaign &&
                        !this.props.campaign.isMasterData ? (
                          <div className="form-group">
                            <label htmlFor="isMigrationRequest">
                              <span>Is this a migration request?</span>
                              <ReactTooltip
                                id={'isMigrationRequest'}
                                place="right"
                                type="info"
                                multiline={true}
                                className="cw-tooltip custom-cw-toolip"
                              />
                              <span
                                className="float-right  cw-icon cw-icon--help"
                                data-for="isMigrationRequest"
                                data-tip="Migration implies that the form currently exists on a brand website but is now being migrated to a new AEM SaaS website"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </label>
                            <div id={'isMigrationRequestDiv'}>
                              <Select
                                className={'cw-select--custom'}
                                name={'migrationFieldRequest'}
                                label={'migrationValue'}
                                placeholder={'Please select'}
                                options={this.state.migrationOption}
                                onChange={this._migrationFieldRequest}
                                value={
                                  this.props &&
                                  this.props.isMigrationRequest != null &&
                                  this.state.migrationOption.find(
                                    e =>
                                      e.value == this.props.isMigrationRequest
                                  )
                                }
                                isDisabled={
                                  this.props.editCampaign ||
                                  this.props.isCampaignEdited
                                }
                              />
                            </div>
                            <span
                              id="migrationFieldMessage"
                              className="cw-error cw-hidden"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please select your answer
                            </span>
                          </div>
                        ) : null}

                        {!isEpsilonMarket ? (
                          <div className="form-check mb-30 mt-30">
                            <p className="cw-heading--saascampaign mb-20">
                              Campaign Tracking
                            </p>
                            {/* <p className="mb-10">
                              Do you want to identify or segment consumers who
                              sign-up with this campaign?
                            </p> */}
                            {constant.featureFlags
                              .ENABLE_CAMPAIGN_ID_GOVERNANCE && editCampaign ? (
                              <>
                                <span className="mb-10">
                                  {`${this.props.campaignConfig.LABEL_SIGN_UP_ID} : `}
                                </span>
                                <span className="mb-10">
                                  {(this.props.campaign &&
                                    this.props.campaign.campaignId) ||
                                    ''}
                                </span>
                              </>
                            ) : (
                              <>
                                {constant.featureFlags
                                  .ENABLE_CAMPAIGN_ID_GOVERNANCE ? (
                                  <>
                                    <ReactTooltip
                                      id="acquisitionIdToolTip"
                                      place="right"
                                      type="info"
                                      multiline={true}
                                      className="cw-toolip custom-cw-toolip"
                                    />

                                    <label className="form-camapaign-tracking-acquisition mb-20">
                                      <p>
                                        {`You will receive a unique `}
                                        <span
                                          data-tip={`Campaign IDs are now known as ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                                          currentitem="false"
                                          data-for="acquisitionIdToolTip"
                                        >
                                          <span className="acquisition-text">{`${this.props.campaignConfig.LABEL_SIGN_UP_ID}`}</span>
                                        </span>
                                         to segment consumers who sign up
                                        through this initiative
                                      </p>
                                    </label>
                                    <Alert
                                      alertType="warningMessageWithIcon"
                                      textMessage={`We will no longer support the use of generic ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s or reuse of existing IDs for any new data acquisition requests. This will not affect any existing sign up requests you many have created in the past.`}
                                      showComponent={true}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="form-check mb-10">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="requireTracking_yes"
                                        name="isTrackingRequired"
                                        ref="isTrackingRequired"
                                        value={true}
                                        disabled={editCampaign}
                                        checked={
                                          isTrackingRequired &&
                                          !isCustomTrackingId
                                        }
                                        onChange={this._handleInputChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="requireTracking_yes"
                                      >
                                        <p>Yes, I need a unique Campaign ID</p>
                                      </label>
                                    </div>
                                    <div className="form-check mb-10">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="requireTracking_no"
                                        name="isTrackingRequired"
                                        ref="isTrackingRequired"
                                        value={false}
                                        disabled={editCampaign}
                                        checked={
                                          !isTrackingRequired &&
                                          !isCustomTrackingId
                                        }
                                        onClick={this._handleInputChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="requireTracking_no"
                                      >
                                        <p>No, I need a generic Campaign ID</p>
                                      </label>
                                    </div>
                                    <div className="form-check mb-20">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="isCustomTrackingId"
                                        name="isCustomTrackingId"
                                        ref="isCustomTrackingId"
                                        value={true}
                                        disabled={editCampaign}
                                        checked={isCustomTrackingId}
                                        onClick={this._handleInputChange}
                                        onBlur={this._handleInputOnBlur}
                                      />
                                      <label
                                        className="form-check-label d-block"
                                        htmlFor="isCustomTrackingId"
                                      >
                                        <p>
                                          I want reuse an existing campaign ID
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="An existing Campaign ID could be reused in instances like migrating a form, creating a new like to like, segmenting consumers from multiple data capture touchpoints together via the same ID. Please ensure a valid ID received previously from Campaign Wizard or Consumer data team"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </p>
                                      </label>
                                    </div>{' '}
                                  </>
                                )}
                              </>
                            )}
                            {isCustomTrackingId &&
                            !constant.featureFlags
                              .ENABLE_CAMPAIGN_ID_GOVERNANCE ? (
                              <div className="form-group">
                                <ReactTooltip
                                  place="right"
                                  type="info"
                                  multiline={true}
                                  className="cw-tooltip"
                                />
                                <label htmlFor="customCampaignId">
                                  <span>Specify Campaign ID to reuse</span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="Please ensure a valid ID received previously from Campaign Wizard or Consumer data team"
                                    currentitem="false"
                                  >
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="customCampaignId"
                                  id="customCampaignId"
                                  maxLength="50"
                                  ref="customCampaignId"
                                  data-custom-error="true"
                                  data-custom-error-type="customCampaignId"
                                  value={customCampaignId}
                                  disabled={editCampaign}
                                  onChange={this._customCampaignIdChange}
                                />
                                <span
                                  className="cw-error cw-hidden"
                                  data-custom-msg="customCampaignId"
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter a valid Campaign ID (example
                                  PN123456)
                                </span>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {campaignDetails &&
                        !campaignDetails.campaignProductionUrl &&
                        (this.props.editCampaign ||
                          this.props.isCampaignEdited) ? (
                          ''
                        ) : (
                          <div className="form-check mb-30">
                            <label>
                              <span className="cw-heading--saascampaign mb-10">
                                Website URLs
                              </span>
                              <p className="mb-10">
                                We will whitelist your website(s) to send
                                consumer data to our profile stores.
                              </p>
                              {!isStandAloneClassic ? (
                                <>
                                  <ReactTooltip
                                    id="website_url"
                                    place="right"
                                    type="info"
                                    multiline={true}
                                    className="cw-toolip custom-cw-toolip"
                                    style={{ width: '70% !important' }}
                                  />
                                  <span
                                    class="float-right cw-icon cw-icon--help"
                                    data-for="website_url"
                                    data-tip={
                                      constants.WEBSITE_URL_TOOLTIP_TEXT
                                    }
                                    currentitem="false"
                                  >
                                    <i class="fas fa-question-circle"></i>
                                  </span>
                                </>
                              ) : null}
                            </label>

                            <label htmlFor="prodUrl">
                              <span>Live website Prod URL</span>
                            </label>
                            <input
                              type="text"
                              className="form-control mb-10"
                              name="prodUrl"
                              id="prodUrl"
                              ref="prodUrl"
                              maxLength="260"
                              aria-describedby="prod_urlhelp"
                              data-required="true"
                              defaultValue={this.state.prodUrl}
                              onChange={this._handleProdUrl}
                              disabled={
                                this.props.editCampaign ||
                                this.props.isCampaignEdited ||
                                constant.featureFlags
                                  .INFRA_AUTO_PROVISIONING_DEFAULT_URL_DISABLE
                              }
                            />
                            <span
                              className="cw-error cw-hidden mb-10"
                              data-control="prodUrl"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter website production URL
                            </span>
                            <span
                              className="cw-error cw-hidden mb-10"
                              data-custom-msg="prodUrl"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter a valid URL
                            </span>

                            <label htmlFor="nonProdUrl">
                              <span>Website Non-Prod URL (Add upto 3)</span>
                            </label>
                            {this.state.nonProdUrl.map((ele, index) => {
                              return (
                                <div className="nonProdUrl-field">
                                  <input
                                    type="text"
                                    className="form-control mb-10"
                                    name={`non_prod_url_${index}`}
                                    id={`non_prod_url_${index}`}
                                    ref={`non_prod_url_${index}`}
                                    maxLength="260"
                                    aria-describedby="campaignnamehelp"
                                    data-required="true"
                                    placeholder="Optional"
                                    value={this.state.nonProdUrl[index]}
                                    onChange={e =>
                                      this._handleNonProdUrl(e, index)
                                    }
                                    disabled={
                                      this.props.editCampaign ||
                                      this.props.isCampaignEdited ||
                                      constant.featureFlags
                                        .INFRA_AUTO_PROVISIONING_DEFAULT_URL_DISABLE
                                    }
                                  />
                                  {index <=
                                    (this.state &&
                                      this.state.nonProdUrl &&
                                      this.state.nonProdUrl.length - 2) &&
                                  this.state.nonProdUrl.length > 1 ? (
                                    <span
                                      className="saas-accordion-close non-prod-url"
                                      data-index={index}
                                      style={{
                                        pointerEvents:
                                          this.props.editCampaign ||
                                          this.props.isCampaignEdited
                                            ? 'none'
                                            : 'auto'
                                      }}
                                      onClick={e =>
                                        this.deleteNonProdUrl(e, index)
                                      }
                                      disabled={
                                        this.props.editCampaign ||
                                        this.props.isCampaignEdited
                                      }
                                    ></span>
                                  ) : null}
                                  <span
                                    className="cw-error cw-hidden mb-10"
                                    data-custom-msg={`non_prod_url_${index}`}
                                  >
                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                    Please enter website non production URL
                                  </span>
                                  <span
                                    className="cw-error cw-hidden mb-10"
                                    data-custom-msg={`non_prodUrl_valid_${index}`}
                                  >
                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                    Please enter a valid URL
                                  </span>
                                  {index ==
                                    (this.state.nonProdUrl &&
                                      this.state.nonProdUrl.length - 1) &&
                                  !constant.featureFlags
                                    .INFRA_AUTO_PROVISIONING_DEFAULT_URL_DISABLE ? (
                                    <span
                                      id={`add_non_prod_url_${index}`}
                                      className={'manage-non-prod-url'}
                                      style={{
                                        pointerEvents:
                                          (this.state &&
                                            this.state.nonProdUrl &&
                                            this.state.nonProdUrl.length ==
                                              3) ||
                                          this.props.editCampaign ||
                                          this.props.isCampaignEdited
                                            ? 'none'
                                            : 'auto'
                                      }}
                                      onClick={e => this.addNonProdUrl()}
                                    ></span>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  }
                  {/* DPC/PRA confirmation */}
                  <div className="cw-form--legend mb-40">
                    <p className="cw-heading--saascampaign mb-3">
                      Privacy Risk Assessment Confirmation
                    </p>
                    {/* <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="dpiaConfirmationFlag"
                        id="dpiaConfirmationFlag"
                        ref="dpiaConfirmationFlag"
                        data-required="true"
                        disabled={editCampaign}
                        value={dpiaConfirmationFlag ? 'true' : 'false'}
                        checked={dpiaConfirmationFlag || ''}
                        onChange={this._handleInputChange}
                      />
                      <label
                        className="form-check-label d-block"
                        htmlFor="dpiaConfirmationFlag"
                      >
                        <span>Confirm your campaign has DPC/PRA approval.</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Privacy Risk Assessment or Data Privacy Checklist- You’ll need to get a DPC/PRA ID by raising a kana ticket. Not sure? Reach out to your local data protection officer (DPO)."
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      TODO: Handle DOM selector for checkboxes in ValidateForm method 
                      <span
                        className="cw-error cw-hidden"
                        data-control="dpiaConfirmationFlag"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Confirm your campaign has DPC/PRA approval
                      </span>
                    </div> */}
                    <div className="mb-10">
                      <span>
                        Confirm you have completed privacy risk assessment.
                      </span>
                      <ReactTooltip
                        id={'dpc_pra_tooltip'}
                        place="right"
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-for="dpc_pra_tooltip"
                        data-tip="You need to have completed the privacy risk assessment in Scout before you begin collecting consumer data. Enter your PRA ID from Scout here to confirm. Please ensure you enter an authentic ID as your campaign requests may be subject to audit by Unilever's privacy teams."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </div>
                    <div>
                      <div className="form-group">
                        <label htmlFor="dpiaReferenceNo">
                          <span>PRA ID</span>
                          <span className="float-right ">
                            <a
                              className="cw-qna-faq-link"
                              href={constant.PRA_LEARN_MORE_LINK}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {'Learn more on PRA'} &nbsp;
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="dpiaReferenceNo"
                          id="dpiaReferenceNo"
                          ref="dpiaReferenceNo"
                          disabled={editCampaign}
                          aria-describedby="dpiaReferenceNo"
                          data-required="true"
                          onChange={this._handlePraIdChange}
                          //  onBlur={this._handleInputOnBlur}
                          value={this.props.dpiaReferenceNo || ''}
                          maxLength={'5'}
                        />
                        <span
                          className="cw-error cw-hidden"
                          data-control="dpiaReferenceNo"
                          id="dpiaReferenceNo_err"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please enter a valid PRA ID from your Scout
                          assessment.
                        </span>
                      </div>
                    </div>
                  </div>
                  {!isWebsiteTypeAdobeSaaS &&
                  constant.featureFlags.ENABLE_AEM_FORM_OPTION ? (
                    <>
                      {/* Email & SMS configuration */}
                      <div>
                        <div className="cw-form--legend mb-30">
                          <p className="cw-heading--saascampaign mb-3">
                            Communication Channel
                          </p>
                          {showSMSChannel ? (
                            <p>Select at least one channel</p>
                          ) : (
                            ''
                          )}
                          <br />
                          {/* adding checkbox for email config*/}
                          {showEmailChannel ? (
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="emailChannel"
                                id="emailChannel"
                                ref="emailChannel"
                                data-required="false"
                                value={
                                  this.props.emailChannel ? 'true' : 'false'
                                } //4827
                                checked={this.props.emailChannel || ''} //4827
                                onChange={this._handleInputChange}
                                onBlur={this._handleInputOnBlur}
                              />
                              <label
                                className="form-check-label d-block mb-20"
                                htmlFor="emailChannel"
                              >
                                <span>Email</span>
                              </label>
                            </div>
                          ) : (
                            ''
                          )}

                          {this.props.emailChannel && showEmailChannel ? (
                            <div className="form-check mb-10">
                              <p className="mb-10">
                                In addition, do you need an email campaign for
                                this request ?
                              </p>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="emailConfirmationFlag_yes"
                                  name="emailConfirmationFlag"
                                  ref="emailConfirmationFlag"
                                  value={this.props.emailConfirmationFlag}
                                  checked={this.props.emailConfirmationFlag}
                                  onClick={e => this.onChangeRadio(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="emailConfirmationFlag_yes"
                                >
                                  <p className="form-check-label d-block mr-2">
                                    Yes
                                  </p>
                                </label>

                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id="emailConfirmationFlag_no"
                                  name="emailConfirmationFlag"
                                  ref="emailConfirmationFlag"
                                  value={
                                    this.props.emailConfirmationFlag || false
                                  }
                                  checked={!this.props.emailConfirmationFlag}
                                  onClick={e => this.onChangeRadio(e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="emailConfirmationFlag_no"
                                >
                                  <p className="form-check-label d-block mb-5">
                                    No
                                  </p>
                                </label>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {this.props.emailChannel ? (
                            <div className={emailClass}>
                              <div className="form-group">
                                <label htmlFor="emailSenderName">
                                  <span>Email Senders Name</span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="This name will appear as the sender on any campaign emails sent to consumers."
                                  >
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="emailSenderName"
                                  id="emailSenderName"
                                  ref="emailSenderName"
                                  maxLength="50"
                                  aria-describedby="emailSenderName"
                                  data-required="true"
                                  value={this.props.emailSenderName || ''}
                                  onChange={this._handleInputChange}
                                  onBlur={this._handleInputOnBlur}
                                />
                                <span
                                  className="cw-error cw-hidden"
                                  data-control="emailSenderName"
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter senders name.
                                </span>
                              </div>

                              <div className="form-group">
                                <label htmlFor="replyToEmailName">
                                  <span>Reply to Email Name (Optional)</span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="If you want consumers to respond, this is the name that will appear in the To field. If you don’t want responses, leave this field blank."
                                  >
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="replyToEmailName"
                                  id="replyToEmailName"
                                  ref="replyToEmailName"
                                  maxLength="50"
                                  aria-describedby="replyToEmailName"
                                  value={this.props.replyToEmailName || ''}
                                  onChange={this._handleInputChange}
                                  onBlur={this._handleInputOnBlur}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="replyToEmailAdderess">
                                  <span>Reply to Email Address (Optional)</span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="The email address to which consumers can send their replies, if required. If you don’t want responses, leave this field blank."
                                  >
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="replyToEmailAdderess"
                                  id="replyToEmailAdderess"
                                  ref="replyToEmailAdderess"
                                  aria-describedby="replyToEmailAdderess"
                                  data-custom-error="true"
                                  data-custom-error-type="email"
                                  value={this.props.replyToEmailAdderess || ''}
                                  onChange={this._handleInputChange}
                                  onBlur={this._handleInputOnBlur}
                                />
                                <span
                                  className="cw-error cw-hidden"
                                  data-custom-msg="replyToEmailAdderess"
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter valid email.
                                </span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        {this.props.showSMSChannel ? (
                          <div className="cw-form--legend mb-30">
                            {/* 5022 adding checkbox for sms config*/}
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="smsChannel"
                                id="smsChannel"
                                ref="smsChannel"
                                data-required="false"
                                value={smsChannel ? 'true' : 'false'}
                                checked={smsChannel || ''}
                                onChange={this._handleInputChange}
                                onBlur={this._handleInputOnBlur}
                              />
                              <label
                                className="form-check-label d-block"
                                htmlFor="smsChannel"
                              >
                                <span>SMS</span>
                              </label>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {smsChannel || emailChannel ? (
                          <span
                            className="cw-error cw-hidden"
                            data-control={'emailChannel&smsChannel'}
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Select a consent channel
                          </span>
                        ) : (
                          <span
                            className="cw-error"
                            data-control={'emailChannel&smsChannel'}
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Select a consent channel
                          </span>
                        )}
                      </div>

                      <div className={`mb-15 ${channelMessageClass}`}>
                        <div className="alert alert-warning">
                          <p>
                            <strong>
                              You have selected both consent channels (Email
                              &amp; SMS) and tracking is selected as yes. If you
                              want to track the campaigns for email and sms
                              separately with two separate campaign id’s, then
                              create two different campaigns in Campaign Wizard
                              one for email channel and other for sms channel
                            </strong>
                          </p>
                        </div>
                      </div>

                      <div className="cw-form--legend mb-30">
                        <p className="cw-heading--saascampaign mb-3">
                          Consent and Compliance
                        </p>
                        {/* Brand URL */}
                        {this.props.isWhitelistedForGigya ? (
                          <div className="form-group">
                            <label htmlFor="brandUrl">
                              <span>Brand URL</span>
                              <span
                                className="float-right  cw-icon cw-icon--help"
                                data-tip="Enter your brand's landing page e.g http//www.unilever.com."
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="brandUrl"
                              id="brandUrl"
                              ref="brandUrl"
                              aria-describedby="brandUrl"
                              data-required="true"
                              data-custom-error="true"
                              data-custom-error-type="url"
                              value={this.props.brandUrl || ''}
                              onChange={this._handleInputChange}
                              onBlur={this._handleInputOnBlur}
                            />
                            <span
                              className="cw-error cw-hidden"
                              data-custom-msg="brandUrl"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter brand url e.g
                              https://www.unilever.com
                            </span>
                            <span
                              className="cw-error cw-hidden"
                              data-control="brandUrl"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter brand url e.g
                              https://www.unilever.com
                            </span>
                          </div>
                        ) : null}

                        {/* Cookie Policy URL */}
                        <div className="form-group">
                          <label htmlFor="cookiePolicyUrl">
                            <span>Brand Cookie Policy URL</span>
                            <span className="cw-external-link">
                              <a
                                href="https://www.unilevernotices.com/cookie-notice/notice.html"
                                target="_blank"
                              >
                                View by country
                              </a>{' '}
                              <i className="fas fa-external-link-alt"></i>
                            </span>
                            <span
                              className="float-right cw-icon cw-icon--help"
                              data-tip="Full URL as it appears on your brand site eg https://www.brand.com/cookiepolicy. 
                        <br/> <br/> <br/>
                        URL must start with HTTPS."
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="cookiePolicyUrl"
                            id="cookiePolicyUrl"
                            ref="cookiePolicyUrl"
                            aria-describedby="cookiepolicy"
                            data-required="true"
                            data-custom-error="true"
                            data-custom-error-type="url"
                            value={
                              !this.props.cookieUrlFieldUpdated &&
                              !this.props.cookiePolicyUrl
                                ? this.props.cookieUrl
                                : this.props.cookiePolicyUrl
                            }
                            onChange={this._handleInputChange}
                            onBlur={this._handleInputOnBlur}
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="cookiePolicyUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter brand cookie policy url.
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="cookiePolicyUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a valid url e.g
                            https://www.unilever.com
                          </span>
                        </div>

                        {/* Privacy Policy URL */}
                        <div className="form-group">
                          <label htmlFor="privacyPolicyUrl">
                            <span>Brand Privacy Policy URL</span>
                            <span className="cw-external-link">
                              <a
                                href="https://www.unilevernotices.com/privacy-notice/notice.html"
                                target="_blank"
                              >
                                View by country
                              </a>{' '}
                              <i className="fas fa-external-link-alt"></i>
                            </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Full URL as it appears on your brand site eg https://www.brand.com/privacypolicy. 
                        <br/> <br/> <br/>
                        URL must start with HTTPS."
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="privacyPolicyUrl"
                            id="privacyPolicyUrl"
                            ref="privacyPolicyUrl"
                            aria-describedby="privacypolicy"
                            data-required="true"
                            data-custom-error="true"
                            data-custom-error-type="url"
                            value={
                              !this.props.privacyUrlFieldUpdated &&
                              !this.props.privacyPolicyUrl
                                ? this.props.privacyUrl
                                : this.props.privacyPolicyUrl
                            }
                            onChange={this._handleInputChange}
                            onBlur={this._handleInputOnBlur}
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="privacyPolicyUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter brand privacy policy url.
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="privacyPolicyUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a valid url e.g
                            https://www.unilever.com
                          </span>
                        </div>

                        {/* Terms & Conditions URL */}
                        {/* TODO: Regular Expression validation for URL format */}
                        <div className="form-group">
                          <label htmlFor="tncUrl">
                            <span>
                              Campaign Terms &amp; Conditions URL{' '}
                              {this.props.campaignType &&
                              this.props.campaignType.toLowerCase() !==
                                'incentive'
                                ? ' (Optional)'
                                : ''}
                            </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Full URL as it appears on your brand site eg https://www.brand.com/campaigntc. 
                        <br/> <br/> <br/>
                        URL must start with HTTPS."
                            >
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </label>
                          {this.props.campaignType &&
                          this.props.campaignType.toLowerCase() ===
                            'incentive' ? (
                            <input
                              type="text"
                              className="form-control"
                              name="tncUrl"
                              id="tncUrl"
                              ref="tncUrl"
                              data-required="true"
                              data-custom-error="true"
                              data-custom-error-type="url"
                              aria-describedby="termsandconditionsurl"
                              value={this.props.tncUrl || ''}
                              onChange={this._handleInputChange}
                              onBlur={this._handleInputOnBlur}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              name="tncUrl"
                              id="tncUrl"
                              ref="tncUrl"
                              data-custom-error="true"
                              data-custom-error-type="url"
                              aria-describedby="termsandconditionsurl"
                              value={this.props.tncUrl || ''}
                              onChange={this._handleInputChange}
                              onBlur={this._handleInputOnBlur}
                            />
                          )}
                          <span
                            className="cw-error cw-hidden"
                            data-control="tncUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter terms and conditions url.
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="tncUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a valid url e.g
                            https://www.unilever.com
                          </span>
                        </div>

                        {/* Age Consent */}

                        <div className="form-group">
                          <label htmlFor="ageOfConsent">
                            <span>Age of consent</span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Legal age limit in your market for consumers to approve communications from you. Not sure? Contact your legal or privacy team."
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="ageOfConsent"
                            id="ageOfConsent"
                            ref="ageOfConsent"
                            aria-describedby="ageOfConsent"
                            maxLength="2"
                            data-required="true"
                            data-custom-error="true"
                            data-custom-error-type="ageOfConsent"
                            value={this.props.ageOfConsent || ''}
                            onChange={this._handleInputChange}
                            onBlur={this._handleInputOnBlur}
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="ageOfConsent"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter age.
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="ageOfConsent"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a valid age of consent
                          </span>
                        </div>

                        {/* Double Optin */}
                        <div className={`form-group ${isMarketSupportedClass}`}>
                          <label htmlFor="doubleOptinReq">
                            <span>Double Opt-in Required </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Send an additional sign-up verification email for added security."
                            >
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </label>
                          <select
                            className="form-control"
                            name="doubleOptinReq"
                            id="doubleOptinReq"
                            ref="doubleOptinReq"
                            aria-describedby="doubleOptinReq"
                            onChange={this._handleInputChange}
                            onBlur={this._handleInputOnBlur}
                            defaultValue={defaultDoubleOptins}
                          >
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        {/* Re-Direction URL */}
                        <div className={`form-group ${isDoubleOptinsReq}`}>
                          <label htmlFor="redirectionUrl">
                            <span>Re-direction URL</span>
                            <span
                              className="float-right cw-icon cw-icon--help"
                              data-tip="Please enter a url for re-direction."
                            >
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </label>

                          {isMarketSupported
                            ? doubleOptinReq === undefined ||
                              doubleOptinReq === doubleOptinReqYes
                              ? redirectionUrlHtmlWithValidation
                              : doubleOptinReq === doubleOptinReqNo
                              ? redirectionUrlHtmlWithOutValidation
                              : redirectionUrlHtmlWithOutValidation
                            : redirectionUrlHtmlWithOutValidation}
                          <span
                            className="cw-error cw-hidden"
                            data-control="redirectionUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter re-direction url.
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="redirectionUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a valid re-direction url e.g
                            https://www.unilever.com
                          </span>
                        </div>
                        {/* Disclaimer Text */}
                        <div className="form-group">
                          <label htmlFor="disclaimerText">
                            <span>Disclaimer Text (Optional)</span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Add your own bespoke disclaimer text to the sign-up form."
                            >
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </label>
                          <textarea
                            className="form-control"
                            name="disclaimerText"
                            id="disclaimerText"
                            ref="disclaimerText"
                            rows="3"
                            data-custom-error="true"
                            data-custom-error-type="maxlength"
                            value={this.props.disclaimerText || ''}
                            onChange={this._handleInputChange}
                            onBlur={this._handleInputOnBlur}
                          ></textarea>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="disclaimerText"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            The character limit has been exceeded.
                          </span>
                        </div>
                      </div>
                      {/* Message when both Email & SMS channel are checked  */}
                      {/* <div className={showAutogenerateOption}>
                        <div className="cw-form--legend mb-40">
                          <p className="cw-heading--saascampaign mb-15">
                            Do you need a signup form in AEM for this campaign?
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Select Yes if you need a signup form in AEM. If you choose No, at the end you will get a PDF with all the master data and the required keys, which can be used to build the form in any platform"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </p>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="autogenerate_yes"
                              name="autogenerate"
                              ref="autogenerate"
                              value={this.props.autogenerate}
                              checked={this.props.autogenerate}
                              onClick={e => this.onChangeAutogenerateRadio(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autogenerate_yes"
                            >
                              <p className="mb-5">Yes</p>
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="autogenerate_no"
                              name="autogenerate"
                              ref="autogenerate"
                              value={this.props.autogenerate}
                              checked={!this.props.autogenerate}
                              onClick={e => this.onChangeAutogenerateRadio(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autogenerate_no"
                            >
                              <p className="mb-5">
                                No, I will create my own form or I don’t need a
                                form
                              </p>
                            </label>
                          </div>
                        </div>
                      </div> */}
                    </>
                  ) : null}
                  <div className="cw-form--action-cta">
                    {this.props.approverEditCampaign ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this._handleSubmit}
                        >
                          Save
                        </button>
                        <a
                          className="new-question-answer-btn cw-save-to-draft mt-25"
                          onClick={this._cancelApproverEdit}
                        >
                          Cancel
                        </a>
                      </>
                    ) : (
                      <>
                        {!editCampaign ? (
                          <button
                            type="button"
                            className="btn btn btn-outline-secondary mr-3"
                            onClick={this._handlebackButton}
                          >
                            Back
                          </button>
                        ) : null}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={this._handleSubmit}
                        >
                          Next
                        </button>
                        {isWebsiteTypeAdobeSaaS && campaign.isEdited ? (
                          <a
                            href="javascript:void(0)"
                            className="cw-cancel-edit mt-40"
                            onClick={event => this._openIsCancelModal(event)}
                          >
                            Cancel changes
                          </a>
                        ) : (
                          <a
                            href="javascript:void(0)"
                            className="cw-save-to-draft mt-40"
                            onClick={event => this._handleSubmit(event, true)}
                          >
                            Save and exit
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  name: state.campaignDetails.name,
  type: state.campaignDetails.type,
  isAlwaysOn: state.campaignDetails.isAlwaysOn,
  addConsentOptinFlag: state.campaignDetails.addConsentOptinFlag,
  additionalConsentFlag: state.campaignDetails.additionalConsentFlag,
  emailConfirmationFlag: state.campaignDetails.emailConfirmationFlag,
  additionalOptinList: state.campaignDetails.additionalOptinList,
  dpiaConfirmationFlag: state.campaignDetails.dpiaConfirmationFlag,
  description: state.campaignDetails.description,
  cookiePolicyUrl: state.campaignDetails.cookiePolicyUrl,
  privacyPolicyUrl: state.campaignDetails.privacyPolicyUrl,
  tncUrl: state.campaignDetails.tncUrl,
  ageOfConsent: state.campaignDetails.ageOfConsent,
  brandUrl: state.campaignDetails.brandUrl,
  doubleOptinReq: state.campaignDetails.doubleOptinReq,
  disclaimerTextReq: state.campaignDetails.disclaimerTextReq,
  disclaimerText: state.campaignDetails.disclaimerText,
  requestersEmail: state.campaignDetails.requestersEmail,
  emailSenderName: state.campaignDetails.emailSenderName,
  replyToEmailName: state.campaignDetails.replyToEmailName,
  replyToEmailAdderess: state.campaignDetails.replyToEmailAdderess,
  emailChannel: state.campaignDetails.emailChannel,
  smsChannel: state.campaignDetails.smsChannel,
  startDate: state.campaignDetails.startDate,
  endDate: state.campaignDetails.endDate,
  endDateTime: state.campaignDetails.endDateTime,
  dpiaReferenceNo: state.campaignDetails.dpiaReferenceNo,
  serviceError: state.campaignDetails.serviceError,
  serviceErrorStatus: state.campaignDetails.serviceErrorStatus,
  serviceErrorMessage: state.campaignDetails.serviceErrorMessage,
  pushstate: state.campaignDetails.pushstate,
  campaignLoaded: state.campaignDetails.campaignLoaded,
  isWhitelistedForGigya: state.campaignDetails.isWhitelistedForGigya,
  campaignType: state.campaignDetails.campaignType,
  incentiveType: state.campaignDetails.incentiveType,
  brandname: state.campaignDetails.brandname,
  dataSaved: state.campaignDetails.dataSaved,
  approverEditCampaign: state.app.approverEditCampaign,
  country: state.campaignDetails.country,
  language: state.campaignDetails.language,
  cookieUrl: state.campaignDetails.cookieUrl,
  privacyUrl: state.campaignDetails.privacyUrl,
  privacyUrlFieldUpdated: state.campaignDetails.privacyUrlFieldUpdated,
  cookieUrlFieldUpdated: state.campaignDetails.cookieUrlFieldUpdated,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  isCampaignEdited: state.campaignDetails.isCampaignEdited,
  isTrackingRequired: state.campaignDetails.isTrackingRequired,
  isCustomTrackingId: state.campaignDetails.isCustomTrackingId,
  websiteType: state.campaignDetails.websiteType,
  type: state.campaignDetails.type,
  showEmailChannel: state.campaignDetails.showEmailChannel,
  showSMSChannel: state.campaignDetails.showSMSChannel,
  autogenerate: state.campaignDetails.autogenerate,
  country: state.brandDetails.country,
  redirectionUrl: state.campaignDetails.redirectionUrl,
  isClone: state.campaignDetails.isClone,
  campaign: state.overview.campaign,
  campaignDetails: state.overview.campaignDetails,
  isPlatformAgnostic: state.campaignDetails.isPlatformAgnostic,
  autogenerate: state.campaignDetails.autogenerate,
  campaignProductionUrl: state.campaignDetails.campaignProductionUrl,
  campaignNonProdUrls: state.campaignDetails.campaignNonProdUrls,
  offlineInjection: state.campaignDetails.offlineInjection,
  customInjection: state.campaignDetails.customInjection,
  isMasterData: state.campaignDetails.isMasterData,
  campaignConfig: state.app.campaignConfig,
  incentiveItPartner: state.campaignDetails.incentiveItPartner,
  vendorPartner: state.campaignDetails.vendorPartner,
  isMigrationRequest: state.campaignDetails.isMigrationRequest,
  masterDataInjection: state.campaignDetails.masterDataInjection,
  masterDataCollectionPartner: state.campaignDetails.masterDataCollectionPartner,
  masterDataOtherCollectionPartner:state.campaignDetails.masterDataOtherCollectionPartner
});

export default connect(mapStateToProps, {
  getCampaignDetails,
  updateCampaignDetails,
  submitCampaignDetails,
  resetSubmissionState,
  setNotificationMessage,
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  getCampaignCookiePrivacyUrl,
  validateCampignName,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs,
  getBrandCountries,
  fetchCampaignData,
  cancelCampaignChanges
})(CampaignDetails);
