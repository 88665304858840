import React from 'react';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';

const buttonTypeIconMap = {
  "Website URL": "zmdi zmdi-open-in-new",
  "Phone number": "zmdi zmdi-phone",
  "Unsubscribe quick reply": "zmdi zmdi-mail-reply",
  "Copy code": "zmdi zmdi-copy",
  "Copy coupon code": "zmdi zmdi-copy"
}

const WAChatButtons = ({data, isCarousel}) => {
  const isButtonsConfigured = !isEmpty(data);

  const getButtonList = () => {
    let list = [];
    for(let i = 0; i < 3; i++){
      if(data[i]){
        if(i == 2){
          if(data.length > 3){
            list.push((
              <div className="wa-chat-btn" key={`chat_btn_${i}`}>
              <div className="wa-chat-btn-icon">
                <i className="zmdi zmdi-menu"></i>
              </div>
              <div className="wa-chat-btn-text">
                <span>See all options</span>
              </div>
            </div>
            ))
          } else {
            list.push((
              <div className="wa-chat-btn" key={`chat_btn_${i}`}>
              <div className="wa-chat-btn-icon">
                <i className={buttonTypeIconMap[data[i].type.value]}></i>
              </div>
              <div className="wa-chat-btn-text">
                <span>{data[i].buttonName}</span>
              </div>
            </div>
            ))
          }
        } else {
          list.push((
            <div className="wa-chat-btn" key={`chat_btn_${i}`}>
            <div className="wa-chat-btn-icon">
              <i className={buttonTypeIconMap[data[i].type.value]}></i>
            </div>
            <div className="wa-chat-btn-text">
              <span>{data[i].buttonName}</span>
            </div>
          </div>
          ))
        } 
      }
    }
    return list;
    
  }

  return isButtonsConfigured? (
    <div className={classNames("wa-chat-btn-container",{
      "wa-chat-btn-mt-0": isCarousel
    })}>
      {getButtonList()}
    </div>
  ): null;
};

export default WAChatButtons;
