import * as yup from 'yup';
import constants from '../../constants';
import { updateWAComms } from '../../actions/wabriefings/waComms';
import { WaToggleInput } from 'components/molecules/WaToggleInput';
import moment from 'moment';
import { EnhancedTextArea } from 'components/molecules/EnhancedTextArea';
//import { WAMessageButtons } from 'components/molecules/WAMessageButtons';
import { WAButtons } from 'components/molecules/FormFieldArray/WAButtons';
import FormUpdateTrack from 'components/organisms/FormUpdateTrack';
import { WACampaignSubmitRedirectURL } from 'selectors';
import { WaLtoInput } from 'components/molecules/WaLtoInput';
import { WACarouselMessageInput } from 'components/molecules/WaCarousel';
import { getWAButtonDefaults } from 'utilities/util';

export const getIsEdited = componentProps => {
  return (
    componentProps &&
    componentProps.history &&
    componentProps.history.location &&
    componentProps.history.location.state &&
    componentProps.history.location.state.isEdited
  );
};

const buttonSchema = yup.object().shape({
  type: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required(),
      value: yup.string().required()
    })
    .required('Please specify button type')
    .default('')
    .test(
      'type-value-not-blank',
      'Please specify button type',
      type => !!type && type.value.trim() !== ''
    ),

  buttonName: yup.string().required('Button name is required'),
  buttonValue: yup
    .string()
    .when('type', {
      is: type => type.value === 'Website URL',
      then: yup.string().required('Website URL is required')
    })
    .when('type', {
      is: type => type.value === 'Phone number',
      then: yup
        .string()
        .matches(/^\+?[1-9]\d{1,14}$/, 'Must be a valid phone number')
        .required('Phone number is required')
    })
    .when('type', {
      is: type => type.value === 'Unsubscribe quick reply',
      then: yup.string().required('Unsubscribe quick reply text is required')
    })
});

// Validation for each carousel card
const carouselCardSchema = yup.object().shape({
  cardHeader: yup.object({
    type: yup.string().required('Please specify card header'),
    headerImage: yup.string().when('type', {
      is: data => data === 'headerImage',
      then: schema => schema.required('Please specify image')
    })
  }),
  cardBodyText: yup
    .string()
    .max(160, 'Card body text must be at most 160 characters')
    .required('Please specify card body text'),
  buttons: yup
    .array()
    .of(buttonSchema)
    .min(1, 'At least one button is required')
});

// Validation for the entire form
const carouselValidationSchema = yup.object().shape({
  messageType: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required()
    })
    .required('Please specify message type')
    .default(undefined),
  mainMessageBubbleText: yup.string().when('messageType.value', {
    is: 'carousel',
    then: yup
      .string()
      .max(1024, 'Main message bubble text must be at most 1024 characters')
      .required('Please specify main message bubble text')
  }),
  carouselCards: yup
    .array()
    .of(carouselCardSchema)
    .when('messageType.value', {
      is: 'carousel',
      then: yup
        .array()
        .of(carouselCardSchema)
        .min(2, 'At least two carousel cards are required')
        .max(10, 'No more than 10 carousel cards are allowed')
        .required('Carousel cards are required')
    })
});

const formSchema = (
  dispatch,
  { defaultValues, match, history, waType, status, isEdit, onFormValueChange }
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    try {
      console.log(
        '==========================ONSUBMIT data================',
        data
      );
      if (data.waHeader.type === 'headerNone') {
        delete data.waHeader.headerText;
      }
      if (
        data &&
        data.waOffer &&
        data.waOffer.waLtoDetails &&
        !data.waOffer.waLtoDetails.ltoEnable
      ) {
        delete data.waOffer.waLtoDetails.expirationDate;
        delete data.waOffer.waLtoDetails.ltoSummary;
      }

      if (
        data &&
        data.waOffer &&
        data.waOffer.waLtoDetails &&
        !data.waOffer.waLtoDetails.expirationEnable
      ) {
        delete data.waOffer.waLtoDetails.expirationDate;
      }
      // dispatch event
      const waDesign = {
        ...data,
        waDesign: data,
        requestersEmail: user.emailId
      };
      const paramUid = match.params.id;
      dispatch(
        updateWAComms(paramUid, waDesign, (error, result) => {
          if (error) {
            console.log('error: ', JSON.stringify(error, null, 2));
          } else {
            if (paramUid) {
              history.push(
                WACampaignSubmitRedirectURL({
                  backToReview: isEdited && backToReview,
                  status: status,
                  uid: paramUid,
                  nextPage: `${constants.WA_ROUTES.REVIEW}`
                })
              );
            } else {
              history.push(
                `/campaign-wizard/wa/${constants.WA_ROUTES.NEW_WA_TYPE}`
              );
            }
          }
        })
      );
    } catch (error) {
      console.log('onSubmit error: ', error);
    }
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    // debug : true,
    // debugError : true,
    fields: [
      {
        type: 'text',
        name: 'templateCategory',
        id: 'templateCategory',
        label: 'Template category',
        maxLength: '9',
        defaultValue: 'Marketing',
        rules: yup
          .string()
          .trim()
          .required('Please enter template category'),
        readOnly: true
      },
      {
        type: 'text',
        name: 'templateName',
        id: 'templateName',
        label: 'WhatsApp template name',
        maxLength: '512',
        subText:
          'Only alphabets, numerics, alphanumeric characters, and underscores are allowed',
        defaultValue: defaultValues?.waDesign?.templateName,
        rules: yup
          .string()
          // .trim()
          .lowercase('Please enter lowercase letters')
          .matches(
            /^[a-zA-Z0-9_ ]+$/,
            'Only alphabets, numerics, alphanumeric characters, and underscores are allowed'
          )
          .strict()
          .required('Please enter WhatsApp template name'),
        readOnly: false
      },
      {
        type: 'custom',
        name: 'carousel',
        component: WACarouselMessageInput,
        defaultValue: defaultValues.waDesign,
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        id: 'Select message card',
        label: 'Select message card',
        rules: carouselValidationSchema
      },
      {
        type: 'custom',
        name: 'waHeader',
        component: WaToggleInput,
        id: 'WaToggle',
        label: 'Header (optional)',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: defaultValues.waDesign,
        waType: waType,
        rules: yup
          .object({
            type: yup.string().nullable(),
            headerText: yup.string().when('type', {
              is: data => data === 'headerText',
              then: schema => schema.required('Please select header text')
            }),
            headerImage: yup.string().when('type', {
              is: data => data === 'headerImage',
              then: schema => schema.required('Please specify image')
            })
          })
          .required('Please select email trigger')
          .default({ type: undefined, details: undefined })
      },
      {
        type: 'custom',
        name: 'waOffer',
        component: WaLtoInput,
        defaultValue: defaultValues.waDesign,
        id: 'waOffer',
        label: 'Limited time offer (Optional)',
        subLabel:
          "The Limited-Time Offer (LTO) component lets you display expiration dates and countdown timers in WhatsApp messages, enhancing urgency and engagement for time-sensitive offers. The message will display an offer expiration details section with an offer summary heading, an optional expiration timer, and the offer code itself. Footer components are not supported in messages that include Limited Time Offers. Users who view an LTO message using that WhatsApp web app or desktop app will not see the offer, but will instead see a message indicating that they have received a message but that it's not supported in the client they are using.",
        rules: yup.object().shape({
          // waOffer: yup.object().shape({
          waLtoDetails: yup.object().shape({
            ltoEnable: yup.boolean().required(),
            ltoSummary: yup.string().when('ltoEnable', {
              is: true,
              then: yup
                .string()
                .max(
                  16,
                  'Limited time offer summary text must be at most 16 characters'
                )
                .required('Limited time offer summary text is required')
            }),
            expirationEnable: yup.boolean().when('ltoEnable', {
              is: true,
              then: yup.boolean().required()
            }),
            expirationDate: yup.string().when('expirationEnable', {
              is: true,
              then: schema =>
                schema
                  .test(
                    'isDateStringParsable',
                    'Please enter a valid date',
                    dateString => {
                      return (
                        moment(dateString, 'DD/MM/YYYY', true).isValid() ||
                        moment(
                          dateString,
                          'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                          true
                        ).isValid()
                      );
                    }
                  )
                  .test(
                    'isNotPastDate',
                    'Please select a future date',
                    dateString => {
                      return moment(dateString, [
                        'DD/MM/YYYY',
                        moment.ISO_8601,
                        'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                      ]).isSameOrAfter(moment(), 'day');
                    }
                  )
                  .required('Please specify your offer expiration date')
            })
          })
          // }),
        })
      },
      {
        type: 'custom',
        component: EnhancedTextArea,
        isDynamicCoupon: false,
        name: 'waBodyText',
        id: 'waBodyText',
        label: 'Body text',
        maxLength: '1024',
        flow: 'wa',
        defaultValue: defaultValues?.waDesign?.waBodyText || '',
        rules: yup
          .string()
          .trim()
          .required('Please enter body text'),
        detectUnicodeContent: false,
        showCharactorCount: true,
        optOutTextLength: 0,
        maxLengthDisplay: 1024,
        fnameOnlyOnce: true,
        hideInsertUrl: true
      },
      {
        type: 'custom',
        component: EnhancedTextArea,
        isDynamicCoupon: false,
        name: 'waFooterText',
        id: 'waFooterText',
        label: 'Footer (optional)',
        maxLength: '60',
        defaultValue: defaultValues?.waDesign?.waFooterText || '',
        waDesign: defaultValues?.waDesign,
        rules: yup.string().trim(),
        detectUnicodeContent: false,
        optOutTextLength: 0,
        maxLengthDisplay: 60,
        fnameOnlyOnce: true,
        hideInsertUrl: true
      },
      {
        type: 'custom',
        // component: WAButtonsFieldArray,
        component: WAButtons,

        isDynamicCoupon: false,
        name: 'waButtons',
        id: 'waButtons',
        label: 'Buttons',
        maxLength: '60',
        showCommonErrors: true,
        waCouponType: defaultValues?.waAttributes?.waCoupon?.type,
        waCouponCode: defaultValues?.waAttributes?.waCoupon?.code,
        defaultValue:
          getWAButtonDefaults(
            defaultValues?.waDesign?.waButtons,
            defaultValues?.waAttributes?.waCoupon?.type,
            defaultValues?.waAttributes?.waCoupon?.code
          ) || [],
        rules: yup.array().of(
          yup.object({
            type: yup
              .object()
              .nullable()
              .required('Please specify button type'),
            buttonName: yup
              .string()
              .nullable()
              .required('Please specify button name'),
            buttonValue: yup.string().when('type', (type, schema) => {
              if (type && type.value) {
                if (type.value === 'Phone number') {
                  return schema
                    .required(`Please specify ${type.value.toLowerCase()}`)
                    .max(20, `Please enter valid ${type.value.toLowerCase()}`);
                }
                if (type.value === 'Website URL') {
                  return schema
                    .matches(
                      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                      `Please specify ${type.value.toLowerCase()}`
                    )
                    .required(`Please specify ${type.value.toLowerCase()}`);
                }
                return schema.required(
                  `Please specify ${type.value.toLowerCase()}`
                );
              }
              return schema;
            })
          })
        )
      },
      {
        type: 'custom',
        component: FormUpdateTrack,
        name: 'formTrack',
        watch: [
          'waHeader',
          'waBodyText',
          'waFooterText',
          'waButtons',
          'waOffer',
          'carousel'
        ],
        onFormValueChange: onFormValueChange,
        defaultValue: []
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      try {
        if (data.waHeader.type === 'headerNone') {
          delete data.waHeader.headerText;
        }
        if (
          data &&
          data.waOffer &&
          data.waOffer.waLtoDetails &&
          !data.waOffer.waLtoDetails.ltoEnable
        ) {
          delete data.waOffer.waLtoDetails.expirationDate;
          delete data.waOffer.waLtoDetails.ltoSummary;
        }

        if (
          data &&
          data.waOffer &&
          data.waOffer.waLtoDetails &&
          !data.waOffer.waLtoDetails.expirationEnable
        ) {
          delete data.waOffer.waLtoDetails.expirationDate;
        }
        // dispatch event
        const waDesign = {
          ...data,
          waDesign: data,
          requestersEmail: user.emailId
        };
        const paramUid = match.params.id;
        dispatch(
          updateWAComms(
            paramUid,
            waDesign,
            (error, result) => {
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                history.push(
                  `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
                );
              }
            },
            { isSaveAsDraft: true }
          )
        );
      } catch (error) {
        console.log('onSubmit error: ', error);
      }
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.AUDIENCE}`
      );
    },
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default formSchema;
