import React, { useState } from 'react';
import classNames from 'classnames';

const DownloadLinkBtn = ({ onClick, label, loaderClass }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button className="cw-link-btn downloadLinkContainer" onClick={e => onClick(e, setIsLoading)}>
      {label}
      {isLoading? <div className={classNames('dw-loader', loaderClass)}>
        <span>
          <i className="fa fa-spinner fa-spin" />
        </span>
        </div> : null
      }
    </button>
  );
};

export default DownloadLinkBtn;
