import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import schema from './waPurchaseOrderInfoSchema';

const WAPurchaseOrderInfo = ({
  pathUid,
  closeModal,
  setToggle,
  waDetails,
  toggle,
  closePoModelClick
}) => {
  const dispatch = useDispatch();

  const { isLoading, uid, deliverySchedule, status, waType, requestInfoText } =
    waDetails || {};
  const { authorizedUserData } = useSelector(state => state.authorized);

  const compProps = {
    pathUid,
    closeModal,
    setToggle,
    defaultValues: deliverySchedule || {},
    waType,
    status,
    requestInfoText,
    toggle,
    authorizedUserData,
    closePoModelClick: closePoModelClick
  };

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={schema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default WAPurchaseOrderInfo;
