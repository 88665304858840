import React, { Component } from 'react';
import { connect } from 'react-redux';
import constant from '../../constants';
import BreadCrumb from '../BreadCrumb';
import AddFieldsButton from '../AddFieldsButton';
import MenuAccordion from '../organisms/MenuAccordion/MenuAccordion';
import {
  epsilonMarket,
  checkIsCIAEnabled,
  fetchQnANotificationTileData,
  isUnbundledConsentAndMarketCheck,
  isBrandEmailSmsConsentVisible,
  isWhatsAppConsentEnableForMarket
} from '../../selectors/index';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import {
  getAllConsentsForLocale,
  getConsentFieldData
} from '../../actions/consentAndCompliance';
import { scrollToTop } from 'selectors';
import axios from 'axios';
import {
  fetchCampaignData,
  cancelCampaignChanges
} from '../../actions/overview';
import {
  getQuestions,
  getAnswers,
  getCampaignDetails
} from '../../actions/questionsAnswers';
import {
  getAuthoringFieldData,
  getIncentiveFields,
  getGenericFieldData
} from '../../actions/saasFormAuthoring';
import Alert from '../Alert';
import SummaryComponent from '../SummaryComponent';
import { setNotificationMessage } from '../../actions/dashboard';
import ProfileField from '../organisms/ProfileField/ProfileField';
import QnAField from '../organisms/QnAField/QnAField';
import QnAFieldv2 from '../organisms/QnAFieldv2/QnAFieldv2';
import QnAFieldEpsilon from '../organisms/QnAFieldEpsilon/QnAFieldEpsilon';
import IncentiveField from '../organisms/IncentiveField/IncentiveField';
import HeadingField from '../organisms/HeadingField/HeadingField';
import ParagraphField from '../organisms/ParagraphField/ParagraphField';
import ConsentsSection from '../organisms/ConsentsSection/ConsentsSection';
import SubmitButton from 'components/organisms/SubmitButton/SubmitButton';
import Loader from '../Loader';
import _ from 'lodash';
import Modal from 'react-modal';
import CampaignCancelModel from '../CampaignCancelModel';
import IConfirm from 'components/organisms/IConfirm/IConfirm';
import SinglePageDescription from 'components/organisms/SinglePageDesc/SinglePageDescription';
import QnaGuideline from 'components/QnaGuideline';
import {
  getUnileverConsent,
  getDefaultOptInTypes,
  getConsentData,
  CONSENT_MESSAGE,
  getConsentVersion,
  getLegalAge,
  getAgeOfConsent,
  isOlderConsentBeingUpdated,
  isSomeConsentAvailable,
  insertDefaultConsentsIfNotAvailable,
  isConsentVersionUpdateRequired
} from '../../utilities/util';
import MultiChannelGuideline from 'components/MultiChannelGuideline';

const { consentTypes, DEFAULT_CONSENTS, QA_QUESTION_TYPES, INCENTIVE_FIELDS } = constant;

const defaultValues = {
  provinces: [{ value: '', label: '' }]
};

const mandatoryConsents = [
  consentTypes.unileverEmailConsent,
  consentTypes.unileverSMSConsent,
  consentTypes.brandSMSConsent,
  consentTypes.brandEmailConsent,
  consentTypes.unileverWhatsAppConsent,
  consentTypes.brandWhatsAppConsent,
  consentTypes.multiChannelUnileverConsent
];

const incentiveNonMandatoryConsents = [
  consentTypes.unileverEmailConsent,
  consentTypes.unileverSMSConsent,
  consentTypes.brandSMSConsent,
  consentTypes.brandEmailConsent,
  consentTypes.unileverEmailOnlyConsent,
  consentTypes.unileverSMSOnlyConsent,
  consentTypes.unileverOnlineOnlyConsent,
  consentTypes.unileverWhatsAppConsent,
  consentTypes.brandWhatsAppConsent,
  consentTypes.multiChannelUnileverConsent
];
 
 
class SaasFormSinglePageDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignId: this.props.match.params.id,
      showMessageAtTop: false,
      matchProps: props.match,
      profile: [],
      profileRichText: [],
      provinces: _.cloneDeep(defaultValues.provinces),
      expand: 0,
      fieldErrorsPresent: false,
      modalIsOpen: false,
      isLoading: false,
      expandEmail: true,
      mandatoryToggle: true,
      nonEmailfieldRequired: false,
      loadedDefaultValues: false,
      loadedDefaultConsents: false,
      isConsentsAvailableFromAPI: false,
      submitData: {},
      submitCtaLabel: '',
      failureMsg: '',
      emailSenderName: '',
      expiryMsg: '',
      mandatoryInd: '',
      consents: [],
      consentsVersion: '',
      consentVersionKey: '',
      areConsentFieldsPopulated: false,
      consentsUpdatedToEnterpriseMessage: false,
      initializedConsentVersion: false,
      consentsUnavailable: false,
      hideNotification: false,
      campaignFetched: false,
      nonRemovableConsents: [],
      consentValidationFail: false,
      emailConsentMissing: false,
      smsConsentMissing: false,
      instantWinLose: false,
      genderData: '',
      fieldLabel: '',
      toolTipText: '',
      errorMessage: '',
      regExp: '',
      errors: {},
      fieldsValidity: {},
      consentFieldsValidity: {},
      incentiveErrors: {},
      qnaErros: {},
      nonRemovableField: '',
      isErrorMessageFilled: true,
      isEmailLabelFilled: true,
      showErrorMessage: false,
      genderErrorMessage: false,
      isGenderErrorMessageFilled: false,
      isGenderFieldLabelErrorMessage: false,
      genderFieldErrorMessage: false,
      showAllPurposeError: false,
      showHeadingError: false,
      showParagraphError: false,
      isNewModalOpen: false,
      showCommChannelValidationMessage: false,
      isCancelModalOpen: false,
      isLegalAge: getLegalAge(this.props.campaign),
      addFieldButtonVisibility: true,
      addFieldAccordionVisibility: false,
      showFreeTextNote: false,
      maximumQualifingQuestionsErrorIndices: [],
      iConfirmQnA: false,
      isOpenModel: false,
      questionsExist: true,
      isEdited: '',
      formType: '',
      profileFieldErrors: {},
      listItems: [],
      emailAddressFieldMissing: false,
      mobileNumberFieldMissing: false,
      whatsAppConsentMissing : false,
      mobileNumberMissingForWhatsapp: false,
      multiEmailConsentMissing : false,
      openMultiGuideLineModel : false
    };

    this.sampleDetails = {
      answers: [
        {
          label: '',
          value: 'prize-0'
        }
      ],
      dataType: 'samplePrizeSelection',
      questionType: 'Single choice',
      label: 'Sample Details',
      fieldLabel: '',
      type: 'incentiveFields',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: ''
    };

    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
  }

  setCheckbox = value => {
    this.setState({ iConfirmQnA: value });
  };

  setIsOpenModel(value) {
    this.setState({ isOpenModel: value });
  }

  isOpenGuidelineModel = event => {
    event.preventDefault();
    this.setIsOpenModel(true);
  };

  isCloseGuidelineModel = event => {
    event.preventDefault();
    this.setIsOpenModel(false);
  };

  isOpenMultiChannelGuideModel = event => {
    event.preventDefault();
    this.setState({openMultiGuideLineModel : true})
  };
  isCloseMultiChannelGuideModel = event => {
    event.preventDefault();
    this.setState({openMultiGuideLineModel : false})
  };

  _handleCheckboxConfirm = event => {
    let isChecked = event.target.checked;
    if (isChecked) {
      this.setCheckbox(true);
    } else {
      this.setCheckbox(false);
    }
  };

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.state.campaignId
    );
  }

  handlebackButton = event => {
    const { match, history } = this.props;
    const campaignId = match.params.id;
    event.preventDefault();
    history.push('/campaign-wizard/campaign/' + campaignId + '/details');
  };
  handleAccordionExpandCollapse = index => {
    this.setState(prevState => ({
      expand: prevState.expand === index ? false : index
    }));
  };

  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.setApproverEditLastUpdatedScreen('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };

  removeAccordion = (index, givenOptInType) => {
    const {
      expand,
      profile,
      errors,
      incentiveErrors,
      maximumQualifingQuestionsErrorIndices
    } = this.state;
    const isIncentiveCamp = this.props.campaign && this.props.campaign.campaignType === 'Incentive'
    const fieldsLength = profile.length;
    if (index >= fieldsLength) {
      let updatedConsentArray = [...this.state.consents];
      updatedConsentArray.splice(index - fieldsLength, 1);
      // check if the remove concent type is unilever email consent
      if (givenOptInType === consentTypes.unileverEmailConsent) {
        const isEmailFieldPresent = this.isProfileFieldPresent(profile, 'email')
        const isBrandEmailConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandEmailConsent
        );
        if (isBrandEmailConsentPresent && isEmailFieldPresent) {
          this.addConsentToNonRemovable(consentTypes.brandEmailConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.brandEmailConsent,
            isIncentiveCamp
          );
        }
      }
      if (givenOptInType === consentTypes.brandEmailConsent) {
        const isEmailFieldPresent = this.isProfileFieldPresent(profile, 'email')
        const isUnileverEmailConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverEmailConsent
        );
        if (isUnileverEmailConsentPresent && isEmailFieldPresent) {
          this.addConsentToNonRemovable(consentTypes.unileverEmailConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverEmailConsent,
            isIncentiveCamp
          );
        }
      }
      if (givenOptInType === consentTypes.unileverSMSConsent) {
        const isMobileFieldPresent = this.isProfileFieldPresent(profile, 'mobileNumber')
        const isBrandSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandSMSConsent
        );
        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isUnileverWhatsAppConsentPresent && isMobileFieldPresent && !isBrandSMSConsentPresent) {
          this.addConsentToNonRemovable(consentTypes.unileverWhatsAppConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverWhatsAppConsent,
            isIncentiveCamp
          );
        }
        if (isBrandSMSConsentPresent && isMobileFieldPresent && !isUnileverWhatsAppConsentPresent) {
          this.addConsentToNonRemovable(consentTypes.brandSMSConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.brandSMSConsent,
            isIncentiveCamp
          );
        }
        if (isBrandSMSConsentPresent && isMobileFieldPresent && isUnileverWhatsAppConsentPresent) {
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.brandSMSConsent,
            isIncentiveCamp
          );
        }
      }
      if (givenOptInType === consentTypes.brandSMSConsent) {
        const isMobileFieldPresent = this.isProfileFieldPresent(profile, 'mobileNumber')
        const isUnileverSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverSMSConsent
        );
       
        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isUnileverWhatsAppConsentPresent && isMobileFieldPresent && !isUnileverSMSConsentPresent) {
          this.addConsentToNonRemovable(consentTypes.unileverWhatsAppConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverWhatsAppConsent,
            isIncentiveCamp
          );
        }
        if (isUnileverSMSConsentPresent && isMobileFieldPresent && !isUnileverWhatsAppConsentPresent) {
          this.addConsentToNonRemovable(consentTypes.unileverSMSConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverSMSConsent,
            isIncentiveCamp
          );
        }
        if (isUnileverSMSConsentPresent && isMobileFieldPresent && isUnileverWhatsAppConsentPresent) {
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverSMSConsent,
            isIncentiveCamp
          );
        }
      }
      if (givenOptInType === consentTypes.unileverWhatsAppConsent) {
        const isMobileFieldPresent = this.isProfileFieldPresent(profile, 'mobileNumber')
        const isBrandSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandSMSConsent
        );
        const isUnileverSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverSMSConsent
        );
        if (isUnileverSMSConsentPresent && isMobileFieldPresent && !isBrandSMSConsentPresent) {
          this.addConsentToNonRemovable(consentTypes.unileverSMSConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverSMSConsent,
            isIncentiveCamp
          );
        }
        if (isBrandSMSConsentPresent && isMobileFieldPresent && !isUnileverSMSConsentPresent) {
          this.addConsentToNonRemovable(consentTypes.brandSMSConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.brandSMSConsent,
            isIncentiveCamp
          );
        }
        if (isBrandSMSConsentPresent && isMobileFieldPresent && isUnileverSMSConsentPresent) {
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.unileverSMSConsent,
            isIncentiveCamp
          );
        }
      }
      if (givenOptInType === consentTypes.brandWhatsAppConsent) {
        const isMobileFieldPresent = this.isProfileFieldPresent(profile, 'mobileNumber')
        const isBrandWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandWhatsAppConsent
        );
        if (isBrandWhatsAppConsentPresent && isMobileFieldPresent) {
          this.addConsentToNonRemovable(consentTypes.brandWhatsAppConsent);
          updatedConsentArray = this.makeConsentMandatory(
            updatedConsentArray,
            consentTypes.brandWhatsAppConsent,
            isIncentiveCamp
          );
        }
      }

      this.setState({
        consents: [...updatedConsentArray],
        consentsTest: [...updatedConsentArray]
      });
      return;
    }
    const elem = profile[index];

    let updatedProfileArray = [...this.state.profile];
    let updateProfileTestData = [...this.state.profileRichText];
    updatedProfileArray.splice(index, 1);
    updateProfileTestData.splice(index, 1);
    const newState = {
      profile: [...updatedProfileArray],
      profileRichText: [...updatedProfileArray],
      expand: expand === index ? -1 : expand,
      fieldsValidity: {}
    };
    if (elem.dataType === 'questionAnswerPair') {
      const qualifyingErrorIndex = maximumQualifingQuestionsErrorIndices.findIndex(
        i => i === index
      );
      if (qualifyingErrorIndex >= 0) {
        maximumQualifingQuestionsErrorIndices.splice(qualifyingErrorIndex, 1);
      }
    } else if (elem.type === 'field') {
      const errorIds = Object.keys(errors);
      const updatedErrors = { ...errors };
      errorIds.forEach(id => {
        if (id.includes(elem.dataType)) {
          delete updatedErrors[id];
        }
        if (elem.dataType === 'stateOrProvince') {
          if (
            id.includes('province_Error_Message_') ||
            id.includes('answerTranslatedLabel_')
          ) {
            delete updatedErrors[id];
          }
        }
      });
      if (elem.dataType === 'stateOrProvince') {
        newState.provinces = _.cloneDeep(defaultValues.provinces);
      }
      if (elem.dataType === 'gender') {
        newState.genderErrorMessage = false;
        newState.genderFieldErrorMessage = false;
      }
      newState.errors = updatedErrors;
    } else if (elem.type === 'formElement') {
      const updatedErrors = { ...errors };
      if (elem.dataType === 'heading') {
        delete updatedErrors[`heading_error_${elem.dataType}_${index}`];
      } else if (elem.dataType === 'paragraph') {
        delete updatedErrors[`paragraph_${elem.id}`];
      }
      newState.errors = updatedErrors;
    } else if (elem.type === 'incentiveFields') {
      const updatedErrors = { ...incentiveErrors };
      delete updatedErrors[`field_label_${index}`];
      delete updatedErrors[`max_file_size_${index}`];
      delete updatedErrors[`fileSize_${index}`];
      delete updatedErrors[`max_file_size_${index}`];
      delete updatedErrors[`errorMessage_${index}`];
      delete updatedErrors[`validationMessage_${index}`];
      delete updatedErrors[`fileUploadMessage_${index}`];
      newState.incentiveErrors = updatedErrors;
    }
    newState.maximumQualifingQuestionsErrorIndices = maximumQualifingQuestionsErrorIndices.map(
      elem => {
        if (elem >= index) {
          return elem - 1;
        }
        return elem;
      }
    );
    this.setState(newState, () => {
      this.updateNonRemovableField();
      if(elem && (elem.dataType ==='mobileNumber' || elem.dataType ==='email')){
        const isMultiChannelConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.multiChannelUnileverConsent
        );
        if(isMultiChannelConsentPresent){
          if(elem.dataType ==='mobileNumber'){
            let removeConsentArr = [consentTypes.multiChannelUnileverConsent]
            this.removeConsentForMultiChannel(removeConsentArr)
          } else {
            // when remove email or mobile field multi channel consent need to update
            const { campaign } = this.props;
            const countrySelected =
              campaign && campaign.country && campaign.country.code;
            const isWhatsAppEnable =
              this.props.campaignConfig &&
              this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT &&
              isWhatsAppConsentEnableForMarket(
                this.props.campaignConfig,
                countrySelected
              );
            if (isWhatsAppEnable) {
              this.updateMultiChannelConset(elem);
            } else {
              let removeConsentArr = [consentTypes.multiChannelUnileverConsent];
              this.removeConsentForMultiChannel(removeConsentArr);
            }
          } 
        } else {
          this.updateNonRemovableConsents();
        }
      }
      this.checkAllFields();
    });
  };


  getMultiChannelConsentValues = (isEmailFieldPresent,isMobileFieldPresent)=>{

    const { campaign } = this.props;
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    const { campaignType } =  campaign
    const isWhatsAppEnable =
      (campaignType != 'Incentive' ||
        (campaignType === 'Incentive' &&
          this.props.campaignConfig.WHATSAPP_CONSENT &&
          this.props.campaignConfig.WHATSAPP_CONSENT.INCENTIVE_WHATSAPP_ENABLE)) &&
      this.props.campaignConfig &&
      this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT &&
      isWhatsAppConsentEnableForMarket(
        this.props.campaignConfig,
        countrySelected
      );
     
      const whatsAppCheckBox = []
      if(isWhatsAppEnable){
        whatsAppCheckBox.push({
          key: 'WhatsApp',
          value: 'WhatsApp',
          translatedValue: 'WhatsApp',
          disabled :isMobileFieldPresent,
          isChecked: isMobileFieldPresent
        })
      }

    return [
      {
        key: 'Email',
        value: 'Email',
        translatedValue: 'Email',
        disabled : !isWhatsAppEnable,  //|| isEmailFieldPresent,
        isChecked: isEmailFieldPresent
      },
      {
        key: 'SMS',
        value: 'SMS',
        translatedValue: 'SMS',
        disabled :isMobileFieldPresent,
        isChecked: isMobileFieldPresent
      },
      ...whatsAppCheckBox
    ]
  }

  getMultiConsentValueBasedOnKey=(multiChannelArr,keyValue)=>{
    return multiChannelArr.find(e=>e.value === keyValue)
  }

  getMultiChannelConsentKey = (multiChannelArr)=>{
    let defaultConsentKey = 'multi_channel'
    for(let ele of multiChannelArr){
      if(ele.value === 'Email' && ele.isChecked){
        defaultConsentKey = `${defaultConsentKey}_email`;
      }else if(ele.value === 'SMS' && ele.isChecked){
        defaultConsentKey = `${defaultConsentKey}_sms`;
      } else if(ele.value === 'WhatsApp' && ele.isChecked){
        defaultConsentKey = `${defaultConsentKey}_whatsapp`;
      }
    }
    return defaultConsentKey
  }

  updateMultiChannelConset = (fieldData)=>{
    let updatedConsentArray = [...this.state.consents];
    let consentVersionKey = this.state.consentVersionKey;
    let consents = this.props.allConsents;
    const multiChannelIndex = updatedConsentArray.findIndex(e=> e.optInType === consentTypes.multiChannelUnileverConsent) 
    const multiChannelObject = updatedConsentArray.find(e=> e.optInType === consentTypes.multiChannelUnileverConsent) 
    const isEmailFieldPresent = this.isProfileFieldPresent(this.state.profile, 'email')
    const isMobileFieldPresent = this.isProfileFieldPresent(this.state.profile, 'mobileNumber')
    multiChannelObject['multi_consents_value'] = this.getMultiChannelConsentValues(isEmailFieldPresent,isMobileFieldPresent)
    let defaultConsentKey = this.getMultiChannelConsentKey(multiChannelObject['multi_consents_value']);
    multiChannelObject['defaultCopy'] =
        consents[consentVersionKey] &&
        consents[consentVersionKey][0] &&
        consents[consentVersionKey][0][defaultConsentKey];
    updatedConsentArray[multiChannelIndex] = multiChannelObject;
    this.setState({
      consents: updatedConsentArray,
      consentsTest: updatedConsentArray,
    })
  }

  handleMultiChannelCheckboxClick = (e,consentIndex,channelIndex)=>{
    let updatedConsentArray = [...this.state.consents];
    let consentVersionKey = this.state.consentVersionKey;
    updatedConsentArray[consentIndex]['multi_consents_value'][channelIndex].isChecked = e.target.checked
    let consents = this.props.allConsents;
    let defaultConsentKey = this.getMultiChannelConsentKey(updatedConsentArray[consentIndex]['multi_consents_value']);
    updatedConsentArray[consentIndex]['defaultCopy'] =
        consents[consentVersionKey] &&
        consents[consentVersionKey][0] &&
        consents[consentVersionKey][0][defaultConsentKey] || "";
        
    // updatedConsentArray[consentIndex]['multi_consents_value'][channelIndex].disabled = e.target.checked
    if(e.target.name === 'Email'){
      const { campaign, formMetaData, allConsents } = this.props;
      if (
        campaign &&
        allConsents &&
        formMetaData &&
        formMetaData.emailData !== undefined
      ) {
        let emailData = formMetaData && formMetaData.emailData;
        emailData[0].fieldLabel = emailData && emailData[0].translatedLabel;
        emailData[0].mandatoryToggle = true;
        const profile = this.state.profile
        const isEmailFieldPresent = this.isProfileFieldPresent(profile, 'email')
        if(e.target.checked){
          updatedConsentArray = updatedConsentArray.filter(e=> e.optInType !== consentTypes.unileverEmailConsent);
        }
        this.setState(
          {
            profile: isEmailFieldPresent ? profile :  [emailData && emailData[0], ...profile],
            consents: updatedConsentArray,
            consentsTest: updatedConsentArray
          },
          () => {
            this.checkValidityOfProfileFields();
            this.updateNonRemovableField();
            this.updateNonRemovableField();
            if(!isEmailFieldPresent){
              const profileFieldLength = this.state.profile.length;
              const findIndex = updatedConsentArray.findIndex(e=> e.optInType === consentTypes.multiChannelUnileverConsent)
              this.handleAccordionExpandCollapse(profileFieldLength + findIndex )
            }
          }
        );
      }
    } else {
       this.setState({
          consents: updatedConsentArray,
          consentsTest: updatedConsentArray,
        })
    }
  }

  isConsentPresent = (consents, consentType) => {
    return consents.some(consent => consent.optInType === consentType);
  };

  isProfileFieldPresent(fields, key) {
    return fields.some(elem => elem.dataType === key);
  }

  isEmailFieldPresent = fields => {
    return fields.some(
      elem => elem.dataType === 'email' && elem.mandatoryToggle
    );
  };

  isMobileNumberFieldPresent = fields => {
    return fields.some(
      elem => elem.dataType === 'mobileNumber' && elem.mandatoryToggle
    );
  };

  updateNonRemovableField() {
    const fields = this.state.profile;
    const isEmailPresent = this.isEmailFieldPresent(fields);
    const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
    if (isEmailPresent && isMobileNumberPresent) {
      this.setState({ nonRemovableField: '' });
    } else if (isEmailPresent && !isMobileNumberPresent) {
      this.setState({ nonRemovableField: 'email' });
    } else if (!isEmailPresent && isMobileNumberPresent) {
      this.setState({ nonRemovableField: 'mobileNumber' });
    }
  }

  updateNonRemovableConsents(isRemoving, options={}) {
    const { campaign } = this.props;
    const {
      brand: { code: brandCode }
    } = campaign;
    const {
      profile
    } = this.state;
    const countryCode = campaign.country && campaign.country.code;
    const isEpsilonMarket =
      countryCode && epsilonMarket(countryCode) ? true : false;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)
    const IsBrandEmailsmsConsentVisible = isBrandEmailSmsConsentVisible(this.props.campaignConfig, countryCode, brandCode)
    const isIncentiveCamp = this.props.campaign && this.props.campaign.campaignType === 'Incentive'

    const isMultiChannelConsentPresent = this.isConsentPresent(
      this.state.consents,
      consentTypes.multiChannelUnileverConsent
    );
    if(isMultiChannelConsentPresent){
      return
    }

    if(isEpsilonMarket && this.props.campaignConfig.US_BRAND_SMS_CONSENT_ENABLE){
      this.addUnileverConsent('mobileNumber', consentTypes.brandSMSConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving
      });
    }
    if(isUnbundledMarket){
      this.addUnileverConsent('email', consentTypes.unileverEmailOnlyConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving
      });
  
      this.addUnileverConsent('email', consentTypes.unileverOnlineOnlyConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving
      });
    } else if(isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT && !IsBrandEmailsmsConsentVisible){
      this.addUnileverConsent('email', consentTypes.combinedUnileverAndBrandEmailConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving
      });
    } else {
      this.addUnileverConsent('email', consentTypes.unileverEmailConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving,
        alternateConsentType: consentTypes.brandEmailConsent
      });
    }
  
    if (!isEpsilonMarket) {
     
      if(isUnbundledMarket){
      this.addUnileverConsent('mobileNumber', consentTypes.unileverSMSOnlyConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving
      });

      this.addUnileverConsent('mobileNumber', consentTypes.unileverOnlineOnlyConsent, {
        isEpsilonMarket,
        nonRemovable: true,
        isRemoving
      });
    } else {
      if(!options.doNotUpdateConsent){
        const isMobileFieldPresent = this.isProfileFieldPresent(profile, 'mobileNumber')
        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          options.givenConsents ? options.givenConsents : this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        const isUnileverSMSConsentPresent = this.isConsentPresent(
          options.givenConsents ? options.givenConsents : this.state.consents,
          consentTypes.unileverSMSConsent
        );
        const isBrandSmsPresent = this.isConsentPresent(
          options.givenConsents ? options.givenConsents : this.state.consents,
          consentTypes.brandSMSConsent
        );
        if(!isBrandSmsPresent && !isUnileverWhatsAppConsentPresent){
          this.addUnileverConsent('mobileNumber', consentTypes.unileverSMSConsent, {
            isEpsilonMarket,
            isRemoving,
            nonRemovable: true,
            alternateConsentType: consentTypes.brandSMSConsent
          });
        }
        if(!isBrandSmsPresent && !isUnileverSMSConsentPresent && !isMobileFieldPresent){
        this.addUnileverConsent('mobileNumber', consentTypes.unileverWhatsAppConsent, {
          isEpsilonMarket,
          isRemoving,
          nonRemovable: true
        });
       }
       if(!isUnileverSMSConsentPresent && !isUnileverWhatsAppConsentPresent && !isMobileFieldPresent){
        this.addUnileverConsent('mobileNumber', consentTypes.brandSMSConsent, {
          isEpsilonMarket,
          isRemoving,
          nonRemovable: true
        });
       }
       if(!isUnileverSMSConsentPresent && !isBrandSmsPresent && isUnileverWhatsAppConsentPresent && isMobileFieldPresent){
        this.addUnileverConsent('mobileNumber', consentTypes.unileverWhatsAppConsent, {
          isEpsilonMarket,
          isRemoving,
          nonRemovable: true
        });
       }
       const editCampaign = this.props.campaign.isEdited;
       const isMobileNumberPresent = this.isMobileNumberFieldPresent(profile);
       const isEditAndMobileFlag =  editCampaign  && isMobileNumberPresent ?  false : true 
       if (!isUnileverSMSConsentPresent  && !isUnileverWhatsAppConsentPresent && (!isEditAndMobileFlag || (isMobileNumberPresent && isBrandSmsPresent))) {
         this.addConsentToNonRemovable(consentTypes.brandSMSConsent,isIncentiveCamp ? false : true);
       }
      }
    }
    }
  }

  updateMandatoryToggleForConsent = (consentType)=>{
    const { consents } = this.state;
    const consentField = consents.find(consent => consent.optInType === consentType);
    const cosentIndex = consents.findIndex(consent => consent.optInType === consentType)
    consentField['consentType'] = 'mandatory';
    if(cosentIndex > -1){
      this.updateConsent(consentField,cosentIndex);
    }
  }

  addUnileverConsent = (
    key,
    consentType,
    { nonRemovable, isEpsilonMarket, isRemoving, alternateConsentType } = {}
  ) => {
    const { profile: fields, consents } = this.state;
    const { campaign , campaignConfig} = this.props;
    const countryCode = campaign.country && campaign.country.code;
    const brandCode = campaign.brand.code;
    const isProfileFieldPresent = this.isProfileFieldPresent(fields, key);
    if (isProfileFieldPresent) {
      const isUnileverConsentPresent = this.isConsentPresent(
        consents,
        consentType
      );
      let flag = false;
      let isBothPresent = false;
      if (alternateConsentType) {
        flag = this.isConsentPresent(consents, alternateConsentType);
      }

      if(isUnileverConsentPresent && flag){
        isBothPresent = true
      }
      const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)
      let tempFlag = true
      if(key === 'mobileNumber' && isUnbundledMarket){
        const isMobileFieldPresent = this.isProfileFieldPresent(fields, 'email');
        if(isMobileFieldPresent){
          //isBothPresent = true
          tempFlag = false
        }
      }

      
      if(key === 'email' && isUnbundledMarket){
        const isEmailFieldPresent = this.isProfileFieldPresent(fields, 'mobileNumber');
        const isConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverEmailOnlyConsent
        );
        if(isEmailFieldPresent && !isConsentPresent){
          //isBothPresent = true
          tempFlag = false
        }
      }
      
      // let isUnileverConsentInDb = false
      // let savedConsents = campaign && campaign.campaignDetail &&  campaign.campaignDetail.consents || [];
      // if(savedConsents && savedConsents.length){
      //    isUnileverConsentInDb = this.isConsentPresent(
      //     savedConsents,
      //     consentType
      //   );
      // }
    //  if(this.props.campaign &&
    //   this.props.campaign.campaignType !== 'Incentive'){
    //   if(isUnileverConsentPresent && !isUnileverConsentInDb && key === 'email' && consentType === consentTypes.unileverEmailOnlyConsent){
    //     this.updateMandatoryToggleForConsent(consentType)
    //   } else if (isUnileverConsentPresent && !isUnileverConsentInDb && key === 'mobileNumber' && consentType === consentTypes.unileverSMSOnlyConsent){
    //     this.updateMandatoryToggleForConsent(consentType)
    //   } else if(isUnileverConsentPresent && !isUnileverConsentInDb && key === 'email' && consentType === consentTypes.unileverEmailConsent){
    //     this.updateMandatoryToggleForConsent(consentType)
    //   } else if(isUnileverConsentPresent && !isUnileverConsentInDb && key === 'mobileNumber' && consentType === consentTypes.unileverSMSConsent){
    //     this.updateMandatoryToggleForConsent(consentType)
    //   }
    // }
      const shouldAddConsent = !isUnileverConsentPresent? !flag : true; 
      if (shouldAddConsent && !isRemoving) {
        this._handleConsentField(
          getUnileverConsent(consentType, isEpsilonMarket, countryCode,brandCode,campaignConfig)
        );
      }

      if (
        nonRemovable ||
        (key === 'mobileNumber' && !this.isProfileFieldPresent(fields, 'email'))
      ) {
        if(!isBothPresent) {
          let consentToAdd = consentType
          if(!isUnileverConsentPresent && flag) {
            consentToAdd = alternateConsentType
          }
          // isUnbundledMarket ? false : true
          const isIncentiveCamp = this.props.campaign && this.props.campaign.campaignType === 'Incentive'
          this.addConsentToNonRemovable(consentToAdd, isIncentiveCamp ? false : (consentType === consentTypes.unileverOnlineOnlyConsent || consentType === consentTypes.combinedUnileverAndBrandEmailConsent || (isEpsilonMarket && consentType === consentTypes.brandSMSConsent) ? false :true));
        } else {

          if(consentType !== consentTypes.unileverOnlineOnlyConsent) {
            this.removeConsentFromNonRemovable(consentType);
          }
          if(alternateConsentType) {
             this.removeConsentFromNonRemovable(alternateConsentType);
          }
        }
      } else {
          if(consentType !== consentTypes.unileverOnlineOnlyConsent) {
            this.removeConsentFromNonRemovable(consentType);
          }
      }
    } else {
            if(consentType !== consentTypes.unileverOnlineOnlyConsent) {
            this.removeConsentFromNonRemovable(consentType);
      }
      if(alternateConsentType){
        this.removeConsentFromNonRemovable(alternateConsentType);
      }
    }
  };

  addConsentToNonRemovable(consentType, adjustMandatory=false) {
    this.setState(({ nonRemovableConsents, consents }) => {
      let consentList = [...consents];
      if(adjustMandatory){
        consentList = this.makeConsentMandatory(
          consentList,
          consentType
        );
      }
      if (nonRemovableConsents.includes(consentType)) {
        return adjustMandatory? {consents: consentList} : {};
      } else {
        return { nonRemovableConsents: [...nonRemovableConsents, consentType], consents: consentList};
      }
    });
  }

  removeConsentFromNonRemovable(consentType) {
    this.setState(({ nonRemovableConsents }) => {
      const consentIndex = nonRemovableConsents.findIndex(
        elem => elem === consentType
      );

      if (consentIndex >= 0) {
        nonRemovableConsents.splice(consentIndex, 1);
        return {
          nonRemovableConsents: [...nonRemovableConsents]
        };
      } else {
        return {};
      }
    });
  }

  removeConsentForMultiChannel = (removeConsentArr) => {
    let updatedConsentArray = [...this.state.consents];
    for(const givenOptInType of removeConsentArr){
      updatedConsentArray = updatedConsentArray.filter(e=> e.optInType !== givenOptInType) 
    }
    this.setState({
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentArray]
    });
  }

  _handleQuestionType = (e, index, elem) => {
    const { errors } = this.state;
    let selectedValue = e.value;
    let newProfile = [...this.state.profile];
    newProfile[index] = { ...newProfile[index], questionType: selectedValue };
    const updatedErrors = { ...errors };
    const errorIds = Object.keys(errors);
    errorIds.forEach(id => {
      if (
        id.includes('province_Error_Message_') ||
        id.includes('answerTranslatedLabel_')
      ) {
        delete updatedErrors[id];
      }
    });
    this.setState(
      {
        profile: [...newProfile],
        errors: updatedErrors
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  _filterFormField = list => {
    let formEl = list.filter(el => {
      return el.dataType.includes('paragraph') ||
        el.dataType.includes('heading')
        ? el
        : null;
    });
    return formEl;
  };

  getTsKeyFromOptInType = optInType => {
    const mapping = constant.consents.find(c => c.optInType === optInType);
    return mapping && mapping.tsKey;
  };

  checkAgeValidation(profileItem) {
    let isValid = true;
    if (
      profileItem &&
      profileItem.dataType === 'dateOfBirth' &&
      (!profileItem.validationMessage ||
        profileItem.validationMessage.trim().length === 0)
    ) {
      isValid = false;
    }
    return isValid;
  }

  validateAgeValidation(profileItem) {
    let isValid = true;
    if (
      profileItem &&
      profileItem.dataType === 'dateOfBirth' &&
      (!profileItem.validationMessage ||
        profileItem.validationMessage.trim().length === 0)
    ) {
      isValid = false;
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          [`ageValidation_${profileItem.dataType}`]: true
        }
      }));
    }
    return isValid;
  }

  validateHeadingField = (elem, index) => {
    let isValid = true;

    if (elem.heading.trim().length === 0) {
      isValid = false;
      this.setState(({ errors, fieldsValidity }) => ({
        errors: {
          ...errors,
          [`heading_error_${elem.dataType}_${index}`]: true
        },
        fieldsValidity: { ...fieldsValidity, [index]: false }
      }));
    } else {
      this.setState(({ errors, fieldsValidity }) => ({
        errors: {
          ...errors,
          [`heading_error_${elem.dataType}_${index}`]: false
        },
        fieldsValidity: { ...fieldsValidity, [index]: true }
      }));
    }
    return isValid;
  };

  checkHeadingField = (elem, index) => {
    const value = elem.heading.trim();
    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: {
        ...fieldsValidity,
        [index]: value.length ? true : false
      }
    }));
  };

  checkParagraphField = (paragraph, index) => {
    let isValid = true;
    if (
      paragraph
        .replace(/\r?\n|\r/gm, '')
        .replace(/<[^>]+>/gm, '')
        .replace(/&nbsp;/gm, '')
        .trim().length === 0
    ) {
      isValid = false;
    }

    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: { ...fieldsValidity, [index]: isValid }
    }));
    return isValid;
  };

  checkValidationErrorMessage(elem, index) {
    let isValid = true;
    if (
      elem &&
      (!elem.validationMessage || elem.validationMessage.trim().length === 0)
    ) {
      isValid = false;
    }
    return isValid;
  }

  validateValidationErrorMessage(elem, index) {
    let isValid = true;
    if (
      elem &&
      (!elem.validationMessage || elem.validationMessage.trim().length === 0)
    ) {
      isValid = false;

      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          [`formatValidation_${elem.dataType}`]: true
        }
      }));
    } else {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          [`formatValidation_${elem.dataType}`]: false
        }
      }));
    }
    return isValid;
  }

  checkProvinceValidity(stateofProvience) {
    let isValid = true;
    if (stateofProvience.value.trim().length === 0) {
      isValid = false;
    }

    return isValid;
  }

  validateProvince(stateofProvience, ansIndex, index) {
    let isValid = true;
    if (stateofProvience.value.trim().length === 0) {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          [`province_Error_Message_${ansIndex}`]: true,
          [`answerTranslatedLabel_${ansIndex}`]: true
        }
      }));

      isValid = false;
    }
    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: { ...fieldsValidity, [index]: isValid }
    }));
    return isValid;
  }

  validateParagraphField = (paragraph, index, elem) => {
    let isValid = true;
    if (
      paragraph
        .replace(/\r?\n|\r/gm, '')
        .replace(/<[^>]+>/gm, '')
        .replace(/&nbsp;/gm, '')
        .trim().length === 0
    ) {
      isValid = false;
      this.setState(({ errors, fieldsValidity }) => ({
        errors: {
          ...errors,
          [`paragraph_${elem.id}`]: true
        },
        fieldsValidity: { ...fieldsValidity, [index]: false }
      }));
    } else {
      this.setState(({ errors, fieldsValidity }) => ({
        errors: {
          ...errors,
          [`paragraph_${elem.id}`]: false
        },
        fieldsValidity: { ...fieldsValidity, [index]: true }
      }));
    }

    return isValid;
  };

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    let campaignType =
      this.props.campaign &&
      this.props.campaign.campaignType === 'Direct messaging'
        ? 'Direct'
        : this.props.campaign && this.props.campaign.campaignType;

    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    if (epsilonMarket(countrySelected)) {
      this.props.fetchCampaignData(
        this.campaignId,
        false,
        true,
        campaignType,
        countrySelected
      );
    } else {
      this.props.fetchCampaignData(
        this.campaignId,
        false,
        true,
        campaignType,
        ''
      );
    }

    this.setState(state => {
      return { ...state, ...{ campaignId: this.campaignId } };
    });

    this.props.getQuestions();
    this.props.getAnswers();
    this.props.getCampaignDetails(this.props.match.params.id);
    this._maxAnswersOptions();
    this.props.fetchCampaignData(
      this.props.match.params.id,
      false,
      true,
      this.props &&
        this.props.campaignDetails &&
        this.props.campaignDetails.campaignType === 'Direct messaging'
        ? 'Direct'
        : this.props &&
            this.props.campaignDetails &&
            this.props.campaignDetails.campaignType,
      ''
    );
    // this.getSavedQnAFields();
    this.handleAccordionExpandCollapse(-1);
    this.language =
      this.props.campaign && this.props.campaign.language
        ? this.props.campaign.language.code
        : '';
    if (this.language) {
      this.props.getGenericFieldData(this.language);
    }
  }

  handleCampaignArrival(campaign) {
    let fields = [];
    const { allConsents } = this.props;
    this.status = campaign && campaign.status;
    let language = campaign.language ? campaign.language.code : '';
    const countryCode = campaign.country.code;
    const brandCode = campaign.brand.code;
    this.props.getIncentiveFields(language);
    this.setConsentAndComplianceFields({
      countryCode,
      brandCode,
      campaign
    });
    fields =
      campaign && campaign.campaignDetail && campaign.campaignDetail.fields;
    let instantWinLoseFlag =
      campaign &&
      campaign.campaignDetail &&
      campaign.campaignDetail.instantWinLose;
    if (instantWinLoseFlag) {
      this.setState({
        instantWinLose: instantWinLoseFlag
      });
    }
    if (fields.length > 0) {
      let stateOrProvinceObj = fields.find(
        elem =>
          elem.dataType === 'stateOrProvince' &&
          elem.questionType === 'Single choice'
      );
      // for legacy video option I have added
      if (fields && fields.length > 0) {
        fields.forEach(ele => {
          if (ele.dataType === 'fileUpload') {
            if (ele.values && ele.values.length) {
              let findVideoIndex = ele.values.findIndex(
                v => v.key === constant.INCENTIVE_VIDEO_OPTION_KEY
              );
              if (findVideoIndex === -1) {
                let newValues = [...ele.values];
                newValues.push({
                  key: constant.INCENTIVE_VIDEO_OPTION_KEY,
                  value: constant.INCENTIVE_VIDEO_OPTION_VALUE,
                  isChecked: true
                });
                ele.values = newValues;
              }
              let findPdfIndex = ele.values.findIndex(v => v.key === 'pdf');
              if (findPdfIndex === -1) {
                let newValues = [...ele.values];
                newValues.push({
                  key: 'pdf',
                  value: 'pdf',
                  isChecked: true
                });
                ele.values = newValues;
              }
              let findDocIndex = ele.values.findIndex(
                v => v.key === 'doc/docx'
              );
              if (findDocIndex === -1) {
                let newValues = [...ele.values];
                newValues.push({
                  key: 'doc/docx',
                  value: 'doc, docx',
                  isChecked: true
                });
                ele.values = newValues;
              }
            }
          }
        });
      }
      let isCampaignIncentive = campaign && campaign.campaignType === 'Incentive';
      const isMasterDataOther = campaign && campaign.isMasterData && campaign.campaignDetail && campaign.campaignDetail.incentiveItPartner === 'other' ? true : false;
      if(isCampaignIncentive && isMasterDataOther){
        fields = fields.filter(e=> e.type !== 'incentiveFields');
      }
      this.setState(
        {
          profile: fields,
          profileRichText: fields,
          provinces:
            stateOrProvinceObj && stateOrProvinceObj['answers']
              ? stateOrProvinceObj['answers']
              : [{ value: '', label: '' }],
          //         expand: fields.length,
          isLegalAge:
            campaign.campaignDetail && campaign.campaignDetail.ageOfConsent,
          loadedDefaultValues: true,
          isLoading: false
          //         incentiveFields: fields
        },
        () => {
          this.setInitialMaximumQualifyingQuestionErrorIndices();
          this.updateNonRemovableField();
          // if (allConsents) {
          //   this.updateNonRemovableConsents();
          // }
          this.addSampleDetailsField(campaign);
          this.checkAllFields();
        }
      );
      //       this.handleAccordionExpandCollapse(-1);
    } else {
      this.setDefaultFields();
    }
    this.setState({
      campaignFetched: true,
      listItems: this._getCampaignConfigAccordionList(campaign)
    });
    this.setInitialValuesOfSubmitButton(campaign);
  }

  addSampleDetailsField(campaign) {
    const { incentiveFields } = this.props;
    let isProductSample =
      campaign && campaign.incentiveType === 'Product samples';
    if (isProductSample && incentiveFields && incentiveFields.length && !(campaign && campaign.isMasterData && campaign.campaignDetail && campaign.campaignDetail.incentiveItPartner === 'other')) {
      this._handleIncentiveField(constant.sampleDetails[0]);
    }
  }

  setDefaultFields = () => {
    const { campaign, formMetaData, allConsents } = this.props;
    const { loadedDefaultValues } = this.state;
    if (
      !loadedDefaultValues &&
      campaign &&
      allConsents &&
      formMetaData &&
      formMetaData.emailData !== undefined
    ) {
      let emailData = formMetaData && formMetaData.emailData;
      emailData[0].fieldLabel = emailData && emailData[0].translatedLabel;
      emailData[0].mandatoryToggle = true;
      this.setState(
        ({ profile }) => ({
          profile: [emailData && emailData[0], ...profile],
          loadedDefaultValues: true,
          isLoading: false
        }),
        () => {
          this.updateNonRemovableField();
          if (allConsents) {
            this.updateNonRemovableConsents();
          }
          this.addSampleDetailsField(campaign);
          this.checkAllFields();
        }
      );
    }
  };

  getConsentVersionLabelFromKey = key => {
    return constant.consentLabels[key];
  };

  componentDidUpdate(prevProps) {
    const {
      campaign = {},
      allConsents,
      formConsentData,
      incentiveFields,
      formMetaData
    } = this.props;
    const prevLanguage =
      prevProps.campaign && prevProps.campaign.language
        ? prevProps.campaign.language.code
        : '';
    const language =
      campaign && campaign.language ? campaign.language.code : '';
    if (prevLanguage !== language) {
      this.props.getGenericFieldData(language);
    }

    const genericData =
      this.props &&
      this.props.formGenericData &&
      this.props.formGenericData.genericData;
    const prevGenericData =
      prevProps &&
      prevProps.formGenericData !== undefined &&
      prevProps.formGenericData &&
      prevProps.formGenericData.genericData;
    if (genericData !== prevGenericData) {
      this.setInitialValuesOfSubmitButton(campaign);
      this.setSubmitFieldData();
    }

    if (prevProps.campaign !== campaign && !_.isEmpty(campaign)) {
      this.handleCampaignArrival(campaign);
    }

    if (
      !_.isEqual(prevProps.allConsents, allConsents) &&
      !_.isEmpty(campaign)
    ) {
      if (allConsents) {
        if (
          allConsents[
            this.getConsentVersionLabelFromKey('enterpriseConsentsVersion3')
          ].length === 0
        ) {
          this.setState({ isConsentsAvailableFromAPI: false });
        }
      }

      this.setInitialConsentVersion(campaign);
    }
    if (
      !_.isEqual(prevProps.formConsentData, formConsentData) &&
      !_.isEmpty(campaign)
    ) {
      this.setInitialConsentVersion(campaign);
    }

    if (!_.isEqual(prevProps.incentiveFields, incentiveFields)) {
      this.addSampleDetailsField(campaign);
    }

    if (
      (formMetaData && formMetaData.emailData) !==
        (prevProps.formMetaData && prevProps.formMetaData.emailData) ||
      allConsents !== prevProps.allConsents
    ) {
      if (allConsents) {
        this.setDefaultFields();
      }
    }

    if (prevProps.allConsents !== allConsents) {
        this.updateNonRemovableConsents(false, {givenConsents : campaign.campaignDetail.consents});
    }
  }

  setInitialConsentVersion(campaign) {
    const { initializedConsentVersion } = this.state;
    const { formConsentData, allConsents: consentsFromAPI } = this.props;
    let profileFieldList =
    this.props.formMetaData.accordionData || constant.profileFields;
    if (
      !initializedConsentVersion &&
      campaign &&
      formConsentData &&
      consentsFromAPI
    ) {
      if (isConsentVersionUpdateRequired(campaign)) {
        this._handleConsentVersion(
          { value: 'enterpriseConsentsVersion3' },
          { isConsentVersionBeingUpdated: true }
        );
      } else {
        const consentsVersion = getConsentVersion(campaign);
        const consentVersionKey = constant.consentLabels[consentsVersion];
        let consents = campaign.campaignDetail.consents;
        const countryCode = campaign.country && campaign.country.code;
        const consentsForSelectedVersion =
          consentsFromAPI && consentsFromAPI[consentVersionKey];
        const { termsAndConditionsData,unileverEmailConsentData } = getConsentData(formConsentData);
        const isEpsilonMarket = countryCode && epsilonMarket(countryCode) ? true : false;
        const brandCode =  campaign && campaign.brand && campaign.brand.code;
        const IsBrandEmailsmsConsentVisible = countryCode && brandCode &&  isBrandEmailSmsConsentVisible(this.props.campaignConfig, countryCode, brandCode)
        const isCombinedStatement = isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT && !IsBrandEmailsmsConsentVisible
        const { campaignType, isEdited, isClone } = campaign;
        let consentObj = consentsForSelectedVersion[0];
        let combinedConsentsArr = []
        if(isCombinedStatement){
          const isCombinedUnileverBrandPresent = this.isConsentPresent(
            consents,
            consentTypes.combinedUnileverAndBrandEmailConsent
          );
          if(!isCombinedUnileverBrandPresent){
            combinedConsentsArr.push(consentTypes.combinedUnileverAndBrandEmailConsent);
          }
          if(combinedConsentsArr && combinedConsentsArr.length){
            let defaultList = constant.consents;
            if((isEdited || isClone) && isCombinedStatement){
              defaultList.map(elem => {
                  let key = elem.tsKey;
                  if (combinedConsentsArr.includes(elem.optInType)) {
                  elem.defaultCopy = consentObj && consentObj[key] ? consentObj[key] : '';
                  elem.tsCopy = consentObj && consentObj[key] ? consentObj[key] : '';
                  elem.consentType = 'mandatory';
                  if(elem.optInType === consentTypes.combinedUnileverAndBrandEmailConsent){
                    elem.serviceId =  '';
                    elem.mandatoryMessage =
                      unileverEmailConsentData &&
                      unileverEmailConsentData.translatedLabel
                        ? unileverEmailConsentData.translatedLabel
                        : '';
                  }
                  consents.push(elem);
                }
                return elem;
              });
            }
          }
        } 
        if (
          campaign.campaignDetail.consents &&
          campaign.campaignDetail.consents.length > 0
        ) {
          if (!isEdited || isClone) {
            if (campaignType === 'Incentive') {
              const termAndConditionIndex = consents.findIndex(
                e => e.optInType === 'termsAndConditions'
              );
              if (termAndConditionIndex === -1) {
                let defaultList = constant.consents;
                defaultList.map(elem => {
                  let key = elem.tsKey;
                  if (elem.optInType === 'termsAndConditions') {
                    elem.defaultCopy = consentObj && consentObj[key] ? consentObj[key] : '';
                    elem.tsCopy = consentObj && consentObj[key] ? consentObj[key] : '';
                    elem.consentType = 'mandatory';
                    if (elem.optInType === 'termsAndConditions') {
                      elem.mandatoryMessage =
                        termsAndConditionsData &&
                        termsAndConditionsData.translatedLabel
                          ? termsAndConditionsData.translatedLabel
                          : '';
                    }
                    consents.push(elem);
                  }
                  return elem;
                });
              }
            }
          }
          this.setLegalAge(campaign, consents);

          const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)
          
          if(isClone && !isEdited){
            const isUnileverSmsPresent = this.isConsentPresent(
              consents,
              consentTypes.unileverSMSConsent
            );
            const isUnileverEmailPresent = this.isConsentPresent(
              consents,
              consentTypes.unileverEmailConsent
            );
            const isBrandSmsPresent = this.isConsentPresent(
              consents,
              consentTypes.brandSMSConsent
            );
            const isBrandEmailPresent = this.isConsentPresent(
              consents,
              consentTypes.brandEmailConsent
            );
            const fields = this.state.profile;
            if(isUnileverSmsPresent){
              const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
              if (!isMobileNumberPresent) {
                const mobileField = profileFieldList.find(
                  elem => elem.dataType === 'mobileNumber'
                );
                if (mobileField) {
                  this.handleAddField(mobileField, {doNotUpdateConsent: true});
                }
              }else {
                this.addUnileverConsent('mobileNumber', consentTypes.unileverSMSOnlyConsent, {
                  isEpsilonMarket,
                  nonRemovable: true
                });
              }
            }
            if(isUnileverEmailPresent){
              const isEmailNumberPresent = this.isEmailFieldPresent(fields);
              if (!isEmailNumberPresent) {
                const emailField = profileFieldList.find(
                  elem => elem.dataType === 'email'
                );
                if (emailField) {
                  this.handleAddField(emailField, {doNotUpdateConsent: true});
                }
              } else {
                this.addUnileverConsent('email', consentTypes.unileverEmailOnlyConsent, {
                  isEpsilonMarket,
                  nonRemovable: true
                });
              }
            }

            if(isBrandSmsPresent){
              const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
              if (!isMobileNumberPresent) {
                const mobileFieldForBrand = profileFieldList.find(
                  elem => elem.dataType === 'mobileNumber'
                );
                if (mobileFieldForBrand) {
                  this.handleAddField(mobileFieldForBrand, {doNotUpdateConsent: true});
                }
              }
            }

            if(isBrandEmailPresent){
              const isEmailNumberPresent = this.isEmailFieldPresent(fields);
              if (!isEmailNumberPresent) {
                const emailFieldForBrand = profileFieldList.find(
                  elem => elem.dataType === 'email'
                );
                if (emailFieldForBrand) {
                  this.handleAddField(emailFieldForBrand, {doNotUpdateConsent: true});
                }
              }
            }
          }

          if(isUnbundledMarket && consents && consents.length && isClone){
            consents = consents.filter(e=> e.optInType !== consentTypes.unileverEmailConsent &&  e.optInType !== consentTypes.unileverSMSConsent)
          }
          if(isCombinedStatement && consents && consents.length && (isEdited || isClone)){
            consents = consents.filter(e=> e.optInType !== consentTypes.unileverEmailConsent &&  e.optInType !== consentTypes.brandEmailConsent)
          }
          if(!isCombinedStatement && consents && consents.length){
            consents = consents.filter(e=> e.optInType !== consentTypes.combinedUnileverAndBrandEmailConsent)
          }

          const isIncentiveCamp = this.props.campaign && this.props.campaign.campaignType === 'Incentive'
          consents && consents.length && consents.forEach(ele=>{
            // this is for removing for this bug https://ce-platform.atlassian.net/browse/WTG-14955
            /*if(isIncentiveCamp && (incentiveNonMandatoryConsents.includes(ele && ele.optInType))){
              ele.consentType = 'optional';
            } else*/
             if(isIncentiveCamp && ((isEdited || isClone) && ele.optInType === consentTypes.termsAndConditions)){
              ele.consentType = 'mandatory';
            } else if(ele.consentType === 'optional' && (campaign && campaign.isPlatformAgnostic) && (isEdited || isClone) && ele.optInType === consentTypes.legalAgeConsent){
              ele.consentType = 'mandatory';
            }
          })

          consents && consents.length && consents.forEach(ele=>{
            if((isEdited || isClone) && !isEpsilonMarket && ele.optInType === 'legalAgeConsent' && ele.consentType === 'implicit') {
              ele.consentType = 'implicitStandalone';
            } else if((isEdited || isClone) && isUnbundledMarket && ele.optInType != 'contentInterestAreas' && ele.consentType === 'implicit') {
              // this is for removing for this bug https://ce-platform.atlassian.net/browse/WTG-14955
              ele.consentType = 'mandatory';
            }             
          })

          if(this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT){
            const isUnileverSMSConsentPresent = this.isConsentPresent(
              consents,
              consentTypes.unileverSMSConsent
            );
            const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
              consents,
              consentTypes.unileverWhatsAppConsent
            );
            let editCampaign = this.props.campaign.isEdited;
            const isMobileNumberPresent = this.isMobileNumberFieldPresent(this.state.profile);
            const isEditAndMobileFlag =  editCampaign  && isMobileNumberPresent ?  false : true 
            if (!isUnileverSMSConsentPresent  && !isUnileverWhatsAppConsentPresent && !isEditAndMobileFlag) {
              this.addConsentToNonRemovable(consentTypes.brandSMSConsent);
            }
          }
           /*const isMultiChannelConsentPresent = this.isConsentPresent(
              consents,
              consentTypes.multiChannelUnileverConsent
            );
            if (isMultiChannelConsentPresent) {
              this.addConsentToNonRemovable(
                consentTypes.multiChannelUnileverConsent
              );
            }*/
          
          this.setState(
            {
              consents,
              consentsTest: consents,
              emailSenderName: campaign.brand.name,
              consentsVersion,
              consentVersionKey
            },
            this.checkConsentValidity
          );
        }

        if (!isSomeConsentAvailable(consentsForSelectedVersion)) {
          this.setState({ consentsUnavailable: true });
        } else {
          this.setState({ consentsUnavailable: false });
        }
      }
      if (isOlderConsentBeingUpdated(campaign)) {
        this.setState({ consentsUpdatedToEnterpriseMessage: true });
      }

      this.setState({ initializedConsentVersion: true });
    }
  }

  setLegalAge(campaign, consents) {
    let legalAge = getLegalAge(campaign);
    const ageConsentIndex = campaign.campaignDetail.consents.findIndex(
      ele => ele.optInType === 'legalAgeConsent'
    );
    if (ageConsentIndex >= 0) {
      let ageConsentData = campaign.campaignDetail.consents[ageConsentIndex];
      if (
        campaign &&
        campaign.campaignDetail &&
        campaign.campaignDetail.ageOfConsent
      ) {
        ageConsentData['ageOfConsent'] = getAgeOfConsent(campaign);
        legalAge = getAgeOfConsent(campaign);
      } else if (
        ageConsentData &&
        ageConsentData.hasOwnProperty('ageOfConsent')
      ) {
        legalAge = ageConsentData['ageOfConsent'];
      }
      if (
        ageConsentData['ageOfConsent'] === undefined ||
        ageConsentData['ageOfConsent'] === '0'
      ) {
        ageConsentData['ageOfConsent'] = getLegalAge(campaign);
        legalAge = getLegalAge(campaign);
      }
      consents[ageConsentIndex] = ageConsentData;
    }
    this.setState({
      isLegalAge: legalAge
    });
  }

  setInitialMaximumQualifyingQuestionErrorIndices() {
    const { profile: fields } = this.state;
    let qualifyingCount = 0,
      maximumQualifingQuestionsErrorIndices = [];
    fields.forEach((elem, index) => {
      if (elem.qualifyingToggle) {
        qualifyingCount++;
      }
      if (qualifyingCount > 5) {
        maximumQualifingQuestionsErrorIndices.push(index);
      }
    });
    if (qualifyingCount > 5) {
      this.setState({
        maximumQualifingQuestionsErrorIndices
      });
    }
  }

  setSubmitFieldData() {
    const genericData =
      this.props &&
      this.props.formGenericData &&
      this.props.formGenericData.genericData;

    const submitData =
      genericData && genericData.filter(elem => elem.dataType === 'submit');
    this.setState({ submitData });
  }

  setConsentAndComplianceFields({ campaign }) {
    const { getAllConsentsForLocale, getConsentFieldData } = this.props;
    const {
      country: { code: countrySelected },
      language: { code: language },
      brand: { code: brandCode }
    } = campaign;

    getAllConsentsForLocale(brandCode, countrySelected, language);
    getConsentFieldData(language);

    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countrySelected)
    this.optInType = getDefaultOptInTypes(this.props.campaignConfig, campaign,isUnbundledMarket);
    this.setInitialConsentVersion(campaign, { fromAPI: true });
  }

  _handleValidationMessageDisplay = selectedOption => {
    this.setState({
      showAllPurposeError: false
    });
    this.state.consents.forEach((elem, index) => {
      if (
        document.getElementById('consent_error_' + elem.optInType) &&
        !document
          .getElementById('consent_error_' + elem.optInType)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('consent_error_' + elem.optInType) &&
          document
            .getElementById('consent_error_' + elem.optInType)
            .classList.add('cw-hidden');

        document
          .getElementById(`consent_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`consent_${elem.optInType}`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.getElementById('consent_mandatoryMessage_' + elem.optInType) &&
        !document
          .getElementById('consent_mandatoryMessage_' + elem.optInType)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('consent_mandatoryMessage_' + elem.optInType) &&
          document
            .getElementById('consent_mandatoryMessage_' + elem.optInType)
            .classList.add('cw-hidden');

        document
          .getElementById(`errorMessage_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`errorMessage_${elem.optInType}`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.getElementById('frequency_error_' + elem.optInType) &&
        !document
          .getElementById('frequency_error_' + elem.optInType)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('frequency_error_' + elem.optInType) &&
          document
            .getElementById('frequency_error_' + elem.optInType)
            .classList.add('cw-hidden');

        document
          .getElementById(`frequency_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`frequency_${elem.optInType}`)
            .classList.remove('cw-error-focus');

        document
          .getElementById(`errorMessage_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`errorMessage_${elem.optInType}`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.getElementById('ageErrorMessage') &&
        !document
          .getElementById('ageErrorMessage')
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('ageErrorMessage') &&
          document.getElementById('ageErrorMessage').classList.add('cw-hidden');

        document
          .getElementById(`ageOfConsent`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`ageOfConsent`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.querySelector("[data-custom-msg='emailSenderName']") &&
        !document
          .querySelector("[data-custom-msg='emailSenderName']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector('[data-custom-msg=' + 'emailSenderName' + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`emailSenderName`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`emailSenderName`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.querySelector("[data-custom-msg='replyToEmailAdderess']") &&
        !document
          .querySelector("[data-custom-msg='replyToEmailAdderess']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector('[data-custom-msg=' + 'replyToEmailAdderess' + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`replyToEmailAdderess`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`replyToEmailAdderess`)
            .classList.remove('cw-error-focus');
      }
    });
  };

  isEpsilonMarket = campaign => {
    const countrySelected = campaign.country && campaign.country.code;
    return countrySelected && epsilonMarket(countrySelected) ? true : false;
  };

  _handleConsentVersion = (
    selectedOption,
    { isConsentVersionBeingUpdated } = {}
  ) => {
    const {
      formConsentData,
      allConsents: consentsFromAPI,
      campaign
    } = this.props;
    if (
      !this.state.loadedDefaultConsents &&
      formConsentData &&
      consentsFromAPI
    ) {
      let isEpsilonMarket = this.isEpsilonMarket(campaign);
      const { campaignType ,isEdited,isClone} = campaign;
      if (!isEdited || isClone) {
        if (campaignType === 'Incentive') {
          DEFAULT_CONSENTS.push(consentTypes.termsAndConditions);
        } else {
          const index = DEFAULT_CONSENTS.indexOf(
            consentTypes.termsAndConditions
          );
          if (index > -1) {
            DEFAULT_CONSENTS.splice(index, 1);
          }
        }
      }
      const countrySelected = campaign.country && campaign.country.code;
      const brandCode =  campaign && campaign.brand && campaign.brand.code;
      const IsBrandEmailsmsConsentVisible = countrySelected && brandCode &&  isBrandEmailSmsConsentVisible(this.props.campaignConfig, countrySelected, brandCode)
      const isCombinedStatement = isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT && !IsBrandEmailsmsConsentVisible
      if(isCombinedStatement){
        DEFAULT_CONSENTS.push(consentTypes.combinedUnileverAndBrandEmailConsent);
      } else {
        let index = DEFAULT_CONSENTS.indexOf(
          consentTypes.combinedUnileverAndBrandEmailConsent
        );
        if (index > -1) {
          DEFAULT_CONSENTS.splice(index, 1);
        }
      }
      this._handleValidationMessageDisplay(selectedOption);
      let ver = constant.consentLabels[selectedOption.value];
      let consentsForSelectedVersion = consentsFromAPI && consentsFromAPI[ver];
      let areConsentsAvailable = false;
      if (!isSomeConsentAvailable(consentsForSelectedVersion)) {
        areConsentsAvailable = false;
        this.setState({ consentsUnavailable: true });
        const defaultConsents = { ...constant.defaultConsents };
        if (selectedOption.value === 'brandUnileverVersion2') {
          delete defaultConsents.age_consent;
        }
        consentsForSelectedVersion = [defaultConsents];
      } else {
        areConsentsAvailable = true;
        consentsForSelectedVersion = insertDefaultConsentsIfNotAvailable(
          consentsForSelectedVersion
        );
        this.setState({ consentsUnavailable: false });
      }

      let defaultList = constant.consents;
      let selectedConsents = [];

      const {
        ageConsentData,
        brandEmailConsentData,
        brandSMSConsentData,
        unileverEmailConsentData,
        unileverSMSConsentData,
        termsAndConditionsData,
        unileverEmailOnlyConsentData,
        unileverSMSOnlyConsentData,
        unileverOnlineOnlyConsentData,
        unileverWhatsAppConsentData,
        brandWhatsAppConsentData,
        multiChannelUnileverConsentData
      } = getConsentData(formConsentData);
      const existAgeOfConsent = isConsentVersionBeingUpdated
        ? getLegalAge(this.props.campaign)
        : (campaign &&
            campaign.campaignDetail &&
            campaign.campaignDetail.ageOfConsent) ||
          getLegalAge(this.props.campaign);

      let areConsentFieldsPopulated = false;

      const countryCode = campaign.country && campaign.country.code;
      const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)

      if (consentsForSelectedVersion && consentsForSelectedVersion.length > 0) {
        let consentObj = consentsForSelectedVersion[0];

        if (selectedOption.value === 'brandUnileverVersion2') {
          let ageOfConsent = {
            consentType: 'implicit',
            label: 'Age Consent',
            optInType: 'legalAgeConsent',
            mandatoryMessage:
              ageConsentData && ageConsentData.translatedLabel
                ? ageConsentData.translatedLabel
                : '',
            defaultCopy: constant.defaultConsents.age_consent,
            ageOfConsent: existAgeOfConsent
          };
          selectedConsents.push(ageOfConsent);
        }

        defaultList.map(elem => {
          let key = elem.tsKey;
          if (DEFAULT_CONSENTS.includes(elem.optInType)) {
            areConsentFieldsPopulated = true;
            elem.defaultCopy = consentObj && consentObj[key] ? consentObj[key] : '';
            elem.tsCopy = consentObj && consentObj[key] ? consentObj[key] : '';
            elem.consentType = 'mandatory';
            if (elem.optInType === 'brandEmailConsent') {
              if(isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT ){
                elem.consentType = 'implicitStandalone';
              }
              elem.serviceId = !isEpsilonMarket
                ? 'BRAND_' +
                  campaign.country.code +
                  '_' +
                  campaign.brand.code +
                  '_EML'
                : '';
              elem.emailSenderName = campaign.brand.name;
              elem.mandatoryMessage =
                brandEmailConsentData && brandEmailConsentData.translatedLabel
                  ? brandEmailConsentData.translatedLabel
                  : '';
            } else if (elem.optInType === 'brandSMSConsent') {
              elem.serviceId = !isEpsilonMarket
                ? 'BRAND_' +
                  campaign.country.code +
                  '_' +
                  campaign.brand.code +
                  '_SMS'
                : '';
              elem.mandatoryMessage =
                brandSMSConsentData && brandSMSConsentData.translatedLabel
                  ? brandSMSConsentData.translatedLabel
                  : '';
            } else if (!isUnbundledMarket && elem.optInType === consentTypes.unileverEmailConsent) {
              elem.serviceId = !isEpsilonMarket
                ? 'UNILEVER_' + campaign.country.code + '_EML'
                : '';
              elem.mandatoryMessage =
                unileverEmailConsentData &&
                unileverEmailConsentData.translatedLabel
                  ? unileverEmailConsentData.translatedLabel
                  : '';
              if (
                !areConsentsAvailable &&
                (isEpsilonMarket ||
                  selectedOption.value === 'brandUnileverVersion2')
              ) {
                elem.consentType = 'implicit';
              }
              if(isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT){
                elem.consentType = 'implicitStandalone';
              }
            } else if (!isUnbundledMarket && elem.optInType === consentTypes.unileverSMSConsent) {
              elem.serviceId = !isEpsilonMarket
                ? 'UNILEVER_' + campaign.country.code + '_SMS'
                : '';
              elem.mandatoryMessage =
                unileverSMSConsentData && unileverSMSConsentData.translatedLabel
                  ? unileverSMSConsentData.translatedLabel
                  : '';
            } else if (elem.optInType === 'legalAgeConsent') {
              elem.ageOfConsent = existAgeOfConsent;

              if (isEpsilonMarket) {
                elem.consentType = 'implicit';
              }
              elem.mandatoryMessage =
                ageConsentData && ageConsentData.translatedLabel
                  ? ageConsentData.translatedLabel
                  : '';
            } else if (elem.optInType === 'termsAndConditions') {
              elem.mandatoryMessage =
                termsAndConditionsData && termsAndConditionsData.translatedLabel
                  ? termsAndConditionsData.translatedLabel
                  : '';
            } 

            if(isUnbundledMarket){

            
            if (elem.optInType === consentTypes.unileverEmailOnlyConsent) {
              elem.serviceId = !isEpsilonMarket
                ? 'UNILEVER_' + campaign.country.code + '_EMLONLY'
                : '';
              elem.mandatoryMessage =
              unileverEmailOnlyConsentData &&
              unileverEmailOnlyConsentData.translatedLabel
                  ? unileverEmailOnlyConsentData.translatedLabel
                  : '';
              if (
                !areConsentsAvailable &&
                (isEpsilonMarket ||
                  selectedOption.value === 'brandUnileverVersion2')
              ) {
                elem.consentType = 'implicit';
              }
            } else if (elem.optInType === consentTypes.unileverSMSOnlyConsent) {
              elem.serviceId = !isEpsilonMarket
                ? 'UNILEVER_' + campaign.country.code + '_SMSONLY'
                : '';
              elem.mandatoryMessage =
              unileverSMSOnlyConsentData &&
              unileverSMSOnlyConsentData.translatedLabel
                  ? unileverSMSOnlyConsentData.translatedLabel
                  : '';
              if (
                !areConsentsAvailable &&
                (isEpsilonMarket ||
                  selectedOption.value === 'brandUnileverVersion2')
              ) {
                elem.consentType = 'implicit';
              }
            } else if (elem.optInType === consentTypes.unileverOnlineOnlyConsent) {
              elem.serviceId = !isEpsilonMarket
                ? 'UNILEVER_' + campaign.country.code + '_ONLINEONLY'
                : '';

              elem.consentType = 'optional'
              elem.mandatoryMessage =
              unileverOnlineOnlyConsentData &&
              unileverOnlineOnlyConsentData.translatedLabel
                  ? unileverOnlineOnlyConsentData.translatedLabel
                  : '';
              if (
                !areConsentsAvailable &&
                (isEpsilonMarket ||
                  selectedOption.value === 'brandUnileverVersion2')
              ) {
                elem.consentType = 'implicit';
              }
            } else if (this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT && elem.optInType === 'unileverWhatsAppConsent') {
              elem.serviceId = ''
               /* !isEpsilonMarket
                ? 'BRAND_' +
                  campaign.country.code +
                  '_' +
                  campaign.brand.code +
                  '_SMS'
                : '';*/
              elem.mandatoryMessage =
              unileverWhatsAppConsentData && unileverWhatsAppConsentData.translatedLabel
                  ? unileverWhatsAppConsentData.translatedLabel
                  : '';
            } else if (this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT && elem.optInType === 'brandWhatsAppConsent') {
              elem.serviceId =  ''
              /*!isEpsilonMarket
                ? 'BRAND_' +
                  campaign.country.code +
                  '_' +
                  campaign.brand.code +
                  '_WHATSAPP'
                : '';*/
              elem.mandatoryMessage =
              brandWhatsAppConsentData && brandWhatsAppConsentData.translatedLabel
                  ? brandWhatsAppConsentData.translatedLabel
                  : '';
            } else if(this.props.campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE && elem.optInType ==='multiChannelUnileverConsent'){
              elem.serviceId =  ''
              /*!isEpsilonMarket
                ? 'MULTI_' +
                  campaign.country.code +
                  '_' +
                  campaign.brand.code +
                  '_WHATSAPP'
                : '';*/
              elem.mandatoryMessage =
              multiChannelUnileverConsentData && multiChannelUnileverConsentData.translatedLabel
                  ? multiChannelUnileverConsentData.translatedLabel
                  : '';
            }
            }
            if(elem.optInType === consentTypes.combinedUnileverAndBrandEmailConsent){
              elem.serviceId =  '';
              elem.mandatoryMessage =
                unileverEmailConsentData &&
                unileverEmailConsentData.translatedLabel
                  ? unileverEmailConsentData.translatedLabel
                  : '';
            }
            selectedConsents.push(elem);
          }
          return elem;
        });
      }

      const stateUpdates = {
        consents: selectedConsents,
        consentsTest: selectedConsents,
        consentsVersion: selectedOption.value,
        emailSenderName: campaign.brand.name,
        consentVersionKey: ver,
        loadedDefaultConsents: true,
        areConsentFieldsPopulated
      };
      if (isConsentVersionBeingUpdated) {
        const { profile, isUpdated } = this.updateLegalAgeInDateOfBirthField(
          existAgeOfConsent
        );
        stateUpdates.isLegalAge = existAgeOfConsent;
        if (isUpdated) {
          stateUpdates.profile = profile;
        }
      }
      this.setState(stateUpdates);
    }
  };

  updateLegalAgeInDateOfBirthField = value => {
    let isUpdated = false;
    const { profile, isLegalAge } = this.state;
    let fieldsArr = [...profile];
    const existingAge = isLegalAge;
    if (fieldsArr && fieldsArr.length > 0) {
      fieldsArr.forEach(elem => {
        if (elem.dataType === 'dateOfBirth' && elem.validationMessage != '') {
          isUpdated = true;
          elem.validationMessage = elem.validationMessage
            ? elem.validationMessage.replace(existingAge, value)
            : null;
        }
      });
    }

    return { profile: fieldsArr, isUpdated };
  };

  _handleLegalAgeConsentInputChange = (e, index) => {
    let fieldData = {
      ageOfConsent: e.value
    };

    const { profile: fieldsArr } = this.updateLegalAgeInDateOfBirthField(
      e.value.toString()
    );

    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      ...fieldData
    };
    this.setState({
      isLegalAge: e.value,
      profile: [...fieldsArr],
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentArray]
    });
  };

  _handleReset = (e, index,eleOptinType) => {
    axios
      .get(constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + this.props.match.params.id)
      .then(response => {
        const {  campaignDetail : { consents }} = response.data[0];
        let consentToReset = consents[index];
        let optInType = eleOptinType
        if(consentToReset){
          optInType = consentToReset.optInType;
        } else {
          const {
            allConsents: consentsFromAPI,
          } = this.props;
          let ver = constant.consentLabels['enterpriseConsentsVersion3'];
          let consentsForSelectedVersion = consentsFromAPI && consentsFromAPI[ver];
          let consentObj = consentsForSelectedVersion[0];
          let defaultList = constant.consents;
          let consentElement = defaultList.find(e=> e.optInType === eleOptinType);
          if(eleOptinType === consentTypes.multiChannelUnileverConsent){
             consentElement = consents.find(e=> e.optInType === consentToReset.optInType);
          }
          let key = consentElement && consentElement.tsKey;
          if(eleOptinType === consentTypes.multiChannelUnileverConsent){
             key = this.getMultiChannelConsentKey(consentElement['multi_consents_value']);
          }
          consentToReset = consentElement
          if(consentToReset){
            consentToReset.defaultCopy = consentObj && consentObj[key] ? consentObj[key] : '';
            consentToReset.tsCopy = consentObj && consentObj[key] ? consentObj[key] : '';
          }
        }
       
        document.getElementById(`consent_error_${optInType}`) &&
          document
            .getElementById(`consent_error_${optInType}`)
            .classList.add('cw-hidden');
    
        document
          .getElementById(`consent_${optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`consent_${optInType}`)
            .classList.remove('cw-error-focus');
    
        let updatedConsentArray = [...this.state.consents];
        updatedConsentArray[index] = {
          ...updatedConsentArray[index],
          ...consentToReset
        };
    
        let updatedConsentTestArray = [...this.state.consentsTest];
        updatedConsentTestArray[index] = {
          ...updatedConsentTestArray[index],
          ...consentToReset
        };
    
        this.setState({
          consents: [...updatedConsentArray],
          consentsTest: [...updatedConsentTestArray]
        });
      });
  };

  _handleLatastApprovedConsent = (e,index) => {
    let consents = this.state.consents;
    let consentToReset = consents[index];
    const {
      allConsents: consentsFromAPI,
    } = this.props;
    let ver = constant.consentLabels['enterpriseConsentsVersion3'];
    let consentsForSelectedVersion = consentsFromAPI && consentsFromAPI[ver];
    let consentObj = consentsForSelectedVersion[0];
    let defaultList = constant.consents;
    let consentElement = defaultList.find(e=> e.optInType === consentToReset.optInType);
    if(consentToReset.optInType === consentTypes.multiChannelUnileverConsent){
       consentElement  = consents.find(e=> e.optInType === consentToReset.optInType);
    }
    let key = consentElement && consentElement.tsKey;
    if(consentToReset.optInType === consentTypes.multiChannelUnileverConsent){
       key = this.getMultiChannelConsentKey(consentElement['multi_consents_value']);
    }
    let optInType = consentToReset.optInType;
    document.getElementById(`consent_error_${optInType}`) &&
      document
        .getElementById(`consent_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`consent_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`consent_${optInType}`)
        .classList.remove('cw-error-focus');

    let consentTestObj =
      this.state.consentsTest && this.state.consentsTest[index]
        ? this.state.consentsTest[index]
        : null;

    if(consentToReset){
      consentToReset.defaultCopy = consentObj && consentObj[key] ? consentObj[key] : '';
      consentToReset.tsCopy = consentObj && consentObj[key] ? consentObj[key] : '';
  
    }
    if(consentTestObj){
      consentTestObj.defaultCopy = consentObj && consentObj[key] ? consentObj[key] : '';
      consentTestObj.tsCopy = consentObj && consentObj[key] ? consentObj[key] : '';
    }
   
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      ...consentToReset
    };

    let updatedConsentTestArray = [...this.state.consentsTest];
    updatedConsentTestArray[index] = {
      ...updatedConsentTestArray[index],
      ...consentToReset
    };

    this.setState({
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentTestArray]
    });
  };

  removeConsentValidationUI(elem) {
    document.getElementById('consent_error_' + elem.optInType) &&
      document
        .getElementById('consent_error_' + elem.optInType)
        .classList.add('cw-hidden');

    document
      .getElementById(`consent_${elem.optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`consent_${elem.optInType}`)
        .classList.remove('cw-error-focus');
  }

  _validConsents = () => {
    
    let isValid = true;
    let regexPattern;
    const { campaign } = this.props;
    this.state.consents.forEach((elem, index) => {
      if (elem.optInType !== 'contentInterestAreas') {
        if (
          elem.consentType === 'mandatory' ||
          elem.consentType === 'optional' || elem.consentType ==='implicitStandalone'
        ) {
          const isConsentTextEmpty =
            elem.defaultCopy === undefined ||
            elem.defaultCopy
              .replace(/\r?\n|\r/gm, '')
              .replace(/<[^>]+>/gm, '')
              .replace(/&nbsp;/gm, '')
              .trim().length === 0;
          if (isConsentTextEmpty) {
            document.getElementById('consent_error_' + elem.optInType) &&
              document
                .getElementById('consent_error_' + elem.optInType)
                .classList.remove('cw-hidden');

            document
              .getElementById(`consent_${elem.optInType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`consent_${elem.optInType}`)
                .classList.add('cw-error-focus');

            isValid = false;
          } else {
            this.removeConsentValidationUI(elem);
          }
        } else {
          this.removeConsentValidationUI(elem);
        }
      }

      if (
        elem.optInType !== 'privacyNotice' &&
        elem.optInType !== 'legalDisclaimer' &&
        elem.consentType &&
        elem.consentType === 'mandatory' &&
        (elem.mandatoryMessage === undefined ||
          Object.values(elem.mandatoryMessage.trim()).length === 0)
      ) {
        document.getElementById('consent_mandatoryMessage_' + elem.optInType) &&
          document
            .getElementById('consent_mandatoryMessage_' + elem.optInType)
            .classList.remove('cw-hidden');

        document
          .getElementById(`errorMessage_${elem.optInType}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`errorMessage_${elem.optInType}`)
            .classList.add('cw-error-focus');

        isValid = false;
      }
      if (campaign && !campaign.isMasterData &&
        (elem.optInType === 'additionalSubscription' ||
        elem.optInType === 'additionalSubscription1')
      ) {
        regexPattern = constant.regex['serviceId'];
        if (
          elem.serviceId &&
          elem.serviceId.trim() !== '' &&
          !regexPattern.test(elem.serviceId.trim())
        ) {
          document.querySelector(
            '[data-custom-msg=' + 'serviceId_' + elem.optInType + ']'
          ) &&
            document
              .querySelector(
                '[data-custom-msg=' + 'serviceId_' + elem.optInType + ']'
              )
              .classList.remove('cw-hidden');
          isValid = false;
        }
        if (
          elem.frequency === undefined ||
          Object.values(elem.frequency).length === 0
        ) {
          document.getElementById('frequency_error_' + elem.optInType) &&
            document
              .getElementById('frequency_error_' + elem.optInType)
              .classList.remove('cw-hidden');

          document
            .getElementById(`frequency_${elem.optInType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`frequency_${elem.optInType}`)
              .classList.add('cw-error-focus');

          isValid = false;
        }
        if (
          elem.frequency === undefined ||
          Object.values(elem.frequency).length === 0
        ) {
          document.getElementById('frequency_error_' + elem.optInType) &&
            document
              .getElementById('frequency_error_' + elem.optInType)
              .classList.remove('cw-hidden');

          document
            .getElementById(`frequency_${elem.optInType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`frequency_${elem.optInType}`)
              .classList.add('cw-error-focus');
          isValid = false;
        }
      }
      if (elem.optInType === 'brandEmailConsent') {
        regexPattern = constant.regex['email'];
        if (
          elem.replyToEmailAdderess &&
          elem.replyToEmailAdderess.trim() !== '' &&
          !regexPattern.test(elem.replyToEmailAdderess)
        ) {
          document
            .querySelector('[data-custom-msg=' + 'replyToEmailAdderess' + ']')
            .classList.remove('cw-hidden');

          document
            .getElementById(`replyToEmailAdderess`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`replyToEmailAdderess`)
              .classList.add('cw-error-focus');

          isValid = false;
        }
        if (
          elem.emailSenderName === undefined ||
          Object.values(elem.emailSenderName.trim()).length === 0
        ) {
          document
            .querySelector('[data-custom-msg=' + 'emailSenderName' + ']')
            .classList.remove('cw-hidden');

          document
            .getElementById(`emailSenderName`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`emailSenderName`)
              .classList.add('cw-error-focus');

          isValid = false;
        }
      }
    });
    
    return isValid;
  };

  checkConsentFieldValidity = (elem, index) => {
    let isValid = true;
    const { campaign } = this.props;
    let regexPattern;
    if (elem.optInType !== 'contentInterestAreas') {
      if (elem.consentType === 'mandatory' || elem.consentType === 'optional' ||  elem.consentType === 'implicitStandalone') {
        const isConsentTextEmpty =
          elem.defaultCopy === undefined ||
          elem.defaultCopy
            .replace(/\r?\n|\r/gm, '')
            .replace(/<[^>]+>/gm, '')
            .replace(/&nbsp;/gm, '')
            .trim().length === 0;

        if (isConsentTextEmpty) {
          isValid = false;
        }
      }
    }

    if (
      elem.optInType !== 'privacyNotice' &&
      elem.optInType !== 'legalDisclaimer' &&
      elem.consentType &&
      elem.consentType === 'mandatory' &&
      (elem.mandatoryMessage === undefined ||
        Object.values(elem.mandatoryMessage.trim()).length === 0)
    ) {
      isValid = false;
    }
    if (campaign && !campaign.isMasterData &&
      (elem.optInType === 'additionalSubscription' ||
      elem.optInType === 'additionalSubscription1')
    ) {
      regexPattern = constant.regex['serviceId'];
      if (
        elem.serviceId &&
        elem.serviceId.trim() !== '' &&
        !regexPattern.test(elem.serviceId.trim())
      ) {
        isValid = false;
      }
      if (
        elem.frequency === undefined ||
        Object.values(elem.frequency).length === 0
      ) {
        isValid = false;
      }
      if (
        elem.frequency === undefined ||
        Object.values(elem.frequency).length === 0
      ) {
        isValid = false;
      }
    }
    if (elem.optInType === 'brandEmailConsent') {
      regexPattern = constant.regex['email'];
      if (
        elem.replyToEmailAdderess &&
        elem.replyToEmailAdderess.trim() !== '' &&
        !regexPattern.test(elem.replyToEmailAdderess)
      ) {
        isValid = false;
      }
      if (
        elem.emailSenderName === undefined ||
        Object.values(elem.emailSenderName.trim()).length === 0
      ) {
        isValid = false;
      }
    }
    this.setState(({ consentFieldsValidity }) => ({
      consentFieldsValidity: { ...consentFieldsValidity, [index]: isValid }
    }));
    return isValid;
  };

  checkConsentValidity = () => {
    let isValid = true;
    this.state.consents.forEach((elem, index) => {
      const isConsentValid = this.checkConsentFieldValidity(elem, index);
      if (!isConsentValid) {
        isValid = isConsentValid;
      }
    });

    return isValid;
  };

  getConsent = (consents, optInType) => {
    return consents.find(elem => elem.optInType === optInType);
  };

  isConsentPresentForField = (dataType, consentType) => {
    const { profile, consents } = this.state;
    if (
      this.isProfileFieldPresent(profile, dataType) &&
      !this.isConsentPresent(consents, consentType)
    ) {
      return false;
    }
    return true;
  };

  validateIfUnileverConsentsArePresent() {
    const { campaign } = this.props;
    const { campaignType } = campaign
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    const isEpsilonMarket = epsilonMarket(countrySelected);

    let isValid = true;
    let emailConsentMissing = false;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countrySelected)

    const brandCode =  campaign && campaign.brand && campaign.brand.code;
    const IsBrandEmailsmsConsentVisible = countrySelected && brandCode &&  isBrandEmailSmsConsentVisible(this.props.campaignConfig, countrySelected, brandCode)
    const isCombinedStatement = isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT && !IsBrandEmailsmsConsentVisible
    const isMultiChannelConsentPresent = this.isConsentPresent(
      this.state.consents,
      consentTypes.multiChannelUnileverConsent
    );
    if(isMultiChannelConsentPresent){
      const isEmailFieldPresent = this.isProfileFieldPresent(this.state.profile, 'email')
      if(isEmailFieldPresent){
        const multiChannelData = this.state.consents.find(e=>e.optInType ===  consentTypes.multiChannelUnileverConsent);
        const emailMultiConsent  = this.getMultiConsentValueBasedOnKey(multiChannelData['multi_consents_value'],'Email')
        const isEmailConsentPresent = this.isConsentPresentForField(
          'email',
          consentTypes.unileverEmailConsent
        );
        if(!(emailMultiConsent && emailMultiConsent.isChecked) && !isEmailConsentPresent){
          isValid = false
          this.setState({ multiEmailConsentMissing: true });
          return isValid
        }else {
          this.setState({ multiEmailConsentMissing: false });
          return isValid
        }
      } else {
        return isValid
      } 
    }
    
       if (isUnbundledMarket) {
         const isEmailOnlyConsentPresent = this.isConsentPresentForField(
           'email',
           consentTypes.unileverEmailOnlyConsent
         );

         if (!isEmailOnlyConsentPresent) {
           isValid = false;
         }
         this.setState({ emailConsentMissing: !isEmailOnlyConsentPresent });
       } else if (isCombinedStatement) {
        const isCombinedConsentPresent = this.isConsentPresentForField(
          'email',
          consentTypes.combinedUnileverAndBrandEmailConsent
        );

        if (!isCombinedConsentPresent) {
          isValid = false;
        }
       } else {
         const isEmailConsentPresent = this.isConsentPresentForField(
           'email',
           consentTypes.unileverEmailConsent
         );
         const isBrandEmailConsentPresent = this.isConsentPresentForField(
           'email',
           consentTypes.brandEmailConsent
         );
         if (!isEmailConsentPresent) {
           if (!isBrandEmailConsentPresent) {
             isValid = false;
             emailConsentMissing = true;
           }
         }
         this.setState({ emailConsentMissing: emailConsentMissing });
       }
   
   

    if (!isEpsilonMarket) {
      let smsConsentMissing = false;
      let whatsAppConsentMissing = false;
      if (isUnbundledMarket) {
        const isSmsOnlyConsentPresent = this.isConsentPresentForField(
          'mobileNumber',
          consentTypes.unileverSMSOnlyConsent
        );

        if (!isSmsOnlyConsentPresent) {
          isValid = false;
          smsConsentMissing = true
        }
        this.setState({ smsConsentMissing: smsConsentMissing });
      } else {
        const isMobileConsentPresent = this.isConsentPresentForField(
          'mobileNumber',
          consentTypes.unileverSMSConsent
        );
        const isBrandMobileConsentPresent = this.isConsentPresentForField(
          'mobileNumber',
          consentTypes.brandSMSConsent
        );
        const isWhatsAppConsentPresent = this.isConsentPresentForField(
          'mobileNumber',
          consentTypes.unileverWhatsAppConsent
        );
        if (!isMobileConsentPresent) {
          if (!isBrandMobileConsentPresent) {
            if(!isWhatsAppConsentPresent){
              isValid = false;
              smsConsentMissing = true;
            }
          }
        }
        this.setState({ smsConsentMissing: smsConsentMissing});
        const isWhatsAppEnable =
          this.props.campaignConfig &&
          this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT &&
          isWhatsAppConsentEnableForMarket(
            this.props.campaignConfig,
            countrySelected
          ) &&
          (campaignType != 'Incentive' ||
            (campaignType === 'Incentive' &&
              this.props.campaignConfig.WHATSAPP_CONSENT &&
              this.props.campaignConfig.WHATSAPP_CONSENT.INCENTIVE_WHATSAPP_ENABLE));

        if(isWhatsAppEnable){
          const isUnileverWhatsAppConsentPresent = this.isConsentPresentForField(
            'mobileNumber',
            consentTypes.unileverWhatsAppConsent
          );
          const isBrandWhatAppConsentPresent = this.isConsentPresentForField(
            'mobileNumber',
            consentTypes.brandWhatsAppConsent
          );
          const isUnileverSMSConsentPresent = this.isConsentPresentForField(
            'mobileNumber',
            consentTypes.unileverSMSConsent
          );
          const isBrandSMSConsentPresent = this.isConsentPresentForField(
            'mobileNumber',
            consentTypes.brandSMSConsent
          );
          if(!isUnileverWhatsAppConsentPresent && !isUnileverSMSConsentPresent && !isBrandWhatAppConsentPresent && !isBrandSMSConsentPresent){
            isValid = false;
            whatsAppConsentMissing = true;
          }
          this.setState({ whatsAppConsentMissing :  whatsAppConsentMissing})
        }
      }
    }

    return isValid;
  }

  validateProfileFieldforConsents = () => {
    const { campaign } = this.props;
    let isValid = true;
    let emailFieldMissing, mobileFieldMissing = false, mobileMissingWhatsapp = false;
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countrySelected)
    this.state.consents.forEach(elem => {
      if(isUnbundledMarket){
        if (elem.optInType === constant.consentTypes.unileverEmailOnlyConsent) {
          // check if email address exist or not 
          const isEmailFieldPresent = this.isProfileFieldPresent(this.state.profile, 'email');
          if(!isEmailFieldPresent){
            isValid = false;
            emailFieldMissing = true;
          }
        }
        if (elem.optInType === constant.consentTypes.unileverSMSOnlyConsent) {
          // check if mobile number field exist or not 
          const isMobileNumberPresent = this.isProfileFieldPresent(this.state.profile, 'mobileNumber');
          if(!isMobileNumberPresent){
            isValid = false;
            mobileFieldMissing = true;
          }
        }
      } else {
        if (elem.optInType === constant.consentTypes.unileverEmailConsent) {
          // check if email address exist or not 
          const isEmailFieldPresent = this.isProfileFieldPresent(this.state.profile, 'email');
          if(!isEmailFieldPresent){
            isValid = false;
            emailFieldMissing = true;
          }
        }
        if (elem.optInType === constant.consentTypes.brandEmailConsent) {
          // check if email address exist or not 
          const isEmailFieldPresent = this.isProfileFieldPresent(this.state.profile, 'email');
          if(!isEmailFieldPresent){
            isValid = false;
            emailFieldMissing = true;
          }
        }
        if (elem.optInType === constant.consentTypes.unileverSMSConsent) {
          // check if mobile number field exist or not 
          const isMobileNumberPresent = this.isProfileFieldPresent(this.state.profile, 'mobileNumber');
          if(!isMobileNumberPresent){
            isValid = false;
            mobileFieldMissing = true;
          }
        }
        if (elem.optInType === constant.consentTypes.brandSMSConsent) {
          // check if mobile number field exist or not 
          const isMobileNumberPresent = this.isProfileFieldPresent(this.state.profile, 'mobileNumber');
          if(!isMobileNumberPresent){
            isValid = false;
            mobileFieldMissing = true;
          }
        }

        if (elem.optInType === constant.consentTypes.unileverWhatsAppConsent) {
          // check if mobile number field exist or not 
          const isMobileNumberPresent = this.isProfileFieldPresent(this.state.profile, 'mobileNumber');
          if(!isMobileNumberPresent){
            isValid = false;
            mobileMissingWhatsapp = true;
          }
        }
      }
      
    })
    this.setState({emailAddressFieldMissing: emailFieldMissing, mobileNumberFieldMissing: mobileFieldMissing, mobileNumberMissingForWhatsapp: mobileMissingWhatsapp})
    return isValid
  } 

  validateConsentFields = () => {
    const { consents } = this.state;
    let showGenericError = true;
    let ageConsentData = this.getConsent(consents, 'legalAgeConsent');
    let privacyConsentData = this.getConsent(consents, 'privacyNotice');

    let isValid = this._validConsents();
    if (!this.validateIfUnileverConsentsArePresent()) {
      isValid = false;
      showGenericError = false;
    }
    if(!this.validateProfileFieldforConsents()){
      isValid = false;
      showGenericError = false;
    }
    
    this.setState({
      showAllPurposeError: !isValid
    });

    if (!ageConsentData || !privacyConsentData) {
      isValid = false;
      this.setState({ consentValidationFail: true });
    }
    if (isValid === false) {
      scrollToTop();
    }
    return { areConsentFieldsValid: isValid, showGenericError };
  };

  isDuplicate = (consents, optInType) => {
    return consents.some(elem => {
      return elem.optInType === optInType;
    });
  };

  _handleConsentField = selectedOption => {
    let consents = this.props.allConsents;
    let consentMessage = this.props.formConsentData;
    let consentVersionKey = this.state.consentVersionKey;
    let editCampaign = this.props.campaign.isEdited;
    const { campaign } = this.props;
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    const isEpsilonMarket = epsilonMarket(countrySelected);
    let profileFieldList =
      this.props.formMetaData.accordionData || constant.profileFields;
    const isDuplicate = this.isDuplicate(
      this.state.consents,
      selectedOption.optInType
    );

    if (!consentVersionKey) {
      return;
    }

    let ageConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'legalAgeConsent')
      : {};
    let brandEmailConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'brandEmailConsent')
      : {};
    let brandSMSConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'brandSMSConsent')
      : {};
    let unileverEmailConsentMessage = consentMessage
      ? consentMessage.find(
          elem => elem.dataType === consentTypes.unileverEmailConsent
        )
      : {};
    let unileverSMSConsentMessage = consentMessage
      ? consentMessage.find(
          elem => elem.dataType === consentTypes.unileverSMSConsent
        )
      : {};
    let additionalSubscriptionMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'additionalSubscription')
      : {};
    let termsAndConditionsMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'termsAndConditions')
      : {};

    const unileverEmailOnlyConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverEmailOnlyConsent')
      : {};
  
    const unileverSMSOnlyConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverSMSOnlyConsent')
      : {};
  
    const unileverOnlineOnlyConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverOnlineOnlyConsent')
      : {};
    
    let unileverWhatsAppConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverWhatsAppConsent')
      : {};

    let brandWhatsAppConsentMessage = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'brandWhatsAppConsent')
    : {};

    const multiChannelUnileverConsentData = consentMessage
    ? consentMessage.find(elem => elem.dataType === 'multiChannelUnileverConsent')
    : {};

    if (!isDuplicate) {
      this.setState({ showErrorMessage: false });
      // if (this._validConsents()) {
      this.setState({ consentValidationFail: false });
      const isIncentiveCamp = this.props.campaign && this.props.campaign.campaignType === 'Incentive'
      if(isIncentiveCamp && incentiveNonMandatoryConsents.includes(selectedOption && selectedOption.optInType)){
        selectedOption.consentType = 'optional';
      }else {
        selectedOption.consentType = 'mandatory';
      }
      
      if (selectedOption && selectedOption.optInType === 'brandEmailConsent') {
        if(isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT ){
          selectedOption.consentType = 'implicitStandalone';
        }
        selectedOption['emailSenderName'] = this.state.emailSenderName;
        selectedOption['mandatoryMessage'] =
          brandEmailConsentMessage && brandEmailConsentMessage.translatedLabel
            ? brandEmailConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          consents[consentVersionKey] &&
          consents[consentVersionKey][0] &&
          consents[consentVersionKey][0].brand_email;
        // check if unilever email is there if yes then remove from madatory consent.
        const isUnileverEmailConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverEmailConsent
        );
        if (isUnileverEmailConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.unileverEmailConsent);
        }

        // check if email profile is not present then add it
        const fields = this.state.profile;
        const isEmailPresent = this.isEmailFieldPresent(fields);
        if (!isEmailPresent && !editCampaign) {
          const emailField = profileFieldList.find(
            elem => elem.dataType === 'email'
          );
          if (emailField) {
            this.handleAddField(emailField);
          }
        }
      }
      if (selectedOption && selectedOption.optInType === 'legalAgeConsent') {
        selectedOption['mandatoryMessage'] =
          ageConsentMessage && ageConsentMessage.translatedLabel
            ? ageConsentMessage.translatedLabel
            : '';
        this.setState({ consentValidationFail: false });
        let countrySelected =
          this.props.campaign &&
          this.props.campaign.country &&
          this.props.campaign.country.code;

        if (epsilonMarket(countrySelected) && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT) {
          selectedOption['consentType'] = 'implicitStandalone';
        } else if(epsilonMarket(countrySelected)){
          selectedOption['consentType'] = 'implicit';
        }
        selectedOption['defaultCopy'] =
          consents[consentVersionKey] &&
          consents[consentVersionKey][0] &&
          consents[consentVersionKey][0].age_consent;
      }

      if (selectedOption && selectedOption.optInType === 'privacyNotice') {
        selectedOption['mandatoryMessage'] =
          brandSMSConsentMessage && brandSMSConsentMessage.translatedLabel
            ? brandSMSConsentMessage.translatedLabel
            : '';
        this.setState({ consentValidationFail: false });
        selectedOption['defaultCopy'] =
          consents[consentVersionKey] &&
          consents[consentVersionKey][0] &&
          consents[consentVersionKey][0].privacy_policy;
      }

      if (selectedOption && selectedOption.optInType === 'brandSMSConsent') {
        selectedOption['mandatoryMessage'] =
          brandSMSConsentMessage && brandSMSConsentMessage.translatedLabel
            ? brandSMSConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          consents[consentVersionKey] &&
          consents[consentVersionKey][0] &&
          consents[consentVersionKey][0].brand_sms;

        // check if unilever sms is there if yes then remove from madatory consent.
        const isUnileverSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverSMSConsent
        );
        if (isUnileverSMSConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.unileverSMSConsent);
        }

        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isUnileverWhatsAppConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.unileverWhatsAppConsent);
        }

        if (!isUnileverSMSConsentPresent  && !isUnileverWhatsAppConsentPresent && !editCampaign) {
          this.addConsentToNonRemovable(consentTypes.brandSMSConsent);
        }
        
        // check if mobile number profile field is not present then add it
        const fields = this.state.profile;
        const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
        if (!isMobileNumberPresent && !editCampaign) {
          const mobileField = profileFieldList.find(
            elem => elem.dataType === 'mobileNumber'
          );
          if (mobileField) {
            this.handleAddField(mobileField);
          }
        }
      }

      const { campaign } = this.props;
      const countryCode = campaign.country && campaign.country.code;
      const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)
      if (
        selectedOption && !isUnbundledMarket &&
        selectedOption.optInType === consentTypes.unileverEmailConsent
      ) {
        if(isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT ){
          selectedOption.consentType = 'implicitStandalone';
        }
        selectedOption['mandatoryMessage'] =
          unileverEmailConsentMessage &&
          unileverEmailConsentMessage.translatedLabel
            ? unileverEmailConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          (consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].unilever_email) ||
          constant.defaultConsents.unilever_email;
        const countrySelected =
          this.props.campaign &&
          this.props.campaign.country &&
          this.props.campaign.country.code;
       
        if (epsilonMarket(countrySelected) && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT) {
          selectedOption['consentType'] = 'implicitStandalone';
        } else if(epsilonMarket(countrySelected)){
          selectedOption['consentType'] = 'implicit';
        }

        // check if brand email is there if yes then remove from madatory consent.
        const isBrandEmailConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandEmailConsent
        );
        if (isBrandEmailConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.brandEmailConsent);
        }

        // check if email profile is not present then add it
        const fields = this.state.profile;
        const isEmailPresent = this.isEmailFieldPresent(fields);
        if (!isEmailPresent && !editCampaign) {
          const emailField = profileFieldList.find(
            elem => elem.dataType === 'email'
          );
          if (emailField) {
            this.handleAddField(emailField);
          }
        }
      }
      if (
        selectedOption && !isUnbundledMarket &&
        selectedOption.optInType === consentTypes.unileverSMSConsent
      ) {
        selectedOption['mandatoryMessage'] =
          unileverSMSConsentMessage && unileverSMSConsentMessage.translatedLabel
            ? unileverSMSConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          consents[consentVersionKey] &&
          consents[consentVersionKey][0] &&
          consents[consentVersionKey][0].unilever_sms;

        // check if brand sms is there if yes then remove from madatory consent.
        const isBrandSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandSMSConsent
        );
        if (isBrandSMSConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.brandSMSConsent);
          this.removeConsentFromNonRemovable(consentTypes.unileverSMSConsent);
        }
        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isUnileverWhatsAppConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.unileverWhatsAppConsent);
        }
        if (!isBrandSMSConsentPresent  && !isUnileverWhatsAppConsentPresent && !editCampaign) {
          this.addConsentToNonRemovable(consentTypes.unileverSMSConsent);
        }

        // check if mobile number profile field is not present then add it
        const fields = this.state.profile;
        const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
        if (!isMobileNumberPresent && !editCampaign) {
          const mobileField = profileFieldList.find(
            elem => elem.dataType === 'mobileNumber'
          );
          if (mobileField) {
            this.handleAddField(mobileField);
          }
        }
      }
      
      
      if(isUnbundledMarket){
      if (
        selectedOption &&
        selectedOption.optInType === consentTypes.unileverEmailOnlyConsent
      ) {
        selectedOption['mandatoryMessage'] =
        unileverEmailOnlyConsentMessage &&
        unileverEmailOnlyConsentMessage.translatedLabel
            ? unileverEmailOnlyConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          (consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].email_only) ||
          constant.defaultConsents.email_only;
        // const countrySelected =
        //   this.props.campaign &&
        //   this.props.campaign.country &&
        //   this.props.campaign.country.code;
        // if (epsilonMarket(countrySelected)) {
        //   selectedOption['consentType'] = 'implicit';
        // }
        // check if email profile is not present then add it
          const fields = this.state.profile;
          const isEmailPresent = this.isEmailFieldPresent(fields);
          if (!isEmailPresent && !editCampaign) {
            const emailField = profileFieldList.find(
              elem => elem.dataType === 'email'
            );
            if (emailField) {
              this.handleAddField(emailField);
            }
          }
      }

      if (
        selectedOption &&
        selectedOption.optInType === consentTypes.unileverSMSOnlyConsent
      ) {
        selectedOption['mandatoryMessage'] =
        unileverSMSOnlyConsentMessage &&
        unileverSMSOnlyConsentMessage.translatedLabel
            ? unileverSMSOnlyConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          (consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].sms_only) ||
          constant.defaultConsents.sms_only;
        // const countrySelected =
        //   this.props.campaign &&
        //   this.props.campaign.country &&
        //   this.props.campaign.country.code;
        // if (epsilonMarket(countrySelected)) {
        //   selectedOption['consentType'] = 'implicit';
        // }

        const fields = this.state.profile;
        const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
        if (!isMobileNumberPresent && !editCampaign) {
          const mobileField = profileFieldList.find(
            elem => elem.dataType === 'mobileNumber'
          );
          if (mobileField) {
            this.handleAddField(mobileField);
          }
        }
      }

      if (
        selectedOption &&
        selectedOption.optInType === consentTypes.unileverOnlineOnlyConsent
      ) {

        selectedOption['consentType'] = 'optional'
        selectedOption['mandatoryMessage'] =
        unileverOnlineOnlyConsentMessage &&
        unileverOnlineOnlyConsentMessage.translatedLabel
            ? unileverOnlineOnlyConsentMessage.translatedLabel
            : '';
        selectedOption['defaultCopy'] =
          (consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].online_only) ||
          constant.defaultConsents.online_only;
        // const countrySelected =
        //   this.props.campaign &&
        //   this.props.campaign.country &&
        //   this.props.campaign.country.code;
        // if (epsilonMarket(countrySelected)) {
        //   selectedOption['consentType'] = 'implicit';
        // }
      }
      }

      if (this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT && selectedOption && selectedOption.optInType === 'unileverWhatsAppConsent') {
        selectedOption['mandatoryMessage'] =
        unileverWhatsAppConsentMessage && unileverWhatsAppConsentMessage.translatedLabel
            ? unileverWhatsAppConsentMessage.translatedLabel
            : '';
         /** consent API version key is not available so we do later ***/  
        selectedOption['defaultCopy'] =
          consents[consentVersionKey] &&
          consents[consentVersionKey][0] &&
          consents[consentVersionKey][0].unilever_whatsapp;

        // check if unilever sms is there if yes then remove from madatory consent.
        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isUnileverWhatsAppConsentPresent) {
         this.removeConsentFromNonRemovable(consentTypes.unileverWhatsAppConsent);
        }
        // check if mobile number profile field is not present then add it
        const fields = this.state.profile;
        const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
        if (!isMobileNumberPresent && !editCampaign) {
          const mobileField = profileFieldList.find(
            elem => elem.dataType === 'mobileNumber'
          );
          if (mobileField) {
            this.handleAddField(mobileField);
          }
        }

        const isUnileverSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverSMSConsent
        );
        const isBrandSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandSMSConsent
        );
        if (isUnileverSMSConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.unileverSMSConsent);
        }
        if (isBrandSMSConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.brandSMSConsent);
        }
        if (!isUnileverSMSConsentPresent  && !isBrandSMSConsentPresent && !isIncentiveCamp && !editCampaign) {
          this.addConsentToNonRemovable(consentTypes.unileverWhatsAppConsent);
        }
      }

      if (this.props.campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT && selectedOption.optInType === 'brandWhatsAppConsent') {
        selectedOption['mandatoryMessage'] =
        brandWhatsAppConsentMessage && brandWhatsAppConsentMessage.translatedLabel
            ? brandWhatsAppConsentMessage.translatedLabel
            : '';
         /** consent API version key is not available so we do later ***/  
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].brand_whatsapp;

        // check if unilever sms is there if yes then remove from madatory consent.
        const isbrandWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandWhatsAppConsent
        );
        if (isbrandWhatsAppConsentPresent) {
          this.removeConsentFromNonRemovable(consentTypes.brandWhatsAppConsent);
        }
        // check if mobile number profile field is not present then add it
        const fields = this.state.profile;
        const isMobileNumberPresent = this.isMobileNumberFieldPresent(fields);
        if (!isMobileNumberPresent && !editCampaign) {
          const mobileField = profileFieldList.find(
            elem => elem.dataType === 'mobileNumber'
          );
          if (mobileField) {
            this.handleAddField(mobileField);
          }
        }
      }

      if(this.props.campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE &&  selectedOption.optInType === consentTypes.multiChannelUnileverConsent){
        const fields = this.state.profile;
        const isMobileFieldPresent = this.isProfileFieldPresent(fields, 'mobileNumber')
        const isEmailFieldPresent = this.isProfileFieldPresent(fields, 'email')
        selectedOption['multi_consents_value'] =  this.getMultiChannelConsentValues(isEmailFieldPresent,isMobileFieldPresent)
        if(multiChannelUnileverConsentData){
          selectedOption["mandatoryMessage"] =
          multiChannelUnileverConsentData && multiChannelUnileverConsentData.translatedLabel
              ? multiChannelUnileverConsentData.translatedLabel
              : '';
          let defaultConsentKey = this.getMultiChannelConsentKey(selectedOption['multi_consents_value']);
          selectedOption['defaultCopy'] =
              consents[consentVersionKey] &&
              consents[consentVersionKey][0] &&
              consents[consentVersionKey][0][defaultConsentKey] ; // dynamic ts key generated
        }
      
        let removeConsentArr = [consentTypes.unileverEmailConsent,consentTypes.unileverSMSConsent,consentTypes.unileverWhatsAppConsent]
        if(removeConsentArr && removeConsentArr.length){
          this.removeConsentForMultiChannel(removeConsentArr)
          // this.removeConsentFromNonRemovable(consentTypes.multiChannelUnileverConsent);
        }
      
          this.setState({ emailConsentMissing: false ,smsConsentMissing : false, whatsAppConsentMissing:false});
      
      }

      if (
        selectedOption &&
        selectedOption.optInType === 'additionalSubscription'
      ) {
        selectedOption['mandatoryMessage'] =
          additionalSubscriptionMessage &&
          additionalSubscriptionMessage.translatedLabel
            ? additionalSubscriptionMessage.translatedLabel
            : '';
      }
      if (
        selectedOption &&
        selectedOption.optInType === 'additionalSubscription1'
      ) {
        selectedOption['mandatoryMessage'] =
          additionalSubscriptionMessage &&
          additionalSubscriptionMessage.translatedLabel
            ? additionalSubscriptionMessage.translatedLabel
            : '';
      }
      if (selectedOption && selectedOption.optInType === 'termsAndConditions') {
        selectedOption['mandatoryMessage'] =
          termsAndConditionsMessage && termsAndConditionsMessage.translatedLabel
            ? termsAndConditionsMessage.translatedLabel
            : '';
      }

      if (
        selectedOption &&
        selectedOption.optInType === 'contentInterestAreas'
      ) {
        selectedOption['consentType'] = 'implicit';
      }

      this.setState(({ consents }) => {
        const isDuplicate = this.isDuplicate(
          consents,
          selectedOption.optInType
        );
        return !isDuplicate
          ? {
              consents: [...consents, selectedOption],
              consentsTest: [...consents, selectedOption],
              showAllPurposeError: false
            }
          : { showErrorMessage: true };
      }, this.checkConsentValidity);
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  _handleConsentInputChange = (e, index, elem) => {
    let fieldData = {
      [e.target.name]: e.target.value
    };
    if (e.target.name === 'emailSenderName') {
      this.setState({
        emailSenderName: e.target.value
      });
    }

    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      ...fieldData
    };

    if (
      updatedConsentArray[index] &&
      updatedConsentArray[index].hasOwnProperty('emailSenderName') &&
      updatedConsentArray[index].emailSenderName.trim().length !== 0
    ) {
      document
        .querySelector('[data-custom-msg=' + 'emailSenderName' + ']')
        .classList.add('cw-hidden');

      document
        .getElementById(`emailSenderName`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`emailSenderName`)
          .classList.remove('cw-error-focus');
    }
    let regexPattern;
    regexPattern = regexPattern = constant.regex['email'];
    if (
      updatedConsentArray[index].replyToEmailAdderess &&
      updatedConsentArray[index].replyToEmailAdderess.trim() !== '' &&
      !regexPattern.test(updatedConsentArray[index].replyToEmailAdderess)
    ) {
      document
        .getElementById(`replyToEmailAdderess`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`replyToEmailAdderess`)
          .classList.remove('cw-error-focus');
    }

    this.setState({
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentArray]
    });
  };

  updateConsent = (elem, index) => {
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = elem;
    this.setState(
      {
        consents: [...updatedConsentArray]
      },
      () => this.checkConsentFieldValidity(elem, index)
    );
  };

  makeConsentMandatory = (consentsList, givenConsent, isIncentiveCamp = false) => {
    return isIncentiveCamp? consentsList : consentsList.map(item => {
      if (item.optInType === givenConsent) {
        if(item.consentType)
        return item.consentType !== 'implicit' && item.consentType !=='implicitStandalone'? { ...item, consentType: 'mandatory' } : item;
      } else {
        return item;
      }
    });
  };

  checkIfMadatory = givenConsent => {
    const consent = this.state.consents.find(
      item => item.optInType === givenConsent
    );
    if (consent) {
      return consent.consentType === 'mandatory';
    }
    return false;
  };

  disableMandatoryToggle = givenOptInType => {
    if (mandatoryConsents.includes(givenOptInType)) {
      if (givenOptInType === consentTypes.unileverEmailConsent) {
        const isBrandEmailConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandEmailConsent
        );
        if (isBrandEmailConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.brandEmailConsent);
          if (!manCheck) {
            return true;
          }
          return false;
        }
        return false;
      } 
      
      if (givenOptInType === consentTypes.brandEmailConsent) {
        const isUnileverEmailConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverEmailConsent
        );
        if (isUnileverEmailConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.unileverEmailConsent);
          if (!manCheck) {
            return true;
          }
          return false;
        }
        return false;
      }

      if (givenOptInType === consentTypes.unileverSMSConsent) {
        const isBrandSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandSMSConsent
        );
        const isunileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isBrandSMSConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.brandSMSConsent);
          if (!manCheck) {
            if(isunileverWhatsAppConsentPresent){
              const whatsAppManCheck = this.checkIfMadatory(consentTypes.unileverWhatsAppConsent);
              if(!whatsAppManCheck){
                return true
              }
              return false;
            }
            return true;
          }
          return false;
        }

        if (isunileverWhatsAppConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.unileverWhatsAppConsent);
          if (!manCheck) {
            if(isBrandSMSConsentPresent){
              const brandSMSManCheck = this.checkIfMadatory(consentTypes.brandSMSConsent);
              if(!brandSMSManCheck){
                return true
              }
              return false;
            }
            return true;
          }
          return false;
        }

        return false;
      }

      if (givenOptInType === consentTypes.brandSMSConsent) {
        const isUnileverSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverSMSConsent
        );
        const isUnileverWhatsAppConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverWhatsAppConsent
        );
        if (isUnileverSMSConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.unileverSMSConsent);
          if (!manCheck) {
            if(isUnileverWhatsAppConsentPresent){
              const whatsAppManCheck = this.checkIfMadatory(consentTypes.unileverWhatsAppConsent);
              if(!whatsAppManCheck){
                return true
              }
              return false;
            }
            return true;
          }
          return false;
        }

        if (isUnileverWhatsAppConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.unileverWhatsAppConsent);
          if (!manCheck) {
            if(isUnileverSMSConsentPresent){
              const unileverSmsManCheck = this.checkIfMadatory(consentTypes.unileverSMSConsent);
              if(!unileverSmsManCheck){
                return true
              }
              return false;
            }
            return true;
          }
          return false;
        }

        return false;
      }

      if (givenOptInType === consentTypes.unileverWhatsAppConsent) {
        const isUnileverSMSConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.unileverSMSConsent
        );
        const isBrandSmsConsentPresent = this.isConsentPresent(
          this.state.consents,
          consentTypes.brandSMSConsent
        );
       
        if (isUnileverSMSConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.unileverSMSConsent);
          if (!manCheck) {
            if (isBrandSmsConsentPresent) {
              const brandSmsManCheck = this.checkIfMadatory(consentTypes.brandSMSConsent);
              if (!brandSmsManCheck) {
                return true;
              }
              return false;
            }
            return true;
          }
          return false;
        }

        if (isBrandSmsConsentPresent) {
          const manCheck = this.checkIfMadatory(consentTypes.brandSMSConsent);
          if (!manCheck) {
            if (isUnileverSMSConsentPresent) {
              const unileverSmsManCheck = this.checkIfMadatory(consentTypes.unileverSMSConsent);
              if (!unileverSmsManCheck) {
                return true;
              }
              return false;
            }
            return true;
          }
          return false;
        }
       
        return false;
      }
      return false;
    }
    return false;
  };

  setInitialValuesOfSubmitButton(campaign) {
    let messages =
      campaign && campaign.campaignDetail && campaign.campaignDetail.messages;

    if (messages) {
      this.setState(
        {
          submitCtaLabel:
            messages && messages.submit ? messages && messages.submit : '',
          failureMsg:
            messages && messages.submissionError
              ? messages && messages.submissionError
              : '',
          expiryMsg:
            messages && messages.campaignExpiry
              ? messages && messages.campaignExpiry
              : '',
          mandatoryInd:
            messages && messages.mandatoryFieldsIndicator
              ? messages && messages.mandatoryFieldsIndicator
              : ''
        },
        this.checkSubmitButtonValidity
      );
    } else {
      this.fillDefaultValuesInSubmitButton();
    }
  }

  getSubmitButtonFieldLabel = fieldName => {
    const field =
      this.props.formGenericData &&
      this.props.formGenericData.genericData &&
      this.props.formGenericData.genericData.find(
        field => field.dataType === fieldName
      );
    return (field && field.translatedLabel) || '';
  };

  fillDefaultValuesInSubmitButton = () => {
    if (
      this.props &&
      this.props.formGenericData !== undefined &&
      this.props.formGenericData &&
      this.props.formGenericData.genericData
    ) {
      this.setState({
        submitCtaLabel: this.getSubmitButtonFieldLabel('submit'),
        failureMsg: this.getSubmitButtonFieldLabel('submissionError'),
        expiryMsg: this.getSubmitButtonFieldLabel('campaignExpiryMessage'),
        mandatoryInd: this.getSubmitButtonFieldLabel(
          'mandatoryFieldIndicatorText'
        )
      });
    } else {
      this.setState(
        {
          submitCtaLabel: constant.messagesFields[0].translatedLabel,
          failureMsg: constant.messagesFields[1].translatedLabel,
          expiryMsg: constant.messagesFields[2].translatedLabel,
          mandatoryInd: constant.messagesFields[3].translatedLabel
        },
        this.checkSubmitButtonValidity
      );
    }
  };

  _maxAnswersOptions = () => {
    let maxAnswers = [];
    maxAnswers.push({ value: 'No limit', label: 'No limit' });
    for (let i = 1; i <= 20; i++) {
      maxAnswers.push({ value: i, label: i });
    }
    this.setState({ answersLimit: maxAnswers });
  };

  getSavedQnAFields = () => {
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          this.props.match.params.id
      )
      .then(response => {
        let { campaignDetail, campaignType, type, websiteType, isEdited } =
          response && response.data[0];
        //let isClone = response && response.data[0] && response.data[0].isClone;
        let fields =
          campaignDetail && campaignDetail.fields ? campaignDetail.fields : [];
        this.setState({
          campaignType,
          websiteType: websiteType.name,
          isEdited,
          formType: type,
          profile: [...fields]
        });
      });
  };

  _getCampaignConfigAccordionList(campaign) {
    const { campaignConfig } = this.props;
    let isProductSample =
      campaign && campaign.incentiveType === 'Product samples';
    let listArray = [
      {
        listId: '1',
        listLabel: 'Profile fields',
        // listItems: constant.profileFields
        listItems:
          this.props.formMetaData.accordionData || constant.profileFields
      }
    ];
    if(!(campaign && campaign.isMasterData && campaignConfig.MASTER_DATA_JOURNEY.HIDE_DEFAULT)){
      listArray.push({
        listId: '2',
        listLabel: 'Form elements',
        listItems: this.props.formMetaData.accordionFormElement
      })
    }
    if (campaign.type === 'Sign-up with Questions & Answers') {
      listArray.push({
        listId: '3',
        listLabel: 'Custom Q&As',
        listItems: QA_QUESTION_TYPES
      });
    }
    if (campaign.campaignType === 'Incentive') {
      listArray.push({
        listId: '4',
        listLabel: 'Incentive fields',
        listItems: isProductSample
          ? [...INCENTIVE_FIELDS, this.sampleDetails]
          : INCENTIVE_FIELDS
      });
    }
    listArray.push({
      listId: '5',
      listLabel: 'Consents & compliance',
      listItems: this.optInType
    });
    return listArray;
  }
  _openModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  }
  _closeModal() {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  }

  canNewProfileItemsBeAdded = profileItem => {
    let isFieldValid = true;
    let isMandatoryFilled = true;
    let isLegalAgeMessageFilled = true;
    let isvalid = true;
    if (profileItem) {
      if (
        profileItem &&
        Object.values(profileItem.fieldLabel).filter(elem => /\S/.test(elem))
          .length === 0
      ) {
        isFieldValid = false;
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [`fieldLabel_${profileItem.dataType}`]: true
          }
        }));
      }
      if (
        profileItem &&
        profileItem.type === 'field' &&
        profileItem.mandatoryToggle
      ) {
        if (
          profileItem &&
          Object.values(profileItem.mandatoryMessage).filter(elem =>
            /\S/.test(elem)
          ).length === 0
        ) {
          isMandatoryFilled = false;
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`errorMessageText_${profileItem.dataType}`]: true
            }
          }));
        }
      }

      if (profileItem && profileItem.dataType === 'dateOfBirth') {
        const isAgeValidationValid = this.validateAgeValidation(profileItem);
        if (!isAgeValidationValid) {
          isLegalAgeMessageFilled = false;
        }
      }
      if (
        profileItem.dataType === 'email' ||
        profileItem.dataType === 'postalCode' ||
        profileItem.dataType === 'mobileNumber'
      ) {
        const isValidationMessageValid = this.validateValidationErrorMessage(
          profileItem
        );
        if (!isValidationMessageValid) {
          isvalid = false;
        }
      }
    }
    return (
      isMandatoryFilled && isFieldValid && isLegalAgeMessageFilled && isvalid
    );
  };

  checkProfileFieldValidity = profileItem => {
    let isFieldValid = true;
    let isMandatoryFilled = true;
    let isLegalAgeMessageFilled = true;
    let isvalid = true;
    if (profileItem) {
      if (!/\S/.test(profileItem.fieldLabel)) {
        isFieldValid = false;
      }
      if (
        profileItem &&
        profileItem.type === 'field' &&
        profileItem.mandatoryToggle
      ) {
        if (
          profileItem &&
          Object.values(profileItem.mandatoryMessage).filter(elem =>
            /\S/.test(elem)
          ).length === 0
        ) {
          isMandatoryFilled = false;
        }
      }

      if (profileItem && profileItem.dataType === 'dateOfBirth') {
        const isAgeValidationValid = this.checkAgeValidation(profileItem);
        if (!isAgeValidationValid) {
          isLegalAgeMessageFilled = false;
        }
      }
      if (
        profileItem.dataType === 'email' ||
        profileItem.dataType === 'postalCode' ||
        profileItem.dataType === 'mobileNumber'
      ) {
        const isValidationMessageValid = this.checkValidationErrorMessage(
          profileItem
        );
        if (!isValidationMessageValid) {
          isvalid = false;
        }
      }

      if (
        profileItem.dataType === 'stateOrProvince' &&
        profileItem.questionType === 'Single choice'
      ) {
        this.state.provinces.map(stateofProvience => {
          const isProvinceValid = this.checkProvinceValidity(stateofProvience);
          if (!isProvinceValid) {
            isvalid = false;
          }
        });
      }

      if (profileItem.dataType === 'gender') {
        const isGenderFieldValid = this.checkGenderField(profileItem);
        if (!isGenderFieldValid) {
          isvalid = false;
        }
      }
    }
    return (
      isMandatoryFilled && isFieldValid && isLegalAgeMessageFilled && isvalid
    );
  };

  checkGenderField = genderData => {
    const selectedLength = genderData.values.filter(ele => ele.isChecked)
      .length;
    let isValid = true;
    const isAnyTranslatedValueMissing =
      genderData.values.filter(
        ele => ele.isChecked && ele.translatedValue.trim() === ''
      ).length > 0;
    if (isAnyTranslatedValueMissing) {
      isValid = false;
    }
    if (selectedLength < 2) {
      isValid = false;
    }
    return isValid;
  };

  richTextData = (richTextData, index, elem) => {
    let paragraph = richTextData.getContent();
    paragraph = paragraph && paragraph.trim();
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      paragraph: paragraph ? paragraph : '',
      dataType: 'paragraph',
      type: elem.type
    };
    this.validateParagraphField(paragraph, index, elem);
    this.setState({
      ...this.state,
      profile: [...newProfile]
    });
  };

  genderInputHandler = async (e, index, genderIndex) => {
    let newProfile = [...this.state.profile];
    newProfile[index].values[genderIndex].translatedValue = e.target.value;
    let profileOptions = [...this.state.profile];
    let genderData = profileOptions.find(ele => ele.dataType === 'gender');
    if (genderData) {
      let seleLength = genderData.values.filter(
        ele => ele.isChecked && ele.translatedValue.trim() === ''
      );
      genderData.values.forEach(el => {
        if (el.isChecked && el.translatedValue.trim() === '') {
          this.setState(({ errors }) => ({
            errors: { ...errors, [`gender_${el.value}`]: true }
          }));
        } else {
          this.setState(({ errors }) => ({
            errors: { ...errors, [`gender_${el.value}`]: false }
          }));
        }
      });
      this.setState({
        genderFieldErrorMessage: seleLength.length > 0 ? true : false
      });
    }
    this.setState(
      {
        profile: newProfile
      },
      () => this.validateGenderField(genderData, index)
    );
  };

  onLabelChange = (e, index, elem) => {
    let fieldLabel = e.target.value;
    let newProfile = [...this.state.profile];

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      fieldLabel: fieldLabel,
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
      newProfile[index].translatedLabel = elem.fieldLabel;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }
    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`fieldLabel_${elem.dataType}`]: fieldLabel.length ? false : true
      }
    }));

    this.setState(
      {
        profile: [...newProfile]
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  onToolTipChange = (e, index, elem) => {
    let toolTip = e.target.value;
    let newProfile = [...this.state.profile];

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: toolTip,
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }
    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }

    this.setState(
      {
        profile: [...newProfile]
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  onErrorMessageChange = (e, index, elem) => {
    const { fieldsValidity } = this.state;
    let errorMessage = e.target.value;
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryToggle
        ? errorMessage
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }
    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }

    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`errorMessageText_${elem.dataType}`]: errorMessage.length
          ? false
          : true
      }
    }));

    if (elem.type === 'field' && elem.mandatoryToggle) {
      if (newProfile[index].mandatoryMessage.trim().length === 0) {
        this.setState({
          fieldsValidity: { ...fieldsValidity, [index]: false }
        });
      } else {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [`errorMessageText_${elem.dataType}`]: errorMessage.length
              ? false
              : true,
            fieldsValidity: { ...fieldsValidity, [index]: true }
          }
        }));
      }
    }

    this.setState(
      {
        profile: [...newProfile]
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  checkValidityOfProfileFields = index => {
    const { profile } = this.state;
    if (index >= 0) {
      const field = profile[index];
      if (field.type === 'field') {
        const isValid = this.checkProfileFieldValidity(field);
        this.setState(({ fieldsValidity }) => ({
          fieldsValidity: { ...fieldsValidity, [index]: isValid }
        }));
      }
    } else {
      let areAllFieldsValid = true;
      profile.forEach((field, index) => {
        if (field.type === 'field') {
          const isValid = this.checkProfileFieldValidity(field);
          this.setState(({ fieldsValidity }) => ({
            fieldsValidity: { ...fieldsValidity, [index]: isValid }
          }));
          if (!isValid) {
            areAllFieldsValid = false;
          }
        }
      });
      this.checkSubmitButtonValidity();
      return areAllFieldsValid;
    }
  };

  onMobileRegexChange = (e, index, elem) => {
    let mobileFormatErrorMessage = e.target.value;
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`formatValidation_${elem.dataType}`]: mobileFormatErrorMessage
          ? false
          : true
      }
    }));

    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      validationMessage: mobileFormatErrorMessage,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }

    this.setState(
      {
        profile: [...newProfile]
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  onLegalAgeValidationChange = (e, index, elem) => {
    let legalAgeValidationMessage = e.target.value;
    let newProfile = [...this.state.profile];
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`ageValidation_${elem.dataType}`]: legalAgeValidationMessage
          ? false
          : true
      }
    }));

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      validationMessage: legalAgeValidationMessage,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };

    this.setState(
      {
        profile: [...newProfile]
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  _handleInputChange(event, elem, index) {
    let newProfile = [...this.state.profile];
    const existingAge = this.state.isLegalAge;
    newProfile[index]['validationMessage'] = newProfile[index][
      'validationMessage'
    ]
      ? newProfile[index]['validationMessage'].replace(
          existingAge,
          event.value.toString()
        )
      : null;

    const updates = {
      isLegalAge: event.value,
      profile: [...newProfile]
    };

    let consentsArray = [...this.state.consents];
    if (consentsArray && consentsArray.length > 0) {
      consentsArray.forEach(consent => {
        if (consent.optInType === consentTypes.legalAgeConsent) {
          consent.ageOfConsent = event.value;
        }
      });

      updates.consents = [...consentsArray];
    }

    this.setState(updates, () => this.checkValidityOfProfileFields(index));
  }

  onMandatoryToggleChange = (e, mandatoryToggle, index, elem) => {
    let newProfile = [...this.state.profile];

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage:
        this.state.profile[index] && this.state.profile[index].mandatoryMessage
          ? this.state.profile[index].mandatoryMessage
          : '',
      mandatoryToggle: mandatoryToggle,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }

    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }

    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`errorMessageText_${elem.dataType}`]: false
      }
    }));

    this.setState(
      {
        profile: [...newProfile]
      },
      () => {
        this.updateNonRemovableField();
        this.updateNonRemovableConsents();
        this.checkValidityOfProfileFields(index);
      }
    );
  };

  handleIncentiveSelection = selectedOption => {
    let field = {};
    if (selectedOption.dataType === 'entryCode') {
      field = {
        dataType: 'entryCode',
        label: selectedOption.label || 'Unique Entry Code',
        type: 'incentiveFields',
        fieldLabel: '',
        mandatoryToggle: false,
        mandatoryMessage: '',
        toolTip: ''
      };
    } else if (selectedOption.dataType === 'samplePrizeSelection') {
      field = {
        answers: [
          {
            label: '',
            value: 'prize-0'
          }
        ],
        dataType: 'samplePrizeSelection',
        questionType: 'Single choice',
        label: selectedOption.label || 'Samples',
        fieldLabel: '',
        type: 'incentiveFields',
        mandatoryToggle: false,
        mandatoryMessage: '',
        toolTip: ''
      };
    } else if (selectedOption.dataType === 'fileUpload') {
      field = {
        dataType: 'fileUpload',
        fieldLabel: '',
        maxFileSize: constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE,
        type: 'incentiveFields',
        mandatoryToggle: false,
        mandatoryMessage: '',
        toolTip: '',
        label: selectedOption.label || 'File Upload',
        validationMessage: '',
        failedUploadMessage: '',
        values: [
          {
            key: 'jpg',
            value: 'jpg',
            isChecked: true
          },
          {
            key: 'jpeg',
            value: 'jpeg',
            isChecked: true
          },
          {
            key: 'tiff',
            value: 'tiff',
            isChecked: true
          },
          {
            key: 'tif',
            value: 'tif',
            isChecked: true
          },
          {
            key: 'png',
            value: 'png',
            isChecked: true
          },
          {
            key: 'Video (3gp, 3g2, mp4, flv, mkv, avi, mov)',
            value: '3gp, 3g2, mp4, flv, mkv, avi, mov',
            isChecked: true
          },
          {
            key: 'pdf',
            value: 'pdf',
            isChecked: true
          },
          {
            key: 'doc/docx',
            value: 'doc, docx',
            isChecked: true
          }
        ]
      };
    }

    return field;
  };
  validateIncentiveFields = () => {
    let isValid = true;
    // if incentive field array is blank then also allow next
    let filterIncentiveField = this.state.profile.filter(
      elem => elem.type === 'incentiveFields'
    );
    if (filterIncentiveField && filterIncentiveField.length === 0) {
      return isValid;
    }
    this.state.profile.map((elem, index) => {
      if (elem.type === 'incentiveFields') {
        const isFieldValid = this.validateIncentiveField(elem, index);
        if (!isFieldValid) {
          isValid = isFieldValid;
        }
      }
    });

    if (!isValid) {
      this.setState({
        showAllPurposeError: true
      });
    } else {
      this.setState({
        showAllPurposeError: false
      });
    }
    return isValid;
  };

  checkIncentiveFields = () => {
    let isValid = true;
    // if incentive field array is blank then also allow next
    let filterIncentiveField = this.state.profile.filter(
      elem => elem.type === 'incentiveFields'
    );
    if (filterIncentiveField && filterIncentiveField.length === 0) {
      return isValid;
    }
    this.state.profile.map((elem, index) => {
      if (elem.type === 'incentiveFields') {
        const isFieldValid = this.checkIncentiveField(elem, index);
        if (!isFieldValid) {
          isValid = isFieldValid;
        }
      }
    });

    if (!isValid) {
      this.setState({
        showAllPurposeError: true
      });
    } else {
      this.setState({
        showAllPurposeError: false
      });
    }
    return isValid;
  };

  checkIncentiveField = (elem, index) => {
    let isValid = true;
    if (
      elem &&
      elem.dataType !== 'competitionDetail' &&
      !/\S/.test(elem.fieldLabel || '')
    ) {
      isValid = false;
    }

    if (elem.mandatoryToggle) {
      if (!elem.mandatoryMessage || elem.mandatoryMessage.trim().length === 0) {
        isValid = false;
      }
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      (!elem.validationMessage || elem.validationMessage.trim().length == 0)
    ) {
      isValid = false;
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      (!elem.failedUploadMessage || elem.failedUploadMessage.trim().length == 0)
    ) {
      isValid = false;
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      (!elem.maxFileSize || elem.maxFileSize.trim().length == 0)
    ) {
      isValid = false;
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      elem.maxFileSize > parseInt(constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE)
    ) {
      isValid = false;
    }

    if (elem && elem.answers && elem.answers.length > 0) {
      elem.answers.map((answer, ansIndex) => {
        if (answer && answer.label.trim().length === 0) {
          isValid = false;
        }
      });
    }
    if (elem && elem.answers && elem.answers.length === 12) {
    }
    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: { ...fieldsValidity, [index]: isValid }
    }));
    return isValid;
  };

  validateIncentiveField = (elem, index) => {
    let isValid = true;
    if (
      elem &&
      elem.dataType !== 'competitionDetail' &&
      !/\S/.test(elem.fieldLabel || '')
    ) {
      isValid = false;
      this.setState(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`field_label_${index}`]: true
        }
      }));
    }

    if (elem.mandatoryToggle) {
      if (!elem.mandatoryMessage || elem.mandatoryMessage.trim().length === 0) {
        isValid = false;
        this.setState(({ incentiveErrors }) => ({
          incentiveErrors: {
            ...incentiveErrors,
            [`errorMessage_${index}`]: true
          }
        }));
      } else {
        this.setState(({ incentiveErrors }) => ({
          incentiveErrors: {
            ...incentiveErrors,
            [`errorMessage_${index}`]: false
          }
        }));
      }
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      (!elem.validationMessage || elem.validationMessage.trim().length == 0)
    ) {
      isValid = false;
      this.setState(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`validationMessage_${index}`]: true
        }
      }));
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      (!elem.failedUploadMessage || elem.failedUploadMessage.trim().length == 0)
    ) {
      isValid = false;
      this.setState(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`fileUploadMessage_${index}`]: true
        }
      }));
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      (!elem.maxFileSize || elem.maxFileSize.trim().length == 0)
    ) {
      isValid = false;
      this.setState(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`fileSize_${index}`]: true
        }
      }));
    }

    if (
      elem &&
      elem.dataType === 'fileUpload' &&
      elem.maxFileSize > parseInt(constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE)
    ) {
      isValid = false;
      this.setState(({ incentiveErrors }) => ({
        incentiveErrors: {
          ...incentiveErrors,
          [`max_file_size_${index}`]: true
        }
      }));
    }

    if (elem && elem.answers && elem.answers.length > 0) {
      elem.answers.map((answer, ansIndex) => {
        if (answer && answer.label.trim().length === 0) {
          document.getElementById(
            `answer_Error_Message_${index}_${ansIndex}`
          ) &&
            document
              .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
              .classList.add('cw-error-focus');

          isValid = false;
        }
      });
    }
    if (elem && elem.answers && elem.answers.length === 12) {
      document.getElementById(`answer_Error_Message_${index}`) &&
        document
          .getElementById(`answer_Error_Message_${index}`)
          .classList.add('cw-hidden');
    }
    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: { ...fieldsValidity, [index]: isValid }
    }));
    return isValid;
  };

  _handleIncentiveField = selectedOption => {
    selectedOption = this.handleIncentiveSelection(selectedOption);

    // entryCode and samplePrizeSelectionValidation only one time able to add
    let isDuplicate = this.state.profile.some(elem => {
      return (
        elem.dataType === selectedOption.dataType &&
        (elem.dataType === 'entryCode' ||
          elem.dataType === 'samplePrizeSelection' ||
          elem.dataType === 'competitionDetail')
      );
    });
    let fileUpdateArr = this.state.profile.filter(
      elem =>
        selectedOption.dataType === 'fileUpload' &&
        elem.dataType === 'fileUpload'
    );

    let fileUploadData =
      this.props.incentiveFields &&
      this.props.incentiveFields.length > 0 &&
      this.props.incentiveFields.filter(e => e.dataType === 'fileUpload');

    selectedOption.fieldLabel =
      this.props.incentiveFields &&
      this.props.incentiveFields.length > 0 &&
      this.props.incentiveFields.filter(
        e => e.dataType === selectedOption.dataType
      )[0] && this.props.incentiveFields.filter(
        e => e.dataType === selectedOption.dataType
      )[0].translatedLabel;

    selectedOption.mandatoryMessage =
      this.props.incentiveFields &&
      this.props.incentiveFields.length > 0 &&
      this.props.incentiveFields.filter(
        e => e.dataType === selectedOption.dataType
      )[0] && this.props.incentiveFields.filter(
        e => e.dataType === selectedOption.dataType
      )[0].mandatoryMessage;

    if (selectedOption.dataType === 'fileUpload') {
      selectedOption.validationMessage =
        fileUploadData &&
        fileUploadData.length > 0 &&
        fileUploadData[0].validationMessage;
      selectedOption.failedUploadMessage =
        fileUploadData &&
        fileUploadData.length > 0 &&
        fileUploadData[0].otherErrorMessage;
    }

    if (isDuplicate) {
      this.setState({ showErrorMessage: true, fileUploadError: false });
      // scrollToTop();
    } else {
      // 3 time fileupload can able to do then need to give error message
      if (fileUpdateArr && fileUpdateArr.length === 3) {
        this.setState({ showErrorMessage: true, fileUploadError: true });
      } else {
        // if (this.validateIncentiveField()) {
        this.setState(
          ({ profile }) => ({
            // expand: this.state.profile.indexOf(selectedOption),
            showErrorMessage: false,
            fileUploadError: false,
            profile: [...profile, selectedOption]
          }),
          this.checkIncentiveFields
        );
        // }
      }
    }
  };

  handleAddFormElement = selectedOption => {
    selectedOption.fieldLabel = selectedOption.translatedLabel;
    selectedOption.mandatoryToggle = false;
    if (selectedOption.dataType === 'heading') {
      selectedOption.heading = '';
      selectedOption.mandatoryToggle = false;
      selectedOption.id = _.uniqueId();
    }
    if (selectedOption.dataType === 'paragraph') {
      selectedOption.paragraph = '';
      selectedOption.mandatoryToggle = false;
      selectedOption.id = _.uniqueId();
    }

    if (
      selectedOption.dataType === 'heading' ||
      selectedOption.dataType === 'paragraph'
    ) {
      this.state.profile.push(selectedOption);
      this.setState(
        {
          profile: [...this.state.profile]
        },
        () => {
          this.checkFormElementValidity();
        }
      );
    }
  };

  checkFormElementValidity = () => {
    const { profile } = this.state;
    let isValid = true;
    profile.forEach((elem, index) => {
      if (elem.dataType === 'heading') {
        const isHeadingValid = this.checkHeadingField(elem, index);
        if (!isHeadingValid) {
          isValid = false;
        }
      }

      if (elem.dataType === 'paragraph') {
        const isParagraphValid = this.checkParagraphField(
          elem.paragraph,
          index
        );
        if (!isParagraphValid) {
          isValid = false;
        }
      }
    });
    return isValid;
  };

  handleAddField = (selectedOption, options={}) => {
    selectedOption = _.cloneDeep(selectedOption);
    const { type, dataType } = selectedOption;
    if (type === 'field') {
      this._handleProfileField(selectedOption, options);
    } else if (type === 'formElement') {
      this.handleAddFormElement(selectedOption);
    } else if (dataType === 'questionAnswerPair') {
      this._handleQuestionSelection(selectedOption);
    } else if (type === 'incentiveFields') {
      this._handleIncentiveField(selectedOption);
    } else if (type === CONSENT_MESSAGE) {
      this._handleConsentField(selectedOption);
    }
  };

  changeFieldValidity = (index, value) => {
    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: { ...fieldsValidity, [index]: value }
    }));
  };

  _handleQuestionSelection = selectedOption => {
    const { iConfirmQnA } = this.state;
    if (selectedOption.value === 'freeText') {
      this.setState({ showFreeTextNote: true });
    } else {
      this.setState({ showFreeTextNote: false });
    }
    if (iConfirmQnA) {
      this.setCheckbox(false);
    }
    this._hanldeQnASelection(selectedOption);
  };

  showQueErrors = index => {
    document.getElementById(`questionError_${index}`) &&
      document
        .getElementById(`questionError_${index}`)
        .classList.remove('cw-hidden');
  };

  validateQAfields = item => {
    let isValid = true;
    const qualifyingQuesData = {
      qualifyingQuesCount: 0,
      qualifyingQuesError: false
    };

    item.map((el, index) => {
      const isQuestionsValid = this.validateQuestionField(
        el,
        index,
        qualifyingQuesData
      );
      if (!isQuestionsValid) {
        isValid = isQuestionsValid;
      }
    });
    return isValid;
  };

  checkQAfields = _.debounce(() => {
    const { profile: fields } = this.state;
    let isValid = true;
    const qualifyingQuesData = {
      qualifyingQuesCount: 0,
      qualifyingQuesError: false
    };

    fields.map((el, index) => {
      const isQuestionsValid = this.checkQuestionValidity(
        el,
        index,
        qualifyingQuesData
      );
      if (!isQuestionsValid) {
        isValid = isQuestionsValid;
      }
    });
    return isValid;
  }, 300);

  checkQuestionValidity = (el, index, qualifyingQuesData = {}) => {
    const { maximumQualifingQuestionsErrorIndices } = this.state;
    const { campaign } = this.props;
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    const isEpsilonMarket = epsilonMarket(countrySelected);
    let isValid = true;
    if (el.dataType === 'questionAnswerPair') {
      let isFreeTextType = el.questionType === 'Free text';
      let isNonEnglish =
        this.props.campaignLanguageCode &&
        this.props.campaignLanguageCode !== 'EN';
      if (
        el.mandatoryToggle &&
        (el.mandatoryMessage === undefined ||
          el.mandatoryMessage.trim().length === 0)
      ) {
        isValid = false;
      }

      if (!isEpsilonMarket) {
        if (el.englishLabel !== undefined && el.englishLabel.length === 0) {
          isValid = false;
        }
        if (
          isNonEnglish &&
          el.translatedLabel !== undefined &&
          el.translatedLabel.length === 0
        ) {
          isValid = false;
        }
      }

      if (el.questionLabel !== undefined && el.questionLabel.length === 0) {
        this.setState(({ fieldsValidity }) => ({
          fieldsValidity: { ...fieldsValidity, [index]: false }
        }));
        isValid = false;
      }

      if (isEpsilonMarket) {
        if (
          (el.id !== undefined && el.id.length === 0) ||
          (document.getElementById(`questionId_${index}`) &&
            document.getElementById(`questionId_${index}`).value.trim()
              .length === 0)
        ) {
          // setExpand(index);
          isValid = false;
        }

        const regex = /^[0-9\b]+$/;

        if (
          document.getElementById(`questionId_${index}`) &&
          document.getElementById(`questionId_${index}`).value.trim().length !=
            0 &&
          !regex.test(document.getElementById(`questionId_${index}`).value)
        ) {
          // setExpand(index);
          isValid = false;
        }
      }
      if (
        el.isNew &&
        el.reasonForCreation !== undefined &&
        el.reasonForCreation.trim().length === 0
      ) {
        isValid = false;
      }

      if (
        el.qualifyingToggle &&
        maximumQualifingQuestionsErrorIndices.includes(index)
      ) {
        this.setState({ iConfirmQnA: false });
        qualifyingQuesData.qualifyingQuesError = true;
        this.setCheckbox(false);
        isValid = false;
      }
      
      if(isFreeTextType && el.questionLabel && el.id && this.validateFreeTextAnswers(el.answers, index)){
        isValid = false;
      } else if (!isFreeTextType && 
        this.checkAnswers(el.answers, index, isNonEnglish)
      ) {
        if (el.answers && el.answers.length < 2) {
        }
        isValid = false;
      }
  
      this.setState(({ fieldsValidity }) => ({
        fieldsValidity: { ...fieldsValidity, [index]: isValid }
      }));

      if (isValid) {
        this.setState({ showAllPurposeError: false });
      } else if (qualifyingQuesData.qualifyingQuesError) {
        this.setState({ showAllPurposeError: false });
      } else {
        this.setState({ showAllPurposeError: true });
      }
    }
    return isValid;
  };

  validateQuestionField = (el, index, qualifyingQuesData = {}) => {
    const { campaign } = this.props;
    const { maximumQualifingQuestionsErrorIndices } = this.state;
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    const isEpsilonMarket = epsilonMarket(countrySelected);
    let isValid = true;
    if (el.dataType === 'questionAnswerPair') {
      let isFreeTextType = el.questionType === 'Free text';
      let isNonEnglish = this.props.campaignLanguageCode !== 'EN';
      if (
        el.mandatoryToggle &&
        (el.mandatoryMessage === undefined ||
          el.mandatoryMessage.trim().length === 0)
      ) {
        this.setState(({ errors }) => ({
          errors: { ...errors, [`errorMessage_${index}`]: true }
        }));
        document.getElementById(`errorMessage_${el.value}_${index}`) &&
          document
            .getElementById(`errorMessage_${el.value}_${index}`)
            .classList.remove('cw-hidden');

        if (document.getElementById(`errorMessage_${index}`)) {
          document
            .getElementById(`errorMessage_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`errorMessage_${index}`)
              .classList.add('cw-error-focus');
        }

        isValid = false;
      }

      if (!isEpsilonMarket) {
        if (el.englishLabel !== undefined && el.englishLabel.length === 0) {
          document.getElementById(`questionDropdown_${index}`) &&
            document
              .getElementById(`questionDropdown_${index}`)
              .classList.remove('cw-hidden');

          // document
          //   .getElementById(`questionDropdown`)
          //   .classList.contains("cw-error-focus") ||
          //   document
          //     .getElementById(`questionDropdown`)
          //     .classList.add("cw-error-focus");
          this.showQueErrors(index);
          isValid = false;
        }
        if (
          isNonEnglish &&
          el.translatedLabel !== undefined &&
          el.translatedLabel.length === 0
        ) {
          document.getElementById(`questionTranslationError_${index}`) &&
            document
              .getElementById(`questionTranslationError_${index}`)
              .classList.remove('cw-hidden');

          if (document.getElementById(`questionTranslatedLabel_${index}`)) {
            document
              .getElementById(`questionTranslatedLabel_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`questionTranslatedLabel_${index}`)
                .classList.add('cw-error-focus');
          }
          // showQueErrors(index)
          isValid = false;
        } else {
          if (document.getElementById(`questionTranslatedLabel_${index}`)) {
            document
              .getElementById(`questionTranslatedLabel_${index}`)
              .classList.contains('cw-error-focus') &&
              document
                .getElementById(`questionTranslatedLabel_${index}`)
                .classList.remove('cw-error-focus');
          }
        }
      }

      if (el.questionLabel !== undefined && el.questionLabel.length === 0) {
        document.getElementById(`questionLabelError_${index}`) &&
          document
            .getElementById(`questionLabelError_${index}`)
            .classList.remove('cw-hidden');

        if (document.getElementById(`questionLabel_${index}`)) {
          document
            .getElementById(`questionLabel_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`questionLabel_${index}`)
              .classList.add('cw-error-focus');
        }

        this.setState(({ fieldsValidity }) => ({
          fieldsValidity: { ...fieldsValidity, [index]: false }
        }));
        isValid = false;
      } else {
        if (document.getElementById(`questionLabel_${index}`)) {
          document
            .getElementById(`questionLabel_${index}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`questionLabel_${index}`)
              .classList.remove('cw-error-focus');
        }
      }

      if (isEpsilonMarket) {
        if (
          (el.id !== undefined && el.id.length === 0) ||
          (document.getElementById(`questionId_${index}`) &&
            document.getElementById(`questionId_${index}`).value.trim()
              .length === 0)
        ) {
          document.getElementById(`questionIdError_${index}`) &&
            document
              .getElementById(`questionIdError_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`questionId_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`questionId_${index}`)
              .classList.add('cw-error-focus');

          // setExpand(index);
          isValid = false;
        }

        const regex = /^[0-9\b]+$/;

        if (
          document.getElementById(`questionId_${index}`) &&
          document.getElementById(`questionId_${index}`).value.trim().length !=
            0 &&
          !regex.test(document.getElementById(`questionId_${index}`).value)
        ) {
          document.getElementById(`questionIdNumError_${index}`) &&
            document
              .getElementById(`questionIdNumError_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`questionId_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`questionId_${index}`)
              .classList.add('cw-error-focus');

          // setExpand(index);
          isValid = false;
        }
      }
      if (
        el.isNew &&
        el.reasonForCreation !== undefined &&
        el.reasonForCreation.trim().length === 0
      ) {
        document.getElementById(`ReasonForMasterdata_${index}`) &&
          document
            .getElementById(`ReasonForMasterdata_${index}`)
            .classList.remove('d-none');
        document.getElementById(`reason_${index}`) &&
          document
            .getElementById(`reason_${index}`)
            .classList.add('cw-error-focus');

        isValid = false;
      }
      
      if (
        el.isNew &&
        (!el.additionalComments  ||
        el.additionalComments.trim().length === 0)
      ) {
        document.getElementById(`additionalComments_description_${index}`) &&
          document
            .getElementById(`additionalComments_description_${index}`)
            .classList.remove('d-none');
        document.getElementById(`additionalComments_${index}`) &&
          document
            .getElementById(`additionalComments_${index}`)
            .classList.add('cw-error-focus');

        isValid = false;
      }
    

      if (
        el.qualifyingToggle &&
        maximumQualifingQuestionsErrorIndices.includes(index)
      ) {
        this.setState({ iConfirmQnA: false });
        qualifyingQuesData.qualifyingQuesError = true;
        this.setCheckbox(false);
        isValid = false;
      }

      if(isFreeTextType &&  this.validateFreeTextAnswers(el.answers, index)){
        isValid = false;
      } else if (!isFreeTextType && 
        this.validateAnswers(el.answers, index, isNonEnglish)
      ) {
        if (el.answers && el.answers.length < 2) {
          this.showAnsErrors(index);
        }
        isValid = false;
      }
      this.setState(({ fieldsValidity }) => ({
        fieldsValidity: { ...fieldsValidity, [index]: isValid }
      }));

      if (isValid) {
        this.setState({ showAllPurposeError: false });
      } else if (qualifyingQuesData.qualifyingQuesError) {
        this.setState({ showAllPurposeError: false });
      } else {
        this.setState({ showAllPurposeError: true });
      }
    }
    return isValid;
  };

  showAnsErrors = index => {
    document.getElementById(`answerError_${index}`) &&
      document
        .getElementById(`answerError_${index}`)
        .classList.remove('cw-hidden');
  };

  checkAnswers = (answerObj, questionIndex, isNonEnglish) => {
    const { campaign } = this.props;
    const regex = /^[0-9\b]+$/;
    let isInvalid = false;
    if (answerObj && answerObj.length < 2) {
      isInvalid = true;
    }
    answerObj &&
      answerObj.map((ans, index) => {
        if (isNonEnglish) {
          if (
            typeof ans.translatedLabel === 'string' &&
            ans.translatedLabel.trim().length === 0
          ) {
            isInvalid = true;
          }
        }
        if (
          (ans.label && ans.label === '') ||
          (ans.label && ans.label.trim().length === 0)
        ) {
          isInvalid = true;
        }
        if (!ans.answerLabel || ans.answerLabel.trim().length === 0) {
          isInvalid = true;
        }
        const countrySelected =
          campaign && campaign.country && campaign.country.code;
        if (epsilonMarket(countrySelected)) {
          if (
            document.getElementById(`answerId_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            isInvalid = true;
          }
          if (
            document.getElementById(`answerId_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .value.trim().length != 0 &&
            !regex.test(
              document.getElementById(`answerId_${questionIndex}_${index}`)
                .value
            )
          ) {
            isInvalid = true;
          }
          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            isInvalid = true;
          }

          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .value.trim().length != 0 &&
            !regex.test(
              document.getElementById(`surveyId_${questionIndex}_${index}`)
                .value
            )
          ) {
            isInvalid = true;
          }
        }
      });
    return isInvalid;
  };
  validateFreeTextAnswers = (answerObj, questionIndex) => {
    const { campaign } = this.props;
    const regex = /^[0-9\b]+$/;
    let isInvalid = false;
    answerObj &&
      answerObj.map((ans, index) => {
        const countrySelected =
          campaign && campaign.country && campaign.country.code;
        if (epsilonMarket(countrySelected)) {
          if (
            document.getElementById(`answerId_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            document
              .getElementById(`answerId_Error_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .value.trim().length != 0 &&
            !regex.test(
              document.getElementById(`answerId_${questionIndex}_${index}`)
                .value
            )
          ) {
            document.getElementById(
              `answerId_NumError_${questionIndex}_${index}`
            ) &&
              document
                .getElementById(`answerId_NumError_${questionIndex}_${index}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            document
              .getElementById(`surveyId_error_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`surveyId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }

          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .value.trim().length != 0 &&
            !regex.test(
              document.getElementById(`surveyId_${questionIndex}_${index}`)
                .value
            )
          ) {
            document.getElementById(
              `surveyId_Numerror_${questionIndex}_${index}`
            ) &&
              document
                .getElementById(`surveyId_Numerror_${questionIndex}_${index}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`surveyId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
        }
      });
    return isInvalid;
  };

  validateAnswers = (answerObj, questionIndex, isNonEnglish) => {
    const { campaign } = this.props;
    const regex = /^[0-9\b]+$/;
    let isInvalid = false;
    if (answerObj && answerObj.length < 2) {
      isInvalid = true;
    }
    answerObj &&
      answerObj.map((ans, index) => {
        if (isNonEnglish) {
          if (
            typeof ans.translatedLabel === 'string' &&
            ans.translatedLabel.trim().length === 0
          ) {
            document
              .getElementById(
                `answerTranslationError_${questionIndex}_${index}`
              )
              .classList.remove('cw-hidden');

            document
              .getElementById(`answerTranslatedLabel_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(
                  `answerTranslatedLabel_${questionIndex}_${index}`
                )
                .classList.add('cw-error-focus');
            isInvalid = true;
          } else {
            if (
              document.getElementById(
                `answerTranslatedLabel_${questionIndex}_${index}`
              )
            ) {
              document
                .getElementById(
                  `answerTranslationError_${questionIndex}_${index}`
                )
                .classList.add('cw-hidden');
              document
                .getElementById(
                  `answerTranslatedLabel_${questionIndex}_${index}`
                )
                .classList.contains('cw-error-focus') &&
                document
                  .getElementById(
                    `answerTranslatedLabel_${questionIndex}_${index}`
                  )
                  .classList.remove('cw-error-focus');
            }
          }
        }
        if (
          (ans.label && ans.label === '') ||
          (ans.label && ans.label.trim().length === 0)
        ) {
          // document
          //   .getElementById(`answerDropdownError_${questionIndex}_${index}`)
          //   .classList.remove("cw-hidden");

          // document
          //   .getElementById(`answerDropdown_${questionIndex}_${index}`)
          //   .classList.contains("cw-error-focus") ||
          //   document
          //     .getElementById(`answerDropdown_${questionIndex}_${index}`)
          //     .classList.add("cw-error-focus");
          isInvalid = true;
        }
        if (!ans.answerLabel || ans.answerLabel.trim().length === 0) {
          document
            .getElementById(`answerLabelError_${questionIndex}_${index}`)
            .classList.remove('cw-hidden');

          document
            .getElementById(`answerLabel_${questionIndex}_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`answerLabel_${questionIndex}_${index}`)
              .classList.add('cw-error-focus');
          isInvalid = true;
        } else {
          document.getElementById(
            `answerLabelError_${questionIndex}_${index}`
          ) &&
            document
              .getElementById(`answerLabelError_${questionIndex}_${index}`)
              .classList.add('cw-hidden');

          document.getElementById(`answerLabel_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerLabel_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') &&
            document
              .getElementById(`answerLabel_${questionIndex}_${index}`)
              .classList.remove('cw-error-focus');
        }
        const countrySelected =
          campaign && campaign.country && campaign.country.code;
        if (epsilonMarket(countrySelected)) {
          if (
            document.getElementById(`answerId_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            document
              .getElementById(`answerId_Error_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
          if (
            document.getElementById(`answerId_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .value.trim().length != 0 &&
            !regex.test(
              document.getElementById(`answerId_${questionIndex}_${index}`)
                .value
            )
          ) {
            document.getElementById(
              `answerId_NumError_${questionIndex}_${index}`
            ) &&
              document
                .getElementById(`answerId_NumError_${questionIndex}_${index}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            document
              .getElementById(`surveyId_error_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`surveyId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }

          if (
            document.getElementById(`surveyId_${questionIndex}_${index}`) &&
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .value.trim().length != 0 &&
            !regex.test(
              document.getElementById(`surveyId_${questionIndex}_${index}`)
                .value
            )
          ) {
            document.getElementById(
              `surveyId_Numerror_${questionIndex}_${index}`
            ) &&
              document
                .getElementById(`surveyId_Numerror_${questionIndex}_${index}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`surveyId_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
        }
      });
    return isInvalid;
  };

  _hanldeQnASelection = selectedOption => {
    const { profile } = this.state;
    const { campaign } = this.props;
    let questionList = [...profile];
    this.setState({ questionsExist: true });
    let selectedQuestionType = selectedOption.value;
    let optionSelected;
    const questionObject =
      this.props &&
      this.props.formMetaData &&
      this.props.formMetaData.qnaFieldData &&
      this.props.formMetaData.qnaFieldData.find(
        e => e.dataType === selectedQuestionType
      );

    if (selectedQuestionType === 'singleChoice') {
      optionSelected = [
        {
          answers: [],
          isNew: false,
          istranslatedNew: false,
          translatedLabel: '',
          englishLabel: '',
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Single Choice Question',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          questionType: 'Single choice',
          toolTip: '',
          translatedId: '',
          value: 'singleChoice'
        }
      ];
    }
    if (selectedQuestionType === 'multipleChoice') {
      optionSelected = [
        {
          answers: [],
          isNew: false,
          istranslatedNew: false,
          translatedLabel: '',
          englishLabel: '',
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Multiple Choice Question',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          questionType: 'Multiple choice',
          toolTip: '',
          translatedId: '',
          value: 'multipleChoice',
          maxAnswerLimit: 'No Limit'
        }
      ];
    }
    if (selectedQuestionType === 'freeText') {
      optionSelected = [
        {
          answers: [],
          isNew: false,
          istranslatedNew: false,
          translatedLabel: '',
          englishLabel: '',
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Free Text Question',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          questionType: 'Free text',
          toolTip: '',
          translatedId: '',
          value: 'freeText'
        }
      ];
    }
    if (selectedQuestionType === 'freeTextArea') {
      optionSelected = [
        {
          answers: [],
          isNew: false,
          istranslatedNew: false,
          translatedLabel: '',
          englishLabel: '',
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Free Text Area',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          numberOfLines: 5,
          questionType: 'Free text',
          toolTip: '',
          translatedId: '',
          value: 'freeTextArea'
        }
      ];
    }
    const countrySelected =
      campaign && campaign.country && campaign.country.code;
    if (epsilonMarket(countrySelected)) {
      if (
        selectedQuestionType === 'singleChoice' ||
        selectedQuestionType === 'multipleChoice'
      ) {
        optionSelected[0].answers = [
          {
            value: '',
            answerLabel: '',
            epsilonQuestionAnswerId: ''
          },
          {
            value: '',
            answerLabel: '',
            epsilonQuestionAnswerId: ''
          }
        ];
      } else if (
        selectedQuestionType === 'freeText' ||
        selectedQuestionType === 'freeTextArea'
      ) {
        optionSelected[0].answers = [
          {
            value: '',
            epsilonQuestionAnswerId: ''
          }
        ];
      }
    }

    this.setState(
      { profile: [...questionList, ...optionSelected] },
      this.checkAllFields
    );
  };

  onHeadingChange = (e, index, elem) => {
    let heading = e.target.value;
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      heading: heading,
      dataType: 'heading',
      type: elem.type
    };
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`heading_error_${elem.dataType}_${index}`]: false
      }
    }));
    this.setState(
      {
        profile: [...newProfile]
      },
      () => this.checkHeadingField(newProfile[index], index)
    );
  };

  validateProfileItems = () => {
    const { fieldsValidity } = this.state;
    let isvalid = true;

    this.state.profile.forEach((elem, index) => {
      if (
        elem.dataType !== 'heading' &&
        elem.dataType !== 'paragraph' &&
        elem.dataType !== 'gender' &&
        elem.type === 'field'
      ) {
        if (
          elem.fieldLabel !== undefined &&
          elem.fieldLabel.trim().length === 0
        ) {
          isvalid = false;
          scrollToTop();
          this.setState({
            fieldsValidity: { ...fieldsValidity, [index]: false }
          });
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`fieldLabel_${elem.dataType}`]: true
            }
          }));
        }

        if (
          elem.type === 'field' &&
          elem.mandatoryToggle &&
          elem.mandatoryMessage.trim().length === 0
        ) {
          isvalid = false;
          this.setState({
            fieldsValidity: { ...fieldsValidity, [index]: false }
          });
          scrollToTop();
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`errorMessageText_${elem.dataType}`]: true
            }
          }));
        }
      }
    });
    return isvalid;
  };

  winLooseOnchange = e => {
    this.setState({
      instantWinLose: e.target.checked
    });
  };

  validateField = () => {
    const { fieldsValidity } = this.state;
    let isvalid = true;
    this.setState({
      showCommChannelValidationMessage: false
    });

    let profileOptions = [...this.state.profile];

    let mobileData = this.state.profile.find(
      elem => elem.dataType === 'mobileNumber'
    );
    let emailData = this.state.profile.find(elem => elem.dataType === 'email');
    let stateOrProvinceData = this.state.profile.find(
      elem => elem.dataType === 'stateOrProvince'
    );
    let headingData = this.state.profile.filter(
      elem => elem.dataType === 'heading'
    );
    let paragraphData = this.state.profile.filter(
      elem => elem.dataType === 'paragraph'
    );

    if (profileOptions && profileOptions.length === 0) {
      isvalid = false;
      scrollToTop();
      this.setState({ showCommChannelValidationMessage: true });
    }

    if (profileOptions && profileOptions.length > 0) {
      // scenario 3 - only mobile avaulable validation scenario
      if (!emailData && mobileData && !mobileData.mandatoryToggle) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      //scenario 1 - none mobile or email available
      if (!mobileData && !emailData) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      //scenario 2 - only email available & no mobile
      if (!mobileData && emailData && !emailData.mandatoryToggle) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      //scenario 4 mobile & email both available
      //Please mark either email or mobile as a mandatory field
      if (
        mobileData &&
        emailData &&
        !emailData.mandatoryToggle &&
        !mobileData.mandatoryToggle
      ) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      if (!this.validateProfileItems()) {
        isvalid = false;
      }

      let genderData = profileOptions.find(ele => ele.dataType === 'gender');
      if (genderData) {
        const index = profileOptions.findIndex(
          ele => ele.dataType === 'gender'
        );
        if (genderData.fieldLabel.trim().length === 0) {
          isvalid = false;
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`fieldLabel_${genderData.dataType}`]: true,
              isGenderFieldLabelErrorMessage: true
            }
          }));
        } else {
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`fieldLabel_${genderData.dataType}`]: false,
              isGenderFieldLabelErrorMessage: false
            }
          }));
        }

        const isGenderFieldValid = this.validateGenderField(genderData, index);
        if (!isGenderFieldValid) {
          isvalid = false;
        }
      }

      this.state.profile.forEach((elem, index) => {
        //checking type === field to segragate QnA validation of mandatoryToggle
        if (elem.type === 'field') {
          if (elem.mandatoryToggle) {
            if (elem.mandatoryMessage.trim().length === 0) {
              isvalid = false;
              this.setState(({ errors }) => ({
                errors: {
                  ...errors,
                  [`errorMessageText_${elem.dataType}`]: true
                }
              }));
            } else {
              this.setState(({ errors }) => ({
                errors: {
                  ...errors,
                  [`errorMessageText_${elem.dataType}`]: false
                }
              }));
            }
          }
          if (elem.dataType === 'dateOfBirth') {
            const isValid = this.validateAgeValidation(elem);
            if (!isValid) {
              isvalid = false;
            }
          }
        }
      });

      if (this.state.profile && this.state.profile.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (
            elem.dataType === 'email' ||
            elem.dataType === 'postalCode' ||
            elem.dataType === 'mobileNumber'
          ) {
            const isValidationMessageValid = this.validateValidationErrorMessage(
              elem,
              index
            );
            if (!isValidationMessageValid) {
              isvalid = false;
              this.setState(({ fieldsValidity }) => ({
                fieldsValidity: { ...fieldsValidity, [index]: false }
              }));
            }
          }
        });
      }

      if (headingData && headingData.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (elem.dataType === 'heading') {
            const isHeadingValid = this.validateHeadingField(elem, index);
            if (!isHeadingValid) {
              isvalid = false;
              this.setState({
                fieldsValidity: { ...fieldsValidity, [index]: false }
              });
            }
          }
        });
      }

      if (paragraphData && paragraphData.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (elem.dataType === 'paragraph') {
            const isParagraphValid = this.validateParagraphField(
              elem.paragraph,
              index,
              elem
            );
            if (!isParagraphValid) {
              isvalid = false;
              this.setState({
                fieldsValidity: { ...fieldsValidity, [index]: false }
              });
            }
          }
        });
      }
    }
    if (
      stateOrProvinceData &&
      stateOrProvinceData.questionType === 'Single choice'
    ) {
      this.state.provinces.map((stateofProvience, ansIndex) => {
        const isProvinceValid = this.validateProvince(
          stateofProvience,
          ansIndex
        );
        if (!isProvinceValid) {
          isvalid = false;
        }
      });
    }
    if (!isvalid) {
      this.setState({
        showAllPurposeError: true
      });
    } else {
      this.setState({
        showAllPurposeError: false
      });
    }
    this.checkValidityOfProfileFields();
    return isvalid;
  };

  submitAllFields(saveAsDraft) {
    let { campaign } = this.props;
    const { iConfirmQnA } = this.state;
    const countryCode = campaign.country && campaign.country.code;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)
    let profileFields = this.state.profile.map((elem, index) => {
      if (elem.dataType === 'stateOrProvince') {
        if (elem.questionType === 'Single choice') {
          return { ...elem, answers: this.state.provinces };
        } else return { ...elem, questionType: 'Free text' };
      } else if (elem.type === 'formElement') {
        delete elem.id;
      }
      return elem;
    });

    let consentFields = [];
    if (
      campaign &&
      campaign.campaignDetail &&
      campaign.campaignDetail.consents &&
      campaign.campaignDetail.consents.length > 0
    ) {
      consentFields = campaign.campaignDetail.consents;
      consentFields.forEach(ele => {
        if (ele && ele.optInType === 'legalAgeConsent') {
          ele.ageOfConsent = this.state.isLegalAge;
        }
      });
    }

    let editCampaign = this.props.campaign.isEdited;

    let messagesData = {};
    messagesData['submit'] = this.state.submitCtaLabel
      ? this.state.submitCtaLabel.trim()
      : this.props.formGenericData &&
        this.props.formGenericData.genericData &&
        this.props.formGenericData.genericData[0].translatedLabel;
    messagesData['submissionError'] = this.state.failureMsg
      ? this.state.failureMsg.trim()
      : this.props.formGenericData &&
        this.props.formGenericData.genericData &&
        this.props.formGenericData.genericData[1].translatedLabel;
    messagesData['campaignExpiry'] = this.state.expiryMsg
      ? this.state.expiryMsg.trim()
      : this.props.formGenericData &&
        this.props.formGenericData.genericData &&
        this.props.formGenericData.genericData[2].translatedLabel;
    messagesData['mandatoryFieldsIndicator'] = this.state.mandatoryInd
      ? this.state.mandatoryInd.trim()
      : this.props.formGenericData.genericData &&
        this.props.formGenericData.genericData[3].translatedLabel;

    const dataToSend = {
      uid: this.props.match.params.id,
      fields: [...profileFields],
      isEdited: editCampaign,
      ageOfConsent: this.state.isLegalAge,
      consents: this.state.consents,
      consentsVersion: this.state.consentsVersion,
      isUnBundledConsent : isUnbundledMarket,
      iConfirmQnA,
      instantWinLose: this.state.instantWinLose,
      messages: messagesData,
      userName: this.props.userName,
      userEmail :this.props.userEmail,
    };

    if (saveAsDraft) {
      dataToSend.saveAsDraft = saveAsDraft;
    }

    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
      .then(response => {
        if (response) {
          if (saveAsDraft) {
            let notificationData = {};
            notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
            notificationData.link = '';
            notificationData.type = 'warning';
            notificationData.campaign = '';

            this.props.setNotificationMessage(
              notificationData.message,
              notificationData.link,
              notificationData.type,
              notificationData.campaign
            );
            this.props.history.push('/campaign-wizard/dashboard');
          } else {
             if (campaign.status === '6') {
              this.props.setApproverEditCampaignStatus(true);
              this.props.setApproverEditCampaignStatusMessage('success');
              this.props.setApproverEditLastUpdatedScreen(
                'configure-form-fields'
              );
              this.props.history.push({
                pathname:
                  '/campaign-wizard/campaign/' +
                  this.props.match.params.id +
                  '/saas-sorting-page',
                state: { isEdited: true }
              });
            } else {
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.state.campaignId +
                  (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION
                    ? '/saas-sorting-page'
                    : '/saas-generic-details')
              );
            }
          }
        }
      });
  }

  validateFormElements = () => {
    let isValid = true;
    this.state.profile.forEach((elem, index) => {
      if (elem.dataType === 'heading') {
        const isHeadingValid = this.validateHeadingField(elem, index);
        if (!isHeadingValid) {
          isValid = false;
        }
      }
      if (elem.dataType === 'paragraph') {
        const isParagraphValid = this.validateParagraphField(
          elem.paragraph,
          index,
          elem
        );
        if (!isParagraphValid) {
          isValid = false;
        }
      }
    });

    return isValid;
  };

  _validateSubmitCtaLabel = () => {
    let isValid = this.isInputFilled('submitCtaLabel');
    if (!isValid) {
      if (
        document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`submitCtaLabel`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`submitCtaLabel`)
            .classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.add('cw-hidden');

        document
          .getElementById(`submitCtaLabel`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`submitCtaLabel`)
            .classList.remove('cw-error-focus');
      }
    }
    this.checkSubmitButtonValidity();
    return isValid;
  };

  _validateFailureMsg = () => {
    let isValid = this.isInputFilled('failureMsg');
    if (!isValid) {
      if (
        document
          .querySelector("[data-control='failureMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='failureMsg']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`failureMsg`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`failureMsg`).classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='failureMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='failureMsg']")
          .classList.add('cw-hidden');

        document
          .getElementById(`failureMsg`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`failureMsg`)
            .classList.remove('cw-error-focus');
      }
    }
    this.checkSubmitButtonValidity();
    return isValid;
  };

  _validateExpiryMsg = () => {
    let isValid = this.isInputFilled('expiryMsg');
    if (!isValid) {
      if (
        document
          .querySelector("[data-control='expiryMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='expiryMsg']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`expiryMsg`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`expiryMsg`).classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='expiryMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='expiryMsg']")
          .classList.add('cw-hidden');

        document
          .getElementById(`expiryMsg`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`expiryMsg`)
            .classList.remove('cw-error-focus');
      }
    }
    this.checkSubmitButtonValidity();
    return isValid;
  };

  _validateMandatoryIndText = () => {
    let isValid = this.isInputFilled('mandatoryInd');
    if (!isValid) {
      if (
        document
          .querySelector("[data-control='mandatoryInd']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='mandatoryInd']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`mandatoryInd`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`mandatoryInd`)
            .classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='mandatoryInd']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='mandatoryInd']")
          .classList.add('cw-hidden');

        document
          .getElementById(`mandatoryInd`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`mandatoryInd`)
            .classList.remove('cw-error-focus');
      }
    }
    this.checkSubmitButtonValidity();
    return isValid;
  };

  _handleSubmitCtaLabel = event => {
    let ctaLabel = event.target.value;
    this.setState({ submitCtaLabel: ctaLabel }, () => {
      this._validateSubmitCtaLabel();
    });
  };
  _handleFailureMsg = event => {
    let failMsg = event.target.value;
    this.setState({ failureMsg: failMsg }, () => {
      this._validateFailureMsg();
    });
  };
  _handleExpiryMsg = event => {
    let expMsg = event.target.value;
    this.setState({ expiryMsg: expMsg }, () => {
      this._validateExpiryMsg();
    });
  };

  _handleMandatoryIndText = event => {
    let mandatoryIndText = event.target.value;
    this.setState({ mandatoryInd: mandatoryIndText }, () => {
      this._validateMandatoryIndText();
    });
  };

  checkSubmitButtonValidity = () => {
    const fields = [
      'submitCtaLabel',
      'expiryMsg',
      'failureMsg',
      'mandatoryInd'
    ];

    const isValid = fields.every(field => this.isInputFilled(field));
    this.setState({ isSubmitButtonValid: isValid });
    return isValid;
  };

  isInputFilled = id => {
    return this.state[id].trim().length > 0;
  };

  validateSubmitButtonField() {
    const isSubmitCtaValid = this._validateSubmitCtaLabel();
    const isFailureMsgValid = this._validateFailureMsg();
    const isExpiryMsgValid = this._validateExpiryMsg();
    const isMandatoryIndTextValid = this._validateMandatoryIndText();

    const isValid =
      isSubmitCtaValid &&
      isFailureMsgValid &&
      isExpiryMsgValid &&
      isMandatoryIndTextValid;
    this.setState({ isSubmitButtonValid: isValid });
    return isValid;
  }

  checkAllFields = () => {
    const { profile } = this.state;

    const areProfileFieldsValid = this.checkValidityOfProfileFields();
    const areQAFieldsValid = this.checkQAfields();
    const areIncentiveFieldsValid = this.checkIncentiveFields();
    const areFormElementsValid = this.checkFormElementValidity();
    const isSubmitButtonValid = this.checkSubmitButtonValidity();

    return (
      areProfileFieldsValid &&
      areQAFieldsValid &&
      areIncentiveFieldsValid &&
      areFormElementsValid &&
      isSubmitButtonValid
    );
  };

  checkAllFields = () => {
    const areProfileFieldsValid = this.checkValidityOfProfileFields();
    const areQAFieldsValid = this.checkQAfields();
    const areIncentiveFieldsValid = this.checkIncentiveFields();
    const areFormElementsValid = this.checkFormElementValidity();
    const isSubmitButtonValid = this.checkSubmitButtonValidity();

    const isValid =
      areProfileFieldsValid &&
      areQAFieldsValid &&
      areIncentiveFieldsValid &&
      areFormElementsValid &&
      isSubmitButtonValid;

    if (isValid) {
      this.setState({ fieldErrorsPresent: false });
    }

    return isValid;
  };

  validateAllFields = () => {
    const { profile } = this.state;

    const areProfileFieldsValid = this.validateField();
    const areQAFieldsValid = this.validateQAfields(profile);
    const areIncentiveFieldsValid = this.validateIncentiveFields();
    const areFormElementsValid = this.validateFormElements();
    const {
      areConsentFieldsValid,
      showGenericError
    } = this.validateConsentFields();
    const isSubmitButtonValid = this.validateSubmitButtonField();

    return {
      areAllFieldsValid:
        areProfileFieldsValid &&
        areQAFieldsValid &&
        areIncentiveFieldsValid &&
        areFormElementsValid &&
        areConsentFieldsValid &&
        isSubmitButtonValid,
      showGenericError
    };
  };

  _handleSubmit = (event, saveAsDraft) => {
    if (saveAsDraft) {
      this.submitAllFields(saveAsDraft);
    } else {
      const { areAllFieldsValid, showGenericError } = this.validateAllFields();
      if (areAllFieldsValid) {
        this.setState({ fieldErrorsPresent: false });
        this.submitAllFields(saveAsDraft);
      } else {
        if (showGenericError) {
          this.setState({ fieldErrorsPresent: true }, () => {
            scrollToTop();
          });
        }
      }
    }
  };

  /**
   * Click handler for accordion menu item (Profile Fields/ Text Saperator/ QnA/ Insentive fields)
   * @method _handleProfileField
   * @param {} selectedOption
   * @return
   */
  _handleProfileField = (selectedOption, options) => {
    const { fieldsValidity } = this.state;
    let hasNoDuplicate = this.state.profile.some(elem => {
      return (
        elem.label === selectedOption.label &&
        selectedOption.dataType !== 'heading' &&
        selectedOption.dataType !== 'paragraph'
      );
    });
    let isLabelFilled = true;
    let isErrorMessageFilled = true;

    let emailData = this.state.profile.find(elem => elem.dataType === 'email');
    let fName = this.state.profile.find(elem => elem.dataType === 'firstName');
    let middleName = this.state.profile.find(
      elem => elem.dataType === 'middleName'
    );
    let lastName = this.state.profile.find(
      elem => elem.dataType === 'lastName'
    );
    let doB = this.state.profile.find(elem => elem.dataType === 'dateOfBirth');
    let mobileData = this.state.profile.find(
      elem => elem.dataType === 'mobileNumber'
    );
    let stateOrProvinceData = this.state.profile.find(
      elem => elem.dataType === 'stateOrProvince'
    );
    let houseNumber = this.state.profile.find(
      elem => elem.dataType === 'houseNameOrNumber'
    );
    let addressData1 = this.state.profile.find(
      elem => elem.dataType === 'addressLine1'
    );
    let addressData2 = this.state.profile.find(
      elem => elem.dataType === 'addressLine2'
    );
    let city = this.state.profile.find(elem => elem.dataType === 'cityName');
    let stateOrProvince = this.state.profile.find(
      elem => elem.dataType === 'stateOrProvince'
    );
    let postalCodeData = this.state.profile.find(
      elem => elem.dataType === 'postalCode'
    );
    let countryData = this.state.profile.find(
      elem => elem.dataType === 'addressCountry'
    );
    let gender = this.state.profile.find(elem => elem.dataType === 'gender');
    let honorificPrefixData = this.state.profile.find(
      elem => elem.dataType === 'honorificPrefix'
    );
    let honorificSufixData = this.state.profile.find(
      elem => elem.dataType === 'honorificSuffix'
    );
    let headingData = this.state.profile.filter(
      elem => elem.dataType === 'heading'
    );
    let paragraphData = this.state.profile.filter(
      elem => elem.dataType === 'paragraph'
    );

    if (this.state.profile && this.state.profile.length > 0) {
      if (
        stateOrProvinceData &&
        stateOrProvinceData.questionType === 'Single choice'
      ) {
        this.state.provinces.map((stateofProvience, ansIndex) => {
          const isProvinceValid = this.checkProvinceValidity(
            stateofProvience,
            ansIndex
          );
          if (!isProvinceValid) {
            isLabelFilled = false;
          }
        });
      }

      if (!this.checkProfileFieldValidity(emailData)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(mobileData)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(honorificPrefixData)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(fName)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(middleName)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(lastName)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(doB)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(houseNumber)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(addressData1)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(addressData2)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(postalCodeData)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(city)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(stateOrProvince)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(countryData)) {
        isLabelFilled = false;
      }
      if (!this.checkProfileFieldValidity(honorificSufixData)) {
        isLabelFilled = false;
      }

      if (gender) {
        if (
          gender &&
          Object.values(gender.fieldLabel.trim()).filter(elem =>
            /\S/.test(elem)
          ).length === 0
        ) {
          isLabelFilled = false;
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`fieldLabel_${gender.dataType}`]: true,
              isGenderFieldLabelErrorMessage: true
            }
          }));
        } else {
          this.setState(({ errors }) => ({
            errors: {
              ...errors,
              [`fieldLabel_${gender.dataType}`]: false,
              isGenderFieldLabelErrorMessage: false
            }
          }));
        }
        if (gender && gender.mandatoryToggle) {
          if (
            gender &&
            Object.values(gender.mandatoryMessage.trim()).filter(elem =>
              /\S/.test(elem)
            ).length === 0
          ) {
            isLabelFilled = false;
            this.setState({});
          }
        }
        if (gender && gender.values) {
          const index = this.state.profile.findIndex(
            elem => elem.dataType === 'gender'
          );
          const isGenderFieldValid = this.validateGenderField(gender, index);
          if (!isGenderFieldValid) {
            isLabelFilled = false;
          }
        }
      }
    }
    this.state.profile.forEach((elem, index) => {
      if (elem.type === 'field' && elem.mandatoryToggle) {
        if (elem.mandatoryMessage.trim().length === 0) {
          isLabelFilled = false;
        }
      }
    });

    if (this.state.profile && this.state.profile.length > 0) {
      this.state.profile.forEach((elem, index) => {
        if (
          elem.dataType === 'email' ||
          elem.dataType === 'postalCode' ||
          elem.dataType === 'mobileNumber'
        ) {
          const isValidationMessageValid = this.checkValidationErrorMessage(
            elem,
            index
          );
          if (!isValidationMessageValid) {
            isLabelFilled = false;
            this.setState(({ fieldsValidity }) => ({
              fieldsValidity: { ...fieldsValidity, [index]: false }
            }));
          }
        }
      });
    }

    if (!hasNoDuplicate) {
      this.setState({ showErrorMessage: false });
      this.setState({ showCommChannelValidationMessage: false });
      selectedOption.fieldLabel = selectedOption.translatedLabel;
      selectedOption.mandatoryToggle = false;
      if (selectedOption.dataType === 'heading') {
        selectedOption.heading = '';
        selectedOption.mandatoryToggle = false;
      }
      if (selectedOption.dataType === 'paragraph') {
        selectedOption.paragraph = '';
        selectedOption.mandatoryToggle = false;
      }
      if (selectedOption.dataType === 'email') {
        selectedOption.mandatoryToggle = true;
      }
      if (selectedOption.dataType === 'mobileNumber') {
        selectedOption.mandatoryToggle = true;
      }
      if (selectedOption && selectedOption.dataType === 'dateOfBirth') {
        const defaultAge = getLegalAge(this.props.campaign);
        this.setState({
          isLegalAge: defaultAge
        });
        selectedOption.validationMessage = selectedOption.validationMessage
          ? selectedOption.validationMessage.replace(
              '${age}',
              defaultAge.toString()
            )
          : null;
      }

      this.state.profile.push(selectedOption);
      this.setState(
        {
          profile: [...this.state.profile]
        },
        () => {
          this.updateNonRemovableField();
          if(selectedOption && (selectedOption.dataType === 'mobileNumber' || selectedOption.dataType === 'email')){
            const isMultiChannelConsentPresent = this.isConsentPresent(
              this.state.consents,
              consentTypes.multiChannelUnileverConsent
            );
            if(isMultiChannelConsentPresent){
              // when remove email or mobile field multi channel consent need to update
              this.updateMultiChannelConset(selectedOption)
            } else {
              this.updateNonRemovableConsents(false, options);
            }
          }
          this.checkAllFields();
        }
      );
      // this.setState({ expand: this.state.profile.indexOf(selectedOption) });
      this.setState({ expandEmail: false });
      if (!isLabelFilled) {
        this.setState(state => ({
          ...state,
          profileFieldErrors: {
            ...state.profileFieldErrors,
            [selectedOption.dataType]: true
          }
        }));
      }
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  validateGenderField = (genderData, index) => {
    const selectedLength = genderData.values.filter(ele => ele.isChecked)
      .length;
    let isValid = true;
    const isAnyTranslatedValueMissing =
      genderData.values.filter(
        ele => ele.isChecked && ele.translatedValue.trim() === ''
      ).length > 0;
    if (isAnyTranslatedValueMissing) {
      isValid = false;
      this.setState({
        genderFieldErrorMessage: true
      });
    } else {
      this.setState({
        genderFieldErrorMessage: false
      });
    }
    if (selectedLength < 2) {
      isValid = false;
      this.setState({
        genderErrorMessage: true
      });
    } else {
      this.setState({
        genderErrorMessage: false
      });
    }
    this.setState(({ fieldsValidity }) => ({
      fieldsValidity: { ...fieldsValidity, [index]: isValid }
    }));
    return isValid;
  };

  _addFieldButtonHandler = btt => {
    this.setState(btt => ({
      addFieldButtonVisibility: !btt.addFieldButtonVisibility
    }));
    setTimeout(() => {
      this.setState(btt => ({
        addFieldAccordionVisibility: !btt.addFieldAccordionVisibility
      }));
    }, 300);
  };
  _accordionCloseButtonHandler = btt => {
    this.setState(btt => ({
      addFieldAccordionVisibility: !btt.addFieldAccordionVisibility
    }));
    setTimeout(() => {
      this.setState(btt => ({
        addFieldButtonVisibility: !btt.addFieldButtonVisibility
      }));
    }, 300);
  };

  handleProvinceLabel = (e, ansIndex, index) => {
    let fieldLabel = e.target.value;
    let provinces = this.state.provinces;
    provinces[ansIndex] = { value: fieldLabel, label: fieldLabel };
    this.setState(({ errors }) => ({
      errors: {
        ...errors,
        [`province_Error_Message_${ansIndex}`]: false,
        [`answerTranslatedLabel_${ansIndex}`]: false
      }
    }));

    this.setState(
      {
        provinces: provinces
      },
      () => this.checkValidityOfProfileFields(index)
    );
  };

  _addProvince = e => {
    let isValid = false;
    let provinces = this.state.provinces;
    provinces.map((province, index) => {
      if (province.value.trim().length === 0) {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [`province_Error_Message_${index}`]: true,
            [`answerTranslatedLabel_${index}`]: true
          }
        }));

        isValid = true;
      }
    });

    if (isValid) {
      return;
    }
    provinces && provinces.push({ label: '', value: '' });

    provinces.forEach((ele, anindex) => {
      ele = provinces[anindex];
    });
    this.setState(
      {
        provinces: provinces
      },
      this.checkValidityOfGenderField
    );
  };

  checkValidityOfGenderField = () => {
    const index = this.state.profile.findIndex(
      field => field.dataType === 'stateOrProvince'
    );
    if (index > -1) {
      this.checkValidityOfProfileFields(index);
    }
  };

  _deleteProvince = async indexValue => {
    let provinces = this.state.provinces;
    // let updatedAnswers =
    provinces && provinces.splice(indexValue, 1);
    provinces.forEach((ele, anindex) => {
      ele = provinces[anindex];
    });
    provinces.map((province, provIndex) => {
      if (province.value.trim().length === 0) {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [`province_Error_Message_${provIndex}`]: true,
            [`answerTranslatedLabel_${provIndex}`]: true
          }
        }));
      } else {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [`province_Error_Message_${provIndex}`]: false,
            [`answerTranslatedLabel_${provIndex}`]: false
          }
        }));
      }
    });
    this.setState(
      {
        provinces: provinces
      },
      this.checkValidityOfGenderField
    );
  };

  genderOnchange = (e, index, genderIndex) => {
    let newProfile = [...this.state.profile];
    newProfile[index].values[genderIndex].isChecked = e.target.checked;
    this.setState({
      profile: newProfile
    });

    let profileOptions = [...this.state.profile];
    let genderData = profileOptions.find(ele => ele.dataType === 'gender');
    if (genderData) {
      let seleLength = genderData.values.filter(ele => ele.isChecked).length;
      let seleEmpltyField = genderData.values.filter(
        ele => ele.isChecked && ele.translatedValue.trim() === ''
      );
      setTimeout(() => {
        genderData.values.forEach(el => {
          if (el.isChecked && el.translatedValue.trim() === '') {
            this.setState(({ errors }) => ({
              errors: {
                ...errors,
                [`gender_${el.value}`]: true
              }
            }));
          } else {
            this.setState(({ errors }) => ({
              errors: {
                ...errors,
                [`gender_${el.value}`]: false
              }
            }));
          }
        });
      }, 100);

      this.validateGenderField(genderData, index);
      this.setState({
        genderFieldErrorMessage: seleEmpltyField.length > 0 ? true : false
      });
      if (seleLength < 2) {
        this.setState({
          genderErrorMessage: true
        });
      } else {
        this.setState({
          genderErrorMessage: false
        });
      }
    }
  };

  setIncetiveFields = updates => {
    const { incentiveFields, ...rest } = updates;
    this.setState({ profile: incentiveFields, ...rest });
  };

  updateIncentiveField = (elem, index) => {
    const { profile: incentiveFields } = this.state;
    incentiveFields[index] = elem;
    this.setState({ profile: [...incentiveFields] });
  };

  setIncetiveErrors = updateFunc => {
    this.setState(updateFunc);
  };

  setQuestionErrors = updates => {
    this.setState(updates);
  };

  addQuestion = profile => {
    this.setState({ profile }, () => {
      this.checkQAfields();
    });
  };

  updateField = (updatedField, index) => {
    const { profile } = this.state;
    profile[index] = updatedField;
    this.setState({ profile: [...profile] });
  };

  updateQuestion = (updatedQuestion, index) => {
    const { profile } = this.state;
    profile[index] = updatedQuestion;
    this.setState({ profile: [...profile] }, () => {
      this.checkQAfields();
    });
  };

  qualifyingQuestionHandlerIncentive = (event, index, elem) => {
    const { profile: fields } = this.state;
    let isChecked = event.target.checked;
    let qualifyingCount = this.getQualifyingQuestionsCount(fields);
    if (isChecked && qualifyingCount >= 5) {
      document.getElementById(`errorMessage_qualifyingToogle_${index}`) &&
        document
          .getElementById(`errorMessage_qualifyingToogle_${index}`)
          .classList.remove('cw-hidden');
      this.setCheckbox(false);
    } else {
      document
        .getElementById(`errorMessage_qualifyingToogle_${index}`)
        .classList.add('cw-hidden');
    }

    elem.qualifyingToggle = isChecked;
    this.updateField({ ...elem }, index);
    if (!isChecked) {
      if (qualifyingCount <= 5) {
        fields.forEach((elem, index) => {
          document.getElementById(`errorMessage_qualifyingToogle_${index}`) &&
            document
              .getElementById(`errorMessage_qualifyingToogle_${index}`)
              .classList.add('cw-hidden');
        });
      }
    }
  };

  qualifyingQuestionHandler = (event, index, elem) => {
    const { maximumQualifingQuestionsErrorIndices } = this.state;
    const { profile: question } = this.state;
    let isChecked = event.target.checked;
    let qualifyingCount = this.getQualifyingQuestionsCount(question);
    if (isChecked && qualifyingCount >= 5) {
      this.setQuestionErrors({
        maximumQualifingQuestionsErrorIndices: [
          ...this.state.maximumQualifingQuestionsErrorIndices,
          index
        ]
      });
      document.getElementById(`errorMessage_qualifyingToogle_${index}`) &&
        document
          .getElementById(`errorMessage_qualifyingToogle_${index}`)
          .classList.remove('cw-hidden');
      this.setCheckbox(false);
    } else {
      document
        .getElementById(`errorMessage_qualifyingToogle_${index}`)
        .classList.add('cw-hidden');
    }

    elem.qualifyingToggle = isChecked;
    this.updateQuestion({ ...elem }, index);
    if (!isChecked) {
      if (this.getQualifyingQuestionsCount(question) <= 5) {
        question.forEach((elem, index) => {
          document.getElementById(`errorMessage_qualifyingToogle_${index}`) &&
            document
              .getElementById(`errorMessage_qualifyingToogle_${index}`)
              .classList.add('cw-hidden');
        });
        this.setQuestionErrors({
          maximumQualifingQuestionsErrorIndices: []
        });
      } else {
        if (maximumQualifingQuestionsErrorIndices.length) {
          const errorIndex = maximumQualifingQuestionsErrorIndices.indexOf(
            index
          );
          maximumQualifingQuestionsErrorIndices.splice(
            errorIndex >= 0 ? errorIndex : 0,
            1
          );
          this.setQuestionErrors({
            maximumQualifingQuestionsErrorIndices: [
              ...maximumQualifingQuestionsErrorIndices
            ]
          });
        }
      }
    }
  };

  getQualifyingQuestionsCount = question => {
    let qualifyingQuesCount = 0;
    let qualifyingQuesList =
      question && question.filter(ele => ele && ele.qualifyingToggle);
    qualifyingQuesCount = qualifyingQuesList && qualifyingQuesList.length;
    return qualifyingQuesCount;
  };

  render() {
    const { campaign = {}, match ,campaignConfig } = this.props;
    const status = campaign && campaign.status;
    const editCampaign = campaign.isEdited;
    let countrySelected = campaign && campaign.country && campaign.country.code;
    let isProductSample =
      campaign && campaign.incentiveType === 'Product samples';
    
    
    const countryCode = campaign.country && campaign.country.code;
    const isEpsilonMarket =
            countryCode && epsilonMarket(countryCode) ? true : false;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(this.props.campaignConfig,countryCode)
    
    const brandCode =  campaign && campaign.brand && campaign.brand.code;
    const IsBrandEmailsmsConsentVisible = countryCode && brandCode &&  isBrandEmailSmsConsentVisible(this.props.campaignConfig, countryCode, brandCode)
    const isCombinedStatement = isEpsilonMarket && this.props.campaignConfig.COMBINED_UNILEVER_BRAND_EMAIL_CONSENT && !IsBrandEmailsmsConsentVisible

    const {
      errors,
      fieldsValidity,
      fieldErrorsPresent,
      emailConsentMissing,
      smsConsentMissing,
      maximumQualifingQuestionsErrorIndices,
      addFieldButtonVisibility,
      addFieldAccordionVisibility,
      hideNotification,
      consentsUnavailable,
      profileFieldErrors,
      isSubmitButtonValid,
      nonRemovableField,
      expand,
      iConfirmQnA,
      isOpenModel,
      instantWinLose,
      emailAddressFieldMissing,
      mobileNumberFieldMissing,
      whatsAppConsentMissing,
      mobileNumberMissingForWhatsapp,
      multiEmailConsentMissing,
      openMultiGuideLineModel
    } = this.state;
    const qualifyingQuestionCount = this.getQualifyingQuestionsCount(
      this.state.profile
    );

    const isNextDisabled =
      campaign.type === constant.CAMPAIGN_QA_TYPE &&
      (epsilonMarket(countrySelected) ? false : !iConfirmQnA);

    let stateTypeOptions = [
      {
        value: 'Free text',
        label: 'Free Text'
      },
      {
        value: 'Single choice',
        label: 'Single Choice'
      }
    ];
    let qnaTileData = fetchQnANotificationTileData(null, this.state.profile);

    return (
      <div className="col-sm-12">
        <div className="cw-section cw-sticky-section ml-20" style={{ flex: 1 }}>
          {this.props.isLoading || this.state.isLoading ? <Loader /> : null}
          {status !== '6' ? (
            <BreadCrumb
              hideBreadCrumb={this.props.approverEditCampaign || editCampaign}
              match={match}
              campaignType={campaign && campaign.campaignType}
              websiteType={
                campaign && campaign.websiteType && campaign.websiteType.name
              }
              formType={campaign && campaign.type}
            />
          ) : null}
          <div className="cw-section--title mb-20">
            <div>
              <div className="row">
                <div className="col-8 pr-5">
                  <div className="cw-section--title-wrap">
                    <h2 className="cw-heading--secondary mb-20">
                      Configure form fields
                    </h2>
                   
                    {editCampaign && (
                      <div className="form-group">
                        <Alert
                          alertType="warningMessageWithIcon"
                          textMessage={
                            constant.SAAS_MESSAGES.editSinglePageFieldsInfoMsg
                          }
                          showComponent="true"
                        />
                      </div>
                    )}
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.SAAS_MESSAGES.consentValidationMessage
                      }
                      showComponent={this.state.consentValidationFail}
                      openModal={this._openNewModal}
                      isLink={true}
                      modelText={'Need to hide age consent?'}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS
                          .emailConsentMissingMessage
                      }
                      showComponent={emailConsentMissing}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS
                          .emailAddressFieldMissingMesg
                      }
                      showComponent={emailAddressFieldMissing}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS
                          .mobileNumberFieldMissingMesg
                      }
                      showComponent={mobileNumberFieldMissing}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS
                          .mobileNumberFieldMissingMesgForWhatsapp
                      }
                      showComponent={mobileNumberMissingForWhatsapp}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS
                          .multiEmailConsentMissingErrMessage
                      }
                      showComponent={multiEmailConsentMissing}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS.smsConsentMissingMessage
                      }
                      showComponent={whatsAppConsentMissing ? false : smsConsentMissing}
                    />
                     <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS.whatsAppConsentMissingMessage
                      }
                      showComponent={whatsAppConsentMissing}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.CONFIGURE_FORM_FIELDS
                          .configurationMissingMessage
                      }
                      showComponent={fieldErrorsPresent}
                    />
                    <Alert
                      alertType={'saasWarningAlertMessageWithIcon'}
                      textMessage={
                        constant.SAAS_MESSAGES
                          .consentsUpdatedToEnterpriseMessage
                      }
                      closable
                      onClose={() => this.setState({ hideNotification: true })}
                      showComponent={
                        this.state.consentsUpdatedToEnterpriseMessage &&
                        !hideNotification
                      }
                    />
                     {campaign && campaign.isMasterData ? (
                      <>
                        <p className="mb-20">
                          Master data IDs are required for Q&As only. However, in addition to selecting your Q&A fields, you are required to select all applicable consumer data fields for your offline ingestion or custom integration initiative, for tracking and audit purpose
                        </p>
                      </>
                    ) : null}
                    <SinglePageDescription
                      isQnaCampaign={
                        campaign.type === 'Sign-up with Questions & Answers'
                          ? true
                          : false
                      }
                      isMultiGuideLine = {!isEpsilonMarket}
                      isOpenGuidelineModel={this.isOpenGuidelineModel}
                      isOpenMultiChannelGuideModel = {this.isOpenMultiChannelGuideModel}
                    />
                  </div>
                  <Modal
                    scrollable={true}
                    isOpen={this.state.isCancelModalOpen}
                    onRequestClose={this._closeIsCancelModal}
                    className="cw-modal cw-modal--branddetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <CampaignCancelModel
                      closeIsCancelModal={this._closeIsCancelModal}
                      isCancelContinue={this._cancelSaasCampaign}
                      data={[]}
                    />
                  </Modal>
                  <div className="content-section">
                    <div className="form-group">
                      {/* Dynamic Section */}
                      {this.state.profile.map((elem, index) => {
                        if (elem.type === 'field') {
                          return (
                            <ProfileField
                              key={elem.dataType}
                              elem={elem}
                              isValid={fieldsValidity[index]}
                              index={index}
                              errors={this.state.errors}
                              epsilonMarket={epsilonMarket}
                              countrySelected={countrySelected}
                              profileFieldErrors={profileFieldErrors}
                              stateTypeOptions={stateTypeOptions}
                              constant={constant}
                              expand={this.state.expand}
                              profile={this.state.profile}
                              provinces={this.state.provinces}
                              modalIsOpen={this.state.modalIsOpen}
                              genderErrorMessage={this.state.genderErrorMessage}
                              genderFieldErrorMessage={
                                this.state.genderFieldErrorMessage
                              }
                              profileRichText={this.state.profileRichText}
                              genderData={this.state.genderData}
                              isGenderFieldLabelErrorMessage={
                                this.state.isGenderFieldLabelErrorMessage
                              }
                              isLegalAge={this.state.isLegalAge}
                              _openModal={this._openModal}
                              handleAccordionExpandCollapse={
                                this.handleAccordionExpandCollapse
                              }
                              removeAccordion={this.removeAccordion}
                              nonRemovableField={nonRemovableField}
                              onLabelChange={this.onLabelChange}
                              _closeModal={this._closeModal}
                              _handleQuestionType={this._handleQuestionType}
                              _deleteProvince={this._deleteProvince}
                              handleProvinceLabel={this.handleProvinceLabel}
                              _addProvince={this._addProvince}
                              genderOnchange={this.genderOnchange}
                              genderInputHandler={this.genderInputHandler}
                              onToolTipChange={this.onToolTipChange}
                              mandatoryToggleOnChange={() => {
                                this.setState(
                                  prevState => ({
                                    nonEmailfieldRequired: !this.state.profile[
                                      index
                                    ].mandatoryToggle
                                  }),
                                  e => {
                                    this.onMandatoryToggleChange(
                                      e,
                                      this.state.nonEmailfieldRequired,
                                      index,
                                      elem
                                    );
                                  }
                                );
                              }}
                              onErrorMessageChange={this.onErrorMessageChange}
                              _handleInputChange={this._handleInputChange}
                              onLegalAgeValidationChange={
                                this.onLegalAgeValidationChange
                              }
                              onMobileRegexChange={this.onMobileRegexChange}
                              onHeadingChange={this.onHeadingChange}
                              isMasterData ={campaign && campaign.isMasterData}
                              campaignConfig ={campaignConfig}
                            />
                          );
                        } else if (elem.type === 'formElement') {
                          if (
                            elem.value === 'heading' ||
                            elem.label === 'Heading'
                          ) {
                            return (
                              <HeadingField
                                elem={elem}
                                index={index}
                                isValid={fieldsValidity[index]}
                                onHeadingChange={this.onHeadingChange}
                                profile={this.state.profile}
                                errors={this.state.errors}
                                removeAccordion={this.removeAccordion}
                                handleAccordionExpandCollapse={
                                  this.handleAccordionExpandCollapse
                                }
                                expand={this.state.expand}
                              />
                            );
                          } else if (
                            elem.value === 'paragraph' ||
                            elem.label === 'Paragraph'
                          ) {
                            return (
                              <ParagraphField
                                elem={elem}
                                index={index}
                                profileRichText={this.state.profileRichText}
                                richTextData={this.richTextData}
                                isValid={fieldsValidity[index]}
                                profile={this.state.profile}
                                errors={this.state.errors}
                                removeAccordion={this.removeAccordion}
                                handleAccordionExpandCollapse={
                                  this.handleAccordionExpandCollapse
                                }
                                expand={this.state.expand}
                              />
                            );
                          }
                        } else if (elem.dataType === 'questionAnswerPair') {
                          if (epsilonMarket(countrySelected)) {
                            return (
                              <QnAFieldEpsilon
                                elem={elem}
                                index={index}
                                errors={errors}
                                isValid={fieldsValidity[index]}
                                campaignType={campaign && campaign.campaignType}
                                question={this.state.profile}
                                addQuestion={profile => {
                                  this.setState({ profile }, () => {
                                    this.checkQAfields();
                                  });
                                }}
                                handleAccordionExpandCollapse={
                                  this.handleAccordionExpandCollapse
                                }
                                expand={this.state.expand}
                                isEdited={campaign.isEdited}
                                removeAccordion={this.removeAccordion}
                              />
                            );
                          }
                          if (constant.featureFlags.ENABLE_NEW_QNA_FOR_SAAS) {
                            return (
                              <QnAFieldv2
                                elem={elem}
                                maximumQualifingQuestionsErrorIndices={
                                  maximumQualifingQuestionsErrorIndices
                                }
                                setQuestionErrors={this.setQuestionErrors}
                                isValid={fieldsValidity[index]}
                                qualifyingQuestionCount={
                                  qualifyingQuestionCount
                                }
                                campaignType={campaign && campaign.campaignType}
                                index={index}
                                setCheckbox={this.setCheckbox}
                                errors={errors}
                                qualifyingQuestionHandler={
                                  this.qualifyingQuestionHandler
                                }
                                updateQuestion={this.updateQuestion}
                                handleAccordionExpandCollapse={
                                  this.handleAccordionExpandCollapse
                                }
                                expand={this.state.expand}
                                isEdited={campaign.isEdited}
                                removeAccordion={this.removeAccordion}
                                isMasterData={campaign && campaign.isMasterData}
                                campaignConfig ={campaignConfig}
                                isOpenGuidelineModel={this.isOpenGuidelineModel}
                              />
                            );
                          }
                          return (
                            <QnAField
                              elem={elem}
                              isValid={fieldsValidity[index]}
                              campaignType={campaign && campaign.campaignType}
                              index={index}
                              errors={errors}
                              question={this.state.profile}
                              addQuestion={profile =>
                                this.setState({ profile })
                              }
                              handleAccordionExpandCollapse={
                                this.handleAccordionExpandCollapse
                              }
                              expand={this.state.expand}
                              isEdited={campaign.isEdited}
                              removeAccordion={this.removeAccordion}
                            />
                          );
                        } else if (elem.type === 'incentiveFields') {
                          return (
                            <IncentiveField
                              isValid={fieldsValidity[index]}
                              errors={this.state.incentiveErrors}
                              removeAccordion={this.removeAccordion}
                              validateIncentiveField={this.checkIncentiveField}
                              elem={elem}
                              isProductSample={isProductSample}
                              index={index}
                              isEdited={campaign.isEdited}
                              expand={this.state.expand}
                              updateIncentiveField={this.updateIncentiveField}
                              setIncetiveErrors={this.setIncetiveErrors}
                              qualifyingQuestionCount={qualifyingQuestionCount}
                              qualifyingQuestionHandlerIncentive={
                                this.qualifyingQuestionHandlerIncentive
                              }
                              handleAccordionExpandCollapse={
                                this.handleAccordionExpandCollapse
                              }
                              isMasterData={campaign && campaign.isMasterData}
                              campaignConfig ={campaignConfig}
                            />
                          );
                        }
                      })}
                      <ConsentsSection
                        updateConsent={this.updateConsent}
                        consentsTest={this.state.consentsTest}
                        consentFieldsValidity={this.state.consentFieldsValidity}
                        nonRemovableConsents={this.state.nonRemovableConsents}
                        _handleInputChange={this._handleConsentInputChange}
                        _handleReset={this._handleReset}
                        _handleLegalAgeConsentInputChange={
                          this._handleLegalAgeConsentInputChange
                        }
                        consentsUnavailable={consentsUnavailable}
                        _handleFrequency={this._handleFrequency}
                        baseIndex={this.state.profile.length}
                        handleAccordionExpandCollapse={
                          this.handleAccordionExpandCollapse
                        }
                        expand={this.state.expand}
                        removeAccordion={this.removeAccordion}
                        consents={this.state.consents}
                        isLegalAge={this.state.isLegalAge}
                        consentsVersion={this.state.consentsVersion}
                        countrySelected={countrySelected}
                        isEdited={campaign.isEdited}
                        isEpsilonMarket={isEpsilonMarket}
                        isMasterData = {campaign && campaign.isMasterData}
                        disableMandatoryToggle={this.disableMandatoryToggle}
                        campaignType={campaign && campaign.campaignType}
                        campaignConfig ={campaignConfig}
                        isUnbundledMarket={isUnbundledMarket}
                        isPlatformAgnostic={campaign && campaign.isPlatformAgnostic}
                        isCombinedStatement={isCombinedStatement}
                        _handleLatastApprovedConsent={this._handleLatastApprovedConsent}
                        userrole={this.props.userrole}
                        handleMultiChannelCheckboxClick={this.handleMultiChannelCheckboxClick}
                        isOpenMultiChannelGuideModel = {this.isOpenMultiChannelGuideModel}
                      />
                      <SubmitButton
                        elem={this.props.formGenericData.submitData || {}}
                        expand={expand}
                        winLooseOnchange={this.winLooseOnchange}
                        instantWinLose={instantWinLose}
                        isValid={isSubmitButtonValid}
                        submitCtaLabel={this.state.submitCtaLabel}
                        failureMsg={this.state.failureMsg}
                        expiryMsg={this.state.expiryMsg}
                        mandatoryInd={this.state.mandatoryInd}
                        _handleSubmitCtaLabel={this._handleSubmitCtaLabel}
                        _handleExpiryMsg={this._handleExpiryMsg}
                        _handleFailureMsg={this._handleFailureMsg}
                        _handleMandatoryIndText={this._handleMandatoryIndText}
                        campaign={campaign}
                        campaignId={match.params.id}
                        handleAccordionExpandCollapse={
                          this.handleAccordionExpandCollapse
                        }
                      />
                    </div>
                    {qnaTileData &&
                    qnaTileData.showQnATile &&
                    qnaTileData.enrichmentQues > 0 ? (
                      <SummaryComponent
                        data={campaign}
                        isBrandSummary={false}
                        isQnASummary={true}
                        qnaTileData={qnaTileData}
                        isQnaPage={true}
                      />
                    ) : null}
                    {campaign.type === 'Sign-up with Questions & Answers' &&
                      !epsilonMarket(countrySelected) && (
                        <IConfirm
                          iConfirmQnA={iConfirmQnA}
                          _handleCheckboxConfirm={this._handleCheckboxConfirm}
                          isOpenGuidelineModel={this.isOpenGuidelineModel}
                        />
                      )}
                    {this.status === '6' ? (
                      <div className="cw-form--action-cta">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isNextDisabled}
                          onClick={event => this._handleSubmit(event)}
                        >
                          Next
                        </button>
                        <button
                          className="cw-save-to-draft mt-40"
                          onClick={this._handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="cw-form--action-cta">
                        <button
                          type="button"
                          className="btn btn btn-outline-secondary mr-3"
                          onClick={this.handlebackButton}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isNextDisabled}
                          onClick={event => this._handleSubmit(event)}
                        >
                          Next
                        </button>
                        {campaign.isEdited ? (
                          <button
                            className="cw-cancel-edit mt-40"
                            onClick={event => this._openIsCancelModal(event)}
                          >
                            Cancel changes
                          </button>
                        ) : (
                          <button
                            className="cw-save-to-draft mt-40"
                            onClick={event => this._handleSubmit(event, true)}
                          >
                            Save and exit
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <Modal
                    scrollable={true}
                    isOpen={isOpenModel}
                    onRequestClose={this.isCloseGuidelineModel}
                    className="cw-modal cw-modal--qnadetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <QnaGuideline
                      closeIsEditModal={this.isCloseGuidelineModel}
                    />
                  </Modal>
                  <Modal
                    scrollable={true}
                    isOpen={openMultiGuideLineModel}
                    onRequestClose={this.isCloseMultiChannelGuideModel}
                    className="cw-modal cw-modal--qnadetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <MultiChannelGuideline
                      onClose={this.isCloseMultiChannelGuideModel}
                    />
                  </Modal>
                </div>
                <div className="col-4">
                  <div className="sidebar-item">
                    {addFieldButtonVisibility ? (
                      <>
                        <div>
                          <AddFieldsButton
                            disabled={editCampaign}
                            name="btn"
                            onClick={e => {
                              this._addFieldButtonHandler(e);
                            }}
                          />
                        </div>
                        {checkIsCIAEnabled(campaign) &&
                          this.state.consentsVersion ===
                            'enterpriseConsentsVersion3' && (
                            <div className="cia-alert">
                              <Alert alertType="ciaNewUpdateInfo" />
                            </div>
                          )}
                      </>
                    ) : (
                      <div>
                        <MenuAccordion
                          menuItems={this._getCampaignConfigAccordionList(
                            campaign
                          )}
                          editCampaign={editCampaign}
                          clickHandler={this.handleAddField}
                          closeHandler={this._accordionCloseButtonHandler}
                          isMasterDataOther = {campaign && campaign.isMasterData && campaign.campaignDetail && campaign.campaignDetail.incentiveItPartner === 'other'}
                          profile={this.state.profile}
                          consents={this.state.consents}
                          campaign={campaign}
                          campaignConfig={campaignConfig}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  name: state.campaignDetails.name,
  language: state.campaignDetails.language,
  formMetaData: state.saasFormAuthoring,
  campaign: state.overview.campaign,
  isLoading: state.saasFormAuthoring.isLoading,
  incentiveFields: state.saasFormAuthoring.incentiveFields,
  questions: state.questionsAnswers.questions,
  addedQuestions: state.questionsAnswers.addedQuestions,
  prevAddedQuestions: state.questionsAnswers.prevAddedQuestions,
  answers: state.questionsAnswers.answers,
  selectedQuestionType: state.questionsAnswers.selectedQuestionType,
  fetchingQuestions: state.questionsAnswers.fetchingQuestions,
  fetchingAnswers: state.questionsAnswers.fetchingAnswers,
  questionsLoaded: state.questionsAnswers.questionsLoaded,
  answersLoaded: state.questionsAnswers.answersLoaded,
  dataSubmittedToApi: state.questionsAnswers.dataSubmittedToApi,
  campaignDetailsLoaded: state.questionsAnswers.campaignDetailsLoaded,
  approverEditCampaign: state.app.approverEditCampaign,
  questionanswerssaved: state.questionsAnswers.questionanswerssaved,
  formConsentData: state.consentAndCompliance.formConsentData,
  allConsents: state.consentAndCompliance.allConsents,
  campaignLanguage: state.questionsAnswers.campaignLanguage,
  campaignLanguageCode: state.questionsAnswers.campaignLanguageCode,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  isCampaignEdited: state.questionsAnswers.isCampaignEdited,
  websiteType: state.questionsAnswers.websiteType,
  isLanguageEnglish: state.questionsAnswers.isLanguageEnglish,
  campaignType: state.questionsAnswers.campaignType,
  autogenerate: state.campaignDetails.autogenerate,
  type: state.campaignDetails.type,
  incentiveType: state.campaignDetails.incentiveType,
  formGenericData: state.saasFormAuthoring,
  fields: state.questionsAnswers.fields,
  campaignConfig: state.app.campaignConfig,
  campaignDetails: state.campaignDetails
});

export default connect(mapStateToProps, {
  setNotificationMessage,
  getGenericFieldData,
  getAuthoringFieldData,
  fetchCampaignData,
  setApproverEditCampaignStatusMessage,
  setApproverEditCampaignStatus,
  setApproverEditLastUpdatedScreen,
  getAllConsentsForLocale,
  getConsentFieldData,
  cancelCampaignChanges,
  getIncentiveFields,
  getQuestions,
  getAnswers,
  getCampaignDetails
})(SaasFormSinglePageDetails);