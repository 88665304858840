import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router'
import constants from 'constants/config';
import WACampaignScheduleDetails from 'pages/WACampaignScheduleDetails';

const WAScheduleModel = ({
  modalIsOpen,
  closeModal,
  waDetailsOverview,
  pathUid
}) => {
  const history = useHistory()
  const { name =null, waType, country ={}, brand ={}, isCrossBrand = false }  = waDetailsOverview;
  const campaignName = (name) || 'Untitled';
  const [success, setSuccess] = useState(false);

  const brandDisplay = isCrossBrand
    ? 'Cross brand'
    : Array.isArray(brand)
    ? brand[0].name
    : brand.label;

  const handleSetSuccess = (v) => {
    setSuccess(v)
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--schedule-sms-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button className="cw-modal--close" title="Close" onClick={closeModal}>
          <i className="fas fa-times"></i>
        </button>
        {success ? (
          <>
            {' '}
            <h3 className="cw-heading--senary">WhatsApp campaign scheduled</h3>
            <p className="mb-10 mt-10 email-summary-font">
              {' '}
              Thank you for marking the campaign as scheduled.
            </p>
            <span className="mb-10">
              <strong className="email-summary-font">Whats next</strong>
            </span>
            <ul className="mb-10 ml-20">
              <li className="qna_guide_list">
                We will notify the requestor that the WhatsApp workflow is setup
                and ready to go in ACS!
              </li>
              <li className="qna_guide_list">
                You will receive an email notification on the go live date to
                mark the campaign 'Live'
              </li>
            </ul>
            <button
              type="button"
              onClick={() => {
                closeModal({ showAlertMessage: true, refetchHistory: true })
              }}
              className="btn btn btn-primary mt-30"
            >
              Close
            </button>
          </>
        ) : (
          <>
            <h3 className="cw-heading--senary">
              Mark campaign as scheduled
            </h3>
            <p className="mb-10 mt-10 email-summary-font">
              {' '}
              Please mark the campaign as scheduled if it has been setup in ACS
              to be auto-executed on the target date.
            </p>
            <p className="font-weight-bold email-summary-font">
              Campaign summary
            </p>
            <h2 className={`cw-email-heading`}>
              <span className="email-highlight campaign-break-word">
                {campaignName}
              </span>
            </h2>
            <p className="email-summary-font">
              {constants.WA_TYPE_LABELS[waType]}
            </p>
            <p className="email-summary-font">
              {country.label}, {brandDisplay}
            </p>
            <br />
            <WACampaignScheduleDetails
              pathUid={pathUid}
              closeModal={closeModal}
              smsDetailsOverview={waDetailsOverview}
              setSuccess={handleSetSuccess}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default WAScheduleModel;
