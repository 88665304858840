import * as yup from 'yup';
import constants from 'constants/config';
import {
  getYupSchema,
  WACampaignSubmitRedirectURL,
  transformWAPayloadBySchedule
} from 'selectors';
import moment from 'moment';
import {
  DisplayWaTriggerInput,
  WaTriggerInput
} from 'components/molecules/WaTriggerInput';
import { updateWAComms } from 'actions/wabriefings/waComms';
export const dateTimeSchema = isABTestOnSendTime => {
  if (isABTestOnSendTime) {
    return {
      waDeliveryDateVariantA: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter WhatsApp delivery date for variant A'),
      waDeliveryDateVariantB: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter WhatsApp delivery date for variant B'),
      waDeliveryTimeVariantA: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time for variant A'),
      waDeliveryTimeVariantB: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter WhatsApp delivery time for variant B')
    };
  } else {
    return {
      waDeliveryDate: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter WhatsApp delivery date'),
      waDeliveryTime: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter WhatsApp delivery time')
    };
  }
};

const waScheduleSchema = (
  dispatch,
  {
    defaultValues = {},
    match,
    history,
    emailDesign,
    waType,
    abTesting,
    waCampaignDetails
  }
) => {
  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    if (data?.waTrigger?.details?.triggerCondition?.value !== 'other') {
      delete data.waTrigger.details.otherTriggerDetails;
    }
    const paramUid = match.params.id;
    const dataToSend = {
      uid: paramUid,
      userEmail: user.emailId,
      userName: user.name,
      deliverySchedule: data
    };
    const transformedData = transformWAPayloadBySchedule(dataToSend);
    dispatch(
      updateWAComms(paramUid, transformedData, (error, result) => {
        if (error) {
          console.log('OnSubmit error: ', error);
        } else {
          history.push(
            WACampaignSubmitRedirectURL({
              backToReview: isEdited && backToReview,
              status: waCampaignDetails.status,
              uid: paramUid,
              nextPage: `${constants.WA_ROUTES.AUDIENCE}`
            })
          );
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'waTrigger',
        component: WaTriggerInput,
        displayComponent: DisplayWaTriggerInput,
        id: 'waTrigger',
        label: 'WhatsApp trigger',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: defaultValues.waTrigger,
        waType: waType,
        abTesting,
        rules: yup
          .object({
            type: yup
              .string()
              .required('Please select WhatsApp trigger')
              .nullable(),
            details: yup
              .object()
              .when('type', {
                is: 'fixedDateAndtime',
                then: yup.object(dateTimeSchema(isABTestOnSendTime))
              })
              .when('type', {
                is: 'dynamicTrigger',
                then: yup.object({
                  triggerCondition: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .required('Please select trigger condition')
                    .default(undefined),
                  triggerConditionStartDate: yup
                    .string()
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition &&
                        triggerCondition.value != 'fixedDateInBirthdayMonth',
                      then: schema =>
                        schema
                          .test(
                            'isDateStringParsable',
                            'Please enter a valid date',
                            dateString => {
                              return (
                                moment(
                                  dateString,
                                  'DD/MM/YYYY',
                                  true
                                ).isValid() ||
                                moment(
                                  dateString,
                                  'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                                  true
                                ).isValid()
                              );
                            }
                          )
                          .test(
                            'isNotPastDate',
                            'Please select a future date',
                            dateString => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(moment(), 'day');
                            }
                          )
                          .required('Please enter start date')
                    }),
                  triggerConditionEndDate: yup
                    .string()
                    .when(['triggerConditionAlwaysOn', 'triggerCondition'], {
                      is: (triggerConditionAlwaysOn, triggerCondition) => {
                        return triggerCondition &&
                          triggerCondition.value === 'fixedDateInBirthdayMonth'
                          ? false
                          : triggerConditionAlwaysOn
                          ? false
                          : true;
                      },
                      then: schema =>
                        schema
                          .test(
                            'isDateStringParsable',
                            'Please enter a valid date',
                            dateString => {
                              return (
                                moment(
                                  dateString,
                                  'DD/MM/YYYY',
                                  true
                                ).isValid() ||
                                moment(
                                  dateString,
                                  'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                                  true
                                ).isValid()
                              );
                            }
                          )
                          .test(
                            'isNotPastDate',
                            'Please select a future date',
                            dateString => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(moment(), 'day');
                            }
                          )
                          .required('Please enter end date'),
                      otherwise: schema => schema.notRequired()
                    }),
                  otherTriggerDetails: yup
                    .string()
                    .trim()
                    .nullable()
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition && triggerCondition.value === 'other',
                      then: yup
                        .string()
                        .required('Please specify trigger condition'),
                      otherwise: yup.string().nullable()
                    }),
                  triggerConditionBirthdayDate: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .default(undefined)
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition &&
                        triggerCondition.value === 'fixedDateInBirthdayMonth',
                      then: yup.object().required('Please select date'),
                      otherwise: yup.object().nullable()
                    })
                })
              })
          })
          .required('Please select email trigger')
          .default({ type: undefined, details: undefined })
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: defaultValues.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(300, 'Max 300 characters are allowed'),
        maxLength: '300'
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),

    onDraft: (data, validationSchema, { user }) => {
      if (
        data?.waTrigger?.details?.triggerCondition?.value !== 'other' &&
        data?.waTrigger?.details?.otherTriggerDetails
      ) {
        delete data.waTrigger.details.otherTriggerDetails;
      }
      const paramUid = match.params.id;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        deliverySchedule: data
      };
      const transformedData = transformWAPayloadBySchedule(dataToSend);
      dispatch(
        updateWAComms(
          paramUid,
          transformedData,
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              history.push(
                `${constants.WA_BASE_ROUTE}/${constants.WA_ROUTES.DASHBOARD}`
              );
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        `${constants.WA_BASE_ROUTE}/${uid}/${constants.WA_ROUTES.CAMPAIN_ATTRIBUTES}`
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default waScheduleSchema;
